import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Route, Switch, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { SERVICES } from '../../config';
import { useParams,useNavigate } from 'react-router-dom';
export const TinyUrlRedirect = () => {
  const { tinyUrl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const currentUrl = window.location.href;
        const response = await axios.get(
          `${SERVICES.apiBaseUrl}/api/tiny_urls?tiny_url=${currentUrl}`
        );
        
        const actualUrl = response?.data?.original_url;

        if (actualUrl) {
          window.location.href = actualUrl;
        } else {
          navigate('/not-found'); // Use navigate instead of history.push
        }
      } catch (error) {
        console.error('Error fetching the URL:', error);
        navigate('/not-found'); // Use navigate instead of history.push
      }
    };

    fetchUrl();
  }, [tinyUrl]);

  return <div>Redirecting...</div>;
};