import { Controller } from "react-hook-form";
import { Input } from ".";

const YearInput = ({ control }: any) => (
  <Controller
    name="year"
    control={control}
    render={({ field, fieldState: { error } }) => (
      <Input
        {...field}
        placeholder="Enter Year"
        label="Year"
        type="number"
        maxLength={4}
        error={error?.message || ""}
        onInput={(e: any) => {
          e.target.value = e.target.value.slice(0, 4);
        }}
      />
    )}
  />
);

export default YearInput;
