import MeetingContent from "./MeetingContent";
import {
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";

const StartMeeting = ({ clickedRow}:any) => {
  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="1rem"
        h="100%"
        w="100%"
      >
        <Text textStyle="h4" fontWeight="500">
          Meetings
        </Text>
        <Tabs variant="primary" w="100%">
          <TabList>
            <Tab>Sales Meeting</Tab>
            <Tab>Service Meeting</Tab>
          </TabList>
          <TabPanels>
            <TabPanel mt="1rem">
             <MeetingContent tab={'sales'} contactid={clickedRow} is_sale_meeting={true}/>
            </TabPanel>
            <TabPanel mt="1rem">
             <MeetingContent tab={'service'}contactid={clickedRow} is_sale_meeting={false}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      {/* <HStack>
        <Button alignSelf="flex-end">Save Progress</Button>
      </HStack> */}
    </VStack>
  );
};

export default StartMeeting;
