import { Box, useToast } from "@chakra-ui/react";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { useMutation } from "@tanstack/react-query";
import { getVideoToken } from "../../../utils/api/lead.api";
import ForbiddenPage from "../../../components/NotFound/NotFound";
import Loader from "../../../components/common/Spinner";

export default function VideoChat() {
  const apiRef = useRef<any>();
  const [params] = useSearchParams();
  const [logItems, updateLog] = useState<any>([]);
  const [jwtToken, setJwtToken] = useState("");
  const [knockingParticipants, updateKnockingParticipants] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const user = useUserInfo("user");
  const toast = useToast();
  const contactId = params.get("contactId");

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async () => {
      try {
        const response = await getVideoToken({
          dealershipId: user.dealership.id,
          userId: user.id,
          contactId: params.get("contactId"),
        });

        setJwtToken(response.data.jwt_token || "");
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onError: (error: any) => {
      toast({
        description: `Error initiated meeting: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      navigate("/leads/lead-details");
    },
  });

  const handleMeetingInitiation = useCallback(async () => {
    await mutation.mutateAsync({});
  }, [mutation]);

  useEffect(() => {
    if (!user?.dealership?.id || !user?.id || !contactId) {
      navigate("/leads/lead-details");
      return;
    }

    handleMeetingInitiation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, user?.dealership?.id, user?.id]);

  const dealership = useUserInfo("dealership");

  const handleKnockingParticipant = (payload: any) => {
    updateLog((items: any) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants: any) => [
      ...participants,
      payload?.participant,
    ]);
  };

  const handleReadyToClose = () => {
    console.log("close video chat");
    navigate("/leads/lead-details");
  };

  const generateRoomName = () =>
    `SimpSocial-${params.get("contactId")}-${dealership.id} - ${Date.now()}`;

  const handleAudioStatusChange = (payload: any, feature: any) => {
    if (payload.muted) {
      updateLog((items: any) => [...items, `${feature} off`]);
    } else {
      updateLog((items: any) => [...items, `${feature} on`]);
    }
  };

  const printEventOutput = (payload: any) => {
    updateLog((items: any) => [...items, JSON.stringify(payload)]);
  };

  const handleChatUpdates = (payload: any) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items: any) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  };

  const handleApiReady = (apiObj: any) => {
    apiRef.current = apiObj;
    apiRef?.current?.on("knockingParticipant", handleKnockingParticipant);
    apiRef?.current?.on("audioMuteStatusChanged", (payload: any) =>
      handleAudioStatusChange(payload, "audio")
    );
    apiRef.current.on("videoMuteStatusChanged", (payload: any) =>
      handleAudioStatusChange(payload, "video")
    );
    apiRef.current.on("raiseHandUpdated", printEventOutput);
    apiRef.current.on("titleViewChanged", printEventOutput);
    apiRef.current.on("chatUpdated", handleChatUpdates);
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
  };

  const handleJitsiIFrameRef1 = (iframeRef: any) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "calc(80vh)";
    iframeRef.style.marginBottom = "20px";
  };

  if (!jwtToken) {
    return (
      <>
        {mutation.isLoading && <Loader />}
        <ForbiddenPage />
      </>
    );
  }

  return (
    <Box>
      <JaaSMeeting
        appId={"vpaas-magic-cookie-9153434df10a46599fe4a71fd17a50dc"}
        roomName={generateRoomName()}
        lang="en"
        onApiReady={(externalApi) => handleApiReady(externalApi)}
        onReadyToClose={handleReadyToClose}
        getIFrameRef={handleJitsiIFrameRef1}
        jwt={jwtToken}
      />
    </Box>
  );
}
