"use client";
import React, { useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
// import { useRouter } from "next/navigation";
import { GlobalSidebarMenu } from "../../config/constants/globalSidebar";
import { useNavigate } from "react-router-dom";
import { getAdminPermissions } from "../../utils/api/usersetting.api";
import { useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import TooltipIfOverflow from "./TooltipIfOverflow";

interface SidebarProps {
  pathname: string;
}

const GlobalSidebar = ({ pathname }: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  // const router = useRouter();
  const navigate = useNavigate();

  const [currentPath, setCurrentPath] = useState<string>();
  const [list, setList] = useState<any>();
  const parts = pathname.split("/");
  const pagePath = parts[1];
  const usernameWithDashes = pathname?.split("/").pop();
  const usernameWithoutDashes =
    usernameWithDashes && usernameWithDashes?.replace(/-/g, " ");

  useEffect(() => {
    setCurrentPath(`/${pagePath}`);
  }, [pagePath]);

  const userID = useUserInfo("id");
  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["sidebar", userID],
    queryFn: getAdminPermissions,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setList(data?.data?.permissions);
    },
  });

  return (
    <Box
      minWidth={isCollapsed ? "4.25rem" : "16rem"}
      bg="white"
      // minH="100vh"
      borderRight="1px solid var(--grey-300, #E0E0E0)"
      transition="all 0.3s"
      position="relative"
      zIndex="2"
      marginTop="69px"
    >
      {/* Sidebar Content */}
      <VStack
        mt="1.5rem"
        spacing={0}
        height="calc(100vh - 169px)"
        overflowY="auto"
      >
        {GlobalSidebarMenu.filter((menuItem) => {
          const permission = list?.find(
            (perm: any) => ((perm.module_key === menuItem.key)||menuItem.key==='Intercom')
          );
          return permission && permission.allowed;
        }).map((menuItem) => {
          return (
            <Flex
              key={menuItem?.id}
              align="center"
              w="full"
              borderBottom="1px solid var(--grey-300)"
              background={
                ((currentPath === menuItem?.path)||(currentPath ==='/'&&menuItem.name==='Dashboard'))
                  ? "var(--primary-600)"
                  : "transparent"
              }
            >
              <Button
                leftIcon={
                  <Box
                    as="span"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="0.38rem"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    sx={{
                      svg: {
                        color:
                        ((currentPath === menuItem?.path)||(currentPath ==='/'&&menuItem.name==='Dashboard'))
                        ? "white"
                            : "var(--grey-800)",
                        path: {
                          fill:
                          ((currentPath === menuItem?.path)||(currentPath ==='/'&&menuItem.name==='Dashboard'))
                          ? "white"
                              : "var(--grey-800)",
                        },
                      },
                    }}
                  >
                    {menuItem?.icon}
                  </Box>
                }
                py="0.5rem"
                pl="1rem"
                pr="1rem"
                w="full"
                onClick={() => {
                  if(menuItem?.key==='Intercom'){
                    window.open('https://app.intercom.com/')

                  }else{
                  navigate(`${menuItem.path}`);
                  setCurrentPath(menuItem.path);
                  }
                }}
                variant="none"
                display="flex"
                alignItems="center"
                fontSize="0.875rem"
                fontWeight={500}
                color={
                  ((currentPath === menuItem?.path)||(currentPath ==='/'&&menuItem.name==='Dashboard'))
                  ? "white" : "var(--grey-800)"
                }
              >
                {!isCollapsed ? menuItem?.name : ""}
              </Button>
            </Flex>
          );
        })}
      </VStack>
    </Box>
  );
};

export default GlobalSidebar;
