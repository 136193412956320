import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getLeadWar = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/digital_lead_wars?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );
export const getLeadWarById = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/digital_lead_wars/${queryKey[2]}`
  );
export const createLeadWar = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.dId}/digital_lead_wars`,
    payload?.payload
  );
export const editLeadWar = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/digital_lead_wars/${payload.id}`,
    payload.payload
  );
export const activateLeadWarCampaign = async (payload: any) =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/digital_lead_wars/${payload.lead_id}/start_campaign?campaign_type=${payload.type}`,
    payload.payload
  );
export const downloadFile = async (id: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${id}/digital_lead_wars/export`,
    {
      responseType: "blob",
    }
  );
export const fetchContacts = async (dId: any, params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/digital_lead_wars/contact_list`,
    {
      params,
    }
  );
};
export const fetchMake = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/vehicles/make`
  );
};
export const fetchModel = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/vehicles/model?make=${queryKey[2]}`
  );
};
