import { HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Input from "../../../components/common/Input";

export default function AutoLoanCalculator() {
  const [sellingPrice, setSellingPrice] = useState("0");
  const [salesTaxRate, setSalesTaxRate] = useState("0");
  const [tax, setTax] = useState("0");
  const [dmvFees, setDmvFees] = useState("0");
  const [tradeAllowance, setTradeAllowance] = useState("0");
  const [tradePayoff, setTradePayoff] = useState("0");
  const [serviceContract, setServiceContract] = useState("0");
  const [downPayment, setDownPayment] = useState("0");
  const [rebate, setRebate] = useState("0");
  const [apr, setApr] = useState("0");
  const [term, setTerm] = useState("0");
  const [amountFinanced, setAmountFinanced] = useState("0");
  const [payment, setPayment] = useState("0");
  const [totalOfPayments, setTotalOfPayments] = useState("0");
  const [financeCharges, setFinanceCharges] = useState("0");
  const [totalCost, setTotalCost] = useState("0");

  const handleInputChange = (e: any, setter: any, field: string) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setter(value);
    }
  };

  useEffect(() => {
    const calculatedTax =
      (parseFloat(sellingPrice) +
        parseFloat(serviceContract) -
        parseFloat(tradeAllowance)) *
      (parseFloat(salesTaxRate) / 100);
    setTax(calculatedTax.toFixed(0));

    const calculatedAmountFinanced =
      parseFloat(sellingPrice) +
      calculatedTax +
      parseFloat(dmvFees) +
      parseFloat(serviceContract) +
      (parseFloat(tradeAllowance) - parseFloat(tradePayoff)) -
      parseFloat(downPayment) -
      parseFloat(rebate);
    setAmountFinanced(calculatedAmountFinanced.toFixed(0));

    const monthlyInterestRate = parseFloat(apr) / 12 / 100;
    const termValue = parseFloat(term);
    const calculatedPayment =
      (monthlyInterestRate * calculatedAmountFinanced) /
      (1 - Math.pow(1 + monthlyInterestRate, -termValue));
    setPayment(calculatedPayment.toFixed(2));

    const calculatedTotalOfPayments = calculatedPayment * termValue;
    setTotalOfPayments(calculatedTotalOfPayments.toFixed(2));

    const calculatedFinanceCharges =
      calculatedTotalOfPayments - calculatedAmountFinanced;
    setFinanceCharges(calculatedFinanceCharges.toFixed(2));

    const calculatedTotalCost =
      calculatedTotalOfPayments +
      parseFloat(downPayment) +
      parseFloat(rebate) +
      (parseFloat(tradeAllowance) - parseFloat(tradePayoff));
    setTotalCost(calculatedTotalCost.toFixed(2));
  }, [
    sellingPrice,
    salesTaxRate,
    tax,
    dmvFees,
    tradeAllowance,
    tradePayoff,
    serviceContract,
    downPayment,
    rebate,
    apr,
    term,
    amountFinanced,
    payment,
    totalOfPayments,
    financeCharges,
    totalCost,
  ]);

  const getValueForNan = (value: string) => (isNaN(Number(value)) ? 0 : value);

  const getValueForNanCommaSeparated = (value: string) => {
    const formattedValue = isNaN(Number(value)) ? 0 : value;
    return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <VStack
      w="100%"
      alignItems="center"
      justify="center"
      spacing={4}
      p="30px"
      background="white"
    >
      <VStack
        w="100%"
        alignItems="center"
        justify="center"
        pb="80px"
        background="white"
      >
        <Text textStyle="h3" fontWeight="1000" mb="20px">
          Auto Loan Calculator
        </Text>
        <HStack gap="75px">
          <VStack
            maxW="700px"
            w={"100%"}
            alignItems={"flex-start"}
            p="20px"
            // background="#f4eee6"
            background="#F5F5F5"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text textStyle="h4" fontWeight="800" mb="20px" w="100%">
              Enter your values
            </Text>
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Selling Price"
              label="Selling Price"
              value={sellingPrice}
              onChange={(e) =>
                handleInputChange(e, setSellingPrice, "sellingPrice")
              }
            />
            <HStack alignItems="center">
              <Input
                w="650px"
                h="40px"
                type="text"
                placeholder="Enter Sales Tax Rate (%)"
                label="Sales Tax Rate"
                value={salesTaxRate}
                onChange={(e) =>
                  handleInputChange(e, setSalesTaxRate, "salesTaxRate")
                }
              />
            </HStack>
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter DMV Fees"
              label="DMV Fees"
              value={dmvFees}
              onChange={(e) => handleInputChange(e, setDmvFees, "dmvFees")}
            />
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Trade Allowance"
              label="Trade Allowance"
              value={tradeAllowance}
              onChange={(e) =>
                handleInputChange(e, setTradeAllowance, "tradeAllowance")
              }
            />
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Trade Payoff"
              label="Trade Payoff"
              value={tradePayoff}
              onChange={(e) =>
                handleInputChange(e, setTradePayoff, "tradePayoff")
              }
            />
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Service Contract"
              label="Service Contract"
              value={serviceContract}
              onChange={(e) =>
                handleInputChange(e, setServiceContract, "serviceContract")
              }
            />
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Down Payment"
              label="Down Payment"
              value={downPayment}
              onChange={(e) =>
                handleInputChange(e, setDownPayment, "downPayment")
              }
            />
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Rebate"
              label="Rebate"
              value={rebate}
              onChange={(e) => handleInputChange(e, setRebate, "rebate")}
            />
            <HStack>
              <Input
                w="650px"
                h="40px"
                type="text"
                placeholder="Enter APR (%)"
                label="APR"
                value={apr}
                onChange={(e) => handleInputChange(e, setApr, "apr")}
              />
            </HStack>
            <Input
              w="650px"
              h="40px"
              type="text"
              placeholder="Enter Term"
              label="Term"
              value={term}
              onChange={(e) => handleInputChange(e, setTerm, "term")}
            />
          </VStack>
          <VStack
            w="600px"
            spacing={2}
            alignItems="flex-start"
            p="20px"
            // background="#d1e1e9"
            background="var(--primary-500)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
          >
            <Text
              textStyle="h4"
              fontWeight="800"
              mb="20px"
              w="100%"
              color="white"
            >
              Your Estimated Loan is
            </Text>
            <VStack w="100%">
              <HStack
                w="full"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Tax
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  {getValueForNan(tax)}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Amount Financed
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  {getValueForNan(amountFinanced)}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Payment
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  ${getValueForNanCommaSeparated(payment)}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Total of Payments
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  ${getValueForNanCommaSeparated(totalOfPayments)}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Finance Charges
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  ${getValueForNanCommaSeparated(financeCharges)}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                borderColor="#c1c4c9"
                pb={1}
              >
                <Text textStyle="h4" fontWeight="300" color="white">
                  Total Cost
                </Text>
                <Text textStyle="h4" fontWeight="600" color="white">
                  ${getValueForNanCommaSeparated(totalCost)}
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  );
}
