"use client";

import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import BroadcastMessage from "../../../components/BroadcastMessage";
import BroadcastRVM from "../../../components/BroadcastRVM";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function BroadcastMessagePage() {
  const [active, setActive] = useState(0);
  const [searchParam] = useSearchParams();
  const activeTab = searchParam.get("active");

  useEffect(() => {
    if (activeTab) {
      setActive(Number(activeTab ?? 0));
    }
  }, [activeTab]);

  return (
    <>
      <Tabs
        variant="primary"
        isLazy
        index={active}
        onChange={(index: number) => setActive(index)}
      >
        <HStack w="100%" justifyContent="space-between" mb="1rem">
          <TabList w="max-content">
            <Tab padding="1rem" w="11rem">
              Message
            </Tab>
            <Tab padding="1rem" w="11rem">
              RVM
            </Tab>
          </TabList>
        </HStack>

        <TabPanels height="100%" overflow="auto">
          <TabPanel h="100%">
            <BroadcastMessage />
          </TabPanel>
          <TabPanel h="100%">
            <BroadcastRVM />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
