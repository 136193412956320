// themes/stepper.ts

import { extendTheme } from "@chakra-ui/react";

const baseStyle = {
  // select the indicator part
  indicator: {
    // change the default border radius to 0
    borderRadius: 0,
    // background: "black",
    width: "100%",
    padding: "2rem 1rem",
  },
  step: {
    flex: "1 !important",
  },
  stepper: {
    width: "100%",
    gap: "0",
  },
};

let stepperTheme = {};

export default stepperTheme = {
  baseStyle,
};
