import * as yup from "yup";

export type startMeetingForm = {
  user: { value: string; label: string };
  notes: string;
};

export const defaultValues = {
  user: { value: '', label: '' },
  notes: "",
};

export const startMeetingFormValidation = yup.object().shape({
  user: yup.object().shape({
    value: yup.string().required("User name is required"),
    label: yup.string() // Add additional validation for label if needed
  }),
});
