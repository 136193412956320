import { Box, Text } from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { loadDealershipsApi } from "../../../../utils/api/users.api";

type DealershipDropdownProps = {
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
  dealerships?: SelectOptionProps | SelectOptionProps[];
  setDealerships?: any;
};
export default function DealershipDropdown(props: DealershipDropdownProps) {
  const {
    label,
    isMulti = false,
    placeholder,
    dealerships,
    setDealerships,
  } = props;

  const loadDealerships = async (inputValue: string) => {
    try {
      const response = await loadDealershipsApi({
        status: "active",
        searchValue: inputValue,
      });
      const options = response?.dealerships?.map((dealership: any) => ({
        value: dealership.id.toString(),
        label: dealership.name,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  return (
    <>
      {label && (
        <Text
          textStyle="h4"
          fontWeight="600"
          fontSize="0.875rem"
          color="var(--grey-900)"
        >
          {label}
        </Text>
      )}
      <Box>
        <AsyncSelect
          loadOptions={(inputValue) => loadDealerships(inputValue)}
          onChange={(value: any) => setDealerships(value)}
          placeholder={placeholder ?? "Enter the dealerships"}
          isMulti={isMulti}
          value={dealerships}
          defaultOptions
          className="delaership-dropsdown"
          styles={{
            control: (provided) => ({
              ...provided,
              minWidth: "128px",
              maxWidth: "100%",
              padding: "2px 4px",
              borderRadius: "0.5rem",
              border: `1px solid var(--grey-300)`,
              // marginTop: "-10px",
              fontSize: "0.875rem",
              fontWeight: "500",
              lineHeight: "1rem",
              color: "#000",
              display: "flex",
              background: "transparent",
            }),
          }}
        />
      </Box>
    </>
  );
}
