import { login } from "../../api/login.api";
interface LoginPayload {
  // Define the structure of your login payload
  // For example, if it contains a username and password:
  user:{
  email: string;
  password: string;
  },
}

export async function loginUser(dispatch: React.Dispatch<any>, loginPayload: LoginPayload ) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });

    let response = await login(loginPayload);
    if (response.data) {
      if(response.data.two_factor_enabled){
        dispatch({ type: '2FA' });
      }
      else{
      const authorizationHeader = response.headers.get('Authorization')
      const token = authorizationHeader.split(' ')[1];
      dispatch({ type: 'LOGIN_SUCCESS', payload: response.data,token:token });
      let data = response?.data?.status?.data?.user;

      // Append token to user data
      data.token = token;

      // Store the updated user data in localStorage
      localStorage.setItem('currentUser', JSON.stringify(data));
      }
    } else {
      console.log(response?.data?.errors[0])
      dispatch({ type: 'LOGIN_ERROR', error: response?.data?.errors[0] });
    }
  } catch (err:any) {
    dispatch({ type: 'LOGIN_ERROR', error: err?.response?.data?.error || 'Something went wrong, Please try again',});

  }
}

export async function resetLogin(dispatch: React.Dispatch<any>) {
  dispatch({ type: 'RESET_LOGIN' });
}

export async function logout(dispatch: React.Dispatch<any>) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}

export function update(dispatch: React.Dispatch<any>, data: { key: string; value: any }, shouldUpdateRole=false) {
  dispatch({ type: 'UPDATE_STORE', data });
}

export function updateAdminRole(dispatch: React.Dispatch<any>, adminRole: string) {
  dispatch({ type: 'UPDATE_ADMIN_ROLE', adminRole });
}
