import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { fetchVariables } from "../../../utils/api/sms-templates.api";
import { Input, PaginatedTable, Spinner } from "../../common";
import { SearchIcon } from "../../icons/svg";
import UpdateMapping from "./UpdateMapping";

const Variable = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);

  const tableHeader = [
    { value: "Database Fields", label: "dbFrields" },
    { value: "Variable Name", label: "varName" },
  ];
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchVariables", searchValue, page, perPage],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchVariables(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.variables_count ?? 0);
    },
  });

  const tableData = data?.variables?.map((variable: any) => {
    return {
      dbFrields: variable?.field,
      varName: <Text>{variable?.name}</Text>,
    };
  });
  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search..."
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
          </HStack>
          <Button onClick={onOpen}>Update Mapping</Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Variables ({count})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={count}
            />
          </Box>
        </VStack>
        {isOpen && (
          <UpdateMapping
            isOpen={isOpen}
            onClose={onClose}
            refetchParent={refetch}
          />
        )}
        {isLoading && <Spinner />}
      </VStack>
    </>
  );
};

export default Variable;
