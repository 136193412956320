import {
  Box,
  Button,
  DrawerFooter,
  FormLabel,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { createContact, getContactByPhoneNo } from "../../utils/api/lead.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import {
  loadLeadSource,
  loadTags,
} from "../AccountSettings/LeadCenterSettings/utils";
import { Drawer, Input, PhoneInput } from "../common";
import SwitchToggle from "../common/Switch";
import {
  CreateLeadForm,
  createLeadValidation,
  defaultValues,
  loadDealershipUsers,
  loadVehicles,
} from "./utils";
import { createCoBuyers } from "../../utils/api/co-buyer.api";
import Popup from "../Charts/Popup";

type CreateNewLeadProps = {
  isOpen: boolean;
  onClose: () => void;
  contactId?: any; // if exists then it means that it is from co-buyer
};

function CreateNewLead({
  isOpen,
  onClose,
  contactId = null,
}: CreateNewLeadProps) {
  const toast = useToast();

  const [currentForm, setCurrentForm] = useState(0);
  const [alreadyCreated, setAlreadyCreated] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [contact, setContact] = useState<any>(null);
  const { handleSubmit, control, setValue, trigger, getValues } =
    useForm<CreateLeadForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(createLeadValidation) as any,
      mode: "all",
    });

  const navigate = useNavigate();

  const addInLeadPool = useWatch({
    control,
    name: "leads_attributes.new_lead_pool",
  });

  const inventoryId = useWatch({
    control,
    name: "leads_attributes.vehicle_attributes.inventory_id",
  });

  const dealership = useUserInfo("dealership");

  const contactMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await getContactByPhoneNo({
          id: dealership.id,
          phoneNumber: getValues("phone_number"),
        });

        return { contact: response.data };
      } catch (error) {
        throw error;
      }
    },
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        let response: any;
        if (contactId) {
          response = await createCoBuyers({
            dId: dealership.id,
            id: contactId,
            payload,
          });
        } else {
          response = await createContact({
            id: dealership.id,
            payload,
          });
        }

        navigate(`/leads/lead-details?contact_id=${response.data.id}`);
      } catch (error) {
        return { contact: undefined };
      }
    },
    onSuccess: () => {
      toast({
        description: `${
          contactId ? "Co-Buyer" : alreadyCreated ? "Lead" : "Contact"
        } created successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    },
    onError: (error: any) => {
      toast({
        description: `Error Creating: ${
          contactId ? "Co-Buyer" : alreadyCreated ? "Lead" : "Contact"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleFormSubmit = async (values: CreateLeadForm) => {
    const vehicle_attributes =
      !!(values.leads_attributes.vehicle_attributes?.inventory_id as any).id ||
      !!values.leads_attributes.vehicle_attributes?.year
        ? {
            vehicle_attributes: {
              ...values.leads_attributes.vehicle_attributes,
              inventory_id: (
                values.leads_attributes.vehicle_attributes?.inventory_id as any
              ).id,
            },
          }
        : {};

      delete values.leads_attributes.vehicle_attributes

    const payload = {
      ...values,
      leads_attributes: [
        {
          ...values.leads_attributes,
          lead_source: (values.leads_attributes.lead_source as any).value,
          dealership_id: dealership?.id,
          ...vehicle_attributes,
        },
      ],
      sales_person_id: (values.sales_person_id as any).value,
      manager_id: (values.manager_id as any).value,
      bdc_agent_id: (values.bdc_agent_id as any).value,
      contacts_tags_attributes: values.contacts_tags_attributes.map(
        (tag) => (tag as any).value
      ),
    };

    try {
      const contact = await mutation.mutateAsync({ contact: payload });
      navigate(`/leads/lead-details?contact_id=${contact.id}`);
    } catch (error) {}
  };
  const handleSubmitCoBuyer = () => {
    const payload = {
      first_name: contact?.first_name,
      last_name: contact?.last_name,
      phone_number: contact?.phone_number,
      cobuyer_id: contact?.id || null,
    };

    mutation.mutate({ contact: payload });
  };

  const id = useUserInfo("dealership")?.id;

  const handleNext = async () => {
    const isValid = await trigger("phone_number");
    if (!isValid) return;

    try {
      const data = await contactMutation.mutateAsync({});
      if (Object.keys(data.contact || {}).length) {
        setContact(data.contact);
        return setAlreadyCreated(true);
      } else {
        setCurrentForm(1);
        return setAlreadyCreated(false);
      }
    } catch (error) {}

    setCurrentForm(1);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={contactId ? "Create a New Co-Buyer" : "Create a New Lead"}
    >
      <VStack padding="1rem" alignItems="flex-start" w="100%">
        {currentForm ? (
          <VStack
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            gap="1rem"
          >
            <HStack w="100%">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="First Name"
                      type="text"
                      value={value}
                      onChange={onChange}
                      isRequired={true}
                      error={error?.message}
                    />
                  );
                }}
                name="first_name"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Last Name"
                      type="text"
                      value={value}
                      onChange={onChange}
                      isRequired={true}
                      error={error?.message}
                    />
                  );
                }}
                name="last_name"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </HStack>
            <HStack w="100%">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Email Address"
                      type="text"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      w="100%"
                    />
                  );
                }}
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
              />

              {!contactId && (
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Box>
                        <FormLabel
                          mb="0.31rem"
                          color="var(--grey-900)"
                          fontSize="0.875rem"
                          fontWeight="600"
                          lineHeight="1rem"
                        >
                          Lead Source (Optional)
                        </FormLabel>
                        <AsyncCreatableSelect
                          loadOptions={(searchStr) =>
                            loadLeadSource(searchStr, id)
                          }
                          defaultOptions
                          value={value}
                          onChange={(value: any) => {
                            setValue(`leads_attributes.lead_source`, value);
                          }}
                          placeholder="Lead Source"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "100%",
                              width: 220,
                            }),
                          }}
                        />
                      </Box>
                    );
                  }}
                  name="leads_attributes.lead_source"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              )}
            </HStack>

            {!contactId && (
              <>
                <HStack w="100%">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box mb="1.5rem">
                          <SwitchToggle
                            label="Add in New Lead Pool"
                            id="#untagged-calls"
                            defaultChecked={value}
                            onChange={(checked: boolean) => {
                              setValue(
                                `leads_attributes.new_lead_pool`,
                                checked
                              );
                              setValue("bdc_agent_id", "");
                              setValue("manager_id", "");
                              setValue("sales_person_id", "");
                            }}
                          />
                        </Box>
                      );
                    }}
                    name={`leads_attributes.new_lead_pool`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                <HStack w="100%">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box>
                          <FormLabel
                            mb="0.31rem"
                            color="var(--grey-900)"
                            fontSize="0.875rem"
                            fontWeight="600"
                            lineHeight="1rem"
                          >
                            Sales Person
                          </FormLabel>
                          <AsyncSelect
                            loadOptions={(searchStr) =>
                              loadDealershipUsers(searchStr, "Sales Person", id)
                            }
                            defaultOptions
                            value={value}
                            onChange={(value: any) => {
                              setValue(`sales_person_id`, value);
                            }}
                            placeholder="Select Agent"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                                width: 220,
                              }),
                            }}
                            isDisabled={addInLeadPool}
                          />
                        </Box>
                      );
                    }}
                    name="sales_person_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box>
                          <FormLabel
                            mb="0.31rem"
                            color="var(--grey-900)"
                            fontSize="0.875rem"
                            fontWeight="600"
                            lineHeight="1rem"
                          >
                            BDC Agent
                          </FormLabel>
                          <AsyncSelect
                            loadOptions={(searchStr) =>
                              loadDealershipUsers(searchStr, "BDC Agent", id)
                            }
                            defaultOptions
                            value={value}
                            onChange={(value: any) => {
                              setValue(`bdc_agent_id`, value);
                            }}
                            placeholder="Select Agent"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                                width: 220,
                              }),
                            }}
                            isDisabled={addInLeadPool}
                          />
                        </Box>
                      );
                    }}
                    name="bdc_agent_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>
                <HStack
                  w="100%"
                  borderBottom="1px solid var(--grey-300)"
                  pb="1rem"
                >
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box>
                          <FormLabel
                            mb="0.31rem"
                            color="var(--grey-900)"
                            fontSize="0.875rem"
                            fontWeight="600"
                            lineHeight="1rem"
                          >
                            Manager/Admin
                          </FormLabel>
                          <AsyncSelect
                            loadOptions={(searchStr) =>
                              loadDealershipUsers(
                                searchStr,
                                "Manager/Admin",
                                id
                              )
                            }
                            defaultOptions
                            value={value}
                            onChange={(value: any) => {
                              setValue(`manager_id`, value);
                            }}
                            placeholder="Select User"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                                width: 220,
                              }),
                            }}
                            isDisabled={addInLeadPool}
                          />
                        </Box>
                      );
                    }}
                    name="manager_id"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box>
                          <FormLabel
                            mb="0.31rem"
                            color="var(--grey-900)"
                            fontSize="0.875rem"
                            fontWeight="600"
                            lineHeight="1rem"
                          >
                            Tags
                          </FormLabel>
                          <AsyncSelect
                            loadOptions={(searchStr) => loadTags(searchStr, id)}
                            defaultOptions
                            value={value}
                            onChange={(value: any) => {
                              setValue(`contacts_tags_attributes`, value);
                            }}
                            isMulti
                            placeholder="Select Tags"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                                width: 220,
                              }),
                            }}
                          />
                        </Box>
                      );
                    }}
                    name="contacts_tags_attributes"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                <Text textStyle="h3" color="var(--grey-900)" fontWeight="600">
                  Vehicle (Optional)
                </Text>

                <HStack w="100%" pb="1rem">
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Box>
                          <AsyncSelect
                            loadOptions={(searchStr) =>
                              loadVehicles(searchStr, id)
                            }
                            defaultOptions
                            value={value}
                            onChange={(value: any) => {
                              setValue(
                                `leads_attributes.vehicle_attributes.inventory_id`,
                                value
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.exterior_color",
                                ""
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.interior_color",
                                ""
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.make",
                                ""
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.model",
                                ""
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.year",
                                ""
                              );
                              setValue(
                                "leads_attributes.vehicle_attributes.mileage",
                                ""
                              );
                            }}
                            placeholder="Select From Inventory"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                                width: 450,
                              }),
                            }}
                            isClearable
                          />
                        </Box>
                      );
                    }}
                    name={`leads_attributes.vehicle_attributes.inventory_id`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>
                {!(inventoryId as any)?.value && (
                  <>
                    <HStack w="100%" gap="1.5rem">
                      <Box
                        as="span"
                        width="100%"
                        height="1px"
                        background="var(--grey-300)"
                      ></Box>
                      <Box
                        as="span"
                        fontSize="1.125rem"
                        fontWeight="600"
                        color="var(--secondary-600)"
                        borderRadius="6.25rem"
                        background="var(--secondary-50)"
                        padding="0.31rem 1.19rem"
                      >
                        Or
                      </Box>

                      <Box
                        as="span"
                        width="100%"
                        height="1px"
                        background="var(--grey-300)"
                      ></Box>
                    </HStack>

                    <HStack w="100%">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Year"
                              type="number"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.year"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />

                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Make"
                              type="text"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.make"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                    </HStack>

                    <HStack w="100%">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Model"
                              type="text"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.model"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />

                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Exterior Color"
                              type="text"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.exterior_color"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                    </HStack>

                    <HStack w="100%">
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Interior Color"
                              type="text"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.interior_color"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />

                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              label="Mileage"
                              type="text"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isDisabled={!!(inventoryId as any)?.value}
                            />
                          );
                        }}
                        name="leads_attributes.vehicle_attributes.mileage"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                    </HStack>
                  </>
                )}
              </>
            )}
          </VStack>
        ) : (
          <VStack
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            gap="1rem"
          >
            {!alreadyCreated ? (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Box mt="0.5rem" w="100%">
                      <PhoneInput
                        label="Phone Number"
                        placeholder="Enter phone number"
                        onChange={onChange}
                        isRequired
                        value={value}
                        error={error?.message}
                        w="100%"
                        // padding="9px 8px"
                      />
                    </Box>
                  );
                }}
                name="phone_number"
                control={control}
                rules={{
                  required: true,
                }}
              />
            ) : (
              <>
                <HStack
                  alignItems="flex-start"
                  justifyContent="space-between"
                  background="var(--grey-200)"
                  padding="1rem"
                  borderTopRightRadius="0.5rem"
                  borderTopLeftRadius="0.5rem"
                  borderBottom="1px solid var(--grey-300)"
                  w="100%"
                  cursor={"pointer"}
                  onClick={() => {
                    if (contactId) {
                      setIsOpenPopup(true);
                    } else {
                      navigate(`/leads/lead-details?contact_id=${contact.id}`);
                    }
                  }}
                >
                  <HStack alignItems="flex-start" gap="0.69rem">
                    <VStack alignItems="flex-start" gap="0">
                      <Text textStyle="h4" fontWeight="600">
                        {contact?.first_name} {contact?.last_name}
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="400"
                        lineHeight="1.25rem"
                      >
                        {contact?.phone_number}
                        <Box color="var(--primary-900)">{contact?.email}</Box>
                      </Text>
                    </VStack>
                  </HStack>
                </HStack>
              </>
            )}
          </VStack>
        )}
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        {!currentForm ? (
          !alreadyCreated ? (
            <Button w="100%" onClick={() => handleNext()}>
              Next
            </Button>
          ) : (
            <></>
          )
        ) : (
          <>
            <Button w="100%" onClick={() => setCurrentForm(0)}>
              Back
            </Button>
            <Button
              w="100%"
              onClick={() =>
                handleSubmit((values) => handleFormSubmit(values))()
              }
            >
              Save
            </Button>
          </>
        )}
      </DrawerFooter>
      {isOpenPopup && contactId && (
        <Popup
          isOpen={true}
          onClose={() => {
            setIsOpenPopup(false);
          }}
          onConfirm={() => {
            handleSubmitCoBuyer();
            setIsOpenPopup(false);
          }}
          title="Add Co-Buyer Confirmation"
          message="Are you sure you want to add this contact as a co-buyer?"
        />
      )}
    </Drawer>
  );
}

export default CreateNewLead;
