import React, { useEffect, useState } from "react";
import { Drawer, Input } from "../common";
import { Button, DrawerFooter, Text, VStack } from "@chakra-ui/react";
import { Notes } from "../../utils/types";

type NotesDrawerProps = {
  open: boolean;
  onClose: () => void;
  handleAddNotes: (notes: string) => void;
  isLoading?: boolean;
  notes?: Notes[];
};

function NotesDrawer({
  open,
  onClose,
  handleAddNotes,
  isLoading,
  notes: paramNotes,
}: NotesDrawerProps) {
  const [notes, setNotes] = useState("");

  return (
    <Drawer isOpen={open} onClose={onClose} title="Add Notes">
      <VStack padding="1rem 1.5rem" alignItems="flex-start" w="100%">
        <VStack
          padding="0.5rem"
          alignItems="flex-start"
          w="100%"
          borderRadius="0.5rem"
          background="var(--grey-50)"
        >
          <Input
            label="Add Notes"
            placeholder="Type here..."
            isTextarea
            value={notes}
            onChange={({ target: { value } }) => setNotes(value)}
          />
        </VStack>

        {paramNotes && paramNotes?.length && (
          <VStack
            padding="0.5rem"
            alignItems="flex-start"
            w="100%"
            borderRadius="0.5rem"
            background="var(--grey-50)"
          >
            <ul>
              {paramNotes?.map((note) => {
                return <Text>• {note.content}</Text>;
              })}
            </ul>
          </VStack>
        )}
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl">
          Cancel
        </Button>
        <Button
          w="100%"
          size="xl"
          disabled={!notes}
          onClick={() => handleAddNotes(notes)}
        >
          Add Notes
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default NotesDrawer;
