import {  Select } from "../../../components/common";
import SwitchToggle from "../../../components/common/Switch";
import { Box, Button, HStack, Text, Tooltip } from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { get24HourTimeArray } from "../../../utils";
export const DayComponent = ({
  day,
  data,
  onToggle,
  onSelectTime,
  onCopy,
  onPaste,
}: any) => {
  return (
    <HStack justifyContent="space-between" w="100%">
      <Box minW="22%">
        <SwitchToggle
          id={day}
          onChange={(check) => onToggle(day, check)}
          label={day.charAt(0).toUpperCase() + day.slice(1)}
          defaultChecked={data.day_status}
        />
      </Box>
      <HStack>
        <Select
          label=""
          onChange={(value) => onSelectTime(day, "start_time", value.value)}
          placeholder="Select Time"
          options={get24HourTimeArray()} // Assuming 'options' is defined in your component or passed as props
          w="11rem"
          value={data.start_time || ""}
          variant="default"
          isDisabled={!data.day_status}
        />
        <Text fontWeight="500">To</Text>
        <Select
          label=""
          onChange={(value) => onSelectTime(day, "end_time", value.value)}
          placeholder="Select Time"
          options={get24HourTimeArray()}
          w="11rem"
          value={data.end_time || ""}
          variant="default"
          isDisabled={!data.day_status}
        />
      </HStack>
      <Tooltip
                label={
                  <Text>Copy</Text>
                }
                background="var(--bluegray-900)"
                border="none"
                borderRadius="0.5rem"
                padding="0.75rem"
                hasArrow
                w="max-content"
                placement="top"
              >
      <Button variant="outline" padding="1.1rem 0.5rem" onClick={onCopy}>
        <ContentCopyIcon
          style={{
            color: "var(--primary-600)",
            fontSize: "1.25rem",
          }}
        />
      </Button>
      </Tooltip>
      <Tooltip
                label={
                  <Text>Paste</Text>
                }
                background="var(--bluegray-900)"
                border="none"
                borderRadius="0.5rem"
                padding="0.75rem"
                hasArrow
                w="max-content"
                placement="top"
              >
      <Button variant="outline" padding="1.1rem 0.5rem" onClick={onPaste}>
        <ContentPasteIcon
          style={{
            color: "var(--primary-600)",
            fontSize: "1.25rem",
          }}
        />
      </Button>
      </Tooltip>
    </HStack>
  );
};
