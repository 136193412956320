import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import BrandListing from "../../../components/GlobalDashboard/BrandListing/BrandListing";
import CampaignListing from "../../../components/GlobalDashboard/CampaignListing/CampaignListing";

export default function CampaignRegisterationPage() {
  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          isLazy
        >
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Brand Registration</Tab>
            <Tab w="15rem">Campaign Registration</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              <Tabs variant="vertical" display="flex" height="100%" isLazy>
                <TabList w="15rem">
                  <Text
                    p="1rem"
                    textStyle="h6"
                    fontWeight="600"
                    color="var(--grey-500)"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    Brand Registration
                  </Text>
                  <Tab>Not Submitted</Tab>
                  <Tab>Approved</Tab>
                  <Tab>Pending</Tab>
                  <Tab>Rejected</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <BrandListing status="not_submitted" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="approved" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="pending" />
                  </TabPanel>
                  <TabPanel>
                    <BrandListing status="rejected" />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>

            <TabPanel w="100%" h="100%">
              <Tabs variant="vertical" display="flex" height="100%" isLazy>
                <TabList width="15rem">
                  <Text
                    p="1rem"
                    textStyle="h6"
                    fontWeight="600"
                    color="var(--grey-500)"
                    borderBottom="1px solid var(--grey-300)"
                    w="100%"
                  >
                    Campaign Registration
                  </Text>
                  <Tab>Not Submitted</Tab>
                  <Tab>Approved</Tab>
                  <Tab>Pending</Tab>
                  <Tab>Rejected</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <CampaignListing status="not_submitted" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="approved" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="pending" />
                  </TabPanel>
                  <TabPanel>
                    <CampaignListing status="rejected" />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
