import * as yup from "yup";
import { EMAIL_REGEX } from "../../../config";

export type SendEmailForm = {
  from: string;
  to: string;
  subject: string;
  cc: string[];
  bcc: string[];
  messageBody: string;
};

export const defaultValues = {
  from: "",
  to: "",
  subject: "",
  cc: [],
  bcc: [],
  messageBody: "",
};

export const sendEmailFormValidation = yup.object().shape({
  to: yup
    .string()
    .nullable()
    .matches(EMAIL_REGEX, "Invalid Email Address")
    .required("Recipient is required"),
});
