import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, PaginatedTable } from "../../../components/common";
import { deleteScript, getScripts } from "../../../utils/api/scripts.api";
import Popup from "../../Charts/Popup";
import CreateNewScript from "./CreateNewScript";
const Scripts = () => {
  const toast = useToast();
  const { id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [editData, setEditData] = useState<any>();
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["scriptsapi", id, page, perPage],
    queryFn: getScripts,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const deletemutation = useMutation(deleteScript);
  const deleteScriptCall = () => {
    deletemutation.mutate(
      {
        id: id,
        script_id: editData?.id,
      },
      {
        onSuccess: () => {
          refetch();
          onClosePopupOpen();
          toast({
            description: "Script deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const options: SelectOptionProps[] = [
    {
      label: "Edit",
      value: "edit",
    },
    {
      label: "Delete",
      value: "delete",
    },
  ];
  const tableHeader = [
    { id: 1, value: "Title", label: "title" },
    { id: 2, value: "Text", label: "description" },
    { id: 3, value: "Category", label: "script_type" },
    { id: 4, value: "Actions", label: "actions" },
  ];
  const handleSelect = (option: SelectOptionProps) => {
    if (option.value === "delete") {
      setPopup({
        name: "Delete Script",
        description: "Are you sure you want to delete this script?",
        value: option.value,
      });
      onOpenPopupOpen();
    } else if (option.value === "edit") {
      onOpen();
    }
  };
  const tableData = data?.data?.scripts?.map((item: any) => ({
    id: item?.id,
    title: item?.title,
    description: item?.description,
    script_type: item?.script_type,
    actions: <Dropdown options={options} onSelect={handleSelect} />,
  }));

  return (
    <Box>
      <HStack justifyContent="space-between">
        <Text textStyle="h4" fontWeight="500">
          Scripts can be displayed to reps and agents while they are on a call
          with a customer.
        </Text>
        <Button
          onClick={() => {
            setEditData(undefined);
            onOpen();
          }}
        >
          Create Script
        </Button>
      </HStack>
      <Text textStyle="h6" color=" var(--grey-600)">
        Scripts Found {data?.data?.total_count}
      </Text>
      <Box mt="1rem">
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          // hasPadding={false}
          lastCenter={false}
          rowOnClick={(rowItem) => {
            setEditData(rowItem);
          }}
          isLoadingData={isLoading}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={data?.data?.total_count}
        />
      </Box>
      {isOpen && (
        <CreateNewScript
          isOpen={isOpen}
          onClose={onClose}
          editData={editData}
          refetch={refetch}
        />
      )}
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={deleteScriptCall}
          title={popup.name}
          message={popup.description}
          loading={deletemutation?.isLoading}
        />
      )}
    </Box>
  );
};

export default Scripts;
