import React from "react";
import {
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  StackDirection,
} from "@chakra-ui/react";

interface CustomRadioProps {
  value?: string;
  setValue?: (value: string) => void;
  name?: string;
  options: SelectOptionProps[];
  label?: string | React.ReactNode;
  alignment?: string;
  size?: string;
  direction?: StackDirection;
}

const CustomRadio = ({
  value,
  setValue,
  name,
  options,
  label = "",
  alignment = "center",
  size = "md",
  direction = "row",
}: CustomRadioProps) => {
  return (
    <RadioGroup defaultValue="1" name={name} onChange={setValue} value={value}>
      {label !== "" && (
        <FormLabel
          mb="0.61rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          {label}
        </FormLabel>
      )}
      <Stack direction={direction} flexWrap="wrap">
        {options?.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            checked={option.value === value}
            size={size ? size : label ? "lg" : "md"}
            alignItems={alignment}
          >
            {option.label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default CustomRadio;
