export const countries = [
  { value: "US", label: "USA" },
  { value: "DE", label: "Germany" },
  { value: "MO", label: "Macao" },
  { value: "HU", label: "Hungary" },
  { value: "BR", label: "Brazil" },
  { value: "IE", label: "Ireland" },
  { value: "AU", label: "Australia" },
  { value: "BE", label: "Belgium" },
  { value: "SE", label: "Sweden" },
  { value: "SK", label: "Slovakia" },
  { value: "PL", label: "Poland" },
  { value: "LT", label: "Lithuania" },
  { value: "JP", label: "Japan" },
  { value: "CA", label: "Canada" },
  { value: "PT", label: "Portugal" },
  { value: "RO", label: "Romania" },
  { value: "FI", label: "Finland" },
  { value: "GB", label: "United Kingdom" },
  { value: "PA", label: "Panama" },
  { value: "PE", label: "Peru" },
  { value: "CZ", label: "Czech Republic" },
  { value: "GH", label: "Ghana" },
  { value: "DK", label: "Denmark" },
  { value: "UG", label: "Uganda" },
  { value: "MX", label: "Mexico" },
  { value: "DZ", label: "Algeria" },
  { value: "ZA", label: "South Africa" },
  { value: "HR", label: "Croatia" },
  { value: "ID", label: "Indonesia" },
  { value: "AT", label: "Austria" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "EC", label: "Ecuador" },
  { value: "KE", label: "Kenya" },
  { value: "NL", label: "Netherlands" },
  { value: "CL", label: "Chile" },
  { value: "CH", label: "Switzerland" },
  { value: "TN", label: "Tunisia" },
  { value: "TH", label: "Thailand" },
  { value: "SI", label: "Slovenia" },
  { value: "PR", label: "Puerto Rico" },
  { value: "PH", label: "Philippines" },
  { value: "NZ", label: "New Zealand" },
  { value: "NA", label: "Namibia" },
  { value: "MU", label: "Mauritius" },
  { value: "ML", label: "Mali" },
  { value: "HK", label: "Hong Kong" },
  { value: "GN", label: "Guinea" },
  { value: "GD", label: "Grenada" },
  { value: "GR", label: "Greece" },
  { value: "GE", label: "Georgia" },
  { value: "EE", label: "Estonia" },
  { value: "SV", label: "El Salvador" },
  { value: "CO", label: "Colombia" },
  { value: "BG", label: "Bulgaria" },
  { value: "BW", label: "Botswana" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BJ", label: "Benin" },
  { value: "BB", label: "Barbados" },
  { value: "AR", label: "Argentina" },
];

export const lineCountries = [
  { value: "US", label: "USA" },
  { value: "CA", label: "Canada" },
];
