import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../../../components/common";
import { ExcelIcon, PDFIcon } from "../../../components/icons/svg";
import TemplateListing from "./TemplateListing";

const Template = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <ExcelIcon />
          Excel
        </Box>
      ),
      value: "excel",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];

  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };
  const BDCCampaignHeader = [
    { id: 1, value: "From", label: "from" },
    { id: 2, value: "Sent Email Blast", label: "sentEmail" },
    { id: 3, value: "Message", label: "message" },
    { id: 4, value: "Date", label: "date" },
    { id: 5, value: "Actions", label: "actions" },
  ];

  const BDCCampaignData = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
    return {
      from: <Text whiteSpace="nowrap">Corey Lubin</Text>,
      sentEmail: (
        <Text color="var(--primary-600)" fontWeight="500">
          654 Contacts{" "}
        </Text>
      ),
      message: "This is my test reply",
      date: "Jul 21, 2022",
      actions: <Dropdown options={options} onSelect={handleSelect} />,
      onRowClick: () => console.log(item),
    };
  });

  return (
    <Box padding="1.5rem">
      <VStack
        w="100%"
        alignItems="flex-start"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        background="white"
      >
        <HStack justifyContent="space-between" w="100%" padding="1rem">
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            fontWeight="500"
            onClick={() => navigate("/email-blast")}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
            Back
          </Button>
          {!!activeTab && (
            <Button
              variant="outlineBlue"
              color="var(--primary-default)"
              onClick={() => navigate("/email-blast/template-compose")}
            >
              Create Template
            </Button>
          )}
        </HStack>
        <Tabs
          variant="vertical"
          display="flex"
          w="100%"
          isLazy
          onChange={(index) => setActiveTab(index)}
        >
          <TabList>
            <Tab>Store Templates</Tab>
            <Tab borderRight="0">User Templates</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TemplateListing type="store_template" />
            </TabPanel>
            <TabPanel>
              <TemplateListing type="user_template" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  );
};

export default Template;
