import {
  Box,
  Button,
  HStack,
  Radio,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Loader } from "@giphy/react-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { humanize } from "../../../../../utils";
import {
  fetchBillingInfo,
  updateBillingInfo,
} from "../../../../../utils/api/dealership-setting.api";
import { Input } from "../../../../common";
import DatePicker from "../../../../common/DateRangePicker";
import {
  BasicInformationForm,
  basicInformationForm,
  defaultValues,
  meteredOptions,
} from "./utils";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";

function BasicInformation() {
  const { handleSubmit, control, setValue, reset } =
    useForm<BasicInformationForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(basicInformationForm) as any,
    });

  const toast = useToast();

  const { id } = useParams();

  const user = useUserInfo("user");

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateBillingInfo({
          dealershipId: id,
          payload,
          sub_menu: "Dealership Settings/Basic Information",
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { isLoading } = useQuery({
    queryKey: ["fetchBillingInfo", id],
    queryFn: () => fetchBillingInfo(id),
    retry: false,
    onSuccess: (data) => {
      reset({
        ...data?.billing_info,
        start_date: moment(data?.billing_info?.start_date).toDate(),
      });
    },
  });

  const handleFormSubmit = (values: BasicInformationForm) => {
    mutation.mutate(values);
  };

  return (
    <VStack
      w="25%"
      border="1px solid var(--grey-300)"
      background="white"
      borderRadius="1rem"
    >
      {isLoading && <Loader />}
      <HStack
        w="100%"
        padding="0.88rem 1rem"
        justifyContent="space-between"
        borderBottom="1px solid var(--grey-300)"
      >
        <Text textStyle="h4" fontWeight="700">
          Basic Information
        </Text>
        {/* <Button variant="none">
          <SettingsIcon />
        </Button> */}
      </HStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="0.75rem 1rem"
        gap="0.75rem"
        // borderBottom="1px solid var(--grey-300)"
        height="590px"
        overflowY="scroll"
      >
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <DatePicker
                  label="Start Date"
                  date={value as any}
                  onDateChange={(range: any) => {
                    setValue("start_date", range);
                  }}
                  w="100%"
                  isRange={false}
                  isRequired
                  // minDate={new Date()}
                />
                {error?.message && (
                  <Text
                    textStyle="captionSmall"
                    color="var(--red-600)"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      // marginTop: "0.75rem",
                    }}
                  >
                    {error.message}
                  </Text>
                )}
              </>
            );
          }}
          name="start_date"
          control={control}
          rules={{
            required: true,
          }}
        />

        <Text textStyle="h5" fontWeight="700">
          Account Type
        </Text>
        {meteredOptions.map((meteredOption) => {
          return (
            <Box mb="0.1rem" display="grid">
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <Radio
                        key={meteredOption}
                        value={meteredOption}
                        isChecked={meteredOption === value}
                        onChange={() => {
                          setValue("metered_type", meteredOption);
                          setValue("billing_features", {
                            call: "",
                            mms: "",
                            ringLessVM: "",
                            sms: "",
                            voicemail: "",
                          });
                          setValue("dollar_amount", "");
                        }}
                      >
                        {meteredOption === "custom"
                          ? "Certain amount included and metered above this amount"
                          : meteredOption === "unlimited" ? 'Unlimited ($)':humanize(meteredOption)}
                      </Radio>
                      {value === "unlimited" &&
                        meteredOption === "unlimited" && (
                          <Box ml="1.5rem" mt="1rem">
                            <Controller
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => {
                                return (
                                  <Input
                                    label=""
                                    placeholder="Enter Dollar Amount"
                                    value={value}
                                    onChange={onChange}
                                    error={error?.message}
                                  />
                                );
                              }}
                              name="dollar_amount"
                              control={control}
                              rules={{
                                required: true,
                              }}
                            />
                          </Box>
                        )}

                      {value === "custom" && meteredOption === "custom" && (
                        <Box ml="1.5rem" mt="1rem">
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  label="SMS ($)"
                                  placeholder="Enter Dollar Amount"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  marginBottom="1rem"
                                  type="number"
                                />
                              );
                            }}
                            name="billing_features.sms"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  label="MMS ($)"
                                  placeholder="Enter Dollar Amount"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  marginBottom="1rem"
                                  type="number"
                                />
                              );
                            }}
                            name="billing_features.mms"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  label="Voicemail ($)"
                                  placeholder="Enter Dollar Amount"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  marginBottom="1rem"
                                  type="number"
                                />
                              );
                            }}
                            name="billing_features.voicemail"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  label="Ringless Voicemail ($)"
                                  placeholder="Enter Dollar Amount"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  marginBottom="1rem"
                                  type="number"
                                />
                              );
                            }}
                            name="billing_features.ringLessVM"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  label="Call ($)"
                                  placeholder="Enter Dollar Amount"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  marginBottom="1rem"
                                  type="number"
                                />
                              );
                            }}
                            name="billing_features.call"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                        </Box>
                      )}
                    </>
                  );
                }}
                name="metered_type"
                control={control}
              />
            </Box>
          );
        })}
      </VStack>

      <VStack w="100%" p="0.5rem">
        <Button
          width="100%"
          onClick={() => handleSubmit((values) => handleFormSubmit(values))()}
          isDisabled={!["SS Owners", "SimpSocial Owner"].includes(user.role)}
        >
          Save
        </Button>
      </VStack>
    </VStack>
  );
}

export default BasicInformation;
