"use client";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  getDealershipPermissions,
  updateDealershipPermissions,
} from "../../../../utils/api/usersetting.api";
import BroadcastSettings from "../../../AccountSettings/BroadcastSettings/BroadcastSettings";
import DealerProfile from "../../../AccountSettings/DealerProfile/DealerProfile";
import DispositionSettings from "../../../AccountSettings/DispositionSettings/DispositionSettings";
import ImportsAndExports from "../../../AccountSettings/Imports/ImportsAndExports";
import LeadCenterSettings from "../../../AccountSettings/LeadCenterSettings/LeadCenterSettings";
import SMSTemplateDealershipSettings from "../../../AccountSettings/SMSTemplates/SMSTemplateDealershipSettings";
import Scripts from "../../../AccountSettings/Scripts/Scripts";
import TranscriptionSettings from "../../../AccountSettings/TranscriptionSettings/TranscriptionSettings";
import Usage from "../../../AccountSettings/Usage/Usage";
import ManagePermission from "../../../UserSettings/ManagePermission";
import GlobalLeadSource from "../../LeadSource/LeadSource";
import BDCCampaignPage from "../../../../pages/(dashboard-routes)/bdc-campaign/page";
import { adminDealershipAPIs } from "../../../../utils/api/bdc-campaign.api";
import { useParams } from "react-router-dom";
import DealershipTemplateListing from "../../../AccountSettings/EmailTemplates";

const CheckIfTable = (title: string) => {
  switch (title) {
    case "Lead Sources":
      return true;
    case "SMS Templates":
      return true;
    case "Disposition Settings":
      return true;
    case "Scorecard Transcription":
      return true;
    default:
      return false;
  }
};

const DealershipSettings = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id } = useParams();
  const TabsData = [
    { id: 0, title: "Dealer Profile", content: <DealerProfile /> },
    {
      id: 1,
      title: "Scorecard Transcription Settings",
      content: <TranscriptionSettings />,
    },
    { id: 16, title: "Lead Sources", content: <GlobalLeadSource /> },

    { id: 3, title: "Lead Center Settings", content: <LeadCenterSettings /> },
    // { id: 5, title: "Power Dialer Settings", content: <PowerDialerSettings /> }, //commenting it, maybe useful in future
    {
      id: 6,
      title: "Disposition Settings",
      content: (
        <DispositionSettings
          mappings={["Dealership Settings", "Disposition Settings"]}
        />
      ),
    },
    { id: 8, title: "Usage ", content: <Usage /> },
    {
      id: 9,
      title: "Imports / Exports ",
      content: <ImportsAndExports />,
      disabled: false,
    },

    { id: 11, title: "Power Dialer Scripts ", content: <Scripts /> },
    {
      id: 12,
      title: "SMS Templates",
      content: <SMSTemplateDealershipSettings />,
    },
    {
      id: 13,
      title: "Email Templates",
      content: <DealershipTemplateListing />,
    },
    {
      id: 14,
      title: "BDC Campaign",
      content: (
        <BDCCampaignPage apiSet={adminDealershipAPIs} dealership_id={id} />
      ),
    },

    { id: 15, title: "Broadcast Settings", content: <BroadcastSettings /> },
    {
      id: 4,
      title: "Manage Permissions",
      content: (
        <ManagePermission
          api={getDealershipPermissions}
          patchApi={updateDealershipPermissions}
          allowed={true}
        />
      ),
    },
  ];

  return (
    <>
      <>
        <Box
          background="white"
          borderBottomLeftRadius="0.5rem"
          borderBottomRightRadius="0.5rem"
        >
          <Tabs
            variant="vertical"
            display="flex"
            borderBottomLeftRadius="0.5rem"
            onChange={(index: any) => setSelectedIndex(index)}
            isLazy
          >
            <TabList
              height="calc(100vh - 240px)"
              overflowY="auto"
              overflowX="hidden"
              borderBottomLeftRadius="0.5rem"
            >
              <Text
                p="1rem"
                textStyle="h6"
                fontWeight="600"
                color="var(--grey-500)"
                borderBottom="1px solid var(--grey-300)"
                w="100%"
              >
                Dealership Settings
              </Text>
              {TabsData.map((item, index) => (
                <Tab
                  key={item.id}
                  borderBottom={
                    index === TabsData.length - 1
                      ? "unset"
                      : "1px solid var(--grey-300)"
                  }
                >
                  {item.title}
                </Tab>
              ))}
            </TabList>

            <TabPanels height="calc(100vh - 240px)" overflow="auto">
              {TabsData.map((item, index) => (
                <TabPanel
                  key={item.id}
                  padding={!CheckIfTable(item?.title) ? "1rem" : "0"}
                >
                  {selectedIndex === index && item.content}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </>{" "}
    </>
  );
};

export default DealershipSettings;
