import React, { Dispatch, SetStateAction } from "react";
import Activity from "../Activity/Activity";
import { CallHistory } from "../../../../../../utils/types";
import { humanize } from "../../../../../../utils";
import moment from "moment";
import { InboundCallIcon, OutboundCallIcon } from "../../../../../icons/svg";
import { HStack, Text, VStack } from "@chakra-ui/react";
import TwoLeggedCard from "./TwoLeggedCard";

type SingleCallCardProps = {
  item: CallHistory;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleCallCard({ item, setClickedRecord }: SingleCallCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => setClickedRecord(item)}
      title={item?.sub_type === "outbound" ? `Outbound Call` : `Inbound Call`}
      status={
        item?.details?.duration
          ? ""
          : humanize(
              item?.details?.status === "no_answered"
                ? "no_answer"
                : item?.details?.status || ""
            )
      }
      minutes={item?.details?.duration || 0}
      dateAndTime={moment(item?.details?.call_datetime).format(
        "dddd DD MMM YY • H:mm a"
      )}
      icon={
        item.sub_type === "outbound" ? (
          <OutboundCallIcon />
        ) : (
          <InboundCallIcon />
        )
      }
      content={
        item?.details?.two_legged_reciever ? (
          <TwoLeggedCard item={item} />
        ) : (
          <>
            <VStack alignItems="flex-start" gap="0.25rem">
              {item?.details?.display_name ? (
                <HStack gap="0.25rem">
                  <Text textStyle="h6" fontWeight="400">
                    Rep:
                  </Text>
                  <Text
                    color="var(--primary-800)"
                    textStyle="h6"
                    fontWeight="500"
                  >
                    {item?.details?.display_name}
                  </Text>
                </HStack>
              ) : (
                <HStack gap="0.25rem">
                  <Text textStyle="h6" fontWeight="400">
                    {"To:"}
                  </Text>
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    {item?.details?.to}
                  </Text>
                </HStack>
              )}
              {item?.details?.delership_name && (
                <Text textStyle="h6" fontWeight="400">
                  {item?.details?.delership_name}
                </Text>
              )}
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="400">
                  {item?.sub_type === "outbound" ? "To:" : "From:"}
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.contact_name
                    ? item?.details?.contact_name
                    : item?.sub_type === "outbound"
                    ? item?.details?.to
                    : item?.details?.from}
                </Text>
              </HStack>

              {item?.details?.summary && (
                <HStack gap="0.25rem">
                  <Text textStyle="h6" fontWeight="400">
                    Summary:
                  </Text>
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    {item?.details?.summary}
                  </Text>
                </HStack>
              )}

              {/* {item?.details?.call_source && ( */}
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="400">
                  Source:
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.call_source === "power_dialer"
                    ? "Power Dialer"
                    : "Regular"}
                </Text>
              </HStack>
              {/* )} */}
            </VStack>
          </>
        )
      }
    />
  );
}

export default SingleCallCard;
