import { Tooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";

interface TooltipIfOverflowProps extends TooltipProps {
  char?: number;
  children: React.ReactNode | string[];
}

const TooltipIfOverflow: React.FC<TooltipIfOverflowProps> = ({
  children,
  char = 25,
  ...rest
}) => {
  // Function to recursively concatenate children's text or handle string arrays
  const concatChildrenText = (children: React.ReactNode | string[]): string => {
    if (Array.isArray(children)) {
      return children.join(" "); // Join array items with '|' or any other separator you prefer
    }
    if (!children) {
      return "";
    }
    return (
      React.Children.map(children, (child) => {
        if (typeof child === "string") {
          return child;
        } else if (
          React.isValidElement(child) &&
          child.props &&
          child.props.children
        ) {
          return concatChildrenText(child.props.children);
        }
        return "";
      })?.join("") || ""
    );
  };

  const content = concatChildrenText(children);
  const showTooltip = content.length > char;
  const contentToShow = showTooltip ? `${content.slice(0, char)}...` : content;

  return (
    <Tooltip
      label={showTooltip ? content : ""}
      isDisabled={!showTooltip}
      {...rest}
    >
      {contentToShow}
    </Tooltip>
  );
};

export default TooltipIfOverflow;
