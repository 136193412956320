import { Box, Flex, ScaleFade } from "@chakra-ui/react";
import Header from "./Header";
// import { usePathname } from "next/navigation";
import { useLocation } from "react-router-dom";
import { usePWCampaign } from "../../utils/hooks/usePWCampaign";

const Content = ({ children, list }: any) => {
  const location = useLocation();
  // const pathName = usePathname();

  // const [isMobile] = useMediaQuery("(max-width: 480px)");
  // const [isTablet] = useMediaQuery("(min-width: 481px) and (max-width: 768px)");
  // const [isDesktop] = useMediaQuery("(min-width: 769px)");

  // // const width = isMobile
  // //   ? "20rem"
  // //   : isTablet
  // //   ? "25rem"
  // //   : isDesktop
  // //   ? "40rem"
  // //   : "91.5rem";

  usePWCampaign();

  return (
    <Flex
      id="main"
      className="main-content"
      as="main"
      ml={{ base: 0, Tablet: 8 }}
      background="var(--grey-100)"
      flexGrow={1}
      direction="column"
      position="relative"
    >
      <ScaleFade
        in={true}
        transition={{ enter: { duration: 0.5, delay: 0.3 } }}
      >
        {location.pathname !== "/vehicle" &&
          location.pathname !== "/email-campaign" && <Header list={list} />}
        <Box
          mt={
            location.pathname !== "/vehicle" &&
            location.pathname !== "/email-campaign"
              ? "4.3rem"
              : "0"
          }
          height={
            location.pathname !== "/vehicle" &&
            location.pathname !== "/email-campaign"
              ? "calc(100vh - 22px)"
              : "100%"
          }
          background={
            location.pathname !== "/vehicle" &&
            location.pathname !== "/email-campaign"
              ? ""
              : "var(--grey-100)"
          }
          overflow="auto"
        >
          {children}
        </Box>
      </ScaleFade>
    </Flex>
  );
};

export default Content;
