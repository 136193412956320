import { Box, FormLabel, Text } from "@chakra-ui/react";
import React from "react";
import { Controller, UseFormSetValue, Control } from "react-hook-form";
import { Input, TagsInput } from "../../common";
import { LeadCenterSettingsForm, loadLeadSource } from "./utils";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router-dom";
import SwitchToggle from "../../common/Switch";

type LeadCenterEmailsProps = {
  control: Control<LeadCenterSettingsForm>;
  setValue: UseFormSetValue<LeadCenterSettingsForm>;
};

function LeadCenterEmails({ control, setValue }: LeadCenterEmailsProps) {
  const { id } = useParams();
  return (
    <>
      <Box
        padding="1rem"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        w="100%"
      >
        <Text textStyle="h4" fontWeight="500" mb="1rem">
          Dealership CRM Intake Email Address
        </Text>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Input
                label=""
                placeholder="Enter Email"
                value={value}
                onChange={onChange}
                error={error?.message}
                type="email"
                w="100%"
              />
            );
          }}
          name="crm_intake_email"
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>

      <Box
        padding="1rem"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        w="100%"
      >
        <Text textStyle="h4" fontWeight="500" mb="1rem">
          ADF XML Based Emails
        </Text>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem" mt="1rem">
                <TagsInput
                  checkDuplicate
                  placeholder="Enter Email"
                  label="ADF XML Emails Recipients"
                  type="email"
                  value={value as any}
                  onChange={onChange}
                  error={error?.message}
                  onAdd={async (newValue: string) => {
                    setValue("xml_email_recipients.emails", [
                      ...value,
                      { id: Date.now(), value: newValue } as any,
                    ]);
                  }}
                  onRemove={(id: number) =>
                    setValue(
                      "xml_email_recipients.emails",
                      value.filter((value: any) => value.id !== id)
                    )
                  }
                />
              </Box>
            );
          }}
          name="xml_email_recipients.emails"
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem">
                <SwitchToggle
                  label={"Appt Recipients"}
                  id={`#xml_appt`}
                  defaultChecked={value}
                  onChange={(checked: boolean) =>
                    setValue("xml_email_recipients.appt", checked)
                  }
                />
              </Box>
            );
          }}
          name={"xml_email_recipients.appt"}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem">
                <SwitchToggle
                  label={"Leads Recipients"}
                  id={`#xml_leads`}
                  defaultChecked={value}
                  onChange={(checked: boolean) =>
                    setValue("xml_email_recipients.leads", checked)
                  }
                />
              </Box>
            );
          }}
          name={"xml_email_recipients.leads"}
          control={control}
          rules={{
            required: true,
          }}
        />

<Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Lead Source
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadLeadSource(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(`xml_email_recipients.lead_source`, value);
                  }}
                  isMulti={true}
                  placeholder="Select Lead Source"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`xml_email_recipients.lead_source`}
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>

      <Box
        padding="1rem"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        w="100%"
      >
        <Text textStyle="h4" fontWeight="500" mb="1rem">
          Text Based Emails
        </Text>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem" mt="1rem">
                <TagsInput
                  checkDuplicate
                  placeholder="Enter Email"
                  label="Text Based Emails Recipients"
                  type="email"
                  value={value as any}
                  onChange={onChange}
                  error={error?.message}
                  onAdd={async (newValue: string) => {
                    setValue("text_email_recipients.emails", [
                      ...value,
                      { id: Date.now(), value: newValue } as any,
                    ]);
                  }}
                  onRemove={(id: number) =>
                    setValue(
                      "text_email_recipients.emails",
                      value.filter((value: any) => value.id !== id)
                    )
                  }
                />
              </Box>
            );
          }}
          name="text_email_recipients.emails"
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem">
                <SwitchToggle
                  label={"Appt Recipients"}
                  id={`#xml_appt`}
                  defaultChecked={value}
                  onChange={(checked: boolean) =>
                    setValue("text_email_recipients.appt", checked)
                  }
                />
              </Box>
            );
          }}
          name={"text_email_recipients.appt"}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1.5rem">
                <SwitchToggle
                  label={"Leads Recipients"}
                  id={`#xml_leads`}
                  defaultChecked={value}
                  onChange={(checked: boolean) =>
                    setValue("text_email_recipients.leads", checked)
                  }
                />
              </Box>
            );
          }}
          name={"text_email_recipients.leads"}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Lead Source
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadLeadSource(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(`text_email_recipients.lead_source`, value);
                  }}
                  isMulti={true}
                  placeholder="Select Lead Source"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`text_email_recipients.lead_source`}
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>
    </>
  );
}

export default LeadCenterEmails;
