import * as yup from "yup";
import { NAME_REGEX } from "../../../config";

export type CreateTagForm = {
  id: string;
  name: string;
  description: string;
};

export const defaultValues = {
  name: "",
  description: "",
};

export const createTagValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is required.")
    .matches(NAME_REGEX, "Invalid Name."),
});

