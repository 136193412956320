import { Button, DrawerFooter, VStack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import {
  extractVimeoVideoId,
  extractYouTubeVideoId,
  isValidVimeoURL,
  isValidYouTubeURL,
} from "../../../utils";
import {
  createTraningVideo,
  fetchTraningVideoThumbnail,
} from "../../../utils/api/training-videos.api";
import { Drawer } from "../../common";
import CustomInput from "../../common/Input";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  training_video: {
    title: string;
    description: string;
    vimeo_url: string;
    thumbnail_url?: string;
  };
}

const UploadVideo = (props: data) => {
  const { isOpen, onClose, refetchParent } = props;
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    vimeo_url: "",
    thumnail_url: "",
  });

  const [nameError, setNameError] = useState("");
  const [urlError, setUrlError] = useState("");

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createTraningVideo(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error creating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!formData?.title?.trim()) {
      setNameError("Title cannot be empty");
      return;
    }
    if (!formData?.vimeo_url?.trim()) {
      setUrlError("URL cannot be empty");
      return;
    } else if (
      !isValidVimeoURL(formData?.vimeo_url) &&
      !isValidYouTubeURL(formData?.vimeo_url)
    ) {
      setUrlError("Enter a Vimeo or YouTube URL");
      return;
    }

    setNameError("");
    setUrlError("");

    const videoId = formData.vimeo_url?.includes("vimeo")
      ? extractVimeoVideoId(formData.vimeo_url)
      : extractYouTubeVideoId(formData.vimeo_url);

    const thumnbnailUrl = formData.vimeo_url?.includes("vimeo")
      ? await fetchTraningVideoThumbnail(videoId)
      : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    const requestData: ApiRequest = {
      training_video: {
        ...formData,
        thumbnail_url: thumnbnailUrl,
      },
    };
    mutation.mutate(requestData);
  };

  const handleChange = (key: string) => (event: any) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Upload Video"
      isLoading={mutation.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <CustomInput
          placeholder="Give your video a title"
          label="Video Title"
          value={formData?.title}
          onChange={handleChange("title")}
          isRequired={true}
          error={nameError}
        />
        <CustomInput
          placeholder="Add Description"
          label="Description"
          isTextarea={true}
          value={formData?.description}
          onChange={handleChange("description")}
        />
        <CustomInput
          placeholder="Add link to your video..."
          label="Import from Vimeo or YouTube"
          value={formData?.vimeo_url}
          onChange={handleChange("vimeo_url")}
          isRequired={true}
          error={urlError}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default UploadVideo;
