"use client";
import { useLocation } from "react-router-dom";
import GlobalContent from "../../components/common/GlobalContent";
import GlobalSidebar from "../../components/common/GlobalSidebar";
import { Flex } from "@chakra-ui/react";
// import { useSession } from "next-auth/react";
import React from "react";
// import { useRouter, usePathname } from "next/navigation";
import { BreadcrumbProvider } from "../../utils/context/BreadCrumsContext/BreadcrumbContext";

const GlobalLayoutClient = ({ children }: ReactChildren) => {
  // const { status } = useSession();
  // const pathname = usePathname();
  const location = useLocation();

  // if (status === "loading") {
  //   return <Spinner />;
  // }

  return (
    <BreadcrumbProvider>
    <Flex className="dashboard-layout" height="full">
      <GlobalSidebar pathname={location.pathname} />
      <GlobalContent>{children}</GlobalContent>
    </Flex>
     </BreadcrumbProvider>
  );
};

export default GlobalLayoutClient;
