import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    fontWeight: "semibold",
  },
});
const primary = definePartsStyle({
  tablist: {
    borderTopLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
  tabpanel: { padding: 0 },
  tab: {
    _first: {
      borderRadius: "0.5rem 0 0 0.5rem",
      borderRight: 0,
    },
    _last: {
      borderRadius: "0 0.5rem 0.5rem 0 ",
    },
    _selected: {
      background: "secondary.600",
      color: "white",
      svg: {
        path: { fill: "white" },
      },
    },
    border: "1px solid var(--grey-300)",
    flexGrow: 1,
  },
});
const table = definePartsStyle({
  tablist: {
    borderBottom: " 1px solid var(--grey-300)",
  },
  tabpanel: { padding: 0 },
  tab: {
    _first: {
      borderRadius: "0.5rem 0 0 0",
    },
    _last: {
      // borderRadius: "0 0.5rem 0.5rem 0 ",
    },
    _selected: {
      background: "secondary.600",
      color: "white",
    },

    minWidth: "142px",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1rem",
    padding: "1.15rem 1rem",
    borderRight: " 1px solid var(--grey-300)",
  },
});
const pill = definePartsStyle({
  tablist: {
    border: " 1px solid var(--grey-300)",
    borderRadius: "0.5rem",
  },
  tabpanel: { padding: 0 },
  tab: {
    _selected: {
      background: "secondary.600",
      color: "white",
      borderRadius: "0.5rem",
    },

    minWidth: "7rem",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1rem",
    padding: "0.65rem 1rem",
  },
});
const vertical = definePartsStyle({
  tablist: {
    flexDirection: "column",
    width: "22rem",
    borderRight: " 1px solid var(--grey-300)",
  },
  tabpanel: { padding: 0 },
  tab: {
    width: "22rem",
    justifyContent: "flex-start",
    _selected: {
      background: "secondary.600",
      color: "white",
    },
    minWidth: "142px",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1rem",
    padding: "1rem",
    borderBottom: " 1px solid var(--grey-300)",
  },
});
const accordion = definePartsStyle({
  tabs: {
    padding: "0",
  },
  tablist: {
    flexDirection: "column",
    borderRight: " 1px solid var(--grey-300)",
  },
  tabpanels: {
    width: "calc(100% - 18rem)",
  },
  tabpanel: { padding: 0, width: "calc(100% - 18rem)" },
  tab: {
    justifyContent: "flex-start",
    _selected: {
      background: "primary.50",
    },
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1rem",
    padding: "1rem",
    borderBottom: " 1px solid var(--grey-300)",
  },
});
const verticalContact = definePartsStyle({
  tablist: {
    flexDirection: "column",
    width: "26rem",
    borderRight: " 1px solid var(--grey-300)",
  },
  tabpanel: { padding: 0 },
  tab: {
    width: "100%",
    justifyContent: "flex-start",

    _selected: {
      background: "primary.50",
      color: "grey.800",
    },
    minWidth: "142px",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1rem",
    padding: "0.75rem 1.1rem 1.1rem 0.75rem",
    borderBottom: " 1px solid var(--grey-300)",
    _last: {
      borderBottom: "0",
    },
    _first: {
      borderTop: " 1px solid var(--grey-300)",
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: { primary, table, vertical, pill, verticalContact, accordion },
});
