
import { Navigate,Outlet } from "react-router-dom";
import { useAuthState } from "../utils/context/AuthContext";
export const AuthenticatedRoutes = ({ children }: { children?: React.ReactNode }) => {
   const {token}=useAuthState()

    if (!Boolean(token)) {
      return <Navigate to={'/login'} replace />;
    }
  
    return children ?  <>{children}</>  : <Outlet />;
  };