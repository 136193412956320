import React from "react";
import { Spinner, VStack } from "@chakra-ui/react";

const Loader = ({ size = "xl" }: { size?: string }) => {
  return (
    <VStack
      position="fixed"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      zIndex="999"
      backdropFilter="blur(1px)"
      background="#00000060"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        color="primary.600"
        emptyColor="gray.300"
        size={size}
      />
    </VStack>
  );
};

export default Loader;
