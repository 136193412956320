import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import MainListing from "./MainListing";

const Announcements = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleTabChange = (index: any) => {
    setActiveIndex(index);
  };

  return (
    <VStack padding="1.5rem" w="100%" alignItems="flex-start">
      <Tabs
        variant="table"
        w="100%"
        background="white"
        borderRadius="0.5rem"
        defaultIndex={0}
        onChange={handleTabChange}
      >
        <TabList borderTopRightRadius="0.5rem">
          <Tab w="14rem">Emails</Tab>
          <Tab w="14rem">SMS</Tab>
        </TabList>
        <TabPanels w="100%" h="100%">
          <TabPanel w="100%" h="100%">
            {activeIndex === 0 && <MainListing />}
          </TabPanel>

          <TabPanel w="100%" h="100%">
            {activeIndex === 1 && <MainListing type="sms" />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default Announcements;
