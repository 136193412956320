import {
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import Car from "../../../../../assets/images/car.svg";
import { useState } from "react";
import { CloseIcon } from "../../../../icons/svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import Popup from "../../../../Charts/Popup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Modal } from "../../../../common";
import {
  deleteTradeIn,
  fetchTradeIns,
} from "../../../../../utils/api/trade-in";
import {
  dealershipAdminChecker,
  simpAdminChecker,
} from "../../../../../utils/helper";
import AddTradeInDrawer from "./AddTradeInDrawer";
import InfiniteScroll from "react-infinite-scroll-component";

const TradeIn = (props: any) => {
  const { clickedRow } = props;
  const dealer = useUserInfo("dealership");
  const role = useUserInfo("user")?.role;
  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";
  const [swiperIndex, setSwipeIndex] = useState<any>(null);
  const [clickedTradeIn, setClickedTradeIn] = useState<any>([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [tradeIns, setTradeIns] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSliderModalOpen,
    onOpen: onSliderModalOpen,
    onClose: onSliderModalClose,
  } = useDisclosure();

  const toast = useToast();

  const getDisableValue = () => {
    if (impSSRole) {
      return !simpAdminChecker(impSSRole);
    }
    return !dealershipAdminChecker(role);
  };

  const { isFetching, refetch } = useQuery({
    queryKey: ["fetchTradeins", dealer?.id, clickedRow?.id, { page }],
    queryFn: fetchTradeIns,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.data?.trade_in_count);
      setTradeIns(data?.data?.trade_in);
    },
  });
  const mutation = useMutation(deleteTradeIn);

  const handleDelete = () => {
    mutation.mutate(
      { dId: dealer?.id, id: clickedRow?.id, tId: clickedTradeIn?.id },
      {
        onSuccess: () => {
          refetch();
          setIsOpenDelete(false);
          toast({
            description: "Trade-in deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error deleting Trade-in: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        alignItems="flex-start"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
        gap="1rem"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
          padding="1rem"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Customer Owned Vehicle History
            </Text>
          </HStack>
          <HStack>
            <Button
              variant="outline"
              padding="0.5rem"
              minW="auto"
              onClick={onOpen}
              isDisabled={count >= 2}
            >
              <AddIcon
                style={{
                  color: "var(--grey-700)",
                  fontSize: "1.5rem",
                }}
              />
            </Button>
          </HStack>
        </HStack>
        <VStack gap="1rem" alignItems="flex-start" w="100%" padding="1rem">
          {tradeIns && tradeIns?.length > 0 && (
            <div
              id="scrollableDiv"
              style={{
                height: "calc(89vh - 120px)",
                overflow: "auto",
                width: "100%",
              }}
            >
              <InfiniteScroll
                dataLength={tradeIns?.length}
                next={() => {
                  setPage((prev) => prev + 1);
                }}
                hasMore={tradeIns.length < count}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <VStack
                  gap="1rem"
                  alignItems="flex-start"
                  w="100%"
                  padding="1rem"
                >
                  {tradeIns?.map((item: any) => (
                    <HStack
                      key={item.id}
                      w="100%"
                      padding="1rem"
                      borderRadius="0.5rem"
                      border="1px solid var(--grey-300)"
                      alignItems="flex-start"
                      gap="1rem"
                      position="relative"
                      onClick={() => setClickedTradeIn(item)}
                    >
                      <VStack gap="0.5rem" mb="1rem" w="35%">
                        <Image
                          src={item?.images?.[0] || Car}
                          width={200}
                          height={150}
                          objectFit="cover"
                          alt="car Image"
                          style={{
                            borderRadius: "0.5rem",
                          }}
                          onClick={onSliderModalOpen}
                          cursor="pointer"
                        />
                      </VStack>
                      <VStack alignItems="flex-start" gap="0.5rem" w="65%">
                        <VStack
                          alignItems="flex-start"
                          w="100%"
                          gap="0.25rem"
                          pb="0.5rem"
                        >
                          <Text textStyle="h4" fontWeight="500">
                            {item.year} {item.make} - {item.model}
                          </Text>

                          <HStack
                            alignItems="flex-end"
                            justifyContent="space-between"
                            w="100%"
                          >
                            <VStack alignItems="flex-start" gap="0">
                              <Text
                                textStyle="captionSmall"
                                fontWeight="500"
                                color="var(--grey-500)"
                              >
                                {item.interior_color}
                              </Text>
                              <Text
                                textStyle="captionSmall"
                                fontWeight="500"
                                color="var(--grey-500)"
                              >
                                Mileage: {item.mileage}
                              </Text>

                              <Text
                                textStyle="captionSmall"
                                fontWeight="500"
                                color="var(--grey-500)"
                              >
                                {item?.vin}
                              </Text>
                              <Text
                                textStyle="captionSmall"
                                fontWeight="500"
                                color="var(--grey-500)"
                              >
                                {item?.notes}
                              </Text>
                            </VStack>
                          </HStack>
                        </VStack>
                      </VStack>
                      <Button
                        variant="none"
                        border="1px solid var(--grey-300)"
                        position="absolute"
                        right="0.5rem"
                        top="0.5rem"
                        padding="0.15rem"
                        borderRadius="0.5rem"
                        onClick={() => {
                          setIsOpenDelete(true);
                        }}
                        sx={{
                          svg: {
                            w: "1rem",
                            h: "1rem",
                            path: {
                              fill: "var(--grey-900)",
                            },
                          },
                        }}
                        isDisabled={getDisableValue()}
                      >
                        <CloseIcon />
                      </Button>
                    </HStack>
                  ))}
                </VStack>
              </InfiniteScroll>
            </div>
          )}
        </VStack>
      </VStack>

      {isOpenDelete && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpenDelete(false)}
          onConfirm={() => handleDelete()}
          title="Delete Confirmation"
          message="Are you sure you want to update delete this trade-in?"
        />
      )}

      <Modal
        onClose={onSliderModalClose}
        isOpen={isSliderModalOpen}
        contentMaxW="50%"
        title="Vehicle Images"
      >
        <VStack
          height="60vh"
          sx={{
            ".swiper-slide": {
              height: "100%",
            },
          }}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              height: "44vh",
              margin: "20px",
            }}
            onSlideChange={(swiper: any) => setSwipeIndex(swiper?.activeIndex)}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper2 showNavs"
          >
            {clickedTradeIn?.images?.map((item: any) => (
              <SwiperSlide key={item.id}>
                <Image
                  src={item}
                  alt="car"
                  objectFit="cover"
                  w="100%"
                  h="100% !important"
                  minH="500px"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {clickedTradeIn?.images?.length > 0 && (
            <Text>{`${swiperIndex + 1} / ${
              clickedTradeIn?.images?.length
            }`}</Text>
          )}
        </VStack>
      </Modal>
      {isFetching && <Spinner />}
      {isOpen && (
        <AddTradeInDrawer
          isOpen={isOpen}
          onClose={() => {
            onClose();
            refetch();
          }}
          info={{ dId: dealer?.id, id: clickedRow?.id }}
        />
      )}
    </VStack>
  );
};

export default TradeIn;
