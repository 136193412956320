import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchMainListing = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[2]}/dms_equity_minings`,
    {
      params: queryKey[1],
    }
  );

export const fetchContactListing = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[2]}/dms_equity_minings/${queryKey[3]}/show_contacts`,
    {
      params: queryKey[1],
    }
  );

export const createDMS = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/dms_equity_minings`,
    payload.payload
  );

export const fetchDMS = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/dms_equity_minings/${queryKey[2]}`
  );
export const fetchContacts = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/dms_equity_minings/contact_list`,
    {
      params: queryKey[2],
    }
  );

export const editDMS = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/dms_equity_minings/${payload.id}`,
    payload.payload
  );
export const activateCampaign = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/dms_equity_minings/${payload.id}/start_campaign?campaign_type=${payload.campaign_type}`,
    payload.payload
  );

export const downloadFile = async (dId: any, category: string) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/dms_equity_minings/export?category=${category}`,
    {
      responseType: "blob",
    }
  );
