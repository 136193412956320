import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { PaginatedTable } from "../../common";
import Loader from "../../common/Spinner";
import { useQuery } from "@tanstack/react-query";
import { previewMapping } from "../../../utils/api/inventory-mapping.api";

export const PreviewMapping = ({ isOpen, onClose, name, mapping }: any) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["pendingApi", name, mapping, page, perPage],
    queryFn: previewMapping,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Object.keys(mapping).length !== 0,
  });

  const totalItems = data?.data?.total_count;
  const headers = data?.data?.data[0]
    ? Object.keys(data?.data?.data[0]).map((key) => ({
        label: key,
        value: key,
      }))
    : [];

  // Calculate pagination
  const activeData = data?.data?.data;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="500px" // Adjust height as needed
        // width="1000px" // Fixed width
        minWidth="1000px"
      >
        <ModalHeader>Mapping Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{ width: "960px", overflow: "auto" }}>
            {" "}
            {/* Container for horizontal scrolling */}
            <PaginatedTable
              header={headers} // Dynamic headers
              data={activeData} // Mapping data according to headers
              itemsPerPage={perPage}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={totalItems}
              isPaginated={true}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              tableHeight={300} // Adjust table height as needed
              maxPageNumbers={5}
            />
          </div>
        </ModalBody>
        {(isLoading || isFetching) && <Loader />}
      </ModalContent>
    </Modal>
  );
};
