
import React from "react";
import { Center, Heading, Text, VStack, Image } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };
  return (
    <Center h="100vh" bg="gray.100">
      <VStack spacing={8} textAlign="center">
        <Heading as="h1" size="2xl" color="gray.700">
        Page Not Found
        </Heading>
        <Text fontSize="xl" color="gray.600">
        Oops! It seems like you've reached a page that doesn't exist. Please  contact your administrator for further assistance.
        </Text>
        <button onClick={handleGoBack} >Go to Home Page</button>
      </VStack>
    </Center>
  );
};