import React from "react";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";

interface SwitchProps {
  label?: string | React.ReactNode;
  id?: string;
  defaultChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
  disabled?: boolean;
}

const SwitchToggle = ({
  label,
  id,
  defaultChecked = false,
  onChange,
  disabled,
}: SwitchProps) => {
  const handleSwitchChange = (event: ChangeEvent) => {
    const newCheckedState = event.target.checked;

    if (onChange) {
      onChange(newCheckedState);
    }
  };

  return (
    <FormControl display="flex" alignItems="center" w="auto">
      <Switch
        id={id}
        isChecked={defaultChecked}
        onChange={handleSwitchChange}
        isDisabled={disabled}
      />
      {label && (
        <FormLabel
          htmlFor={id}
          ml="1rem"
          mb="0"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="500"
          lineHeight="1rem"
        >
          {label}
        </FormLabel>
      )}
    </FormControl>
  );
};

export default SwitchToggle;
