import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { Device } from "@twilio/voice-sdk";
import { useLongPress } from "@uidotdev/usehooks";
import { KeyboardEvent, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { KEYPAD_DATA } from "../../config";
import {
  handleHangUp,
  makeOutgoingCall,
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { PhoneInput, Popover, Select } from "../common";
import SoundTest from "../common/SoundTest";
import Stopwatch from "../common/Stopwatch";
import {
  CallIcon,
  CloseIcon,
  NotesIcon,
  SettingsIcon,
  VoicemailIcon,
} from "../icons/svg";
import { filterByUniqueKey } from "../../utils";

type KeypadProps = {
  onClose?: () => void;
  showHeader?: boolean;
};

const Keypad = ({ onClose, showHeader = false }: KeypadProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [soundTestOpen, setSoundTestOpen] = useState(false);
  const [currentLine, setCurrentLine] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { currentDevice, call, startCallTimestamp, devices } = useTwilioState();
  const lineDevices = filterByUniqueKey(devices.filter((device) => device.phoneNumber), 'phoneNumber');

  useEffect(() => {
    const defaultLine =
      lineDevices?.find((line) => line.is_default)?.phoneNumber ||
      lineDevices?.[0].phoneNumber;
    setCurrentLine(defaultLine);
  }, [lineDevices]);

  const user = useUserInfo("user");

  const dispatch = useTwilioDispatch();
  const toast = useToast();

  const attrs = useLongPress(
    () => {
      handleKeyPress(`+`);
    },
    {
      threshold: 500,
      onCancel: () => handleKeyPress("0"),
    }
  );
  const handleKeyPress = (digit: string) => {
    setPhoneNumber((prevNumber) => prevNumber + digit);
  };

  const inputDevices = Array.from(
    currentDevice?.device?.audio?.availableInputDevices || [],
    ([id, value]) => ({ value: id, label: value?.label })
  );
  const outputDevices = Array.from(
    currentDevice?.device?.audio?.availableOutputDevices || [],
    ([id, value]) => ({ value: id, label: value.label })
  );

  const handleCall = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      return toast({
        description: "Please enter valid phone number.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    const currentDevice = lineDevices.find(
      (device) => device.phoneNumber === currentLine
    )?.device;

    await makeOutgoingCall(
      dispatch,
      currentDevice as Device,
      phoneNumber,
      currentLine,
      user,
      undefined,
      "Open Phone"
    );

    onClose && onClose();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && phoneNumber && currentLine) {
      handleCall();
    }
  };

  return (
    <VStack w="100%" alignItems="flex-start">
      {showHeader && (
        <HStack
          justifyContent="space-between"
          w="100%"
          background="var(--secondary-600)"
          borderTopRightRadius="0.5rem"
          borderTopLeftRadius="0.5rem"
          padding="0.69rem 1rem"
        >
          <Text color="white" textStyle="h5" fontWeight="500">
            Phone Ready
          </Text>
          <HStack alignItems="flex-start">
            <Popover
              btnVariant="none"
              variant="custom"
              btnStyle={{ width: "max-content", paddingLeft: "1.4rem" }}
              trigger={
                <Button
                  variant="none"
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                >
                  <SettingsIcon />
                </Button>
              }
              placement="bottom-start"
              contentStyle={{
                width: "16rem",
                borderRadius: "0.5rem",
              }}
              isOpen={popoverOpen}
              onOpen={() => setPopoverOpen(true)}
              onClose={() => setPopoverOpen(false)}
            >
              {popoverOpen && (
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  position="relative"
                  gap="0"
                >
                  <VStack
                    w="100%"
                    alignItems="flex-start"
                    gap="0"
                    padding="0.5rem 0.96rem"
                  >
                    <Box mb="1rem" w="100%">
                      <Select
                        options={inputDevices}
                        label="Microphone"
                        w="100%"
                        value={
                          inputDevices?.find(
                            (value) => value.value === "default"
                          )?.value
                        }
                        onChange={(value) =>
                          currentDevice?.device?.audio?.setInputDevice(
                            value.value
                          )
                        }
                      />
                    </Box>

                    <Box mb="1rem" w="100%">
                      <Select
                        options={outputDevices}
                        label="Speaker"
                        w="100%"
                        value={
                          Array.from(
                            currentDevice?.device?.audio?.speakerDevices
                              .get()
                              .values() || []
                          )[0]?.deviceId
                        }
                        onChange={(value) =>
                          currentDevice?.device?.audio?.speakerDevices.set(
                            value.value
                          )
                        }
                      />
                    </Box>

                    <Box w="100%">
                      <Button w="100%" onClick={() => setSoundTestOpen(true)}>
                        Speaker Test
                      </Button>
                    </Box>
                  </VStack>
                </VStack>
              )}
            </Popover>
            <Button
              onClick={onClose}
              variant="none"
              sx={{
                svg: {
                  path: {
                    fill: "white",
                  },
                },
              }}
            >
              <CloseIcon />
            </Button>
          </HStack>
        </HStack>
      )}
      {!call ? (
        <VStack w="100%" alignItems="flex-start" padding="0.69rem 0.94rem">
          <HStack w="100%">
            <Select
              label="The number we are calling from"
              onChange={(value: any) => setCurrentLine(value.value)}
              placeholder="Select Line"
              options={
                lineDevices?.map((lineDevice) => ({
                  value: lineDevice.phoneNumber,
                  label: lineDevice.phoneNumber,
                })) || []
              }
              variant="default"
              w="100%"
              value={currentLine}
            />
          </HStack>

          <HStack width="100%">
            <PhoneInput
              value={phoneNumber}
              placeholder="Click to type..."
              onChange={(value) => setPhoneNumber(value)}
              onKeyDown={(event) => handleKeyDown(event)}
              styles={{ width: "100%" }}
              containerStyles={{ w: "100%" }}
              label="The number you want to call"
            />
          </HStack>
          <HStack w="100%">
            {KEYPAD_DATA.slice(0, 3).map((keypadValue) => {
              return (
                <Button
                  fontSize="1.5rem"
                  minH="4.5rem"
                  fontWeight="500"
                  color="#1565C0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="33%"
                  background="var(--grey-50)"
                  flexDirection="column"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  variant="none"
                  onClick={() => handleKeyPress(keypadValue.value)}
                >
                  {keypadValue.value}
                  {keypadValue.subValue && (
                    <Text textStyle="h6" color="var(--grey-900)">
                      {keypadValue.subValue}
                    </Text>
                  )}
                </Button>
              );
            })}
          </HStack>
          <HStack w="100%">
            {KEYPAD_DATA.slice(3, 6).map((keypadValue) => {
              return (
                <Button
                  fontSize="1.5rem"
                  minH="4.5rem"
                  fontWeight="500"
                  color="#1565C0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="33%"
                  background="var(--grey-50)"
                  flexDirection="column"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  variant="none"
                  onClick={() => handleKeyPress(keypadValue.value)}
                >
                  {keypadValue.value}
                  {keypadValue.subValue && (
                    <Text textStyle="h6" color="var(--grey-900)">
                      {keypadValue.subValue}
                    </Text>
                  )}
                </Button>
              );
            })}
          </HStack>
          <HStack w="100%">
            {KEYPAD_DATA.slice(6, 9).map((keypadValue) => {
              return (
                <Button
                  fontSize="1.5rem"
                  minH="4.5rem"
                  fontWeight="500"
                  color="#1565C0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="33%"
                  background="var(--grey-50)"
                  flexDirection="column"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  variant="none"
                  onClick={() => handleKeyPress(keypadValue.value)}
                >
                  {keypadValue.value}
                  {keypadValue.subValue && (
                    <Text textStyle="h6" color="var(--grey-900)">
                      {keypadValue.subValue}
                    </Text>
                  )}
                </Button>
              );
            })}
          </HStack>
          <HStack w="100%">
            {KEYPAD_DATA.slice(9, 12).map((keypadValue) => {
              return (
                <Button
                  fontSize="1.5rem"
                  minH="4.5rem"
                  fontWeight="500"
                  color="#1565C0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="33%"
                  background="var(--grey-50)"
                  flexDirection="column"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  variant="none"
                  onClick={() =>
                    keypadValue.value === "0"
                      ? null
                      : handleKeyPress(keypadValue.value)
                  }
                  {...(keypadValue.value === "0" ? attrs : {})}
                >
                  {keypadValue.value}
                  {keypadValue.subValue && (
                    <Text textStyle="h6" color="var(--grey-900)">
                      {keypadValue.subValue}
                    </Text>
                  )}
                </Button>
              );
            })}
          </HStack>
          <Button
            fontSize="0.875rem"
            background="var(--secondary-600)"
            w="100%"
            textAlign="center"
            onClick={handleCall}
            isDisabled={!(phoneNumber && currentLine)}
          >
            <HStack w="100%" gap="0.5rem" justifyContent="center">
              <CallIcon />
              <Text color="white" fontWeight="700" textStyle="h6">
                Call
              </Text>
            </HStack>
          </Button>
        </VStack>
      ) : (
        <VStack w="100%" alignItems="flex-start" padding="0.69rem 0.94rem">
          <HStack display={"flex"} alignSelf={"center"}>
            <Text fontWeight="700" textStyle="h4">
              {phoneNumber}
            </Text>
          </HStack>

          <Box border={"1px solid lightgray"} w="100%" />

          <HStack display={"flex"} alignSelf={"center"}>
            <Text fontWeight="500" textStyle="h6">
              {startCallTimestamp ? "In Progress..." : "Calling..."}
            </Text>
          </HStack>

          {startCallTimestamp && (
            <HStack display={"flex"} alignSelf={"center"} mt="1rem">
              <Text fontWeight="500" textStyle="h6">
                <Stopwatch />
              </Text>
            </HStack>
          )}

          <HStack height="250px" />

          <HStack display={"flex"} justifyContent={"space-around"} w="100%">
            <Button
              fontSize="0.875rem"
              background="var(--grey-500)"
              textAlign="center"
              // onClick={handleCall}
              border={"none"}
            >
              <HStack w="100%" gap="0.5rem" justifyContent="center">
                <NotesIcon />
              </HStack>
            </Button>

            <Button
              fontSize="0.875rem"
              background="var(--red-700)"
              textAlign="center"
              onClick={() => handleHangUp(dispatch, call, startCallTimestamp)}
              border={"none"}
              borderRadius="100%"
            >
              <HStack w="100%" gap="0.5rem" justifyContent="center">
                <CallEndIcon />
              </HStack>
            </Button>

            <Button
              fontSize="0.875rem"
              background="var(--grey-500)"
              textAlign="center"
              // onClick={handleCall}
              border={"none"}
            >
              <HStack w="100%" gap="0.5rem" justifyContent="center">
                <VoicemailIcon />
              </HStack>
            </Button>
          </HStack>
        </VStack>
      )}

      {soundTestOpen && (
        <SoundTest
          isOpen={soundTestOpen}
          onClose={() => setSoundTestOpen(false)}
        />
      )}
    </VStack>
  );
};

export default Keypad;
