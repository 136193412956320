import { Box, Progress, Text, VStack } from "@chakra-ui/react";

interface UsageCardProps {
  heading: string;
  amount: number;
  count: number;
  progress?: number;
}

const UsageCard = ({ heading, amount, count, progress }: UsageCardProps) => {
  return (
    <Box
      padding="0.75rem"
      borderRadius="0.5rem"
      border="1px solid var(--grey-300)"
      height="120px"
    >
      <VStack gap="0.5rem" alignItems="flex-start">
        <Text textStyle="h4">{heading}</Text>
        <Text textStyle="h6">Count:{count}</Text>
        <Text textStyle="h6">Amount:{Number(amount).toFixed(3)}$</Text>
        {progress && (
          <Progress
            value={progress}
            height="12px"
            width="100%"
            colorScheme="orange"
            borderRadius="0.5rem"
          />
        )}
      </VStack>
    </Box>
  );
};

export default UsageCard;
