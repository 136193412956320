import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Image,
  Input,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import AutoExpo from "../../../assets/images/autoExpo.svg";
import { Drawer, Dropdown, PaginatedTable } from "../../../components/common";
import CustomInput from "../../../components/common/Input";
import {
  EditIcon,
  PenIcon,
  SearchIcon,
  UploadVideoIcon,
} from "../../../components/icons/svg";
// import { useNavigate } from "react-router-dom";

export default function TrainingVideosPage() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  // const navigate = useNavigate();

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];
  // const [selectedOption, setSelectedOption] =
  //   useState<SelectOptionProps | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    console.log(option);
  };
  const activeHeader = [
    { id: 1, value: "Video Title", label: "title" },
    { id: 2, value: "Description", label: "description" },
    { id: 3, value: "Duration", label: "duration" },
    { id: 4, value: "Upload Date", label: "date" },
    { id: 5, value: "Views Count", label: "viewsCount" },
    { id: 9, value: "Actions", label: "actions" },
  ];

  const activeData = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
    return {
      title: (
        <HStack gap="1rem">
          <Image
            src={AutoExpo}
            width={38}
            height={38}
            style={{
              borderRadius: "50%",
            }}
            alt="avatar"
          />
          <VStack alignItems="flex-start" gap="0">
            <Text textStyle="h6" fontWeight="500">
              Social Media Lead Generation Proven Tactics for Building...
            </Text>
          </VStack>
        </HStack>
      ),
      description:
        "Discover the art of effective lead generation through the power of social media with our comprehensive guide. Uncover proven tactics and strategies for building a robust ...",
      duration: "01:00:00",
      date: (
        <Text textStyle="h6" fontWeight="500" whiteSpace="nowrap">
          12 Nov, 2023
        </Text>
      ),
      viewsCount: 23,
      actions: <Dropdown options={options} onSelect={handleSelect} />,
    };
  });

  const [tableHeader, setTableHeader] = useState(activeHeader);
  const [tableData, setTableData] = useState(activeData);

  const handleFileChange = (event: ChangeEvent) => {
    const file = event.target.files[0];

    if (file) {
      console.log(file);
    }
  };
  return (
    <VStack w="100%" alignItems="flex-start" gap="0">
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1.5rem"
        background="white"
      >
        <CustomInput
          maxW="20rem"
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Search Accounts..."
        />
        <Button onClick={onOpen}>Upload Video</Button>
      </HStack>
      <VStack w="100%" alignItems="flex-start" padding="1.5rem">
        <Box
          w="100%"
          alignItems="flex-start"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          <Text
            textStyle="caption"
            padding="1.5rem 1rem 0.75rem"
            w="100%"
            color="var(--grey-600)"
          >
            Active Accounts (324)
          </Text>
          <PaginatedTable
            header={tableHeader}
            data={tableData}
            itemsPerPage={10}
            maxPageNumbers={5}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
          />
        </Box>
      </VStack>
      {/* Create User Drawer */}
      <Drawer isOpen={isOpen} onClose={onClose} title="Upload Video">
        <VStack
          alignItems="flex-start"
          padding="1rem 1.5rem"
          w="100%"
          gap="1rem"
        >
          <CustomInput
            placeholder="Give your video a title"
            label="Video Title"
          />
          <CustomInput
            placeholder="Add Description"
            label="Description"
            isTextarea={true}
          />
          <CustomInput
            placeholder="Add link to your video on vimeo..."
            label="Import form vimeo"
          />
          <HStack w="100%" gap="1.5rem">
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
            <Box
              as="span"
              fontSize="1.125rem"
              fontWeight="600"
              color="var(--secondary-600)"
              borderRadius="6.25rem"
              background="var(--secondary-50)"
              padding="0.31rem 1.19rem"
            >
              Or
            </Box>
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
          </HStack>
          <VStack w="100%" alignItems="center">
            <Text textStyle="h6" w="100%" textAlign="center" fontWeight="500">
              Upload Video
            </Text>
            <VStack
              position="relative"
              width="100%"
              gap="1rem"
              textAlign="center"
              w="22rem"
              h="14rem"
              justifyContent="center"
              alignItems="center"
              border="1px dashed var(--grey-300)"
              borderRadius="0.5rem"
              mx="auto"
            >
              <Input type="file" onChange={handleFileChange} variant="file" />

              <UploadVideoIcon />
              <HStack gap="0.25rem">
                <Text textStyle="h6" color="var(--primary-600)">
                  Click to upload
                </Text>
                <Text textStyle="h6" color="var(--grey-600)">
                  or drag and drop
                </Text>
              </HStack>
              <Text textStyle="h6" color="var(--grey-600)">
                MP4, MOV or WebM (max. 800x400px)
              </Text>
            </VStack>
            <Button variant="none">
              <HStack>
                <PenIcon />
                <Text
                  textStyle="h6"
                  w="100%"
                  textAlign="center"
                  fontWeight="500"
                >
                  Edit Thumbnaill
                </Text>
              </HStack>
            </Button>
          </VStack>
        </VStack>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl">
            Cancel
          </Button>
          <Button w="100%" size="xl">
            Upload
          </Button>
        </DrawerFooter>
      </Drawer>
    </VStack>
  );
}
