import axios from "axios";
import { SERVICES } from "../../config";
import {
  DocumentApiRequest,
  DocumentRemoveApiRequest,
  NotesApiRequest,
} from "../../components/GlobalDashboard/CampaignListing/utils";
import { CreateCampaignForm } from "../../components/GlobalDashboard/CreateCampaign/util";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//campaign apis
export const getCampaigns = async (params: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/campaigns`, { params });

export const getCampaign = async (id: string): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/campaigns/${id}`);

export const addCampaignNotes = async (
  payload: NotesApiRequest
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/campaigns/${payload.id}/add_notes?notes=${payload.notes}`,
    undefined,
    {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Campaign Registration',
      },
    }
  );
export const removeCampaignDocuments = async (
  payload: DocumentRemoveApiRequest
): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/campaigns/${payload.id}/remove_file?attachment_id=${payload.attachmentId}`, {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Campaign Registration',
      }
    }
  );
export const addCampaignDocuments = async (
  payload: DocumentApiRequest
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/campaigns/${payload.id}/attach_file`,
    payload.file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Campaign Registration',
      },
    }
  );
export const updateCampaign = async (
  payload: CreateCampaignForm
): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/campaigns/${payload.id}`,
    {
      campaign: payload,
    },
    {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Campaign Registration',
      },
    }
  );
