import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchTrainingVideos = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/training_videos`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateTraningVideo = async (id: any, payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/training_videos/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          menu: "Global Configurations",
          "Sub-Menu": "Training Videos",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateTrainingVideoCount = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/training_videos/${payload.id}/increase_count`
  );

export const deleteTraningVideo = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/training_videos/${id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Training Videos",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createTraningVideo = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/training_videos`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          menu: "Global Configurations",
          "Sub-Menu": "Training Video",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchTraningVideoById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/training_videos/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchTraningVideoThumbnail = async (videoId: string | null) => {
  const response = await fetch(
    `https://vimeo.com/api/v2/video/${videoId}.json`
  );
  const data = await response.json();

  if (Array.isArray(data) && data.length > 0) {
    return data[0].thumbnail_large;
  } else {
    return "";
  }
};
