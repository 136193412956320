import {
  Box,
  Button,
  Checkbox,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Input, PhoneInput, Select } from "../../../../common";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchContactsById,
  fetchPrefix,
  updateContact,
} from "../../../../../utils/api/contact-details";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .matches(/^[A-Za-z\s]+$/, "First name must contain only alphabets"),
  middle_name: yup
    .string()
    .nullable()
    .matches(/^[A-Za-z\s]+$/, "Middle name must contain only alphabets"),
  last_name: yup
    .string()
    .nullable()
    .matches(/^[A-Za-z\s]+$/, "Last name must contain only alphabets"),
  nick_name: yup
    .string()
    .nullable()
    .matches(/^[A-Za-z\s]+$/, "Nick name must contain only alphabets"),
  phone_number: yup
    .string()
    .required("Please enter your phone number")
    .test("is-valid-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value || "")
    ),
  home_number: yup
    .string()
    .nullable()
    .test("is-valid-phone-number", "Invalid phone number", (value) => {
      if (!value || value === "+1") {
        return true;
      } else {
        return isValidPhoneNumber(value);
      }
    }),
  work_number: yup
    .string()
    .nullable()
    .test("is-valid-phone-number", "Invalid phone number", (value) => {
      if (!value || value === "+1") {
        return true;
      } else {
        return isValidPhoneNumber(value);
      }
    }),
  email: yup.string().nullable().email(),
});

const PersonalInfo = (props: any) => {
  const {
    id,
    onCPClose,
    refetchParent,
    setIsLoadingChild,
    direction = false,
  } = props;
  const dealer = useUserInfo("dealership");
  const toast = useToast();
  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { isFetching } = useQuery({
    queryKey: ["fetchContactsById"],
    queryFn: () => fetchContactsById(dealer?.id, id),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      if (data?.data) {
        Object.keys(data.data).forEach((key: any) => {
          setValue(key, data.data[key]);
        });
      }
    },
  });

  const { data: options, isLoading: isLoadingPrefix } = useQuery({
    queryKey: ["fetchPrefix"],
    queryFn: () => fetchPrefix(dealer?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    if (!data.home_number) {
      data.home_number = "";
    }
    if (!data.work_number) {
      data.work_number = "";
    }
    mutation.mutate(
      { dId: dealer?.id, id, payload: { contact: data } },
      {
        onSuccess: () => {
          refetchParent();
          onCPClose();
          toast({
            description: "Setting updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error updating setting: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  useEffect(() => {
    if (isFetching || mutation?.isLoading || isLoadingPrefix) {
      setIsLoadingChild(true);
    } else {
      setIsLoadingChild(false);
    }
  }, [isFetching, mutation?.isLoading, isLoadingPrefix, setIsLoadingChild]);

  return (
    <VStack position="relative">
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem 0 4rem"
        gap="0.5rem"
        h="calc(100vh - 140px)"
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            User Information
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <HStack
              w="100%"
              alignItems="flex-start"
              flexWrap={{ md: "nowrap", base: "wrap" }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="First Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="middle_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Middle Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Last Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-start">
              <Controller
                name="prefix"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    label="Prefix"
                    placeholder="Prefix"
                    onChange={(value: any) => setValue("prefix", value?.value)}
                    options={options?.data?.prefixes?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                    error={error?.message || ""}
                  />
                )}
              />

              <Controller
                name="nick_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Nick Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-end">
              <Controller
                name="company_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Company Name"
                    maxLength={200}
                    error={error?.message || ""}
                  />
                )}
              />
              <Box w="50%" padding="10px">
                <Controller
                  name="use_nick_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      {...field}
                      size="lg"
                      variant="whiteCheck"
                      onChange={(e: any) => {
                        setValue("use_nick_name", e.target.checked);
                      }}
                      isChecked={Boolean(field.value)}
                    >
                      User Nickname
                    </Checkbox>
                  )}
                />
              </Box>
            </HStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            Phone
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <VStack
              w="100%"
              alignItems="flex-start"
              flexDirection={`${direction ? "row" : "column"}`}
              // flexWrap={direction ? "wrap" : "nowrap"}
            >
              <Controller
                name="phone_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Mobile"
                    placeholder="Enter phone number"
                    isRequired
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="home_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Home"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="work_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Work"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
            </VStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Email
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder=""
                  label="Email Address"
                  maxLength={320}
                  error={error?.message || ""}
                />
              )}
            />
          </VStack>
        </VStack>
      </VStack>
      <Button
        w="100%"
        marginTop="auto"
        position="absolute"
        left="0"
        bottom="0"
        onClick={handleSubmit(onSubmit)}
      >
        Save Settings
      </Button>
    </VStack>
  );
};

export default PersonalInfo;
