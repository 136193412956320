import { useQuery, useMutation } from "@tanstack/react-query";
import { VStack, Text, Box, HStack } from "@chakra-ui/layout";
import Loader from "../../../../common/Spinner";
import { useState } from "react";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { Button, useToast } from "@chakra-ui/react";
import { PaginatedTable } from "../../../../common";
import {
  getCreditScore,
  postCreditScore,
} from "../../../../../utils/api/700credit.api";
import moment from "moment";
import { humanize } from "../../../../../utils";
import Popup from "../../../../Charts/Popup";
import ContactSettings from "../../ContactDetails/ContactSettings/ContactSettings";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Sevencredit = ({ clickedRow }: any) => {
  const creditMutation = useMutation(postCreditScore);
  const [page, setPage] = useState(1);
  const dId = useUserInfo("dealership")?.id;
  const [count, setCount] = useState(0);
  const toast = useToast();
  const [isCPDrawerOpen, setIsCPDrawerOpen] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["fetchscorecard", dId, clickedRow, page, 5],
    queryFn: getCreditScore,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.data?.credit_scores_count);
    },
  });
  const fetchScoreCall = (type: string) => {
    creditMutation.mutate(
      {
        id: dId,
        data: {
          contact_id: clickedRow,
          report_name: type,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          toast({
            description: "Record fetched successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          console.log(error?.response?.data?.error);
          if(error?.response?.data?.missing_fields?.length>0){
          setPopup({
            name: error?.response?.data?.error,
            description:
              "It seems like some information is missing. Please complete the contact information first, then return here. Click 'Confirm' to proceed to the form.",
            value: "",
          });
          onOpenPopupOpen();
        }else{
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
        },
      }
    );
  };
  const tableHeader = [
    { id: 1, value: "Report", label: "report" },
    { id: 2, value: "Date&Time", label: "time" },
  ];
  const tableData = data?.data?.credit_scores?.map((item: any) => ({
    id: item.id,
    report: (
      <Text
        cursor={"pointer"}
        color={"blue"}
        onClick={() => {
          setDetail(item?.url);
        }}
      >
        {humanize(item?.name) + " Report"}
      </Text>
    ),
    time: moment(item?.created_at)?.format("dddd DD MMM YY • hh:mm a"),
  }));
  return (
    <>
      {(isFetching || creditMutation?.isLoading) && <Loader />}

      <VStack
        height="calc(100vh - 120px)"
        background="var(--grey-50)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-end"
        w="100%"
      >
        <VStack
          background="white"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
          alignItems="flex-start"
          gap="1rem"
          h="100%"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500">
            700 Credit
          </Text>

          {detail ? (
            <>
              <Button
                variant="none"
                display="flex"
                alignItems="center"
                gap="0.63rem"
                onClick={() => setDetail(null)}
              >
                <Box
                  as="span"
                  display="flex"
                  alignItems="center"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  padding="0.38rem"
                >
                  <ChevronLeftIcon
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                      color: "black",
                    }}
                  />
                </Box>
                Back
              </Button>
              <VStack
                w="100%"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                gap="0"
                h="100%"
              >
                <HStack
                  alignItems="flex-start"
                  justifyContent="space-between"
                  background="var(--grey-200)"
                  padding="1rem"
                  borderTopRightRadius="0.5rem"
                  borderTopLeftRadius="0.5rem"
                  borderBottom="1px solid var(--grey-300)"
                  width="100%"
                >
                  <iframe src={detail} width="100%" height="500px" />
                </HStack>
              </VStack>
            </>
          ) : (
            <>
              <HStack>
                <Button
                  maxW="100%"
                  width="100%"
                  my="0.5rem"
                  onClick={() => fetchScoreCall("soft_pull")}
                >
                  Soft Pull
                </Button>
                <Button
                  maxW="100%"
                  width="100%"
                  my="0.5rem"
                  onClick={() => fetchScoreCall("hard_pull")}
                >
                  Hard Pull
                </Button>
              </HStack>

              <PaginatedTable
                header={tableHeader}
                data={tableData}
                // itemsPerPage={perPage}
                maxPageNumbers={5}
                hasPadding={false}
                isPaginated={true}
                perPageChange={(value) => {
                  // setPerPage(Number(value?.label));
                }}
                hasMultiBody={false}
                currentPage={page}
                setCurrentPage={setPage}
                rowCount={count}
                itemsPerPage={5}
              />
            </>
          )}
        </VStack>
      </VStack>
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={() => {
            setIsCPDrawerOpen(true);
            onClosePopupOpen();
          }}
          title={popup.name}
          message={popup.description}
        />
      )}
      {isCPDrawerOpen && (
        <ContactSettings
          isCPDrawerOpen={isCPDrawerOpen}
          onCPClose={() => {
            setIsCPDrawerOpen(false);
          }}
          id={clickedRow}
          refetchParent={refetch}
        />
      )}
    </>
  );
};

export default Sevencredit;
