import {
  Button,
  FormControl,
  Input,
  InputGroup,
  Text,
  VStack,
  HStack,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { isValidEmail } from "../../../utils";
import { forgotpassword } from "../../../utils/api/login.api";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "../../../components/common";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

const ForgetPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const mutation = useMutation(forgotpassword);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: "", password: "" };

    // Validate email
    if (!form.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(form.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (e: ReactEvent) => {
    e.preventDefault();
    if (validateForm()) {
      mutation.mutate(
        {
          user: {
            email: form.email,
          },
        },
        {
          onSuccess: () => {
            setForm({ email: "" });
            toast({
              title: "Email sent successfully",
              description:
                "Please check your inbox and spam folder. If you don't receive an email, please ensure the email entered is correct.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
              onCloseComplete() {
                navigate("/login");
              },
            });
          },
          onError: (error, variables, context) => {
            toast({
              description:
                "Oops! It seems we're experiencing some technical difficulties. Please check your internet connection and try again. If the issue persists, please try again later. We apologize for any inconvenience caused.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  };

  return (
    <VStack
      position="relative"
      alignItems="center"
      justifyContent="center"
      w="50%"
    >
      <Box
        style={{ paddingLeft: 30, paddingTop: 30 }}
        position="absolute"
        top="0"
        left="0"
        sx={{
          a: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          },
        }}
      >
        <Link to="/login">
          <ChevronLeft />
          <Text textStyle="h6" fontWeight="700">
            Back to Login
          </Text>
        </Link>
      </Box>
      <VStack
        w="31.25rem"
        borderRadius={8}
        bg="white"
        alignItems="flex-start"
        alignSelf="center"
        spacing={4}
        m="0 auto"
      >
        <Text textStyle="h2">Forgot Password?</Text>
        <Text textStyle="h4" color="grey.700">
          Enter your email to recover your password
        </Text>
        <FormControl w="full">
          <InputGroup>
            <Input
              type="email"
              name="email"
              value={form.email}
              placeholder="Enter email address"
              onChange={handleChange}
            />
          </InputGroup>
          <Text color="red.500" fontSize="sm">
            {errors.email}
          </Text>
        </FormControl>
        <HStack w="full" justify="space-between"></HStack>
        <Button size="xl" w="full" mt={4} onClick={handleSubmit}>
          Send Email
        </Button>
        {mutation.isLoading && <Spinner />}
      </VStack>
    </VStack>
  );
};

export default ForgetPage;
