import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchTradeIns = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/trade_ins`,
    { params: queryKey[3] }
  );
};
export const createTradeIns = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/trade_ins`,
    payload.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const deleteTradeIn = async (payload: any) => {
  return await axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/trade_ins/${payload.tId}`
  );
};

