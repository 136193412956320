import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Clear } from "@mui/icons-material";
import Draggable from "react-draggable";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";

function ScriptModal() {
  const { scriptParams } = useTwilioState();
  const dispatch = useTwilioDispatch();
  const { open, title, description } = scriptParams || {};
  console.log("open", open);
  return open ? (
    <Draggable
      // bounds="parent"
      allowAnyClick
      defaultPosition={{
        x: 300,
        y: 200,
      }}
    >
      <VStack
        w="480px"
        alignItems="flex-start"
        gap="0"
        paddingTop="0"
        background="white"
        border="1px solid black"
        borderRadius="10px"
        zIndex="9999"
        position="absolute"
      >
        <Box width="100%" borderBottom="1px solid var(--grey-300)">
          <HStack justifyContent="space-between">
            <Text
              textStyle="h5"
              fontWeight="600"
              color="var(--grey-800)"
              padding="1rem"
            >
              {title}
            </Text>
            <Box
              padding="1rem"
              cursor="pointer"
              onClick={() =>
                dispatch({
                  type: "SET_SCRIPT_PARAMS",
                  payload: {
                    scriptParams: { open: false, title: "", description: "" },
                  },
                })
              }
            >
              <Clear />
            </Box>
          </HStack>
        </Box>
        <Text padding="1rem">{description}</Text>
      </VStack>
    </Draggable>
  ) : (
    <></>
  );
}

export default ScriptModal;
