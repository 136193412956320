import { Box, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PaginatedTable } from "../../../components/common";
import ExportReport from "../../../pages/(dashboard-routes)/reports/ExportReport";
import { fetchAvgLeadRespReport } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";

const AverageLeadResponse = () => {
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchAvgLeadRespReport",
      dId,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      perPage,
    ],
    queryFn: fetchAvgLeadRespReport,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const TableHeader = [
    { id: 1, value: "Lead Sources", label: "leadSources" },
    { id: 2, value: "# Of Leads", label: "noOfLeads" },
    { id: 3, value: "1st 0-15 Min", label: "0" },
    { id: 4, value: "1st 16-30 Min", label: "16" },
    { id: 5, value: "1st 31-60 Min", label: "31" },
    { id: 6, value: "1st 61 & More", label: "61" },
  ];

  const TableData = data?.data?.data?.map((item: any) => ({
    leadSources: item["lead_source"],
    noOfLeads: (
      <Text color="var(--primary-600)">{item["number_of_leads"]}</Text>
    ),
    0: item["first_0_15_min"],
    16: item["first_16_30_min"],
    31: item["first_31_60_min"],
    61: item["first_61_and_more"],
  }));

  const columnBackgrounds = [
    "",
    "",
    "#43A047",
    "#FFF7CC",
    "#FFB938",
    "#FF5454",
  ];
  return (
    <Box>
      <HStack justifyContent="space-between" mb="1rem">
        <Box>
          <DatePicker
            value={selectionRange}
            onChange={(value) => setSelectionRange(value)}
            w="100%"
          />
        </Box>{" "}
        <ExportReport reportType="avg_lead_response" />
      </HStack>
      <Text textStyle="caption" color="var(--grey-600)" mb="1rem">
        Found {data?.data?.total_count}
      </Text>
      <PaginatedTable
        header={TableHeader}
        data={TableData}
        maxPageNumbers={3}
        isPaginated={true}
        hasMultiBody={false}
        itemsPerPage={perPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.total_count}
        isLoadingData={isLoading}
        columnBackgrounds={columnBackgrounds}
      />
    </Box>
  );
};

export default AverageLeadResponse;
