import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { CustomRadio, Drawer, Dropdown, Input } from "../../components/common";
import {
  ExcelIcon,
  FilterIcon,
  HandShakeIcon,
  NoOfMeetingIcon,
  PDFIcon,
  RefreshIcon,
  SearchIcon,
} from "../../components/icons/svg";
import { getDeskLogTiles } from "../../utils/api/desk-log.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import DeskLogListing from "./DeskLogListing";
import NumberCard from "./NumberCard";
import axios from "axios";
import { SERVICES } from "../../config";
import fileDownload from "js-file-download";
import Loader from "../common/Spinner";
import DatePicker from "../common/DateRangePicker";
import moment from "moment";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

export default function DeskLog() {
  const [isAdvanceFilterOpen, setIsAdvanceFilerOpen] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState("");
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: new Date(""),
    selected: false,
  });
  const onAdvanceFilterClose = () => setIsAdvanceFilerOpen(false);
  const onAdvanceFilterOpen = () => setIsAdvanceFilerOpen(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const user = useUserInfo("user");

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/admin/dealerships/${user.dealership?.id}/dashboard/desk_log/export.${value}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `DeskLogs.${value}`);
      })
      .catch((response) => {});
  };

  const {
    data: tilesData,
    isFetching: isTilesLoading,
    refetch,
  } = useQuery({
    queryKey: ["fetchDeskLogTiles", searchStr, dateRange],
    queryFn: () =>
      getDeskLogTiles({
        dealershipId: user?.dealership?.id,
        searchStr,
        dateRange,
      }),
    retry: false,
    onSuccess: () => setShouldRefresh(false),
  });

  useEffect(() => {
    if (shouldRefresh) {
      refetch();
    }
  }, [refetch, shouldRefresh]);

  const { todays_ups, number_of_sales, closing_ratio } =
    tilesData?.data || {};

  const numberCards = useMemo(() => {
    return [
      {
        id: 1,
        icon: <NoOfMeetingIcon />,
        stats: [
          {
            key: "Today's Ups",
            value: todays_ups || 0,
          },
        ],
        bg: "var(--primary-900)",
      },
      {
        id: 2,
        icon: <HandShakeIcon />,
        stats: [
          {
            key: "Number of Sales",
            value: number_of_sales || 0,
          },
          {
            key: "Closing Ratio",
            value: `${(closing_ratio || 0) * 100} %`,
          },
        ],
        bg: "var(--primary-700)",
      },
    ];
  }, [closing_ratio, number_of_sales, todays_ups]);

  return (
    <VStack w="100%">
      {isTilesLoading && <Loader />}
      <HStack
        w="100%"
        justifyContent="space-between"
        p="1rem 1.5rem"
        background="white"
      >
        <HStack width="80%">
          <Input
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon color="var(--grey-900)" />}
            placeholder="Search.."
            maxW="20rem"
            value={searchStr}
            onChange={({ target: { value } }) => setSearchStr(value)}
          />
          <Box>
            <DatePicker
              label="Select Date Range"
              value={{
                startDate: dateRange.start_date as unknown as Date,
                endDate: dateRange.end_date as Date,
              }}
              onChange={(range: {
                startDate: Date;
                endDate: Date;
                clear?: boolean;
              }) => {
                setDateRange({
                  start_date: range?.startDate as any,
                  end_date: range?.endDate,
                  selected: range.clear
                    ? false
                    : moment(range?.startDate).format("DD-MM-YYYY") !==
                      moment(range?.endDate).format("DD-MM-YYYY"),
                });
              }}
              w="100%"
              shouldShowClear
              isRange
              shouldShowLabel={false}
            />
          </Box>
        </HStack>
        <HStack>
          <Button
            variant="outline"
            onClick={() => setShouldRefresh(!shouldRefresh)}
          >
            <RefreshIcon />
          </Button>
          <Dropdown
            options={ExportOptions}
            hasText={true}
            text="Export"
            onSelect={(value: any) => handleExport(value.value)}
          />
        </HStack>
      </HStack>
      <VStack p="1rem 1.5rem" gap="1rem" w="100%" alignItems="flex-start">
        <HStack
          p="1.5rem"
          background="white"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          w="100%"
        >
          {/* <HStack alignItems="flex-start" gap="2.75rem" w="10%">
            <VStack alignItems="flex-start" gap="0.25rem">
              <Button
                variant="none"
                display="flex"
                alignItems="center"
                gap="0.6rem"
                color="var(--primary-600)"
                onClick={onAdvanceFilterOpen}
              >
                <FilterIcon color="var(--primary-600)" />
                <Text textStyle="h6">Advance Filters</Text>
              </Button>
            </VStack>
          </HStack> */}
          <HStack w="100%" gap="1rem" display="flex">
            {numberCards.map((item) => (
              <NumberCard icon={item.icon} stats={item.stats} bg={item.bg} />
            ))}
          </HStack>
        </HStack>
        <DeskLogListing
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
          searchStr={searchStr}
          dateRange={dateRange}
        />
      </VStack>
      {/* Advance Filters Drawer */}
      <Drawer
        onClose={onAdvanceFilterClose}
        isOpen={isAdvanceFilterOpen}
        title="Filters"
      >
        <VStack w="100%" padding="1.5rem" alignItems="flex-start" gap="1.5rem">
          <CustomRadio
            options={[
              { label: "New", value: "new" },
              { label: "Pre Owned", value: "pre-owned" },
            ]}
            label="Vehicle Type"
            alignment="flex-start"
            size="md"
            direction="column"
            //   value={sendOption}
            //   setValue={(value: string) => setSendOption(value)}
          />
          <CustomRadio
            options={[
              { label: "Owner/GM", value: "gm" },
              { label: "Manager/ Admin", value: "admin" },
              { label: "Sales person", value: "sales" },
              { label: "BDC Agent", value: "bdc" },
            ]}
            label="Employee Tag"
            alignment="flex-start"
            size="md"
            direction="column"
            //   value={sendOption}
            //   setValue={(value: string) => setSendOption(value)}
          />
          <CustomRadio
            options={[
              { label: "Internet", value: "internet" },
              { label: "Service Flip", value: "service-flip" },
              { label: "Phone up", value: "phone-up" },
              { label: "Walk in", value: "walk-in" },
            ]}
            label="Origin"
            alignment="flex-start"
            size="md"
            direction="column"
            //   value={sendOption}
            //   setValue={(value: string) => setSendOption(value)}
          />
        </VStack>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl">
            Cancel
          </Button>
          <Button w="100%" size="xl">
            Apply Filters
          </Button>
        </DrawerFooter>
      </Drawer>
    </VStack>
  );
}
