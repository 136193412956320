import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const createBroadcastMessage = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns`,
    params.payload
  );

export const editBroadcastMessage = async (params: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}`,
    params.payload
  );

export const pauseMessageCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}/pause_campaign`,
    params.payload
  );

export const resumeMessageCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}/resume_campaign`,
    params.payload
  );

export const pauseEmailCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_logs/${params.id}/pause_emails`,
    params.payload
  );

export const resumeEmailCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_logs/${params.id}/resume_emails`,
    params.payload
  );

export const getBroadcastMessages = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${
      params.dealership_id
    }/message_campaigns?status=${params.status}&page=${params.page}&per_page=${
      params.perPage
    }${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `&start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );

export const getBroadcastCounts = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${
      params.dealershipId
    }/message_campaigns/broadcast_status_count${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `?start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );

export const getVoicemailCounts = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${
      params.dealershipId
    }/ringless_voicemail_campaigns/voicemail_status_count${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `?start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );
export const getEmailsCommunication = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/email_logs/${params.id}/communication_logs?page=${params.page}&per_page=${params.perPage}`
  );

export const getBroadcastCommunication = async (
  params: any
): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contact_message_campaigns?id=${params.id}&page=${params.page}&per_page=${params.perPage}`
  );

export const getBroadcastMessage = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}`
  );

export const fetchInbox = async (params: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/inbound_emails_list?page=${params.page}&per_page=${params.perPage}&search=${params.search}`
  );

export const fetchEmailBlast = async (params: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/outbound_emails_list?page=${params.page}&per_page=${params.perPage}&search=${params.search}`
  );

export const getBroadcastRVMs = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${
      params.dealership_id
    }/ringless_voicemail_campaigns?status=${params.status}&page=${
      params.page
    }&per_page=${params.perPage}${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `&start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );

export const getBroadcastRVM = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns/${params.id}`
  );

export const createBroadcastRVM = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns`,
    params.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );

export const editBroadcastRVM = async (params: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns/${params.id}`,
    params.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );

export const toggleRVMCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns/${params.id}/pause_or_resume?perform_action=${params.status}&campaign_id=${params.campaignId}`,
    params.payload
  );

export const approveCampaign = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns/${params.id}/approve_campaign`,
    { params: params.payload }
  );

export const rejectCampaign = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/ringless_voicemail_campaigns/${params.id}/reject_campaign`,
    { params: params.payload }
  );

export const approveMessageCampaign = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}/approve_campaign`,
    { params: params.payload }
  );

export const rejectMessageCampaign = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/message_campaigns/${params.id}/reject_campaign`,
    { params: params.payload }
  );
