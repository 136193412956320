import { Button, DrawerFooter, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  CustomRadio,
  Drawer,
  Input,
  PhoneInput,
} from "../../../../components/common";
import SwitchToggle from "../../../../components/common/Switch";
import {
  fetchRoles,
  fetchUserById,
  updateDPUser,
  updateUserForDealershipMenu,
} from "../../../../utils/api/users.api";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import {
  isValidName,
  isValidPhoneFunc,
} from "../../../../utils";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  id: number;
  isGlobal?: boolean;
  dealershipId?: string;
};
type permission = {
  allowed: boolean;
  display_name: string;
  module_key: string;
};

interface ApiResult {
  data: any;
}
interface ApiRequest {
  user: {
    first_name: string;
    last_name: string;
    phone: string;
    user_role_attributes: {
      role_id: number;
      permissions: permission[];
    };
  };
}
const EditDPUser = (props: data) => {
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, refetchParent, id, dealershipId } = props;
  const toast = useToast();
  //..........api calling..............
  const { data, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(false),
  });

  const { data: user } = useQuery({
    queryKey: ["userbyid"],
    queryFn: () => fetchUserById(id),
  });

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [roleOptions, setRoleOptions] = useState<SelectOptionProps[]>([]);
  const [permissionOptions, setPermissionOptions] = useState<permission[]>([]);

  //.......error states......
  const [nameError, setNameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    setName(user?.first_name);
    setLastname(user?.last_name);
    setEmail(user?.email);
    setPhone(user?.phone);

    setRoleOptions(
      data?.roles?.map((role: any) => ({
        value: role.id.toString(),
        label: role.title,
      }))
    );

    setRole(
      data?.roles
        ?.find((r: any) => r.title.toString() === user?.access_level)
        ?.id.toString()
    );

    if (Array.isArray(user?.permissions)) {
      setPermissionOptions(user?.permissions);
    }
  }, [data, user]);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      // if dealershipId exists then it is from the global portal dealership users
      if (dealershipId) {
        try {
          const response = await updateUserForDealershipMenu(
            id,
            payload,
            dealershipId
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await updateDPUser(id, dealerId?.id, payload);
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "User edited successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error editing user: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let fnameError = isValidName(name, setNameError, "First Name");
    let lnameError = isValidName(lastname, setLastnameError, "Last Name");
    let phoneError = isValidPhoneFunc(phone, setPhoneError);

    if (!fnameError || !lnameError || !phoneError) {
      return;
    }

    const requestData: ApiRequest = {
      user: {
        first_name: name,
        last_name: lastname,
        phone: phone ?? "",
        user_role_attributes: {
          role_id: Number(role),
          permissions: permissionOptions,
        },
      },
    };

    mutation.mutate(requestData);
    onClose();
  };

  const handlePermissionToggle = (moduleKey: string, isChecked: boolean) => {
    setPermissionOptions((prevPermissions: permission[]) => {
      return prevPermissions?.map((permission: permission) =>
        permission.module_key === moduleKey
          ? { ...permission, allowed: isChecked }
          : permission
      );
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Edit User"
      isLoading={isLoading || mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Enter First Name"
          label="First Name"
          onChange={(e: any) => {
            setNameError("");
            setName(e.target.value);
          }}
          value={name}
          isRequired={true}
          error={nameError}
        />
        <Input
          placeholder="Enter Last Name"
          label="Last Name"
          onChange={(e: any) => {
            setLastnameError("");
            setLastname(e.target.value);
          }}
          value={lastname}
          isRequired={true}
          error={lastnameError}
        />
        <Input
          placeholder="Enter email address"
          label="Email"
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
          value={email}
          isRequired={true}
          isDisabled={true}
        />

        <PhoneInput
          label="Phone number"
          placeholder="Enter phone number"
          onChange={(value) => {
            setPhoneError("");
            setPhone(value);
          }}
          isRequired={false}
          value={phone}
          error={phoneError}
        />

        <CustomRadio
          label="Place choose an access level for the user"
          direction="column"
          options={roleOptions}
          value={role}
          setValue={(value: string) => {
            setRole(value);
            const selectedRole = data?.roles.find(
              (r: any) => r.id.toString() === value
            );
            setPermissionOptions(
              selectedRole?.permissions?.map((permission: permission) => ({
                allowed: permission.allowed,
                display_name: permission.display_name,
                module_key: permission.module_key,
              }))
            );
          }}
        />

        <VStack
          padding="1rem"
          background="var(--grey-100)"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          gap="1rem"
          w="100%"
          alignItems="flex-start"
        >
          <Text textStyle="h6" color="var(--grey-800)">
            Set custom permissions below
          </Text>

          {permissionOptions?.map((permission: any) => (
            <SwitchToggle
              key={permission.module_key}
              defaultChecked={permission.allowed}
              label={permission.display_name}
              onChange={(isChecked: any) =>
                handlePermissionToggle(permission.module_key, isChecked)
              }
            />
          ))}
        </VStack>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Edit User
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default EditDPUser;
