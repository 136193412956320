import {
  Box,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { humanize } from "../../../../../../utils";
import {
  createNote,
  getNotes,
  getScoreCard,
} from "../../../../../../utils/api/communication.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import { Notes } from "../../../../../../utils/types";
import NotesDrawer from "../../../../../Drawers/NotesDrawer";
import AudioPlayer from "../../../../../common/AudioPlayer";
import {
  GreenAddIcon,
  InboundCallIcon,
  OutboundCallIcon,
  SimpleDateIcon,
} from "../../../../../icons/svg";
import { PieChart } from "@mui/icons-material";
import ProgresBar from "./ProgresBar";

type CallDetailType = {
  clickedRecord: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function CallDetail({ clickedRecord, setClickedRecord }: CallDetailType) {
  const [addNotes, setAddNotes] = useState(false);
  const [notes, setNotes] = useState<Notes[]>([]);
  const { details, sub_type, history_log_id } = clickedRecord || {};
  const audioData = details?.recording_url ?? "";
  const transcribed = details?.recording_url ?? "";

  const dealership = useUserInfo("dealership");

  const toast = useToast();

  const { isLoading, refetch } = useQuery({
    queryKey: ["callNotes", dealership.id],
    queryFn: () =>
      getNotes({ dealershipId: dealership.id, callHistoryId: history_log_id }),
    retry: false,
    onSuccess: (data) => {
      setNotes(data.data);
    },
  });
  const { data } = useQuery({
    queryKey: ["callScorecard", dealership.id],
    queryFn: () =>
      getScoreCard({
        dealershipId: dealership.id,
        callHistoryId: history_log_id,
        contactId: clickedRecord?.contact_id,
      }),
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createNote({
          dealershipId: dealership.id,
          callHistoryId: history_log_id,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Note added Successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setAddNotes(false);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error adding note: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      background="var(--grey-50)"
      padding="1rem 1.3rem"
      pb="3rem"
      height="calc(100vh - 290px)"
    >
      <VStack
        w="100%"
        alignItems="flex-start"
        borderTop="1px solid var(--grey-300)"
      >
        <HStack w="100%" justifyContent="space-between" padding="1rem 0">
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => setClickedRecord(null)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
            Back
          </Button>
        </HStack>

        {data?.data?.scorecard && (
          <HStack
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            gap="0"
          >
            <VStack
              alignItems="flex-start"
              width="50%"
              borderRight="1px solid var(--grey-300)"
            >
              <Box
                borderBottom="1px solid var(--grey-300)"
                w="100%"
                background="var(--grey-200)"
              >
                <Text
                  p="1rem"
                  textStyle="h4"
                  fontWeight="700"
                  color="var(--grey-900)"
                >
                  Score Board
                </Text>
              </Box>
              <Box p="1rem">
                <VStack>
                  <ProgresBar
                    value={data?.data?.scorecard?.score}
                    size={data?.data?.scorecard?.total}
                  />
                </VStack>
                <Text
                  textStyle="h5"
                  fontWeight="500"
                  mt="2rem"
                  color="var(--grey-900)"
                >
                  Total score :
                  <span style={{ fontWeight: "700" }}>
                    {data?.data?.scorecard?.score} out of{" "}
                    {data?.data?.scorecard?.total}
                  </span>
                </Text>
                <Text textStyle="h5" color="var(--grey-900)" mt="1rem">
                  This score represents the quantity of keywords that are being
                  used in this call. You can also increase the count by using
                  rule conditionals in templates
                </Text>
                <VStack alignItems="flex-start" mt="1rem">
                  <Text textStyle="h4" fontWeight="600" color="var(--grey-900)">
                    Keyword Count
                  </Text>

                  <VStack
                    gap="1rem"
                    alignItems="flex-start"
                    width="100%"
                    mt="1rem"
                  >
                    <VStack alignItems="flex-start">
                      {Object?.entries(
                        data?.data?.scorecard?.keyword_count ?? {}
                      ).map(([key, value]) => (
                        <HStack gap="3rem" key={key}>
                          <Text
                            textStyle="h6"
                            color="var(--grey-700)"
                            lineHeight="1.125"
                            fontWeight="400"
                            w="5rem"
                            whiteSpace="nowrap"
                          >
                            {key}
                          </Text>
                          <Text
                            textStyle="captionSmall"
                            lineHeight="1.125"
                            fontWeight="500"
                            color="#1565C0"
                          >
                            {value as any}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </VStack>
                </VStack>
              </Box>
            </VStack>

            <VStack
              alignItems="flex-start"
              width="50%"
              borderLeft="1px solid var(--grey-300)"
            >
              <Box
                borderBottom="1px solid var(--grey-300)"
                w="100%"
                background="var(--grey-200)"
              >
                <Text
                  p="1rem"
                  textStyle="h4"
                  fontWeight="700"
                  color="var(--grey-900)"
                >
                  Communication Summary
                </Text>
              </Box>
              <Box p="1rem">
                <VStack alignItems="flex-start" mt="1rem">
                  <UnorderedList>
                    <ListItem
                      fontSize="1rem"
                      lineHeight="1.5rem"
                      fontWeight={400}
                    >
                      {data?.data?.scorecard?.summary}
                    </ListItem>
                  </UnorderedList>
                </VStack>
              </Box>
            </VStack>
          </HStack>
        )}

        <VStack
          w="100%"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          gap="0"
        >
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            background="var(--grey-200)"
            padding="1rem"
            borderTopRightRadius="0.5rem"
            borderTopLeftRadius="0.5rem"
            borderBottom="1px solid var(--grey-300)"
            width="100%"
          >
            <HStack alignItems="flex-start" gap="0.69rem">
              <Box
                sx={{
                  svg: {
                    width: "1.25rem",
                    height: "1.25rem",
                  },
                }}
              >
                {sub_type === "outbound" ? (
                  <OutboundCallIcon />
                ) : (
                  <InboundCallIcon />
                )}
              </Box>
              <VStack alignItems="flex-start" gap="0">
                <Text textStyle="h4" fontWeight="600" whiteSpace="nowrap">
                  {sub_type === "outbound" ? "Outbound call" : "Inbound Call"}
                </Text>
              </VStack>
            </HStack>
            <HStack gap="0.5rem" w="100%" justifyContent="flex-end">
              <SimpleDateIcon />
              <Text textStyle="h6" fontWeight="600">
                {moment(details.call_datetime).format(
                  "dddd DD MMM YY • H:mm a"
                )}
              </Text>
            </HStack>
          </HStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            gap="0"
            background="white"
            borderBottomRightRadius="0.5rem"
            borderBottomLeftRadius="0.5rem"
          >
            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  Caller
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {sub_type === "outbound"
                    ? details.display_name
                    : details.contact_name
                    ? details.contact_name
                    : details.from}
                </Text>
                {sub_type === "outbound" && (
                  <Text textStyle="h6" fontWeight="400" mt="0.25rem">
                    At {details.dealership_name} {details.from}
                  </Text>
                )}
              </Box>
            </HStack>
            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  To:
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {sub_type === "outbound"
                    ? details.contact_name
                      ? details.contact_name
                      : details.to
                    : details.display_name
                    ? details.display_name
                    : details.to}
                </Text>
                {sub_type === "inbound" && (
                  <Text textStyle="h6" fontWeight="400" mt="0.25rem">
                    At {details.dealership_name} {details.to}
                  </Text>
                )}
              </Box>
            </HStack>
            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  Status:
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {details.duration
                    ? `Completed in ${details.duration} seconds`
                    : humanize(
                        details.status === "no_answered"
                          ? "no_answer"
                          : details.status || "Manual Inbound"
                      )}
                </Text>
              </Box>
            </HStack>

            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  Source:
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {details.call_source === "power_dialer"
                    ? "Power Dialer"
                    : "Regular"}
                </Text>
              </Box>
            </HStack>

            {audioData && (
              <Box padding="1rem 0" w="100%">
                <AudioPlayer
                  audioSrc={audioData}
                  transcribed={transcribed}
                  isDownload
                />
              </Box>
            )}

            {details?.voicemail_recording && (
              <Box padding="1rem 0" w="100%">
                <AudioPlayer
                  audioSrc={details?.voicemail_recording}
                  transcribed={details?.voicemail_recording}
                  isDownload
                />
              </Box>
            )}
          </VStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            borderBottomRightRadius="0.5rem"
            borderBottomLeftRadius="0.5rem"
            gap="0"
          >
            <HStack
              w="100%"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              justifyContent="space-between"
              background="var(--grey-200)"
            >
              <Text fontWeight="600" textStyle="h4">
                Notes
              </Text>
              <Button
                variant="none"
                display="flex"
                alignItems="center"
                color="var(--secondary-600)"
                fontWeight="500"
                gap="0.2rem"
                onClick={() => setAddNotes(true)}
              >
                <GreenAddIcon />
              </Button>
            </HStack>

            <VStack
              padding="1rem"
              alignItems="flex-start"
              w="100%"
              gap="0.5rem"
              borderBottomRightRadius="0.5rem"
              borderBottomLeftRadius="0.5rem"
            >
              <UnorderedList>
                {notes?.map((note) => {
                  return (
                    <ListItem>
                      <Text>{note.content}</Text>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </VStack>
          </VStack>
        </VStack>
      </VStack>

      {addNotes && (
        <NotesDrawer
          open={addNotes}
          onClose={() => setAddNotes(false)}
          handleAddNotes={(note) => {
            mutation.mutate({ note: { content: note } });
          }}
          isLoading={isLoading || mutation.isLoading}
        />
      )}
    </VStack>
  );
}

export default CallDetail;
