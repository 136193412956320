import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchUsage = async (dId: any, params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/billings/usage_settings_info`,
    { params }
  );
};
export const fetchLeadById = async ({ queryKey }: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/leads/${queryKey[3]}`
  );
};

export const fetchGraphData = async (dId: any, params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/billings/usage_settings_cost_data`,
    { params }
  );
};

export const fetchBillingSummary = async (params: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/billing_summaries/billing_summary`,
    { params }
  );
};
