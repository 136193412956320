import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
const CaptureImage = ({ onClose, isOpen, setCapturedImage }: any) => {
  const webcamRef = useRef<Webcam>(null);
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc); // Save captured image data in state
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Take a Photo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {" "}
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
        </ModalBody>
        <ModalFooter>
          <Button onClick={captureImage}>Capture Image</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CaptureImage;
