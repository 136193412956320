import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import BrandListing from "../../../components/GlobalDashboard/BrandListing/BrandListing";
import CampaignListing from "../../../components/GlobalDashboard/CampaignListing/CampaignListing";
import UsageInfo from "../../../components/GlobalDashboard/Billing/UsageInfo";
import UsageTrend from "../../../components/GlobalDashboard/Billing/UsageTrend";

export default function BillingPage() {
  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          isLazy
        >
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Usage Info</Tab>
            <Tab w="15rem">Usage Trend</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              <UsageInfo />
            </TabPanel>

            <TabPanel w="100%" h="100%">
              <UsageTrend/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
