import { apiLogs } from "../../../../utils/api/php-endpoints.api";
import { useQuery } from "@tanstack/react-query";
import { PaginatedTable } from "../../../common";
import { HStack, Text, VStack, Button,  Modal,
  Box,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay, } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

export const ApiLogs = (props: any) => {
  const typeSetter = () => {
    if (props?.type === "cdk_sales") {
      return "cdk_sales";
    } else if (props?.type === "VinSolutions") {
      return "vs_dealer";
    } else if (props?.type === "eleads") {
      return "elead";
    } else if (props?.type === "DealerSocket") {
      return "dealersocket";
    } else return undefined;
  };
  const [isOpen, setIsOpen] = useState(false);
  const [rawData, setRawData] = useState<any>();
  const [page, setPage] = useState(1);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["apilogs", props?.id, typeSetter(), props?.companyid, page],
    queryFn: apiLogs,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: typeSetter() !== undefined,
  });

  const tableHeader = [
    { value: "Request ID", label: "rquestid" },
    { value: "HTTP Response Code", label: "response" },
    { value: "Requested At", label: "time" },
    { value: "Raw Data", label: "actionButtons" },
  ];
  const tableData = data?.data?.data?.data?.map((item: any) => ({
    rquestid: item?.id,
    response: item?.response_status_code,
    time: moment(item.created_at).format("MMM DD, YYYY hh:mm A"),
    actionButtons: (
      item?.response_body_content&&<HStack
        alignItems="center"
        justifyContent="flex-start"
        padding="0"
        margin="0"
      >
        <Button onClick={()=>{setIsOpen(true); setRawData(item?.response_body_content)}}>View Raw Data</Button>
      </HStack>
    ),
  }));
  console.log(rawData)
  return (
    <VStack
      border="1px solid var(--grey-300)"
      borderRadius="0.5rem"
      w="100%"
      alignItems="flex-start"
      background="white"
    >
      <HStack w="100%" padding="1rem" justifyContent="space-between">
        <Text textStyle="h5" fontWeight="600">
          API Logs
        </Text>
      </HStack>
      {data?.data?.data?.data?.length ? (
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          itemsPerPage={5}
          maxPageNumbers={3}
          hasPadding={false}
          currentPage={page}
          isPaginated={true}
          setCurrentPage={setPage}
          // isLoadingData={isFetching}
          rowCount={data?.data?.data?.total}
        />
      ) : (
        <VStack p="1rem" paddingTop="0">
          <Text fontWeight="600" textStyle="h5">
            No data found against your selected provider
          </Text>
        </VStack>
      )}
                  {(isOpen&&rawData) && (
              <Modal isOpen={isOpen} onClose={() => {setIsOpen(false);setRawData(undefined)}}>
                <ModalOverlay />
                <ModalContent maxH="calc(100vh - 128px)" overflow="auto">
                  <ModalHeader>Raw Data</ModalHeader>
                  <ModalBody maxH="calc(100vh - 124px)" overflow="auto">
                    <Text whiteSpace="pre-wrap">
                    {rawData &&
                    rawData === 'string'
                    ? rawData // If rawData is a string, display it directly
                    :   JSON?.stringify(
                         rawData,
                          null,
                          5
                        )}

                    </Text>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
    </VStack>
  );
};
