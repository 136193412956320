import { HStack, Text, VStack, Checkbox, Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import SwitchToggle from "../common/Switch";
import { useQuery, useMutation } from "@tanstack/react-query";
import { get2fa, active2fa } from "../../utils/api/login.api";
import { useToast } from "@chakra-ui/react";
import PhoneNumberInput from "../common/PhoneInput";
import Loader from "../common/Spinner";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useParams } from "react-router-dom";
export const TwoFactor = (props: any) => {
  const { id } = useParams();
  const mutation = useMutation(active2fa);
  const toast = useToast();
  const userID = useUserInfo("id");
  const { data, refetch, isSuccess } = useQuery({
    queryKey: ["permissionsapi", id ?? userID],
    queryFn: get2fa,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [setting, setSetting] = useState<any>(data?.data?.settings_hash);
  const [phone, setPhone] = useState<any>(data?.data?.phone_number);
  useEffect(() => {
    setSetting(data?.data?.settings_hash);
    setPhone(data?.data?.phone_number);
  }, [data]);
  const handleSubmit = async () => {
    if (setting?.enabled && !setting?.email_enabled && !setting?.sms_enabled) {
      toast({
        description:
          "Enable either Email or Phone No for two-factor authentication.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return; // Stop submission if validation fails
    }
    if (
      setting?.sms_enabled &&
      (phone === "" || phone === undefined || phone === null)
    ) {
      toast({
        description: "Please add phone number",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return; // Stop submission if validation fails
    }
    if (phone !== "" && phone !== undefined && phone !== null) {
      if (!isValidPhoneNumber(phone)) {
        toast({
          description: "Please enter a valid phone number",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return; // Stop submission if validation fails}
      }
    }
    mutation.mutate(
      {
        payload: {
          key: "two_factor_auth",
          settings_hash: setting,
          phone: phone ?? "",
        },
        id: id ?? userID,
      },
      {
        onSuccess: () => {
          toast({
            description: "2FA updated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error, variables, context) => {
          toast({
            description: "Something went wrong.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleSwitchToggle = (value: boolean) => {
    setSetting((prevSetting: any) => ({
      ...prevSetting,
      enabled: value,
      email_enabled: value ? prevSetting.email_enabled : false,
      sms_enabled: value ? prevSetting.sms_enabled : false,
    }));
  };
  const handleCheckboxToggle = (type: string, value: boolean) => {
    setSetting((prevSetting: any) => ({
      ...prevSetting,
      [type]: value,
    }));
  };
  return (
    <HStack
      gap="0.75rem"
      alignItems="flex-start"
      key={setting}
      position="relative"
      h="100%"
    >
      <VStack
        w="50%"
        alignItems="flex-start"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        gap="1rem"
      >
        <Text textStyle="h4" fontWeight="500">
          Two Factor Authentication
        </Text>
        <SwitchToggle
          label="Enable / Disable"
          defaultChecked={setting?.enabled}
          onChange={(val) => {
            handleSwitchToggle(val);
          }}
          id={"1"}
        />
        <HStack>
          <Checkbox
            size="md"
            textStyle="h6"
            variant="whiteCheck"
            defaultChecked={setting?.email_enabled}
            isChecked={setting?.email_enabled}
            onChange={(e) =>
              handleCheckboxToggle("email_enabled", e.target.checked)
            }
          >
            With Email
          </Checkbox>
          <Checkbox
            size="md"
            textStyle="h6"
            variant="whiteCheck"
            defaultChecked={setting?.sms_enabled}
            isChecked={setting?.sms_enabled}
            onChange={(e) =>
              handleCheckboxToggle("sms_enabled", e.target.checked)
            }
          >
            With Phone No
          </Checkbox>
        </HStack>
        <Text textStyle="h6" color="var(--grey-800)">
          Following is the phone number on which you will receive your 2
          factor-authentication code. Changing this will also change your
          primary number.
        </Text>
        <PhoneNumberInput
          isRequired={false}
          onChange={(val) => {
            setPhone(val);
          }}
          value={phone ?? ""}
        />
      </VStack>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        zIndex="0"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Button
          width="8rem"
          onClick={handleSubmit}
          isDisabled={!props?.allowed}
        >
          Save
        </Button>
      </HStack>
      {mutation.isLoading && <Loader />}
    </HStack>
  );
};
