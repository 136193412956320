import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { calculateEndDate, calculateStartDate } from "../../utils";
import { getDealershipUsers } from "../../utils/api/line-rings.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Select } from "../common";

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};
moment.locale("en-GB");
const localizer = momentLocalizer(moment);
class CustomToolbar extends React.Component<any, any> {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;
    return (
      <>
        <VStack className="rbc-toolbar" alignItems="flex-start">
          <HStack
            w="100%"
            padding="0.59rem 1.5rem"
            background="var(--grey-100)"
            borderBottom="1px solid var(--grey-300)"
            justifyContent="right"
          >
            {/* <HStack w="100%">
              </HStack> */}
            <HStack>
              {!this?.props?.myCalendar && (
                <Select
                  placeholder="Filter User"
                  options={
                    this?.props?.options?.map((user: any) => ({
                      label: user?.full_name,
                      value: user?.id,
                    })) ?? []
                  }
                  onChange={this?.props?.userFilter}
                  w={"240px"}
                  value={this?.props?.selectedUser}
                  isClearable
                />
              )}
              <Button
                variant="none"
                w="6.875rem"
                minH="2.75rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="0.875rem"
                borderRadius="0.5rem !important"
                fontWeight="500"
                color="var(--grey-900)"
                border="1px solid var(--grey-300) !important"
                backgroundColor="white !important"
                _focus={{
                  backgroundColor: "var(--secondary-600) !important",
                  color: "white !important",
                }}
                onClick={this.navigate.bind(null, navigate.TODAY)}
              >
                Today
              </Button>

              <HStack>
                <Select
                  options={[
                    { id: 1, label: "Day", value: "day" },
                    { id: 2, label: "Week", value: "week" },
                    { id: 3, label: "Month", value: "month" },
                  ]}
                  variant="default"
                  w="8rem"
                  value={this.props.view}
                  onChange={(e) => this.onViewChange(e.value)}
                />
                <Button
                  background="var(--primary-600) !important"
                  color="white !important"
                  padding="0 1.5rem !important"
                  borderRadius="0.5rem !important"
                  onClick={() => this.props.onSelectSlot("")}
                >
                  Add Event
                </Button>
              </HStack>
            </HStack>
          </HStack>
          <HStack padding="1.5rem 1.5rem 0.87rem" justifyContent="flex-start">
            <Box
              as="span"
              className="rbc-toolbar-label"
              fontSize="1.125rem"
              fontWeight="500"
              color="var(--grey-900)"
            >
              {label}
            </Box>
            <HStack className="rbc-btn-group nav-btn" gap="0.25rem">
              <Button
                variant="none"
                border="none"
                padding="0"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                variant="none"
                border="none"
                padding="0"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <ChevronRightIcon />
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </>
    );
  }

  navigate = (action: any) => {
    this.props.onNavigate(action, this.props.view);
  };
  onViewChange = (view: any) => {
    this.props.onView(view);
    this.props.viewSetter(view); // Call viewSetter with the updated view value
  };
}
export const CommonCalendar = ({
  eventsData,
  onSelectSlot,
  selectedDate,
  onSelectEvent,
  handleDateChange,
  onDateRangeChange,
  viewSetter,
  userFilter,
  selectedUser,
  myCalendar,
}: any) => {
  const dealership = useUserInfo("dealership");
  const { data } = useQuery({
    queryKey: ["Appointment-users", dealership?.id, ""],
    queryFn: getDealershipUsers,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handler = (event: any) => {
    onSelectEvent && onSelectEvent(event);
  };
  const handleNavigate = (action: any, currentView: any) => {
    let newDate = selectedDate;
    switch (action) {
      case "PREV":
        newDate = getPreviousDate(selectedDate, currentView);
        break;
      case "NEXT":
        newDate = getNextDate(selectedDate, currentView);
        break;
      case navigate.TODAY:
        newDate = new Date();
        break;
      default:
        break;
    }
    handleDateChange(newDate);

    // Calculate date range based on the current view
    const startDate = calculateStartDate(newDate, currentView);
    const endDate = calculateEndDate(newDate, currentView);
    onDateRangeChange(startDate, endDate);
  };

  const getPreviousDate = (date: Date, view: string) => {
    switch (view) {
      case "day":
        return moment(date).subtract(1, "day").toDate();
      case "week":
        return moment(date).subtract(1, "week").toDate();
      case "month":
        return moment(date).subtract(1, "month").toDate();
      default:
        return date;
    }
  };

  const getNextDate = (date: Date, view: string) => {
    switch (view) {
      case "day":
        return moment(date).add(1, "day").toDate();
      case "week":
        return moment(date).add(1, "week").toDate();
      case "month":
        return moment(date).add(1, "month").toDate();
      default:
        return date;
    }
  };
  const rangeSetter = (currentView: string) => {
    const startDate = calculateStartDate(selectedDate, currentView);
    const endDate = calculateEndDate(selectedDate, currentView);
    onDateRangeChange(startDate, endDate);
    viewSetter(currentView);
  };
  return (
    <Calendar
      views={["day", "week", "month"]}
      selectable={true}
      localizer={localizer}
      defaultDate={selectedDate} // Set the defaultDate to the selected date
      date={selectedDate} // Set the defaultDate to the selected date
      defaultView="week"
      components={{
        toolbar: (props: any) => (
          <CustomToolbar
            {...props}
            onNavigate={handleNavigate}
            viewSetter={rangeSetter}
            options={data?.data?.users}
            userFilter={userFilter}
            onSelectSlot={onSelectSlot}
            selectedUser={selectedUser}
            myCalendar={myCalendar}
          />
        ),
      }}
      events={eventsData}
      style={{
        height: "calc(100vh - 180px)",
        width: "100%",
        borderLeft: "1px solid var(--grey-300)",
      }}
      onSelectEvent={handler}
      onSelectSlot={onSelectSlot}
    />
  );
};
