"use client";
import { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Image } from "@chakra-ui/react";
import Logo from "../../../assets/images/logo.svg";
import Location from "../../../assets/images/location.svg";
import Car from "../../../assets/images/car.svg";
import {
  TransparentMsgIcon,
  TransparentPhoneIcon,
} from "../../../components/icons/svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { useQuery } from "@tanstack/react-query";
import {
  fetchVehicleById,
  getVehicleDetails,
} from "../../../utils/api/vehicles";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { convertDateContacts } from "../../../utils";

export default function VehicleDetail() {
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const dealer = useUserInfo("dealership");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("slug");
  const id = queryParams.get("id");
  const [detail, setDetail] = useState<any>();
  const [images, setImages] = useState<any>([]);

  const { isFetching } = useQuery({
    queryKey: ["vehicleDetails", id, slug],
    queryFn: () => {
      return id
        ? fetchVehicleById({ dId: dealer?.id, vId: slug, id })
        : getVehicleDetails(slug);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: any) {
      setDetail(data?.data);
      setImages(data?.data?.inventory?.photo_url_list?.split(",") ?? []);
    },
  });
  let vh = window.innerHeight * 0.01;

  useEffect(() => {
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <VStack
      maxW="70rem"
      mx="auto"
      alignItems="flex-start"
      w="100vw"
      className="vehicle-responsive"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1rem 0"
        className="header"
      >
        <Image
          src={Logo}
          alt="logo"
          onClick={() => navigate("/leads/lead-details")}
          cursor="pointer"
        />
      </HStack>
      <HStack
        gap="1.5rem"
        w="100%"
        alignItems="flex-start"
        padding="2rem"
        overflow="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        height={`calc((${vh}px * 100) - 54px)`}
        className="car-details-wrapper"
      >
        <VStack
          padding="1.25rem"
          w="65%"
          gap="1.38rem"
          alignItems="flex-start"
          className="slider-section"
        >
          <VStack w="100%" alignItems="flex-start" gap="0">
            {detail?.contact?.use_nick_name && (
              <Text textStyle="h2" fontWeight="600">
                Welcome {detail?.contact?.nick_name}
              </Text>
            )}
            {!detail?.contact?.use_nick_name && (
              <Text textStyle="h2" fontWeight="600">
                Welcome {detail?.contact?.first_name}{" "}
                {detail?.contact?.last_name},
              </Text>
            )}
            <Text textStyle="h4" fontWeight="500" color="var(--grey-600)">
              What do you think of this vechicle?
            </Text>
          </VStack>
          <VStack w="100%">
            {(images?.length > 0 && (
              <Swiper
                spaceBetween={10}
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper?.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, Thumbs]}
                className="mySwiper2"
              >
                {images?.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Image src={item} alt="car" />
                  </SwiperSlide>
                ))}
              </Swiper>
            )) || <Image src={Car} alt="placeholder" height="400" />}
            {images?.length > 0 && (
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {images?.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Image
                      src={item}
                      width={125}
                      height={100}
                      alt="car-image"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </VStack>
          {detail?.inventory?.price && (
            <VStack alignItems="flex-start" gap="0">
              <Text
                textStyle="h2"
                color="var(--secondary-600)"
                fontWeight="700"
              >
                USD {detail?.inventory?.price}
              </Text>
            </VStack>
          )}
        </VStack>
        <VStack
          padding="1rem 1.36rem"
          background="white"
          w="35%"
          border="1px solid var(--grey-200)"
          gap="0.89rem"
          borderRadius="0.5rem"
          alignItems="flex-start"
          className="detail-section"
        >
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="700"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Details
            </Text>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              gap="0.3rem"
            >
              <Text textStyle="h4" fontWeight="700">
                {detail?.inventory ? detail?.inventory.year : detail?.year}{" "}
                {detail?.inventory ? detail?.inventory.make : detail?.make} -{" "}
                {detail?.inventory ? detail?.inventory.model : detail?.model}
              </Text>

              <Text
                fontSize="0.69519rem"
                fontWeight="400"
                color="var(--grey-700)"
              >
                <Box as="span" fontWeight="500">
                  Exterior:
                </Box>
                {detail?.inventory
                  ? detail?.inventory.exterior_color
                  : detail?.exterior_color}
              </Text>
              <Text
                fontSize="0.69519rem"
                fontWeight="400"
                color="var(--grey-700)"
              >
                <Box as="span" fontWeight="500">
                  Interior:
                </Box>
                {detail?.inventory
                  ? detail?.inventory.interior_color
                  : detail?.interior_color}
              </Text>
              <Text
                fontSize="0.69519rem"
                fontWeight="400"
                color="var(--grey-700)"
              >
                <Box as="span" fontWeight="500">
                  Mileage:
                </Box>
                {detail?.inventory
                  ? detail?.inventory.mileage
                  : detail?.mileage}
              </Text>
              {detail?.inventory && (
                <>
                  <Text
                    fontSize="0.69519rem"
                    fontWeight="400"
                    color="var(--grey-700)"
                  >
                    <Box as="span" fontWeight="500">
                      VIN:
                    </Box>
                    {detail?.inventory?.vin}
                  </Text>
                  <Text
                    fontSize="0.69519rem"
                    fontWeight="400"
                    color="var(--grey-700)"
                  >
                    <Box as="span" fontWeight="500">
                      Stock #:
                    </Box>
                    {detail?.inventory?.stock_number}
                  </Text>
                </>
              )}
            </VStack>
          </VStack>
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="700"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Point Person Details
            </Text>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              gap="0.85rem"
            >
              <Text textStyle="h6" fontWeight="650" w="100%">
                Sales Person
              </Text>
              <HStack alignItems="flex-start">
                <Image
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                  src={`https://source.unsplash.com/random/1920x1080/?abstract,pattern&${detail?.contact?.assign_person?.sales_person?.id}`}
                  alt="avatar"
                />
                <VStack gap="0" alignItems="flex-start">
                  <Text textStyle="h6" fontWeight="600">
                    {detail?.contact?.assign_person?.sales_person?.name}
                  </Text>
                </VStack>
              </HStack>
              <Button
                w="100%"
                gap="0.4rem"
                alignItems="center"
                onClick={() => {
                  const isMobile = /iPhone|iPad|iPod|Android/i.test(
                    navigator.userAgent
                  );
                  if (isMobile) {
                    window.location.href = `tel:${detail?.contact?.assign_person?.sales_person?.phone_number}`;
                  }
                }}
              >
                <TransparentPhoneIcon />
                {detail?.contact?.assign_person?.sales_person?.phone_number}
              </Button>
              <Button
                w="100%"
                gap="0.4rem"
                alignItems="center"
                variant="outlineBlue"
                onClick={() => {
                  const isMobile = /iPhone|iPad|iPod|Android/i.test(
                    navigator.userAgent
                  );
                  if (isMobile) {
                    window.location.href = `sms:${detail?.contact?.assign_person?.sales_person?.phone_number}`;
                  }
                }}
              >
                <TransparentMsgIcon />
                Send SMS
              </Button>
              <Text textStyle="h6" fontWeight="650" w="100%">
                BDC Agent
              </Text>
              <HStack alignItems="flex-start">
                <Image
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                  src={`https://source.unsplash.com/random/1920x1080/?abstract,pattern&${detail?.contact?.assign_person?.bdc_agent?.id}`}
                  alt="avatar"
                />
                <VStack gap="0" alignItems="flex-start">
                  <Text textStyle="h6" fontWeight="600">
                    {detail?.contact?.assign_person?.bdc_agent?.name}
                  </Text>
                </VStack>
              </HStack>
              <Button
                w="100%"
                gap="0.4rem"
                alignItems="center"
                onClick={() => {
                  const isMobile = /iPhone|iPad|iPod|Android/i.test(
                    navigator.userAgent
                  );
                  if (isMobile) {
                    window.location.href = `tel:${detail?.contact?.assign_person?.bdc_agent?.phone_number}`;
                  }
                }}
              >
                <TransparentPhoneIcon />
                {detail?.contact?.assign_person?.bdc_agent?.phone_number}
              </Button>
              <Button
                w="100%"
                gap="0.4rem"
                alignItems="center"
                variant="outlineBlue"
                onClick={() => {
                  const isMobile = /iPhone|iPad|iPod|Android/i.test(
                    navigator.userAgent
                  );
                  if (isMobile) {
                    window.location.href = `sms:${detail?.contact?.assign_person?.bdc_agent?.phone_number}`;
                  }
                }}
              >
                <TransparentMsgIcon />
                Send SMS
              </Button>
            </VStack>
          </VStack>
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="700"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Location
            </Text>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              gap="0.85rem"
            >
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  detail?.contact?.dealership_address || ""
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <HStack alignItems="flex-start">
                  <Image width={62} height={62} src={Location} alt="location" />
                  <VStack gap="0" alignItems="flex-start">
                    <Text textStyle="h6" fontWeight="600">
                      {detail?.contact?.dealership_address}
                    </Text>
                  </VStack>
                </HStack>
              </a>
            </VStack>
          </VStack>
          {/* <Button
            w="100%"
            border="none"
            color="var(--secondary-600)"
            background="var(--secondary-50)"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Schedule a Visit
          </Button> */}
        </VStack>
      </HStack>
      {isFetching && <Spinner />}
    </VStack>
  );
}
