import React from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Box,
  InputLeftElement,
  FormLabel,
  Textarea,
  TextareaProps,
  BoxProps,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";

interface InputProps extends BoxProps {
  type?: string;
  value?: any;
  onChange?: (value: any | any[]) => void;
  onKeyDown?: (value: any | any[]) => void;
  hasIcon?: boolean;
  isIconRight?: boolean;
  icon?: React.ReactNode;
  label?: string;
  maxW?: string;
  minW?: string | undefined;
  isTextarea?: boolean;
  fullHeight?: boolean;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  error?: string;
  info?: string;
  errorStyle?: any;
  accept?: string;
  max?: any;
  min?: any;
  isNumeric?: boolean;
}

const CustomInput = ({
  type = "text",
  value,
  onChange,
  icon,
  hasIcon = false,
  isIconRight,
  label = "",
  maxW = "100%",
  minW = "auto",
  isTextarea = false,
  fullHeight = false,
  isRequired,
  error,
  defaultValue,
  onKeyDown,
  info = "",
  errorStyle = {},
  accept = "",
  isNumeric = false,
  ...rest
}: InputProps & TextareaProps & NumberInputProps) => {
  return (
    <Box
      maxW={maxW}
      minW={minW}
      w="100%"
      height={fullHeight ? "100%" : "auto"}
      className="input-wrapper"
    >
      {label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
          alignContent={"center"}
          display={"flex"}
        >
          {label}{" "}
          {isRequired && (
            <Box as="span" color="var(--red-600)">
              *
            </Box>
          )}{" "}
          {info && (
            <Tooltip label={info}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <InputGroup height={fullHeight ? "100%" : "auto"}>
        {hasIcon && !isIconRight && (
          <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
        )}
        {!isTextarea && !isNumeric ? (
          <Input
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={(e) => {
              if (type === "time") {
                e?.target?.showPicker();
              }
            }}
            paddingRight={hasIcon && isIconRight ? "20px" : ""}
            paddingLeft={hasIcon && !isIconRight ? "42px" : ""}
            borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            accept={accept}
            {...rest}
          />
        ) : isNumeric ? (
          <NumberInput value={value} onChange={onChange} min={0} max={60}>
            <NumberInputField
              placeholder={rest.placeholder}
              borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        ) : (
          <Textarea
            background="white"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
            {...rest}
          />
        )}
        {hasIcon && isIconRight && (
          <InputRightElement
            pointerEvents="none"
            background="white"
            right="2px"
            top="50%"
            transform="translateY(-50%)"
            height="30px"
          >
            {icon}
          </InputRightElement>
        )}
      </InputGroup>

      <Text
        textStyle="captionSmall"
        color="var(--red-600)"
        style={{ ...errorStyle }}
      >
        {error}
      </Text>
    </Box>
  );
};

export default CustomInput;
