import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { MessageHistory } from "../../../../../../utils/types";
import { ChatIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";
import { filterLinks, getFileNameFromURL } from "../../../../../../utils";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";

type SingleMessageCardProps = {
  item: MessageHistory;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleMessageCard({ item, setClickedRecord }: SingleMessageCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => setClickedRecord(item)}
      title={"Message"}
      status={""}
      dateAndTime={moment(item?.created_at).format("dddd DD MMM YY")}
      icon={<ChatIcon />}
      content={
        <Text textStyle="h6" fontWeight="400" maxW="23.5rem" noOfLines={2}>
          {`From: ${item.details.from} ☺️ ${item?.details?.content}`}
          <Box mt="0.5rem" display="flex" flexWrap="wrap" width="100%">
            {filterLinks(item.details.media_urls || [])?.map((media: any) => {
              return <img src={media} alt={media} width={100} height={100} />;
            })}

            {filterLinks(item.details.media_urls || [], false)?.map(
              (media: any) => {
                const filename = getFileNameFromURL(media);
                return (
                  <a
                    href={media}
                    download
                    onClick={(event) => event.stopPropagation()}
                  >
                    <TooltipIfOverflow char={70}>{filename}</TooltipIfOverflow>
                  </a>
                );
              }
            )}
          </Box>
        </Text>
      }
    />
  );
}

export default SingleMessageCard;
