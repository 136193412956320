"use client";

import { Box, Button, HStack, Text, VStack, Image } from "@chakra-ui/react";
import Logo from "../../../assets/images/logo.svg";
import Location from "../../../assets/images/location.svg";
import Avatar from "../../../assets/avatar.svg";
import { useState } from "react";
import {
  SearchIcon,
  TransparentMsgIcon,
  TransparentPhoneIcon,
  WhitePhone,
} from "../../../components/icons/svg";
// import { useRouter } from "next/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import vehicleOne from "../../../assets/images/vehicleOne.svg";
import vehicleTwo from "../../../assets/images/vehicleTwo.svg";
import vehicleThree from "../../../assets/images/vehicleThree.svg";
import vehicleFour from "../../../assets/images/vehicleFour.svg";
import vehicleFive from "../../../assets/images/vehicleFive.svg";
import vehicleSix from "../../../assets/images/vehicleSix.svg";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Input } from "../../../components/common";
import { useNavigate } from "react-router-dom";

export const images = [
  { id: 1, src: vehicleOne },
  { id: 2, src: vehicleTwo },
  { id: 3, src: vehicleThree },
  { id: 4, src: vehicleFour },
  { id: 5, src: vehicleFive },
  { id: 6, src: vehicleSix },
];

export default function EmailCampaignPage() {
  // const router = useRouter();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>();
  return (
    <VStack maxW="92rem" mx="auto" alignItems="flex-start" gap="2rem">
      <HStack w="100%" justifyContent="space-between" padding="1rem 0">
        <Image
          src={Logo}
          alt="logo"
          onClick={() => navigate("/leads/lead-details")}
          cursor="pointer"
        />
        <Button variant="outlineBlue" background="transparent">
          Need a help? Contact us
        </Button>
      </HStack>
      <HStack gap="1.5rem" w="100%" alignItems="flex-start">
        <VStack
          w="30%"
          gap="0.62rem"
          borderRadius="0.5rem"
          alignItems="flex-start"
        >
          <VStack
            alignItems="flex-start"
            w="100%"
            gap="0"
            padding="1rem"
            border="1px solid var(--grey-300)"
            background="var(--secondary-600)"
            borderRadius="0.5rem"
            color="white"
          >
            <Text textStyle="h5" fontWeight="500">
              Dealer's Time:
            </Text>
            <Text textStyle="h5" fontWeight="500">
              August 10, 2023 02:39:12
            </Text>
          </VStack>
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            background="var(--secondary-600)"
            color="white"
            alignItems="flex-start"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="600"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Details
            </Text>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              gap="0.3rem"
            >
              <Text textStyle="h4" fontWeight="700">
                Honda Civic Turbo 1.5 VTEC 2020
              </Text>
              <Text textStyle="h6" fontWeight="500">
                4 Door sport utility
              </Text>
              <Text textStyle="h6" fontWeight="500">
                VIN: 5FNYF8H54KB026864
              </Text>
              <Text fontSize="0.69519rem" fontWeight="400">
                <Box as="span" fontWeight="500">
                  Exterior:{" "}
                </Box>{" "}
                Lunar Metallic Silver
              </Text>
              <Text fontSize="0.69519rem" fontWeight="400">
                <Box as="span" fontWeight="500">
                  Mileage:
                </Box>{" "}
                70,186 miles
              </Text>
              <Text fontSize="0.69519rem" fontWeight="400">
                <Box as="span" fontWeight="500">
                  Interior:{" "}
                </Box>{" "}
                Black
              </Text>
              <Text fontSize="0.69519rem" fontWeight="400">
                <Box as="span" fontWeight="500">
                  Stock #:{" "}
                </Box>{" "}
                13961
              </Text>
            </VStack>
          </VStack>
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            background="white"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="600"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Price
            </Text>
            <VStack alignItems="flex-start" gap="0" padding="1rem">
              <Text
                textStyle="h2"
                color="var(--secondary-600)"
                fontWeight="700"
              >
                PKR 69.95 lacs
              </Text>
              <Text
                textStyle="captionSmall"
                fontWeight="500"
                color="var(--grey-700)"
              >
                Financing starts at PKR 113,091/Month
              </Text>
            </VStack>
          </VStack>
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            background="white"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="600"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Seller Details
            </Text>
            <VStack
              w="100%"
              alignItems="flex-start"
              padding="1rem"
              gap="0.85rem"
            >
              <HStack alignItems="flex-start">
                <Image
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                  src={Avatar}
                  alt="avatar"
                />
                <VStack gap="0" alignItems="flex-start">
                  <Text textStyle="h6" fontWeight="600">
                    Javeria
                  </Text>
                  <Text textStyle="captionSmall" fontWeight="500">
                    Member since feb 2021
                  </Text>
                </VStack>
              </HStack>
              <Button w="100%" gap="0.4rem" alignItems="center">
                <TransparentPhoneIcon />
                Show Phone Number
              </Button>
              <Button
                w="100%"
                gap="0.4rem"
                alignItems="center"
                variant="outlineBlue"
              >
                <TransparentMsgIcon />
                Send SMS
              </Button>
            </VStack>
            <VStack
              w="100%"
              alignItems="flex-start"
              gap="0"
              borderTop="1px solid var(--grey-300)"
            >
              <Text textStyle="h6" fontWeight="600" padding="1rem" w="100%">
                Seller Location
              </Text>
              <VStack
                w="100%"
                alignItems="flex-start"
                padding="1rem"
                gap="0.85rem"
              >
                <HStack alignItems="flex-start">
                  <Image width={62} height={62} src={Location} alt="location" />
                  <VStack gap="0" alignItems="flex-start">
                    <Text textStyle="h6" fontWeight="600">
                      885 US 1 and Tappan St, Avenel, NJ
                    </Text>
                    <Text textStyle="h6" fontWeight="600">
                      Postal Code077001
                    </Text>
                    <Text textStyle="h6" fontWeight="600">
                      Street No. 03
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </VStack>
          </VStack>

          <Button
            w="100%"
            border="none"
            color="var(--secondary-600)"
            background="var(--secondary-50)"
          >
            Schedule a Visit
          </Button>
        </VStack>
        <VStack padding="1.25rem" w="70%" gap="1.38rem" alignItems="flex-start">
          <VStack w="100%" alignItems="flex-start" gap="0">
            <Text textStyle="h2" fontWeight="600">
              Welcome Chris Lowton,
            </Text>
            <Text textStyle="h4" fontWeight="500" color="var(--grey-600)">
              What do you think of this vechicle?
            </Text>
          </VStack>
          <VStack
            w="100%"
            sx={{
              ".mySwiper2 .swiper-slide": {
                height: "34rem",
              },
            }}
          >
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper?.destroyed
                    ? thumbsSwiper
                    : null,
              }}
              modules={[FreeMode, Thumbs]}
              className="mySwiper2"
            >
              {images.map((item) => (
                <SwiperSlide key={item.id}>
                  <Image src={item.src} alt="car" />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              {images.map((item) => (
                <SwiperSlide key={item.id}>
                  <Image
                    src={item.src}
                    width={125}
                    height={100}
                    alt="car-image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </VStack>

          <VStack w="100%" alignItems="flex-start">
            <Text textStyle="h4" fontWeight="500">
              Search other Vehicles
            </Text>
            <Input
              maxW="25rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Vehicles"
            />
            <HStack w="100%" flexWrap="wrap">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <VStack
                  key={item}
                  w="32.33%"
                  padding="0.75rem"
                  background="white"
                  borderRadius="0.5rem"
                  gap="0.5rem"
                >
                  <Image
                    src={vehicleFive}
                    alt="car"
                    style={{ objectFit: "cover", width: "100%" }}
                  />
                  <VStack w="100%" alignItems="center">
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      pb="0.5rem"
                      borderBottom="1px solid var(--primary-600)"
                    >
                      <Text textStyle="h4" fontWeight="700">
                        2020 Ford
                      </Text>
                      <Text
                        textStyle="h4"
                        fontWeight="700"
                        color="var(--grey-700)"
                      >
                        Used
                      </Text>
                    </HStack>
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      pb="0.5rem"
                      borderBottom="1px solid var(--primary-600)"
                    >
                      <Text textStyle="h5" fontWeight="600">
                        Mileage
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="600"
                        color="var(--grey-700)"
                      >
                        70,186 miles
                      </Text>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between" pb="0.5rem">
                      <Text textStyle="h5" fontWeight="600">
                        Model Year
                      </Text>
                      <Text
                        textStyle="h6"
                        fontWeight="600"
                        color="var(--grey-700)"
                      >
                        2017
                      </Text>
                    </HStack>
                    <Button w="100%">View This Car</Button>
                  </VStack>
                </VStack>
              ))}
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
}
