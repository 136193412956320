"use client";

import { Drawer } from "../../../components/common";
import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { assignContact } from "../../../utils/api/tags";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AsyncSelect from "react-select/async";
import { CustomSelect } from "../../../theme/components";
import { loadDPUsersApi } from "../../../utils/api/users.api";
import { useState } from "react";

const AssignContact = (props: any) => {
  const { onClose, id } = props;
  const toast = useToast();
  const dealerId = useUserInfo("dealership");

  const [salesPerson, setSalesPerson] = useState<SelectOptionProps | null>(
    null
  );
  const [bdcAgent, setBDCAgent] = useState<SelectOptionProps | null>(null);
  const [manager, setManager] = useState<SelectOptionProps | null>(null);
  const [isDistributed, setIsDistributed] = useState(false);

  const mutation = useMutation(assignContact);

  const loadActiveDPUsers = async (inputValue: string, role: string) => {
    try {
      const response = await loadDPUsersApi({
        dealership_id: dealerId?.id,
        role_titles: role,
        status: "active",
        q: inputValue,
      });
      const options = response?.users?.map((user: any) => {
        return {
          value: user.id,
          label: user.full_name,
        };
      });
      return options;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };
  return (
    <Drawer isOpen={true} onClose={onClose} title="Assign Contacts">
      <Box padding="1.25rem">
        <VStack gap="20px" w="100%">
          <Box maxW="100%" minW="auto" w="100%" height="auto">
            <Text
              textStyle="h4"
              fontWeight="600"
              fontSize="0.875rem"
              color="var(--grey-900)"
            >
              Sales Person
            </Text>
            <AsyncSelect
              styles={{ ...CustomSelect?.styles?.customSelect?.default }}
              loadOptions={(inputValue) =>
                loadActiveDPUsers(inputValue, "Sales Person")
              }
              onChange={(value: any) => setSalesPerson(value)}
              placeholder="Select User"
              value={salesPerson}
            />
          </Box>
          <Box maxW="100%" minW="auto" w="100%" height="auto">
            <Text
              textStyle="h4"
              fontWeight="600"
              fontSize="0.875rem"
              color="var(--grey-900)"
            >
              BDC Agent
            </Text>
            <AsyncSelect
              styles={{ ...CustomSelect?.styles?.customSelect?.default }}
              loadOptions={(inputValue) =>
                loadActiveDPUsers(inputValue, "BDC Agent")
              }
              onChange={(value: any) => setBDCAgent(value)}
              placeholder="Select User"
              value={bdcAgent}
            />
          </Box>
          <Box maxW="100%" minW="auto" w="100%" height="auto">
            <Text
              textStyle="h4"
              fontWeight="600"
              fontSize="0.875rem"
              color="var(--grey-900)"
            >
              Manager
            </Text>
            <AsyncSelect
              styles={{ ...CustomSelect?.styles?.customSelect?.default }}
              loadOptions={(inputValue) =>
                loadActiveDPUsers(inputValue, "Manager/Admin")
              }
              onChange={(value: any) => setManager(value)}
              placeholder="Select User"
              value={manager}
            />
          </Box>
          <Box
            padding="0.5rem"
            background="var(--grey-200)"
            borderRadius="0.5rem"
            w="100%"
          >
            <Text textStyle="h6">
              By default, this tool only distribute unassigned contacts.
            </Text>
            <Box mt="0.5rem">
              <Checkbox
                variant="whiteCheck"
                defaultChecked={false}
                isChecked={isDistributed}
                onChange={(event) => {
                  setIsDistributed(event.target.checked);
                }}
              >
                Also distribute assigned contacts
              </Checkbox>
            </Box>
          </Box>
        </VStack>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              mutation.mutate(
                {
                  dId: dealerId?.id,
                  tId: id,
                  payload: {
                    sales_person_id: salesPerson?.value,
                    bdc_agent_id: bdcAgent?.value,
                    manager_id: manager?.value,
                    is_distributed: isDistributed,
                  },
                },
                {
                  onSuccess: (data: any) => {
                    onClose();
                    toast({
                      description: "Contacts assigned successfully.",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                  onError: (error: any) => {
                    toast({
                      description: `Error assigning contacts: ${
                        error?.response?.data?.errors?.toString() ??
                        "Something went wrong"
                      }`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                }
              );
            }}
          >
            Assign
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};
export default AssignContact;
