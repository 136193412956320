
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from '../utils/context/AuthContext';

export const UnAuthenticatedRoutes = ({ children }: { children?: React.ReactNode }) => {
  const { token } = useAuthState();


  if (Boolean(token)) {
    return <Navigate to={'/'} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
