import { SimpleDateIcon } from "../../../../../../components/icons/svg";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { fmtHMS } from "../../../../../../utils";

interface ActivityProps {
  type?: string;
  icon: React.ReactNode;
  title: string;
  handleClick?: () => void | undefined;
  dateAndTime?: string;
  minutes?: number | undefined;
  content: any;
  id: number;
  status?: string;
  gap?: string;
  isOpen?: boolean;
  shouldShowIsOpen?: boolean;
}

const Activity = ({
  type,
  icon,
  title,
  dateAndTime,
  handleClick,
  minutes,
  content,
  id,
  status,
  gap,
  isOpen,
  shouldShowIsOpen,
}: ActivityProps) => {
  return (
    <Box
      background="white"
      border="1px solid var(--grey-300)"
      borderRadius="0.5rem"
      w="100%"
      padding="0.5rem 1.3rem"
      cursor="pointer"
      minH="6.88rem"
      _hover={{
        background: "#F4FBFF",
      }}
      onClick={handleClick}
      key={id}
    >
      <HStack alignItems="flex-start" gap="0.7rem" w="100%">
        <Box
          sx={{
            svg: {
              width: "1.5rem",
              height: "1.5rem",
            },
          }}
        >
          {icon}
        </Box>
        <VStack w="100%" alignItems="flex-start" gap={gap ? gap : "0.75rem"}>
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            w="100%"
          >
            <Text textStyle="h5" fontWeight="600">
              {title}
            </Text>
            {dateAndTime && (
              <HStack gap="0.5rem">
                <SimpleDateIcon />
                <Text textStyle="h6" fontWeight="600">
                  {dateAndTime}
                </Text>
              </HStack>
            )}
          </HStack>
          <HStack alignItems="center" justifyContent="space-between" w="100%">
            {content}
            {minutes && (
              <HStack gap="0.75rem">
                <Text textStyle="h4">{fmtHMS(Number(minutes))}</Text>
              </HStack>
            )}
            {status && (
              <HStack gap="0.75rem">
                <VStack
                  padding="0.4rem"
                  border={`2px solid var(--${
                    status === "No Answer" ? "red" : "secondary"
                  }-600)`}
                  minW="2.5rem"
                  minH="2.5rem"
                >
                  <Text
                    textStyle="h4"
                    color={`var(--${
                      status === "No Answer" ? "red" : "secondary"
                    }-600)`}
                    fontWeight="600"
                  >
                    {status}
                  </Text>
                </VStack>
              </HStack>
            )}

            {shouldShowIsOpen && (
              <HStack gap="0.75rem">
                <VStack
                  padding="0.4rem"
                  border={`2px solid var(--${
                    !isOpen ? "red" : "secondary"
                  }-600)`}
                  minW="2.5rem"
                  minH="2.5rem"
                >
                  <Text
                    textStyle="h4"
                    color={`var(--${
                      !isOpen ? "red" : "secondary"
                    }-600)`}
                    fontWeight="600"
                  >
                    {isOpen ? "Read" : "Unread"}
                  </Text>
                </VStack>
              </HStack>
            )}
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default Activity;
