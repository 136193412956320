import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, PaginatedTable } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { humanize } from "../../../utils";
import { getEmailsCommunication } from "../../../utils/api/broadcast.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import EmailDetail from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/Emails/EmailDetail";
import { ExcelIcon, PDFIcon } from "../../../components/icons/svg";
import { SERVICES } from "../../../config";
import axios from "axios";
import fileDownload from "js-file-download";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

export default function EmailsCommunicationLogPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tableView, setTableView] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState("");

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  const communicationTableHeader = [
    { id: 1, value: "First Name", label: "first_name" },
    { id: 1, value: "Last Name", label: "last_name" },
    { id: 1, value: "Email", label: "email" },
    { id: 4, value: "Read Status", label: "read_status" },
    { id: 1, value: "Status", label: "status" },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["emailsCommunicationLogs", id, page, perPage],
    queryFn: () => getEmailsCommunication({ id, dealershipId, page, perPage }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableData = useMemo(() => {
    if (!data?.data?.emails?.length) {
      return [];
    }

    return data?.data?.emails.map((item: any) => ({
      ...item,
      status: humanize(item.status),
      first_name: item?.first_name,
      last_name: item?.last_name,
      email: item?.email,
      read_status: item.is_open ? (
        <Text textStyle="h6" fontWeight="500" color="green">
          Read
        </Text>
      ) : (
        <Text textStyle="h6" fontWeight="500" color="var(--red-600)">
          Unread
        </Text>
      ),
    }));
  }, [data?.data?.emails]);

  const totalCount = data?.data?.emails_count;

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${
          dealership.id
        }/email_logs/${id}/communication_logs.${value}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(
          response.data,
          `emails_communication_logs.${value}`
        );
      })
      .catch((response) => {});
  };

  return (
    <Box>
      {isLoading && <Loader />}
      <HStack padding="1rem" w="100%" background="white">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      {/* {tableView ? ( */}
      <Box background="white" borderRadius="0.5rem" m="1.5rem">
        <HStack
          background="white"
          padding="1rem"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start">
            <Text textStyle="h2" pl="1rem" pt="1.5rem">
              Communication Logs
            </Text>
            <Text
              textStyle="caption"
              color="var(--grey-600)"
              mb="1rem"
              pl="1rem"
              pt="1.5rem"
            >
              Found ({totalCount || 0})
            </Text>
          </VStack>
          <Box m="1.5rem">
            <Dropdown
              options={ExportOptions}
              hasText={true}
              text="Export"
              onSelect={(value: any) => handleExport(value.value)}
            />
          </Box>
        </HStack>

        <PaginatedTable
          header={communicationTableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          isPaginated={true}
          hasMultiBody={false}
          lastCenter={false}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={totalCount || 0}
          rowOnClick={(item: any) => {
            setSelectedItem(item);
            setTableView(false);
          }}
        />
      </Box>
    </Box>
  );
}
