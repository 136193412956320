import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Drawer, Input } from "../../common";
import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import {
  fetchDispositionById,
  fetchDispositionByIdDS,
  updateDisposition,
  updateDispositionDS,
} from "../../../utils/api/disposition-settings.api";
import { useParams } from "react-router-dom";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  id: number;
  mappings: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  contact_disposition: {
    name: string;
    description: string;
    synced: string;
  };
}

const EditDispositionSettings = (props: data) => {
  const { id: dId } = useParams();
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, refetchParent, id } = props;
  const toast = useToast();
  const [disposition, setDisposition] = useState({
    name: "",
    description: "",
    synced: "",
  });
  const [nameError, setNameError] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["fetchDispositionSettings", id],
    queryFn: () =>
      dId
        ? fetchDispositionByIdDS(dId, id)
        : dealerId?.id
        ? fetchDispositionByIdDS(dealerId?.id, id)
        : fetchDispositionById(id),
  });

  useEffect(() => {
    setDisposition(data);
  }, [data]);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (dId
          ? updateDispositionDS(dId ?? "", id, payload, props.mappings)
          : dealerId?.id
          ? updateDispositionDS(dealerId?.id, id, payload, props.mappings)
          : updateDisposition(id, payload, props.mappings));
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error creating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!disposition?.name?.trim()) {
      setNameError("Name cannot be empty");
      return;
    }
    setNameError("");

    const requestData: ApiRequest = {
      contact_disposition: disposition,
    };

    mutation.mutate(requestData);
  };

  const handleNameChange = (value: string) => {
    setDisposition((prevTemplate) => ({ ...prevTemplate, name: value }));
    setNameError("");
  };

  const handleBodyChange = (value: string) => {
    setDisposition((prevTemplate) => ({ ...prevTemplate, description: value }));
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Disposition Setting"
      isLoading={isLoading || mutation?.isLoading}
    >
      <Box padding="1.25rem">
        <Box
          background="var(--grey-50)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
        >
          <Box mb="1rem">
            <Input
              label="Name"
              type="text"
              maxW="lg"
              isRequired={true}
              error={nameError}
              value={disposition?.name}
              onChange={(e: any) => handleNameChange(e.target.value)}
            />
          </Box>
          <Box mb="1rem">
            <Input
              label="Description (Optional)"
              isTextarea={true}
              placeholder="Type Here.."
              maxW="lg"
              value={disposition?.description}
              onChange={(e: any) => handleBodyChange(e.target.value)}
              isRequired={false}
            />
          </Box>
        </Box>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" size="xl" onClick={handleSubmit}>
            Confirm
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};

export default EditDispositionSettings;
