import {
  Box,
  Button,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import USMap from "../../../../components/Charts/USMap";
import WorldMap from "../../../../components/Charts/WorldMap";
import DatePicker from "../../../../components/common/DateRangePicker";
import { fetchMap } from "../../../../utils/api/global-dashboard";
import UsersDrawer from "./UsersDrawer";

export default function BottomPane(props: any) {
  const { isGlobalView, setIsGlobalView, selectionRange, setSelectionRange } =
    props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stateValue, setStateValue] = useState(null);
  const [country, setCountry] = useState(null);
  const [page, setPage] = useState(1);
  const { data, isFetching } = useQuery({
    queryKey: ["fetchMap", isGlobalView, selectionRange],
    queryFn: () =>
      fetchMap({
        map: isGlobalView ? "world" : "us",
        start_date: selectionRange?.startDate,
        end_date: selectionRange?.endDate,
      }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: selectionRange?.startDate !== selectionRange?.endDate,
  });

  return (
    <VStack border="1px solid var(--grey-300)" borderRadius="0.75rem" w="100%">
      <HStack
        w="100%"
        p="1rem"
        borderBottom="1px solid var(--grey-300)"
        justifyContent="space-between"
      >
        <HStack gap="2rem">
          <SkeletonText isLoaded={!isFetching} noOfLines={1}>
            <Text textStyle="h5" fontWeight="700">
              Users Location Heatmap
            </Text>
          </SkeletonText>
          <HStack
            border="1px solid var(--grey-300)"
            h="2.25rem"
            borderRadius="0.5rem"
            alignItems="normal"
            gap="0"
          >
            <Button
              variant="none"
              w="6.25rem"
              h="100%"
              textAlign="center"
              borderRight="1px solid var(--grey-300)"
              background={
                !isGlobalView ? "var(--secondary-600)" : "transparent"
              }
              color={!isGlobalView ? "white" : "var(--grey-900)"}
              borderTopLeftRadius="0.5rem"
              borderBottomLeftRadius="0.5rem"
              onClick={() => setIsGlobalView(false)}
            >
              <Skeleton isLoaded={!isFetching}>
                <Text textStyle="h6" fontWeight="500">
                  {!isGlobalView && (
                    <Box
                      as="span"
                      mr="0.32rem"
                      background="var(--secondary-50)"
                      borderRadius="0.35rem"
                      p="0.12rem 0.25rem"
                      color="var(--secondary-600)"
                      fontSize="0.75rem"
                    >
                      {data?.data?.count}
                    </Box>
                  )}
                  US Map
                </Text>
              </Skeleton>
            </Button>

            <Button
              variant="none"
              w="6.25rem"
              h="100%"
              textAlign="center"
              borderTopRightRadius="0.5rem"
              borderBottomRightRadius="0.5rem"
              background={isGlobalView ? "var(--secondary-600)" : "transparent"}
              color={isGlobalView ? "white" : "var(--grey-900)"}
              onClick={() => setIsGlobalView(true)}
            >
              <Skeleton isLoaded={!isFetching}>
                <Text textStyle="h6" fontWeight="500">
                  {isGlobalView && (
                    <Box
                      as="span"
                      mr="0.32rem"
                      background="var(--secondary-50)"
                      borderRadius="0.35rem"
                      p="0.12rem 0.25rem"
                      color="var(--secondary-600)"
                      fontSize="0.75rem"
                    >
                      {data?.data?.count}
                    </Box>
                  )}
                  Global
                </Text>
              </Skeleton>
            </Button>
          </HStack>
        </HStack>
        <HStack>
          <Skeleton isLoaded={!isFetching}>
            <DatePicker
              value={selectionRange}
              onChange={(value) => {
                setSelectionRange(value);
                setPage(1);
              }}
            />
          </Skeleton>
        </HStack>
      </HStack>
      <HStack w="100%" padding="1rem" justifyContent="space-between">
        <Box w="90%">
          <Skeleton isLoaded={!isFetching} height="100%">
            {isGlobalView ? (
              <WorldMap
                count={data?.data?.user_per_location}
                onOpen={onOpen}
                setCountry={setCountry}
              />
            ) : (
              <USMap
                count={data?.data?.user_per_location}
                onOpen={onOpen}
                setStateValue={setStateValue}
              />
            )}
          </Skeleton>
        </Box>
      </HStack>
      {isOpen && (
        <UsersDrawer
          page={page}
          setPage={setPage}
          onClose={onClose}
          country={isGlobalView ? country : "United States of America"}
          state={isGlobalView ? null : stateValue}
          selectionRange={selectionRange}
        />
      )}
    </VStack>
  );
}
