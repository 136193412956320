import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { humanize } from "../../../utils";
import { fetchGraphData, fetchUsage } from "../../../utils/api/usage";
import { loadDealershipsApi } from "../../../utils/api/users.api";
import StackedColumnChart from "../../Charts/StackedColumnChart";
import { Select } from "../../common";
import DatePicker from "../../common/DateRangePicker";
import Loader from "../../common/Spinner";
import moment from "moment";
import { EmptyIcon } from "../../icons/svg";

const keyMapper: any = {
  inbound_voice_minutes: "calls-inbound",
  inbound_calls_local_toll_free: "calls",
  outbound_voice_minutes: "calls-outbound",
  phone_numbers: "phonenumbers",
  SMS_inbound: "sms-inbound",
  SMS_outbound: "sms-outbound",
  MMS_inbound: "mms-inbound",
  MMS_outbound: "mms-outbound",
  SMS_messages_carrier_fees: "sms-messages-carrierfees",
  MMS_messages_carrier_fees: "mms-messages-carrierfees",
  A2P_registration_fees: "a2p-registration-fees",
};

const transformData = (data: any, selectedOption: string, isUsage = true) => {
  const categories = Object.keys(data);

  const cost = categories.map((category: string) => data[category].total_price);
  const count = categories.map((category: string) => data[category].count);

  return {
    categories,
    series: [
      {
        name: selectedOption,
        data: isUsage ? count : cost,
      },
    ],
  };
};

function UsageTrend() {
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: "",
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [selectedOption, setSelectedOption] = useState<SelectOptionProps>();
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [selectedDealership, setSelectedDealership] = useState<any>(null);

  const getParams = (isGraph: boolean) => {
    let params: any = {};
    if (selectionRange && selectionRange?.selected) {
      if (selectionRange?.startDate)
        params.start_time = selectionRange?.startDate.toString();
      if (selectionRange?.endDate)
        params.end_time = selectionRange?.endDate.toString();
    }
    if (isGraph) {
      if (selectedOption) {
        params.category = selectedOption?.value;
      }
    }
    return params;
  };

  const { data, isFetching: isFetchingGraph } = useQuery({
    queryKey: [
      "fetchGraphData",
      selectedDealership?.value || "",
      selectionRange,
      selectedOption,
    ],
    queryFn: () =>
      fetchGraphData(selectedDealership?.value || "", getParams(true)),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!selectedDealership,
  });

  const {
    data: usageResData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["fetchUsage", selectedDealership?.value || "", selectionRange],
    queryFn: () =>
      fetchUsage(selectedDealership?.value || "", getParams(false)),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const keys = Object.keys(data?.data?.data || {});
      setSelectedOption({
        label: humanize(keys?.[0] || "", false),
        value: keyMapper[keys?.[0] || ""],
      });
    },
  });

  const { data: usageData } = usageResData?.data || {};
  const { total_price: totalCost, ...remainingUsageInfo } = usageData || {};

  const { categories, series } = transformData(
    data?.data?.graph_data || {},
    (selectedOption?.label || "") as any,
    activeIndex === 1
  );

  const titleOptions = Object.keys(remainingUsageInfo || {}).map(
    (key: string) => {
      return {
        label: humanize(key, false),
        value: keyMapper[key],
      };
    }
  );

  const loadDealerships = async (inputValue: string) => {
    try {
      const response = await loadDealershipsApi({
        status: "active",
        searchValue: inputValue,
      });

      const options = response?.dealerships?.map((dealership: any) => ({
        value: dealership.id.toString(),
        label: dealership.name,
      }));

      // setSelectedDealership({
      //   value: response?.dealerships?.[0]?.id?.toString(),
      //   label: response?.dealerships?.[0]?.name,
      // });

      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      padding="1.25rem 1.5rem"
      gap="1rem"
    >
      {isFetching && <Loader />}
      <HStack w="100%" justifyContent="flex-start" alignItems="center">
        <DatePicker
          value={selectionRange}
          onChange={(value) => {
            setSelectionRange(value);
          }}
          shouldShowClear
          label="Select Date Range"
          shouldShowLabel={false}
        />
        <Text textStyle="h6" color="var(--red-600)">
          Last Updated at:{" "}
          <i style={{ color: "var(--grey-900)" }}>
            {moment(data?.data?.updated_at).format("DD-MM-YYYY hh:mm A")}
          </i>
        </Text>
      </HStack>
      <HStack w="100%" gap="1rem">
        <VStack
          padding="1rem 0rem 0"
          alignItems="flex-start"
          border="1px solid var(--grey-300)"
          borderRadius="1rem"
          w="100%"
        >
          <Tabs
            variant="pill"
            defaultIndex={activeIndex}
            onChange={(index) => setActiveIndex(index)}
            index={activeIndex}
            w="100%"
          >
            <HStack padding="1rem">
              <TabList>
                <Tab minW="4rem">Cost</Tab>
                <Tab minW="4rem">Usage</Tab>
              </TabList>
              <HStack gap="1rem">
                <VStack minHeight="38px">
                  <AsyncSelect
                    loadOptions={(searchStr) => loadDealerships(searchStr)}
                    defaultOptions
                    value={selectedDealership}
                    onChange={(value: any) => {
                      setSelectedDealership(value);
                    }}
                    placeholder="Select Dealership"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        // maxWidth: "550%",
                        // width: "max-content",
                        // minWidth: "100%",
                        width: "200px",
                        height: "42px",
                      }),
                    }}
                  />
                </VStack>

                <Select
                  onChange={(value: any) => setSelectedOption(value)}
                  placeholder="Total"
                  options={titleOptions}
                  variant="default"
                  value={selectedOption?.value}
                  w={"100%"}
                />
              </HStack>
            </HStack>
            <TabPanels>
              <TabPanel>
                {selectedDealership?.value ? (
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                    style={{ height: 430, width: "100%" }}
                    type="line"
                  />
                ) : (
                  <>
                    <VStack
                      w="100%"
                      justifyContent="center"
                      alignItems="center"
                      height="300px"
                    >
                      <EmptyIcon />
                      <Text
                        textStyle="h5"
                        fontWeight="400"
                        color="var(--grey-500)"
                      >
                        Please select a dealership.
                      </Text>
                    </VStack>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {selectedDealership?.value ? (
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                    style={{ height: 430, width: "100%" }}
                    type="line"
                  />
                ) : (
                  <>
                    <VStack
                      w="100%"
                      justifyContent="center"
                      alignItems="center"
                      height="300px"
                    >
                      <EmptyIcon />
                      <Text
                        textStyle="h5"
                        fontWeight="400"
                        color="var(--grey-500)"
                      >
                        Please select a dealership.
                      </Text>
                    </VStack>
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </HStack>
    </VStack>
  );
}

export default UsageTrend;
