import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  acceptIncomingCall,
  rejectIncomingCall,
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import { Popover, Select } from "../common";
import { CloseIcon, SettingsIcon } from "../icons/svg";

const IncomingCall = () => {
  const { caller, incomingCall, currentDevice, startCallTimestamp } =
    useTwilioState();
  const dispatch = useTwilioDispatch();

  const inputDevices = Array.from(
    currentDevice?.device?.audio?.availableInputDevices || [],
    ([id, value]) => ({ value: id, label: value?.label })
  );
  const outputDevices = Array.from(
    currentDevice?.device?.audio?.availableOutputDevices || [],
    ([id, value]) => ({ value: id, label: value.label })
  );

  const handleClose = () => {
    rejectIncomingCall(dispatch, incomingCall);
  };

  return (
    <Drawer
      isOpen={!!incomingCall && !startCallTimestamp}
      onClose={handleClose}
      placement="bottom"
      id="phone-drawer"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerContent padding="0">
        <DrawerBody padding="0">
          <VStack w="100%" alignItems="flex-start">
            <HStack
              justifyContent="space-between"
              w="100%"
              background="var(--secondary-600)"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              padding="0.69rem 1rem"
            >
              <Text color="white" textStyle="h5" fontWeight="500">
                Phone Ready
              </Text>
              <HStack>
                <Popover
                  btnVariant="none"
                  variant="custom"
                  btnStyle={{ width: "max-content", paddingLeft: "1.4rem" }}
                  trigger={
                    <Button
                      variant="none"
                      sx={{
                        svg: {
                          path: {
                            fill: "white",
                          },
                        },
                      }}
                    >
                      <SettingsIcon />
                    </Button>
                  }
                  placement="bottom-start"
                  contentStyle={{
                    width: "16rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  <VStack
                    w="100%"
                    alignItems="flex-start"
                    position="relative"
                    gap="0"
                  >
                    <VStack
                      w="100%"
                      alignItems="flex-start"
                      gap="0"
                      padding="0.5rem 0.96rem"
                    >
                      <Box mb="1rem" w="100%">
                        <Select
                          options={inputDevices}
                          label="Input Device"
                          w="100%"
                          value={
                            inputDevices?.find(
                              (value) => value.value === "default"
                            )?.value
                          }
                          onChange={(value) =>
                            currentDevice?.device?.audio?.setInputDevice(
                              value.value
                            )
                          }
                        />
                      </Box>

                      <Box mb="1rem" w="100%">
                        <Select
                          options={outputDevices}
                          label="Output Device"
                          w="100%"
                          value={
                            Array.from(
                              currentDevice?.device?.audio?.speakerDevices
                                .get()
                                .values() || []
                            )[0]?.deviceId
                          }
                          onChange={(value) =>
                            currentDevice?.device?.audio?.speakerDevices.set(
                              value.value
                            )
                          }
                        />
                      </Box>
                    </VStack>
                  </VStack>
                </Popover>
                <Button
                  onClick={handleClose}
                  variant="none"
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </HStack>
            </HStack>
            <VStack w="100%" alignItems="flex-start" padding="0.69rem 0.94rem">
            <VStack w="100%" alignItems="center" padding="0.69rem 0.94rem">
              <HStack display={"flex"} alignSelf={"center"}>
                <Text fontWeight="500" textStyle="h6">
                  Incoming call from
                </Text>
              </HStack>
              <Text fontWeight="700" textStyle="h4">
                {caller}
              </Text>
              <Text fontWeight="700" textStyle="h4">
                ({incomingCall?.parameters.From} )
              </Text>
              </VStack>
              <HStack display={"flex"} justifyContent={"space-around"} w="100%">
                <Button
                  fontSize="0.875rem"
                  background="var(--secondary-500)"
                  textAlign="center"
                  onClick={() => {
                    acceptIncomingCall(dispatch, incomingCall);
                  }}
                  border={"none"}
                >
                  Accept
                </Button>

                <Button
                  fontSize="0.875rem"
                  background="var(--red-700)"
                  textAlign="center"
                  onClick={() => rejectIncomingCall(dispatch, incomingCall)}
                  border={"none"}
                >
                  Reject
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default IncomingCall;
