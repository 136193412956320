import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
  HStack,
  Box,
  Checkbox
} from "@chakra-ui/react";
import { WarningIcon } from "../icons/svg";
import Loader from "../common/Spinner";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  variant?: "delete" | "confirm";
  loading?: boolean;
  hasCheckbox?:boolean;
  checkboxContent?:string;
  onCheckboxChange?:(val:boolean)=>void;
  checkboxValue?:boolean;
}

const Popup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  variant = "confirm",
  loading = false,
  hasCheckbox=false,
  checkboxContent='',
  onCheckboxChange,
  checkboxValue=false,
}) => {
  const ref = useRef(null);

  return (
    // leastDestructiveRef is used to set where the default focus would be on the popup
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={ref}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text textStyle="h4" fontWeight="700" textAlign="center">
                {title}
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <HStack
                w="100%"
                padding="1rem"
                position="relative"
                paddingLeft="2rem"
                background="#FFF3E0"
              >
                <Box
                  height="100%"
                  width="0.75rem"
                  position="absolute"
                  background="#FB8C00"
                  left="0"
                  top="0"
                ></Box>
                <WarningIcon />
                <Text>{message}</Text>
              </HStack>
             {hasCheckbox&& <HStack
                w="100%"
                padding="1rem"
                position="relative"
                paddingLeft="2rem"
                background="#FFF3E0"
              >
                <Box
                  height="100%"
                  width="0.75rem"
                  position="absolute"
                  background="#FB8C00"
                  left="0"
                  top="0"
                ></Box>
               <Checkbox
                  variant="whiteCheck"
                  size="lg"
                  onChange={({ target: { checked } }) =>
                    onCheckboxChange&&onCheckboxChange(checked)
                  }
                  isChecked={checkboxValue}
                >
                 {checkboxContent}
                </Checkbox>
              </HStack>}
            </AlertDialogBody>

            <HStack w="100%" padding="1.5rem">
              <Button ref={ref} onClick={onClose} variant="outline" w="100%">
                Cancel
              </Button>
              <Button
                onClick={onConfirm}
                ml={3}
                w="100%"
                borderColor={
                  variant === "confirm"
                    ? "var(--primary-600)"
                    : "var(--red-500)"
                }
                _hover={{
                  background:
                    variant === "confirm"
                      ? "var(--primary-600)"
                      : "var(--red-500) !important",

                  borderColor:
                    variant === "confirm"
                      ? "var(--primary-600)"
                      : "var(--red-500)",
                }}
                background={
                  variant === "confirm"
                    ? "var(--primary-600)"
                    : "var(--red-500)"
                }
              >
                {variant === "confirm" ? "Confirm" : "Delete"}
              </Button>
            </HStack>
            {loading && <Loader />}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Popup;
