import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//line apis
export const getLines = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/v1/lines?dealership_id=${queryKey[1]}&page=${queryKey[2]}&line_status=${queryKey[5]}&per_page=${queryKey[3]}&q=${queryKey[4]}&advance_filter=${queryKey[6]}`
  );

export const getInfiniteLines = async ({
  dealershipId,
  page = 1,
  perPage = 10,
  searchStr = "",
}: any): Promise<ApiResult> => {
  return axios.get(
    `${
      SERVICES.apiBaseUrl
    }/api/v1/lines?dealership_id=${dealershipId}&page=${page}&per_page=${perPage}&line_status=${"active"}&q=${searchStr}`
  );
};
export const createLines = async (payload: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/v1/lines`, payload, {
    headers: {
      menu: "Dealership",
      "Sub-Menu": "Settings/Line & Ring Groups",
      "Dealership": payload.dealership_id,
    },
  });
export const getDealershipUsers = async ({ queryKey }: any): Promise<any> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/users?role_titles=Owner%2FGM,Manager%2FAdmin,Sales+Person,BDC+Agent&status=active&only_standard=true&page=1&per_page=20&q=${queryKey[2]}`
  );
export const getTwilloNumber = async ({
  queryKey,
}: any): Promise<ApiResult> => {
  const [, id, inRegion, nearNumber, areaCode, contains, lineType] = queryKey;

  // Build the URL dynamically based on the provided parameters
  const url =
    `${SERVICES.apiBaseUrl}/api/v1/lines/search_lines?country=${inRegion}` +
    `${nearNumber ? `&near_number=${nearNumber}` : ""}` +
    `${areaCode ? `&area_code=${areaCode}` : ""}` +
    `${contains ? `&contains=${contains}` : ""}` +
    `${lineType ? `&line_type=${lineType}` : ""}` +
    `${id ? `&dealership_id=${id}` : ""}`;

  return axios.get(url);
};

export const modifyStatus = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/v1/lines/${payload?.lineID}/${payload?.query}`,
    undefined,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Lines & Ring Groups/Line/Status",
        "Dealership": payload.id,
      },
    }
  );

// line setting apis
export const getLineSettings = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/settings/line/${queryKey[1]}/setting?key=${queryKey[2]}`
  );
export const modifyLineSettings = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/settings/line/${payload.id}/update_setting`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Lines & Ring Groups/call settings",
        "Dealership": payload.dealerId,
      },
    }
  );
export const postRecording = async (payload: any) => {
  return axios.post(
    `${SERVICES.apiBaseUrl}/api/v1/lines/${payload.id}/attach_recording`,
    payload.data,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
        menu: "Dealership",
        "Sub-Menu": "Settings/Lines & Ring Groups/Line/Settings/Greet & Record",
        "Dealership": payload.dealerId,
      },
    }
  );
};
export const addUserRouting = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/v1/lines/${payload.id}`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Lines & Ring Groups/Lines",
        "Dealership": payload.dealerId,
      },
    }
  );
export const addRingRouting = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups/${payload.id}/update_line`,
    payload.data,
    {
      headers: {
        menu: "Dealership",
        "Sub-Menu": "Settings/Lines & Ring Groups/Ring Group/Settings/General",
        "Dealership": payload.dealership_id,
      },
    }
  );
  export const getTCPA =  async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}//api/admin/dealerships/${queryKey[1]}/contact_dispositions`
  );

// ring creation
export const createRing = async (payload: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/v1/ring_groups`, payload, {
    headers: {
      menu: "Dealership",
      "Sub-Menu": "Settings/Lines & Ring Groups/Ring Group",
      "Dealership": payload.dealership_id,
    },
  });
export const getRings = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups?dealership_id=${queryKey[1]}&page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );
export const ringSettings = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/settings/ring_group/${payload.id}/update_setting`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Lines & Ring Groups/Ring Group",
      },
    }
  );
export const viewRing = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/v1/ring_groups/${queryKey[1]}`);
export const editRing = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups/${payload.id}`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Lines & Ring Groups/Ring Group",
        "Dealership": payload.data.ring_group.dealership_id,
      },
    }
  );
export const getRingLines = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups/ring_group_lines?dealership_id=${queryKey[3]}&id=${queryKey[1]}&q=${queryKey[2]}`
  );

//call setting
export const getCallSetting = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${queryKey[1]}/setting?key=dealership_call_settings`
  );
export const updateCallSetting = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${payload.id}/update_setting`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Call Settings",
        "Dealership": payload.id,
      },
    }
  );
export const postDealershipRecording = async (payload: any) => {
  return axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/attach_recording`,
    payload.data,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
        menu: "Dealership",
        "Sub-Menu": "Settings/Lines & Ring Groups/Calling Settings",
        "Dealership": payload.id,
      },
    }
  );
};
