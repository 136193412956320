import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import TikTok from "../../../components/TikTok/TikTok";
import { MessengerAuthentication } from "../../../components/WebChat/MessengerAuthentication";
import Webchat from "../../../components/WebChat/Webchat";

export default function SocialTalk() {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <VStack padding="1.5rem" w="100%" alignItems="flex-start" h="100%">
      <Tabs
        variant="table"
        w="100%"
        background="white"
        borderRadius="0.5rem"
        h="100%"
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
      >
        <TabList borderTopRightRadius="0.5rem">
        <Tab w="15rem">Messenger</Tab>
        <Tab w="15rem">Web Chat</Tab>
          <Tab w="15rem">Tik Tok</Tab>
        </TabList>
        <TabPanels w="100%">
        <TabPanel w="100%" h="100%">
            {tabIndex === 0 && <MessengerAuthentication />}
          </TabPanel>
          <TabPanel w="100%" h="100%" padding="1.5rem">
          {tabIndex === 1 && <Webchat />}
          </TabPanel>
          <TabPanel w="100%" h="calc(100vh - 168px)" overflow="auto">
            {tabIndex === 2 && <TikTok />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
