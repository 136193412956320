import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  deleteVoicemail,
  fetchSettingsIO,
} from "../../utils/api/user-settings-dp.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Spinner } from "../common";
import AudioPlayer from "../common/AudioPlayer";
import { AddVoicemail } from "./AddVoicemail";
interface ApiResult {
  data: any;
}
type AudioDataType = {
  id: number;
  title: string;
  recording_url: string;
};
interface ApiRequest {}

export const OutboundSetting = (props: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { id: userIdFromParam } = useParams();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");
  const toast = useToast();

  const [audioData, setAudioData] = useState<AudioDataType[]>([]);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (id) => {
      try {
        const response = await deleteVoicemail(id, [
          dealerId?.id,
          userIdFromParam ?? userID,
        ]);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["outbound", dealerId?.id, userIdFromParam ?? userID],
    queryFn: fetchSettingsIO,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: any) {
      setAudioData(data?.voicemails);
    },
  });
  return (
    <HStack gap="1rem" alignItems="flex-start" height="100%">
      <VStack
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        w="57%"
        alignItems="flex-start"
        gap="1rem"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Box padding="1rem" w="100%" paddingBottom="0">
          <HStack w="100%" justifyContent="space-between" mb="1rem">
            <Text textStyle="h4" fontWeight="500" mb="1rem">
              Voicemail Drop Library
            </Text>
            <Button onClick={onOpen}>Create Voicemail Drop</Button>
          </HStack>
          <Text textStyle="h6" fontWeight="600" color="var(--grey-800)">
            Build you voicemail drop library here. When using the PowerDialer or
            calling a lot of leads manually, you can use voicemail drop to put a
            voicemail after the beep without being on the call.
          </Text>
        </Box>
        <Box w="100%" mb="1rem">
          {audioData &&
            audioData?.map((item: any) => (
              <HStack
                key={item.id}
                w="100%"
                borderTop="1px solid var(--grey-300)"
              >
                <Text
                  textStyle="h6"
                  fontWeight="500"
                  flexGrow="1"
                  flexBasis="20%"
                  paddingLeft="1rem"
                  w="20%"
                >
                  {item.title}
                </Text>
                <HStack w="80%" flexBasis="80%">
                  <AudioPlayer
                    isMulti={true}
                    itemIndex={item.id}
                    audioSrc={item.recording_url ?? ""}
                  />
                  <HStack paddingRight="1rem">
                    <Button
                      variant="outline"
                      onClick={() => {
                        mutation.mutate(item.id);
                      }}
                    >
                      <DeleteIcon
                        style={{
                          color: "var(--grey-800)",
                          width: "1.2rem",
                        }}
                      />
                    </Button>
                  </HStack>
                </HStack>
              </HStack>
            ))}
        </Box>
        {isOpen && (
          <AddVoicemail
            isOpen={isOpen}
            onClose={onClose}
            refetch={refetch}
            type="outbound"
          ></AddVoicemail>
        )}
      </VStack>
      {(isLoading || mutation.isLoading) && <Spinner />}
    </HStack>
  );
};
