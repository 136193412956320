import { Button, FormLabel, HStack, Input } from "@chakra-ui/react";
import React, { useState } from "react";

interface ToggleProps {
  value1: string;
  value2: string;
  onChange: (value: string) => void;
  selectedValue?: string;
}

const SimpleToggle = ({
  value1,
  value2,
  onChange,
  selectedValue,
}: ToggleProps) => {
  const handleToggle = (value: string) => {
    onChange(value);
  };

  return (
    <HStack
      gap="0"
      border="1px solid var(--grey-300)"
      borderRadius="0.5rem"
      w="100%"
      maxW="max-content"
    >
      <Button
        variant="none"
        p="0.62rem 0.37rem !important"
        fontSize="0.875rem"
        fontWeight="500"
        borderTopLeftRadius="0.5rem"
        borderBottomLeftRadius="0.5rem"
        background={selectedValue === value1 ? "var(--secondary-600)" : "white"}
        color={selectedValue === value1 ? "white" : "var(--grey-900)"}
        onClick={() => handleToggle(value1)}
      >
        {value1}
      </Button>
      <Button
        variant="none"
        p="0.62rem 0.37rem !important"
        fontSize="0.875rem"
        fontWeight="500"
        borderTopRightRadius="0.5rem"
        borderBottomRightRadius="0.5rem"
        background={selectedValue === value2 ? "var(--secondary-600)" : "white"}
        color={selectedValue === value2 ? "white" : "var(--grey-900)"}
        onClick={() => handleToggle(value2)}
      >
        {value2}
      </Button>
    </HStack>
  );
};

export default SimpleToggle;
