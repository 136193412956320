import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSearchParams } from "react-router-dom";
import {
  calculateEndDate,
  calculateStartDate,
  extractTime,
} from "../../../../../utils";
import { getContactReminder } from "../../../../../utils/api/meeting.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { AppointmentDetail } from "../../../../MeetingCalendar/AppointmentDetail";
import { CreateAppointment } from "../../../../MeetingCalendar/CreateAppointment";
import Loader from "../../../../common/Spinner";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

class CustomToolbar extends React.Component<any, any> {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;
    return (
      <>
        <VStack className="rbc-toolbar" alignItems="flex-start">
          <HStack padding="1rem 1rem 0.87rem" justifyContent="flex-start">
            <Box
              as="span"
              className="rbc-toolbar-label"
              fontSize="1.125rem"
              fontWeight="500"
              color="var(--grey-900)"
            >
              {label}
            </Box>
            <HStack className="rbc-btn-group nav-btn" gap="0.25rem">
              <Button
                variant="none"
                border="none"
                padding="0"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                variant="none"
                border="none"
                padding="0"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <ChevronRightIcon />
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </>
    );
  }

  navigate = (action: any) => {
    this.props.onNavigate(action, this.props.view, this?.props?.date);
  };
  onViewChange = (view: any) => {
    this.props.onView(view);
  };
}

const Reminder = ({ clickedRow }: any) => {
  const dealership = useUserInfo("dealership");
  let [searchParams] = useSearchParams();
  const contactId = searchParams.get("contact_id");
  const reminderId = searchParams.get("reminder_id");
  const [slotData, setSlotData] = useState<any>(null);
  const [eventsData, setEventsData] = useState();
  const [selectedID, setSelectedID] = useState<any>();
  const [contactID, setContactID] = useState<any>(clickedRow);
  const [editMode, setEditMode] = useState(false);
  const [startDate, setStartDate] = useState(
    moment.utc().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc().endOf("week").add(1, "day").format("YYYY-MM-DD")
  );
  useEffect(() => {
    if (reminderId) {
      setSelectedID(reminderId);
      onOpenDetail();
      setContactID(contactId);
    }
  }, [reminderId]);
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();
  const { isLoading, refetch } = useQuery({
    queryKey: ["Rminders", dealership?.id, clickedRow, startDate, endDate],
    queryFn: getContactReminder,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setEventsData(
        data?.data?.events.map((appointment: any) => ({
          id: appointment.id,
          title: (
            <VStack
              background={colorSetter(appointment?.status)}
              gap="0"
              padding="0.38rem 0.38rem 0.12rem"
              borderRadius="0.5rem"
              alignItems="flex-start"
              h="100%"
            >
              <Text
                textStyle="captionSmall"
                whiteSpace="normal"
                fontWeight="500"
              >
                {appointment.title}
              </Text>
            </VStack>
          ),
          start: new Date(appointment.start_time),
          end: new Date(appointment.end_time),
        }))
      );
    },
  });
  const onSelectEvent = (event: any) => {
    setSelectedID(event.id);
    onOpenDetail();
  };

  const colorSetter = (val: any) => {
    if (val === "completed_reminder") {
      return "var(--primary-200)";
    }
    if (val === "missed_reminder") {
      return "var(--bluegray-600)";
    }
    if (val === "scheduled_reminder") {
      return "var(--yellow-default)";
    }
  };
  const [isSetAppointment, setIsSetAppointment] = useState<boolean>();

  const handleNavigate = (action: any, currentView: any, selectedDate: any) => {
    let newDate = new Date();
    switch (action) {
      case "PREV":
        newDate = getPreviousDate(selectedDate, currentView);
        break;
      case "NEXT":
        newDate = getNextDate(selectedDate, currentView);
        break;
      case navigate.TODAY:
        newDate = new Date();
        break;
      default:
        break;
    }
    setStartDate(calculateStartDate(newDate, currentView));
    setEndDate(calculateEndDate(newDate, currentView));
  };

  const getPreviousDate = (date: Date, view: string) => {
    switch (view) {
      case "day":
        return moment(date).subtract(1, "day").toDate();
      case "week":
        return moment(date).subtract(1, "week").toDate();
      case "month":
        return moment(date).subtract(1, "month").toDate();
      default:
        return date;
    }
  };

  const getNextDate = (date: Date, view: string) => {
    switch (view) {
      case "day":
        return moment(date).add(1, "day").toDate();
      case "week":
        return moment(date).add(1, "week").toDate();
      case "month":
        return moment(date).add(1, "month").toDate();
      default:
        return date;
    }
  };
  const onSelectSlot = (event: any) => {
    let { start, end } = event;
    let start_time = start?.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    if (start_time === "24:00") {
      start_time = "00:00";
    }
    let endTime = end?.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    const year = start?.getFullYear();
    const month = String(start?.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns 0-indexed month
    const day = String(start?.getDate()).padStart(2, "0");
    const date = `${year}-${month}-${day}`;

    setSlotData({ start_time, endTime, date, type: { value: "reminder" } });
    setIsSetAppointment(true);
  };
  const editMeeting = (data: any) => {
    const modifiedData = {
      ...data,
      date: data?.start_time?.substring(0, 10),
      start_time: extractTime(data?.start_time),
      type: { value: data?.event_type },
      userid: data?.participants?.map((user: any) => ({
        value: user.id,
        label: user.full_name,
      })),
    };
    setSlotData(modifiedData);
    setEditMode(true);
    setIsSetAppointment(true);
  };

  return (
    <>
      <VStack
        alignItems="flex-start"
        padding="1rem"
        background="var(--grey-100)"
      >
        <VStack
          w="100%"
          alignItems="flex-start"
          padding="1rem"
          background="white"
          borderRadius="0.5rem"
          gap="1rem"
        >
          <HStack
            w="100%"
            justifyContent="space-between"
            pb="1rem"
            borderBottom="1px solid var(--grey-300)"
          >
            <Text textStyle="h4" fontWeight="500">
              Reminder
            </Text>
            <Button
              background="var(--primary-600) !important"
              color="white !important"
              padding="0 1.5rem !important"
              borderRadius="0.5rem !important"
              onClick={() => setIsSetAppointment(true)}
            >
              Add Reminder
            </Button>
          </HStack>
          <Calendar
            views={["day", "week", "month"]}
            selectable
            localizer={localizer}
            defaultDate={startDate}
            date={startDate}
            defaultView="week"
            components={{
              toolbar: (props: any) => (
                <CustomToolbar {...props} onNavigate={handleNavigate} />
              ),
            }}
            events={eventsData}
            style={{
              height: "calc(100vh - 300px)",
              width: "100%",
              border: "1px solid var(--grey-300)",
              borderRadius: "0.5rem",
            }}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
          />
        </VStack>
        {isLoading && <Loader />}
      </VStack>
      {isSetAppointment && (
        <CreateAppointment
          onClose={() => {
            setIsSetAppointment(false);
            setEditMode(false);
            onCloseDetail();
          }}
          isOpen={isSetAppointment}
          contactid={clickedRow}
          leadReminder={true}
          slotData={slotData}
          editMode={editMode}
          apiCall={refetch}
        />
      )}
      {isOpenDetail && (
        <AppointmentDetail
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          selectedID={selectedID}
          editMeeting={editMeeting}
          contactid={contactID}
          apiCall={refetch}
        />
      )}
    </>
  );
};

export default Reminder;
