import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";

interface DataPoint {
  period: string;
  messageCount?: number;
  avgResponseTime?: number;
  engagementCount?: number;
  hour?: string;
  messages_sent?: number;
  replies_received?: number;
}

interface TextRepliesRatioChartProps {
  data: DataPoint[];
}

const chartContainerStyle: React.CSSProperties = {
  padding: "20px",
  margin: "20px 0",
  border: "1px solid #ddd",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  backgroundColor: "#fff",
  width:'100%'
};

const TextRepliesRatioChart: React.FC<TextRepliesRatioChartProps> = ({
  data,
}) => {
  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>({});
  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);

  useEffect(() => {
    const seriesData = prepareChartData(data);

    setChartOptions({
      chart: {
        type: "bar",
      },
      xaxis: {
        type: "datetime",  // Use datetime type for x-axis
        categories: seriesData.categories || [],
        labels: {
          format: 'dd MMM',  // Format the labels as needed
          rotate: -45,  // Rotate labels for better readability
        },
        tickAmount: 10,  // Adjust the number of ticks on the x-axis
      },
      title: {
        text: "Proportion of Messages Sent vs. Replies Received",
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toString();
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
    });

    setChartSeries([
      {
        name: "Messages Sent",
        data: seriesData.messages_sent,
      },
      {
        name: "Replies Received",
        data: seriesData.replies_received,
      },
    ]);
  }, [data]);

  const prepareChartData = (data: DataPoint[]) => {
    const categories: string[] = [];
    const messages_sent: number[] = [];
    const replies_received: number[] = [];
    data?.forEach((item) => {
      categories.push(new Date(item.period).toISOString());
      if (item.messages_sent !== undefined) {
        messages_sent.push(item.messages_sent);
      }
      if (item.replies_received !== undefined) {
        replies_received.push(item.replies_received);
      }
    });
    return { categories, messages_sent, replies_received };
  };

  return (
    <div style={chartContainerStyle}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default TextRepliesRatioChart;
