import { ChakraProvider } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ActionCable from "actioncable";
import * as React from "react";
import "./App.css";
import { SERVICES } from "./config";
import { IdleTracking } from "./pages/(dashboard-routes)/IdleTracking";
import AppRoutes from "./routes/routes";
import theme from "./theme";
import Fonts from "./theme/styles/fonts";
import { AuthProvider } from "./utils/context/AuthContext";

const AppContainer = styled.div`
  font-family: "Red Hat Display Variable";
`;

const CableApp: { cable: ActionCable.Cable | null } = { cable: null };
CableApp.cable = ActionCable.createConsumer(SERVICES.socketUrl);
export const ActionCableContext = React.createContext(CableApp);

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ActionCableContext.Provider value={CableApp}>
        <AuthProvider>
          <ChakraProvider theme={theme}>
            <Fonts />
            <AppContainer>
              <IdleTracking>
                <AppRoutes />
              </IdleTracking>
            </AppContainer>
          </ChakraProvider>
        </AuthProvider>
      </ActionCableContext.Provider>
    </QueryClientProvider>
  );
};
