import axios from "axios";
import { SERVICES } from "../../config";
import { useUserInfo } from "../hooks/useUserInfo";

export const fetchUsers = async ({
  role_titles = "",
  status = "active",
  searchValue = "",
  page,
  per_page,
}) => {
  try {
    const params = {
      role_titles,
      status,
    };

    if (searchValue !== "") {
      params.q = searchValue;
    }

    if (page !== undefined && per_page !== undefined) {
      params.page = page;
      params.per_page = per_page;
    }

    const response = await axios.get(`${SERVICES.apiBaseUrl}/api/admin/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const fetchDealershipUsers = async ({
  role_titles = "",
  dealership_status = "active",
  searchValue = "",
  page,
  per_page,
}) => {
  try {
    const params = {
      role_titles,
      dealership_status,
    };

    if (searchValue !== "") {
      params.q = searchValue;
    }

    if (page !== undefined && per_page !== undefined) {
      params.page = page;
      params.per_page = per_page;
    }

    const response = await axios.get(`${SERVICES.apiBaseUrl}/api/admin/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchUserById = async (id) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/users/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const updateUser = async (userId, payload, customHeaders = {}) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/users/${userId}`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...customHeaders,
      },
    }
  );

export const updateUserForProfileSettings = async (userId, payload) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/users/${userId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          menu: "",
          "Sub-Menu": "",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createUser = async (payload) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/users`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "User",
          "Sub-Menu": "",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoles = async (simp_social_user) => {
  try {
    const url = `${SERVICES.apiBaseUrl}/api/admin/users/new`;

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (simp_social_user) {
      config.params = { simp_social_user };
    }

    const response = await axios.get(url, config);

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const resetPassword = async (payload, customHeaders = {}) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/users/reset_password`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...customHeaders,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

//....................dealership menu main..................
export const fetchDealershipListing = async ({
  status = "active",
  searchValue = "",
  page,
  per_page,
}) => {
  try {
    const params = {
      status,
    };

    if (searchValue !== "") {
      params.q = searchValue;
    }
    if (page !== undefined && per_page !== undefined) {
      params.page = page;
      params.per_page = per_page;
    }

    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const loadDealershipsApi = async ({
  status = "active",
  searchValue = "",
}) => {
  try {
    const params = {
      status,
    };

    if (searchValue !== "") {
      params.q = searchValue;
    }
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const loadUsersApi = async (params) => {
  try {
    const response = await axios.get(`${SERVICES.apiBaseUrl}/api/admin/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const loadDPUsersApi = async (params) => {
  try {
    const response = await axios.get(`${SERVICES.apiBaseUrl}/api/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const updateModifySettingsApi = async ({ id, payload }) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/users/${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "User",
          "Sub-Menu": "Modify Settings",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const fetchTimezones = async () => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/global/time_zones`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const createDealership = async (payload) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealerships",
          "Sub-Menu": "",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateDealership = async (id, payload, customHeaders) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...customHeaders,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
//....................dealership menu user detail..................
export const fetchDealershipUserById = async ({
  role_titles = "",
  id,
  status = "active",
  searchValue = "",
  page,
  per_page,
}) => {
  try {
    const params = {
      role_titles,
      status,
    };
    if (searchValue !== "") {
      params.q = searchValue;
    }

    if (page !== undefined && per_page !== undefined) {
      params.page = page;
      params.per_page = per_page;
    }
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${id}/users`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const createUserForDealershipMenu = async (id, payload) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/users?dealership_id=${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealerships",
          "Sub-Menu": "Settings/User",
          Dealership: id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserForDealershipMenu = async (userId, payload, id) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/users/${userId}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealerships",
          "Sub-Menu": "Settings/User",
          Dealership: id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const suspendOrRestoreDealershipUser = async (
  userId,
  dealershipId,
  payload,
  customHeaders = {}
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/users/${userId}/update_suspended/${dealershipId}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...customHeaders,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

//.................delaership portal,,,,,,,,,,,,,,,,,,
export const fetchUsersDP = async ({
  role_titles = "",
  dealership_id,
  status = "active",
  searchValue = "",
  page = 1,
  per_page = 10,
}) => {
  try {
    const params = {
      role_titles,
      dealership_id,
      status,
    };

    if (searchValue !== "") {
      params.q = searchValue;
    }

    if (page !== undefined && per_page !== undefined) {
      params.page = page;
      params.per_page = per_page;
    }

    const response = await axios.get(`${SERVICES.apiBaseUrl}/api/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createDPUser = async (id, payload) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/users?dealership_id=${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "User",
          "Sub-Menu": "Dealership",
          Dealership: id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const textToSpeech = async (text, key='') => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/text_to_speech_url?text=${text}&key=${key}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDPUser = async (userId, id, payload) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/users/${userId}?dealership_id=${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "User",
          "Sub-Menu": "Dealership",
          Dealership: id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const suspendOrRestoreDPUser = async (
  userId,
  dealershipId,
  payload,
  customHeaders = {}
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/users/${userId}/update_suspended/?dealership_id=${dealershipId}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...customHeaders,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const impersonateUser = async (
  userId,
  dispatch,
  isDealership = false,
  customHeaders = {},
  role,
  previousUserId
) => {
  try {
    const response = await axios.post(
      isDealership
        ? `${SERVICES.apiBaseUrl}/api/admin/impersonate/dealership/${userId}`
        : `${SERVICES.apiBaseUrl}/api/admin/impersonate/${userId}`,
      undefined,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...customHeaders,
        },
      }
    );

    const authorizationHeader = response.headers.get("Authorization");
    const token = authorizationHeader.split(" ")[1];
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: response.data,
      token: token,
      previousUserId,
    });
    let data = response?.data?.status?.data?.user;

    // Append token to user data
    data.token = token;

    // Store the updated user data in localStorage
    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        ...data,
        impersonating: true,
        impSSRole: role,
        previousUserId,
      })
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const stopImpersonateUser = async (dispatch) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/stop_impersonating`,
      undefined,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "",
          "Sub-Menu": "",
        },
      }
    );

    const authorizationHeader = response.headers.get("Authorization");
    const token = authorizationHeader.split(" ")[1];
    dispatch({ type: "LOGIN_SUCCESS", payload: response.data, token: token });
    let data = response?.data?.status?.data?.user;

    // Append token to user data
    data.token = token;

    // Store the updated user data in localStorage
    localStorage.setItem(
      "currentUser",
      JSON.stringify({ ...data, impersonating: false, impSSRole: null })
    );

    return token;
  } catch (error) {
    throw error;
  }
};
