import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { markAllMessageAsRead } from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { PingCard } from "./PingCard";

interface PingsProps {
  onOpen: (val: string, count: number) => void;
  count: number;
  onClose: () => void;
  countRefetch?: () => void;
}

export const Pings = ({ onOpen, count, onClose, countRefetch }: PingsProps) => {
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const mutation = useMutation(markAllMessageAsRead);
  const toast = useToast();
  const [refetch, setRefetch] = useState(false);

  const handleMarkAllAsRead = async () => {
    await mutation.mutateAsync(
      { dId: dealerId?.id, ping: true },
      {
        onSuccess: (data: any) => {
          toast({
            status: "success",
            description: "All Pings are marked as read",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          countRefetch && countRefetch();
          setRefetch(true);
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );

    onClose && onClose();
  };
  return (
    <>
      <VStack w="100%" alignItems="flex-start" gap="0">
        <HStack w="100%" justifyContent="space-between" padding="1rem">
          <Text textStyle="h5" fontWeight="500">
            All Pings ({count})
          </Text>
          <HStack>
            {isImpersonating && (
              <Text
                textStyle="h6"
                color="var(--primary-600)"
                fontWeight="500"
                cursor="pointer"
                onClick={() => handleMarkAllAsRead()}
              >
                Mark all as read
              </Text>
            )}
            <Button
              variant="none"
              onClick={() => onOpen("Ping", count)}
              marginTop="5px"
            >
              <FullscreenIcon />
            </Button>
          </HStack>
        </HStack>

        <PingCard page={1} perPage={5} refetch={refetch} onClose={onClose} />
      </VStack>
    </>
  );
};
