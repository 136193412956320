import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const custom = definePartsStyle({
  content: defineStyle({
    width: "28rem",
  }),
});
export default defineMultiStyleConfig({
  variants: { custom },
});
