import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ChevronDown } from "../icons/svg";

interface DropdownProps {
  options: SelectOptionProps[];
  onSelect?: (option: SelectOptionProps) => void;
  hasText?: boolean;
  hasChevron?: boolean;
  text?: any;
  isBlue?: boolean;
  hasBorder?: boolean;
  value?: object | undefined;
  disabled?: boolean;
}

const Dropdown = ({
  options,
  onSelect,
  hasText = false,
  text,
  hasChevron = true,
  hasBorder = true,
  isBlue = false,
  value,
  disabled,
}: DropdownProps) => {
  return (
    <VStack>
      <Menu>
        {({ isOpen }) => (
          <>
            {/* add props to control menu button appearance as icon or as default text with icons on left or right side */}

            {!hasText ? (
              <MenuButton
                as={IconButton}
                variant="icon"
                className="menu-button"
                border={!hasBorder ? "none" : "1px solid var(--grey-300)"}
                w="2.25rem"
                h="2.25rem"
                icon={
                  <MoreVertIcon
                    style={{
                      color: "var(--bluegray-900)",
                    }}
                  />
                }
                onClick={(event) => event.stopPropagation()}
                isDisabled={disabled}
              />
            ) : (
              <MenuButton
                as={Button}
                variant="icon"
                w="max-content"
                padding="0"
                h="2.25rem"
                background={isBlue ? "var(--primary-600)" : ""}
                isDisabled={disabled}
              >
                <HStack h="100%" alignItems="center" gap="0">
                  <Text
                    padding="0.62rem 0.5rem"
                    textStyle="h6"
                    fontWeight="500"
                    color={isBlue ? "white" : "inherit"}
                  >
                    {text}
                  </Text>
                  {hasChevron && (
                    <VStack
                      borderLeft={
                        !isBlue ? "1px solid var(--grey-300)" : "none"
                      }
                      h="100%"
                      justifyContent="center"
                      padding="0.62rem"
                    >
                      <ChevronDown color={isBlue ? "white" : "#212121"} />
                    </VStack>
                  )}
                </HStack>
              </MenuButton>
            )}

            <MenuList width="13.75rem" position="relative" zIndex="3">
              {options?.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={(event) => {
                    // event.stopPropagation()
                    onSelect?.(option);
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    </VStack>
  );
};

export default Dropdown;
