"use client";
import { Box, Container, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Spinner } from "../../components/common";
import Logo from "../../assets/logo.png";
import Dashboard from "../../assets/images/dashboard.png";

export default function AuthLayout({ children }: ReactChildren) {
  //   const { data: session, status } = useSession();
  //   const router = useRouter();

  //   useEffect(() => {
  //     if (session) {
  //       router.push(Path.Dashboard);
  //     }
  //   }, [session, router]);

  //   if (status === "loading") {
  //     return <Spinner />;
  //   }

  //   if (session) router.push(Path.Dashboard);

  return (
    <Container className="auth-layout" maxW="100%" display="flex" padding={0}>
      <Box flex={1} h="100vh" maxW="50%" bg="primary.800" overflow="hidden">
        <VStack pl="5.3rem" pr="7rem" pt="5.3rem" align="start">
          <Image
            src={Logo}
            alt="SimpSocial Logo"
            width={323}
            height={63}
            style={{ width: "323px", height: "auto" }}
          />
          {/* <Text textStyle="h2" color="white" mt={12}>
            The Simplest way to Empower Connections, Uniting the World
          </Text> */}
          <Text textStyle="h4" color="white" fontWeight={600} mb={12}>
            {/* Seamless Networking, Effortless Management */}
          </Text>
        </VStack>
        <Image
          src={Dashboard}
          alt="SimpSocial Dashboard"
          width="848px"
          height="auto"
          objectFit="cover"
          style={{ width: "auto", marginLeft: "4rem" }}
        />
      </Box>
      {children}
    </Container>
  );
}
