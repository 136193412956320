import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import UptimeBars from "./UptimeBars";

const AppStatus = (props: any) => {
  const { status } = props;
  return (
    <VStack
      w="100%"
      padding="0.75rem"
      border="1px solid var(--grey-300)"
      borderRadius="0.5rem"
    >
      <Accordion allowToggle w="100%">
        {status.isAccordion ? (
          <AccordionItem background="none" border="none">
            <AccordionButton
              w="100%"
              padding="0"
              _expanded={{
                paddingBottom: "1rem",
              }}
              _hover={{
                background: "none",
              }}
            >
              <HStack w="30%" gap="0.62rem">
                <Box
                  padding="0.5rem"
                  borderRadius="0.5rem"
                  background={status.color}
                >
                  {status.icon}
                </Box>
                <VStack alignItems="flex-start" gap="0.12rem">
                  <Text textStyle="h5" fontWeight="700">
                    {status.name}
                  </Text>
                  <Text
                    textStyle="h6"
                    fontWeight="400"
                    color="var(--secondary-600)"
                  >
                    {status.uptime}% uptime
                  </Text>
                </VStack>
              </HStack>
              <HStack w="70%" justifyContent="space-between">
                <UptimeBars data={status.data} />
                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel padding="0">
              <VStack
                alignItems="flex-start"
                paddingTop="1.5rem"
                paddingBottom="0.5rem"
                borderTop="1px solid var(--grey-300)"
                gap="1.5rem"
              >
                {status.accordionData.map((item: any) => (
                  <HStack key={item.id} w="100%" gap="0">
                    <Text w="30%" textStyle="h6" fontWeight="500">
                      {item.name}
                    </Text>
                    <HStack justifyContent="space-between" w="70%">
                      <UptimeBars data={item.data} />
                      <Text
                        textStyle="h6"
                        fontWeight="400"
                        color="var(--secondary-600)"
                      >
                        100.0% uptime
                      </Text>
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ) : (
          <HStack w="100%" gap="0" justifyContent="space-between">
            <HStack w="30%" gap="0.62rem">
              <Box
                padding="0.5rem"
                borderRadius="0.5rem"
                background={status.color}
                display={"flex"}
              >
                {status.icon}
              </Box>
              <VStack alignItems="flex-start" gap="0.12rem">
                <Text textStyle="h5" fontWeight="700">
                  {status.name}
                </Text>
                <Text
                  textStyle="h6"
                  fontWeight="400"
                  color="var(--secondary-600)"
                >
                  {status.uptime}% uptime
                </Text>
              </VStack>
            </HStack>
            <UptimeBars
              data={status.data}
              selectedMonth={status?.selectedMonth}
              details={status?.details}
            />
          </HStack>
        )}
      </Accordion>
    </VStack>
  );
};

export default AppStatus;
