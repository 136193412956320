import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import DealershipAnalytics from "./DealershipAnalytics/DealershipAnalytics";
import SystemHealth from "./SystemHealth/SystemHealth";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };

  return (
    <VStack padding="1.5rem" w="100%" alignItems="flex-start">
      <Tabs
        variant="table"
        w="100%"
        background="white"
        borderRadius="0.5rem"
        defaultIndex={0}
        onChange={handleTabChange}
      >
        <TabList borderTopRightRadius="0.5rem">
          <Tab w="14rem">Dealership Analytics</Tab>
          <Tab w="14rem">System Health</Tab>
        </TabList>
        <TabPanels w="100%" h="100%">
          <TabPanel w="100%" h="100%">
            {activeTab === 0 && <DealershipAnalytics />}
          </TabPanel>

          <TabPanel w="100%" h="100%">
            {activeTab === 1 && <SystemHealth />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default Dashboard;
