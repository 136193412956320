// https://chakra-ui.com/docs/components/radio/theming
import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { grey } from "@mui/material/colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  control: {
    borderRadius: "7.25rem",
    borderColor: "grey.500",
    bg: "white",
    borderWidth: "1px",
    background: "transparent",
    _checked: {
      background: "transparent",
      borderColor: "primary.default",
      color: "primary.default",
      borderWidth: "1px",
      _hover: {
        borderColor: "primary.default",
      },
    },
  },
  label: {
    // fontFamily: "var(--font-roboto)",
    fontWeight: 600,
    color: "grey.900",
    fontSize: "0.875rem",
  },
});

const sizes = {
  sm: definePartsStyle({
    control: { w: "1rem", h: "1rem" },
  }),
  md: definePartsStyle({
    control: { w: "1rem", h: "1rem" },
    label: { fontSize: "0.875rem" },
  }),
  lg: definePartsStyle({
    control: { w: "1.5rem", h: "1.5rem" },
    label: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
    },
  }),
};

// export the component theme
export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  //   variants,
  defaultProps: {
    size: "md",
  },
});
