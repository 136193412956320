export const variables = {
  "--primary-50": "#e3f2fd",
  "--primary-100": "#bbdefb",
  "--primary-200": "#90caf9",
  "--primary-300": "#64b5f6",
  "--primary-400": "#42a5f5",
  "--primary-500": "#2196f3",
  "--primary-600": "#1e88e5",
  "--primary-700": "#1976d2",
  "--primary-800": "#1565c0",
  "--primary-900": "#0d47a1",
  "--primary-default": "#1e88e5",
  "--secondary-50": "#e8f5e9",
  "--secondary-100": "#c8e6c9",
  "--secondary-200": "#a5d6a7",
  "--secondary-300": "#81c784",
  "--secondary-400": "#66bb6a",
  "--secondary-500": "#4caf50",
  "--secondary-600": "#43a047",
  "--secondary-700": "#388e3c",
  "--secondary-800": "#2e7d32",
  "--secondary-900": "#1b5e20",
  "--secondary-default": "#43a047",
  "--grey-50": "#fafafa",
  "--grey-100": "#f5f5f5",
  "--grey-200": "#eeeeee",
  "--grey-300": "#E0E0E0",
  "--grey-400": "#bdbdbd",
  "--grey-500": "#9E9E9E",
  "--grey-600": "#757575",
  "--grey-700": "#616161",
  "--grey-800": "#424242",
  "--grey-900": "#212121",
  "--grey-default": "#757575",
  "--red-50": "#ffeaea",
  "--red-100": "#ffcdd2",
  "--red-200": "#ef9a9a",
  "--red-300": "#e57373",
  "--red-400": "#ef5350",
  "--red-500": "#f44336",
  "--red-600": "#e53935",
  "--red-700": "#d32f2f",
  "--red-800": "#c62828",
  "--red-900": "#b71c1c",
  "--red-default": "#e53935",
  "--yellow-50": "#fffde7",
  "--yellow-100": "#fff9c4",
  "--yellow-200": "#fff59d",
  "--yellow-300": "#fff176",
  "--yellow-400": "#ffee58",
  "--yellow-500": "#ffeb3b",
  "--yellow-600": "#fdd835",
  "--yellow-700": "#fbc02d",
  "--yellow-800": "#f9a825",
  "--yellow-900": "#f57f17",
  "--yellow-default": "#fdd835",
  "--orange-50": "#fff3e0",
  "--orange-100": "#ffe0b2",
  "--orange-200": "#ffcc80",
  "--orange-300": "#ffb74d",
  "--orange-400": "#ffa726",
  "--orange-500": "#ff9800",
  "--orange-600": "#fb8c00",
  "--orange-700": "#f57c00",
  "--orange-800": "#ef6c00",
  "--orange-900": "#e65100",
  "--orange-default": "#fb8c00",
  "--bluegray-50": "#eceff1",
  "--bluegray-100": "#cfd8dc",
  "--bluegray-200": "#babec5",
  "--bluegray-300": "#90a4ae",
  "--bluegray-400": "#78909c",
  "--bluegray-500": "#607d8b",
  "--bluegray-600": "#546e7a",
  "--bluegray-700": "#455a64",
  "--bluegray-800": "#37474f",
  "--bluegray-900": "#263238",
  "--bluegray-default": "#546e7a",
  "--purple-50": "#EDE7F6",
  "--purple-500": "#9C27B0",
  "--flame-100": "#FCEEEA",
  "--flame-600": "#E2502A",
};
