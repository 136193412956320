import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Dropdown, Input, PaginatedTable } from "../../common";
import { ExcelIcon, PDFIcon, RefreshIcon, SearchIcon } from "../../icons/svg";

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { SERVICES } from "../../../config";
import { fetchInbox } from "../../../utils/api/broadcast.api";
import { markEmailAsRead } from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import EmailDetail from "../../LeadCenter/LeadDetails/DetailTabs/History/Emails/EmailDetail";
import Loader from "../../common/Spinner";

const options: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

const Inbox = () => {
  const [tableView, setTableView] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const [searchStr, setSearchStr] = useState("");

  const user = useUserInfo("user");

  const mutation = useMutation(markEmailAsRead);

  const location = useLocation();
  const itemId = location.state?.email_id;

  useEffect(() => {
    if (itemId) {
      setSelectedItem({ id: itemId || "" } as any);
      setTableView(false);
    }
  }, [itemId]);

  const { data, isFetching: isLoading, refetch } = useQuery({
    queryKey: ["emailInbox", page, perPage, searchStr],
    queryFn: () =>
      fetchInbox({
        dealershipId: user?.dealership?.id,
        page,
        perPage,
        search: searchStr,
      }),
    retry: false,
  });

  const { emails = [], emails_count = 0 } = data?.data || {};

  const handleSelect = (option: SelectOptionProps) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/inbound_emails_list.${option.value}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `Inbox.${option.value}`);
      })
      .catch((response) => {});
    setSelectedOption(option);
  };

  const BDCCampaignHeader = [
    { id: 1, value: "From", label: "from_address" },
    { id: 2, value: "Recipient", label: "to_address" },
    { id: 3, value: "Subject", label: "subject" },
    // { id: 4, value: "Message", label: "body" },
    { id: 4, value: "Date & Time", label: "date" },
    // { id: 5, value: "Actions", label: "actions" },
  ];

  const inboxEmails = useMemo(() => {
    if (!emails?.length) {
      return [];
    }

    return emails.map((item: any) => {
      return {
        ...item,
        from_address: (
          <HStack>
            {!item?.is_read && (
              <Box
                w="0.47rem"
                h="0.47rem"
                borderRadius="50%"
                background="#F44336"
                // position="absolute"
                // top="0.5rem"
                // left="0.5rem"
              ></Box>
            )}
            <Text
              whiteSpace="nowrap"
              ml={`${!item?.is_read ? "0" : "1rem"}`}
              fontWeight={`${!item?.is_read && "bold"}`}
            >
              {item.from_address}
            </Text>
          </HStack>
        ),
        to_address: item.to_address ? (
          <Text whiteSpace="nowrap" fontWeight={`${!item?.is_read && "bold"}`}>
            {item.to_address}
          </Text>
        ) : (
          "- -"
        ),
        message: item.body,
        subject: (
          <Text fontWeight={`${!item?.is_read && "bold"}`}>
            {item.subject || "- -"}
          </Text>
        ),
        // date: moment(item.created_at).format("MM-DD-YYYY - hh:mm A"),
        date: (
          <Text whiteSpace="nowrap" fontWeight={`${!item?.is_read && "bold"}`}>
            {moment(item.created_at).format("MM-DD-YYYY - hh:mm A")}
          </Text>
        ),
        // actions: <Dropdown options={options} onSelect={handleSelect} />,
      };
    });
  }, [emails]);

  const handleMarkAsRead = (item: any) => {
    mutation.mutate(
      {
        dId: user?.dealership?.id,
        payload: `id=${item?.id}`,
      },
      {
        onSuccess: (data: any) => {
          refetch(); // This will trigger the refetch of the parent's query
        },
      }
    );
  };

  return (
    <Box>
      {isLoading && <Loader />}
      {tableView && (
        <>
          <HStack justifyContent="space-between" mb="1rem">
            <HStack alignItems="center">
              {" "}
              <Text textStyle="h4" color="var(--grey-900)" fontWeight="500">
                Inbox ({emails_count})
              </Text>
              <Button
                variant="none"
                display="flex"
                alignItems="center"
                gap="0.5rem"
                sx={{
                  svg: {
                    path: {
                      fill: "var(--primary-600)",
                    },
                  },
                }}
                onClick={() => refetch()}
              >
                <RefreshIcon />
                <Text textStyle="h6" color="var(--primary-600)">
                  Refresh to update results
                </Text>
              </Button>
            </HStack>
            <Box
              display="flex"
              alignItems="center"
              width="27.5rem"
              className="emailInboxSearch"
            >
              <Input
                maxW="20rem"
                type="text"
                hasIcon={true}
                isIconRight={false}
                icon={<SearchIcon />}
                placeholder="Search..."
                onChange={({ target: { value } }) => setSearchStr(value)}
                value={searchStr}
                marginRight={5}
                height="35px"
              />
              <VStack p="relative" zIndex="3">
                <Dropdown
                  options={options}
                  onSelect={handleSelect}
                  hasText={true}
                  text="Export"
                />
              </VStack>
            </Box>
          </HStack>
          <PaginatedTable
            header={BDCCampaignHeader}
            data={inboxEmails}
            itemsPerPage={perPage}
            maxPageNumbers={5}
            isPaginated={true}
            hasMultiBody={false}
            lastCenter={false}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={emails_count}
            rowOnClick={(item: any) => {
              setSelectedItem(item);
              handleMarkAsRead(item);
              setTableView(false);
            }}
          />
        </>
      )}
      {!tableView && (
        <EmailDetail
          clickedRecord={selectedItem}
          setClickedRecord={(value: any) => {
            setSelectedItem(value);
            setTableView(true);
          }}
        />
      )}
    </Box>
  );
};

export default Inbox;
