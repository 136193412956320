import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';
import { initialState, HistoryAction, HistoryReducer, HistoryState } from './reducer';

interface HistoryContextProps {
  children: ReactNode;
}

export const HistoryStateContext = createContext<HistoryState | undefined>(undefined);
export const HistoryDispatchContext = createContext<Dispatch<HistoryAction> | undefined>(undefined);

export function useHistoryState() {
  const context = useContext(HistoryStateContext);
  if (context === undefined) {
    throw new Error('useHistoryState must be used within an HistoryProvider');
  }

  return context;
}

export function useHistoryDispatch() {
  const context = useContext(HistoryDispatchContext);
  if (context === undefined) {
    throw new Error('useHistoryDispatch must be used within an HistoryProvider');
  }

  return context;
}

export const HistoryProvider: React.FC<HistoryContextProps> = ({ children }) => {
  const [user, dispatch] = useReducer(HistoryReducer, initialState);

  return (
    <HistoryStateContext.Provider value={user}>
      <HistoryDispatchContext.Provider value={dispatch}>
        {children}
      </HistoryDispatchContext.Provider>
    </HistoryStateContext.Provider>
  );
};
