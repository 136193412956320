import React, { useEffect, useState } from "react";
import { Text, VStack, Button, useToast, HStack } from "@chakra-ui/react";
import SwitchToggle from "../common/Switch";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Spinner } from "../common";
import {
  fetchUserSettingsDP,
  updateUserSettingsDP,
} from "../../utils/api/user-settings-dp.api";

interface ApiResult {
  data: any;
}
interface ApiRequest {
  key: string;
  settings_hash: { focus_mode: boolean };
}

export const VisibilitySetting = (props: any) => {
  const [focus, setFocus] = useState<boolean>(true);
  const { id: userIdFromParam } = useParams();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");

  const toast = useToast();

  const { data, isLoading } = useQuery({
    queryKey: ["fetchNotif", userIdFromParam, userID],
    queryFn: () =>
      fetchUserSettingsDP(
        dealerId?.id,
        userIdFromParam ?? userID,
        "visibility_settings"
      ),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setFocus(data?.settings_hash?.focus_mode);
    },
  });

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateUserSettingsDP(
          payload,
          dealerId?.id,
          userIdFromParam ?? userID
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {
      key: "visibility_settings",
      settings_hash: {
        focus_mode: focus,
      },
    };

    mutation.mutate(requestData);
  };
  return (
    <VStack alignItems="flex-start" h="100%" position="relative">
      <VStack
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-start"
        w="100%"
      >
        <Text textStyle="h4" fontWeight="500">
          Focus Mode
        </Text>
        <Text textStyle="h6" fontWeight="600" color="var(--grey-800)">
          Only show me contacts that are assigned to me.
        </Text>
        <SwitchToggle
          label="Enable focus mode"
          id="#1"
          defaultChecked={focus}
          onChange={() => setFocus((prevFocus) => !prevFocus)}
        />
      </VStack>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        zIndex="0"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Button
          width="8rem"
          onClick={handleSubmit}
          isDisabled={!props?.allowed}
        >
          Save
        </Button>
      </HStack>

      {(isLoading || mutation.isLoading) && <Spinner />}
    </VStack>
  );
};
