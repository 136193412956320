import { Box, FormLabel, Text } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../common/DateRangePicker";
import {
  SearchAndFilterForm,
  loadDispositionSettings,
  loadLeadSource,
  loadLines,
  loadRoles,
  loadTags,
  loadUsers,
} from "./utils";

type ContactPropertiesProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  dealership_id?: string;
  prefix?: string;
};

function ContactProperties({
  control,
  setValue,
  dealership_id,
  prefix = "",
}: ContactPropertiesProps) {
  const user = useUserInfo("user");
  const { dealership } = user || {};

  const id = dealership?.id ?? dealership_id;

  const roles: any[] = useWatch({
    control,
    name: `${prefix}filter_and_sort.contact_attributes.roles`,
  });

  const mappedRoles = roles?.reduce(
    (acc, role) => (acc += `${(role as any).label},`),
    ""
  );

  return (
    <>
      <Box background="var(--secondary-50)" padding="1.25rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Contact Attributes
        </Text>
      </Box>
      <Box padding="1.25rem">
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Disposition Status
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) =>
                    loadDispositionSettings(searchStr, id)
                  }
                  defaultOptions
                  closeMenuOnSelect={false}
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.disposition_status`,
                      value
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Disposition Status"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.disposition_status`}
          control={control}
          rules={{
            required: true,
          }}
        />
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Tags
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadTags(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.tags`,
                      value
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Multiple Tags"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.tags`}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Lead/Contact Source
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadLeadSource(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.lead_source`,
                      value
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Lead/Contact Source"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.lead_source`}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Lines
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadLines(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.lines`,
                      value
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Lines"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.lines`}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Select Roles
                </FormLabel>
                <AsyncSelect
                  loadOptions={(searchStr) => loadRoles(searchStr, id)}
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.roles`,
                      value
                    );
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.users`,
                      []
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Roles"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.roles`}
          control={control}
          rules={{
            required: true,
          }}
        />

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box mb="1rem">
                <FormLabel
                  mb="0.31rem"
                  color="var(--grey-900)"
                  fontSize="0.875rem"
                  fontWeight="600"
                  lineHeight="1rem"
                >
                  Select Users
                </FormLabel>
                <AsyncSelect
                  key={mappedRoles}
                  loadOptions={(searchStr) =>
                    loadUsers(searchStr, id, mappedRoles)
                  }
                  defaultOptions
                  value={value}
                  onChange={(value: any) => {
                    setValue(
                      `${prefix}filter_and_sort.contact_attributes.users`,
                      value
                    );
                  }}
                  isMulti={true}
                  placeholder="Select Multiple Users"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.contact_attributes.users`}
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>
    </>
  );
}

export default ContactProperties;
