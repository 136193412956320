import { useToast } from "@chakra-ui/react";
import {
  makeOutgoingCall,
  useTwilioDispatch,
  useTwilioState,
} from "../context/TwilioContext";
import { useUserInfo } from "./useUserInfo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Device } from "@twilio/voice-sdk";
import {
  getPowerDialerContacts,
} from "../api/power-dialer.api";
import { useQuery } from "@tanstack/react-query";
import { SERVICES } from "../../config";
import { filterByUniqueKey } from "../helper";

const timeStrMapping: any = {
  15000: "15 Seconds",
  30000: "30 seconds",
};

export const usePWCampaign = () => {
  const {
    campaignParams,
    devices,
    campaignInterval,
    shouldUpdateCampaignContacts,
  } = useTwilioState();

  const dispatch = useTwilioDispatch();

  const { contacts, from, id, running, warmUpDuration, firstContact } =
    campaignParams;

  const lineDevices = filterByUniqueKey(devices.filter((device) => device.phoneNumber), 'phoneNumber');

  const user = useUserInfo("user");
  const toast = useToast();
  const navigate = useNavigate();

  const { refetch } = useQuery({
    queryKey: ["fetchDialerMainContacts", id],
    queryFn: () =>
      getPowerDialerContacts({
        dealership_id: user?.dealership?.id,
        id,
        page: 1,
        perPage: 10,
      }),
    retry: false,
    onSuccess(data) {
      if (
        !/^\/power-dialer\/\d+$/.test(window.location.pathname) &&
        campaignParams.running
      ) {
        dispatch({
          type: "SET_CAMPAIGN_PARAMS",
          payload: {
            campaignParams: {
              ...campaignParams,
              contacts: data?.data?.contacts,
              running: campaignParams.running
                ? !!contacts?.length
                : campaignParams.running,
            },
          },
        });
      }
    },
    enabled:
      !!id &&
      !/^\/power-dialer\/\d+$/.test(window.location.pathname) &&
      campaignParams.running,
  });

  useEffect(() => {
    let interval: any;
    const handleCallWithDispatch = () => {
      handleCall();
      dispatch({
        type: "SET_CAMPAIGN_PARAMS",
        payload: {
          campaignParams: { ...campaignParams, firstContact: false },
        },
      });
    };

    const handleCall = () => {
      if (contacts[0]?.id) {
        makeOutgoingCall(
          dispatch,
          lineDevices[0]?.device as Device,
          contacts[0]?.phone_number,
          from,
          user,
          contacts[0]?.id || "",
          "Power Dialer Campaign",
          id,
          user?.dealership?.id || ""
        );
      } else if (running) {
        dispatch({
          type: "SET_CAMPAIGN_PARAMS",
          payload: {
            campaignParams: {
              contacts: [],
              firstContact: true,
              from: "",
              id: "",
              running: false,
              warmUpDuration: 0,
            },
          },
        });
        clearInterval(interval);
        /^\/power-dialer\/\d+$/.test(window.location.pathname) &&
          navigate("/power-dialer");
      }
    };
    if (running) {
      interval = setInterval(handleCallWithDispatch, warmUpDuration);

      !campaignInterval &&
        dispatch({
          type: "SET_CAMPAIGN_INTERVAL",
          payload: {
            campaignInterval: interval,
          },
        });
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    campaignInterval,
    campaignParams,
    contacts,
    dispatch,
    firstContact,
    from,
    id,
    lineDevices,
    navigate,
    running,
    toast,
    user,
    warmUpDuration,
  ]);

  useEffect(() => {
    function handler(e: BeforeUnloadEvent) {
      e.preventDefault();
      if (!running) {
        return;
      }

      e.returnValue =
        "The Power Dialer campaign is currently running. Are you sure you want to refresh the page?";
    }

    const unloadHandler = (e: any) => {
      e.preventDefault();
      if (!running) {
        return;
      }
       
      debugger
      fetch(
        `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${id}/pause_resume_dialer?status=pause`,
        {
          body: JSON.stringify({ userId: user?.id }),
          method: "POST",
          keepalive: true,
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      fetch(
        `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${id}/toggle_lock?lock_status=unlock&user_id=${user.id}`,
        {
          body: JSON.stringify({ userId: user?.id }),
          method: "POST",
          keepalive: true,
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      clearInterval(campaignInterval);
    };

    if (running) {
      window.addEventListener("beforeunload", handler);
      window.addEventListener("unload", unloadHandler);
    }
    return () => {
      window.removeEventListener("beforeunload", handler);
      window.removeEventListener("unload", unloadHandler);
    };
  }, [
    campaignInterval,
    id,
    running,
    user?.dealership?.id,
    user.id,
    user?.token,
  ]);

  const getIsMainRoute = () => {
    return !/^\/power-dialer\/\d+$/.test(window.location.pathname);
  };

  useEffect(() => {
    if (running && shouldUpdateCampaignContacts) {
      toast({
        description: !contacts[1]?.id
          ? "Power Dialer campaign completed, redirecting to listing..."
          : warmUpDuration !== 3000 || !warmUpDuration
          ? `Call completed, dialing the next call in ${timeStrMapping[warmUpDuration]}...`
          : `Call completed, dialing the next call now...`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      getIsMainRoute() && refetch();
      dispatch({
        type: "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS",
        payload: { shouldUpdateCampaignContacts: false },
      });
    }
  }, [
    contacts,
    dispatch,
    refetch,
    running,
    shouldUpdateCampaignContacts,
    toast,
    warmUpDuration,
  ]);
};
