import { Box, HStack, Text, VStack } from "@chakra-ui/react";

interface ColorKeyProps {
  isLarge: boolean;
  isMap?: boolean;
}

const ColorKey = ({ isLarge, isMap = false }: ColorKeyProps) => {
  return (
    <VStack alignItems="flex-start" gap="1rem" w={isMap ? "100%" : "auto"}>
      <Text textStyle={isLarge ? "h3" : "h6"} fontWeight="500">
        Color Key
      </Text>
      <HStack gap="0.5rem" alignItems="flex-start" w={isMap ? "100%" : "auto"}>
        {!isMap && (
          <VStack alignItems="flex-start">
            <Box
              background="var(--primary-50)"
              w={isLarge ? "8rem" : isMap ? "100%" : "3.75rem"}
              h={isLarge ? "2rem" : "1.25rem"}
              borderRadius={isLarge ? "0.5rem" : "0.2rem"}
            ></Box>
            <Text textStyle={isLarge ? "h5" : "captionSmall"} fontWeight="500">
              Low
            </Text>
          </VStack>
        )}
        <VStack
          alignItems="flex-start"
          gap={isLarge ? "0.81rem" : "0.32rem"}
          w={isMap ? "100%" : "auto"}
        >
          <Box
            background="var(--primary-100)"
            w={isLarge ? "8rem" : isMap ? "100%" : "3.75rem"}
            h={isLarge ? "2rem" : "1.25rem"}
            borderRadius={isLarge ? "0.5rem" : "0.2rem"}
          ></Box>
          {isMap && (
            <Text textStyle={isLarge ? "h5" : "captionSmall"} fontWeight="500">
              Low
            </Text>
          )}
        </VStack>
        <VStack alignItems="flex-start" w={isMap ? "100%" : "auto"}>
          <Box
            background="var(--primary-300)"
            w={isLarge ? "8rem" : isMap ? "100%" : "3.75rem"}
            h={isLarge ? "2rem" : "1.25rem"}
            borderRadius={isLarge ? "0.5rem" : "0.2rem"}
          ></Box>
        </VStack>
        {!isMap && (
          <VStack alignItems="flex-start">
            <Box
              background="var(--primary-500)"
              w={isLarge ? "8rem" : isMap ? "100%" : "3.75rem"}
              h={isLarge ? "2rem" : "1.25rem"}
              borderRadius={isLarge ? "0.5rem" : "0.2rem"}
            ></Box>
          </VStack>
        )}
        <VStack
          alignItems="flex-end"
          gap={isLarge ? "0.81rem" : "0.32rem"}
          w={isMap ? "100%" : "auto"}
        >
          <Box
            background="var(--primary-900)"
            w={isLarge ? "8rem" : isMap ? "100%" : "3.75rem"}
            h={isLarge ? "2rem" : "1.25rem"}
            borderRadius={isLarge ? "0.5rem" : "0.2rem"}
          ></Box>
          <Text textStyle={isLarge ? "h5" : "captionSmall"} fontWeight="500">
            High
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default ColorKey;
