import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchFloorTrafficAnalysis = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/floor_traffic?start_date=${queryKey[2]}&end_date=${queryKey[3]}`
  );
//.................communication reports..............
export const fetchCommunicationUser = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/communication/users?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchCommunicationBroadcast = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/communication/broadcast?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchCommunicationRvm = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/communication/rvm_report?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchCommunicationPowderDialer = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/communication/power_dialer?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchCommunicationBDCCampaign = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/communication/bdc_campaign?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );

//..........................
export const fetchLeadProviderReport = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/lead_provider?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchSoldReportListing = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/sold_listing?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchSoldReportTitle = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/sold_tiles?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchAvgLeadRespReport = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/avg_lead_response?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchLeaderBoardReport = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/leaderboard?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}&sort_by=${queryKey[6]}`
  );
export const fetchCreditAndInventoryReport = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/credit_and_inventory?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const fetchAnalytics = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/analytics?start_date=${queryKey[2]}&end_date=${queryKey[3]}`
  );
export const fetchAnalyticsLeadSource = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/reports/analytics_lead_source?start_date=${queryKey[2]}&end_date=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}`
  );
export const downloadFile = async (payload: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.dId}/dashboard/reports/downloads/${payload.reportType}/${payload.userId}?report_format=${payload.format}`
  );
