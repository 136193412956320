import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import GuestModeSidebar from "../../components/common/GuestModeSidebar";
import GuestModeContent from "../../components/common/GuestModeContent";

const GuestModeLayoutClient = ({ children }: ReactChildren) => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Flex className="dashboard-layout" height="full">
      <GuestModeSidebar
        pathname={location.pathname}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <GuestModeContent setIsDrawerOpen={setIsDrawerOpen}>
        {children}
      </GuestModeContent>
    </Flex>
  );
};

export default GuestModeLayoutClient;
