import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LiveConferenceIcon,
  VideoUploadIcon,
} from "../../../../../components/icons/svg";
import { getHistory } from "../../../../../utils/api/history.api";
import {
  getVideoToken,
  uploadVideo as uploadLeadVideo,
} from "../../../../../utils/api/lead.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { VideoHistory, VideoParticipants } from "../../../../../utils/types";
import { Drawer, Input, PaginatedTable } from "../../../../common";
import Loader from "../../../../common/Spinner";
import SwitchToggle from "../../../../common/Switch";
import { useTwilioState } from "../../../../../utils/context/TwilioContext";

type VideoChatProps = {
  clickedRow: any;
};

const tableHeader = [
  { id: 1, value: "Created By", label: "createdBy" },
  { id: 2, value: "Created At", label: "createdAt" },
  { id: 4, value: "Type", label: "type" },
  { id: 4, value: "URL", label: "url" },
];

const VideoChat = ({ clickedRow }: VideoChatProps) => {
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [width, setWidth] = useState<number | undefined>(0);
  const stackRef = useRef<HTMLInputElement>(null);

  const { devices } = useTwilioState();

  useEffect(() => {
    if (stackRef?.current !== undefined) {
      setWidth(stackRef?.current?.offsetWidth);
    }
  }, [stackRef]);
  const [uploadVideo, setUploadVideo] = useState({
    email: false,
    sms: false,
    videoLink: "",
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const user = useUserInfo("user");
  const navigate = useNavigate();
  const toast = useToast();

  const { data, refetch } = useQuery({
    queryKey: ["videoHistory", clickedRow?.id, page, perPage],
    queryFn: () =>
      getHistory({
        dealershipId: user?.dealership?.id,
        contactId: clickedRow?.id,
        page,
        type: "VideoCall",
        days: "",
        per_page: perPage,
      }),
    refetchOnWindowFocus: true,
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async () => {
      try {
        const response = await getVideoToken({
          dealershipId: user.dealership.id,
          userId: user.id,
          contactId: clickedRow.id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Meeting initiated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error initiated meeting: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const uploadMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await uploadLeadVideo({
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Video uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetch();
      onClose();
      setIsUploadVideo(false);
      setUploadVideo({
        sms: false,
        videoLink: "",
        email: false,
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error uploading video: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const tableData = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return data?.data.histories.map((history: VideoHistory) => {
      const createdBy =
        history.details.call_status !== "manual"
          ? history?.details?.participants?.find(
              (participant: VideoParticipants) => participant.role === "owner"
            )?.name || ""
          : history.details.full_name;
      return {
        createdBy,
        createdAt: moment(history.created_at).format(
          "dddd DD MMM YY • hh:mm a"
        ),
        type:
          history.details.call_status !== "manual"
            ? "Live Conference Call"
            : "Pre-recorded",
        url: history.details.video_identifier
          ? history.details?.meeting_url
          : "",
      };
    });
  }, [data?.data]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleStartVideo = async () => {
    window.open(
      `${window.location.host}/leads/video-chat?contactId=${clickedRow.id}`, '_blank'
    );
  };

  const handleChange = (key: string, value: string | boolean) => {
    setUploadVideo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file && file.size <= 62914560) {
      setUploadVideo((prev) => ({
        ...prev,
        videoLink: e.target.files[0],
      }));
    } else {
      toast({
        description: "File size exceeds the limit of 60MB.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setUploadVideo((prev) => ({
        ...prev,
        videoLink: "",
      }));
      e.target.value = "";
    }
  };

  const handleUpload = () => {
    if (!uploadVideo.videoLink) {
      toast({
        description: "Please select a file",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    let formData = new FormData();
    formData.append("dealership_id", user?.dealership?.id); // Append the File
    formData.append("sms", uploadVideo.sms as any); // Append other data as string
    formData.append("email", uploadVideo.email as any); // Append other data as string
    formData.append("contact_id", clickedRow?.id); // Append other data as string
    formData.append("file", uploadVideo.videoLink); // Append other data as string
    formData.append("user_id", user?.id); // Append other data as string

    uploadMutation.mutate(formData);
  };

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="center"
        gap="0"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
        ref={stackRef}
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Video Chat
          </Text>
          <Button
            variant="outline"
            padding="0.5rem"
            minW="auto"
            onClick={onOpen}
          >
            <AddIcon
              style={{
                color: "var(--grey-700)",
                fontSize: "1.5rem",
              }}
            />
          </Button>
        </HStack>
        <Box
          mt="1rem"
          maxW={`calc(${width}px - 40px)`}
          w="100%"
          overflowX="auto"
          justifyContent="center"
        >
          <PaginatedTable
            header={tableHeader}
            data={tableData}
            itemsPerPage={perPage}
            maxPageNumbers={5}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={data?.data?.histories_count || 0}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
            }}
          />
        </Box>
      </VStack>
      <Drawer
        onClose={() => {
          onClose();
          setIsUploadVideo(false);
          setUploadVideo({
            sms: false,
            videoLink: "",
            email: false,
          });
        }}
        isOpen={isOpen}
        title="Add New Video"
      >
        {(mutation.isLoading || uploadMutation.isLoading) && <Loader />}
        {!isUploadVideo ? (
          <VStack w="100%" h="100%" justifyContent="center" gap="2rem">
            <VStack justifyContent="flex-end" gap="1rem">
              <HStack
                padding="1.3rem"
                background="var(--grey-100)"
                borderRadius="50%"
              >
                <LiveConferenceIcon />
              </HStack>
              <Text textStyle="h5" fontWeight="600">
                Do you want to live video?
              </Text>
              <Button onClick={handleStartVideo}>
                Click to start video chat{" "}
              </Button>
            </VStack>
            <HStack w="100%" gap="1.5rem">
              <Box
                as="span"
                width="100%"
                height="1px"
                background="var(--grey-300)"
              ></Box>
              <Box
                as="span"
                fontSize="1.125rem"
                fontWeight="600"
                color="var(--secondary-600)"
                borderRadius="6.25rem"
                background="var(--secondary-50)"
                padding="0.31rem 1.19rem"
              >
                Or
              </Box>
              <Box
                as="span"
                width="100%"
                height="1px"
                background="var(--grey-300)"
              ></Box>
            </HStack>
            <VStack justifyContent="flex-start" gap="1rem">
              <HStack
                padding="1.3rem"
                background="var(--grey-100)"
                borderRadius="50%"
              >
                <VideoUploadIcon />
              </HStack>
              <Text textStyle="h5" fontWeight="600">
                Do you want to upload recoreded video?
              </Text>
              <Button onClick={() => setIsUploadVideo(true)}>
                Upload Recorded Video{" "}
              </Button>
            </VStack>
          </VStack>
        ) : (
          <>
            <VStack
              w="100%"
              h="100%"
              gap="2rem"
              alignItems="flex-start"
              padding="2rem"
            >
              <Tooltip
                hasArrow
                label={devices.length ? "" : "No Line Exists!"}
                borderRadius="0.5rem"
                placement="top"
                background="var(--grey-900)"
                padding="0.5rem"
                fontSize="0.75rem"
                fontWeight="600"
                color="white"
              >
                <SwitchToggle
                  label="SMS"
                  id="#untagged-sms"
                  defaultChecked={uploadVideo.sms}
                  onChange={(checked: boolean) => handleChange(`sms`, checked)}
                  disabled={!devices.length}
                />
              </Tooltip>

              <SwitchToggle
                label="Email"
                id="#untagged-emauk"
                defaultChecked={uploadVideo.email}
                onChange={(checked: boolean) => handleChange(`email`, checked)}
              />

              <Input
                accept="video/*"
                type="file"
                label="Upload Video"
                onChange={handleFileUpload}
              />
            </VStack>
            <DrawerFooter
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              borderTop="1px solid var(--grey-300)"
              display="flex"
              alignItems="center"
              gap="0.81rem"
            >
              <Button
                variant="outline"
                w="100%"
                size="xl"
                onClick={() => {
                  setIsUploadVideo(false);
                  setUploadVideo({
                    sms: false,
                    videoLink: "",
                    email: false,
                  });
                }}
              >
                Back
              </Button>
              <Button
                w="100%"
                size="xl"
                onClick={(event) => {
                  event.preventDefault();
                  handleUpload();
                }}
                disabled={!uploadVideo.videoLink}
              >
                Save
              </Button>
            </DrawerFooter>
          </>
        )}
      </Drawer>
    </VStack>
  );
};

export default VideoChat;
