import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  fetchById,
  updateSMSTemplate,
} from "../../../utils/api/sms-templates.api";
import AttachmentBar from "../../AttachmentBar";
import { Drawer, Input } from "../../common";
import AttachmentViewer from "../../common/AttachmentViewer";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  endpoint?: string;
  headers?: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  sms_template: {
    name: string;
    body: string;
    media_urls: string[];
  };
}

const EditSMSTemplate = (props: data) => {
  const { isOpen, onClose, refetchParent, endpoint = "", headers } = props;
  const toast = useToast();
  const [template, setTemplate] = useState({
    name: "",
    body: "",
  });
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);
  const [nameError, setNameError] = useState("");
  const [bodyError, setBodyError] = useState("");

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateSMSTemplate(
          endpoint,
          payload,
          headers ?? ""
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Edited successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error editing: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!template?.name?.trim()) {
      setNameError("Name cannot be empty");
      return;
    }
    if (!template?.body?.trim()) {
      setBodyError("Body cannot be empty");
      return;
    }

    setNameError("");
    setBodyError("");

    const requestData: ApiRequest = {
      sms_template: {
        ...template,
        media_urls: attachmentUrls.map((attachment: any) => attachment.url),
      },
    };

    mutation.mutate(requestData);
  };
  const { data, isLoading } = useQuery({
    queryKey: ["fetchSMSTemplateById"],
    queryFn: () => fetchById(endpoint),
  });

  useEffect(() => {
    setTemplate(data);
    setAttachmentUrls(data?.media_urls);
  }, [data]);

  const handleNameChange = (value: string) => {
    setTemplate((prevTemplate) => ({ ...prevTemplate, name: value }));
    setNameError("");
  };

  const handleBodyChange = (value: string) => {
    setTemplate((prevTemplate) => ({ ...prevTemplate, body: value }));
  };
  return (
    <Drawer
      isLoading={isLoading || mutation?.isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title="Edit SMS Template"
    >
      <Box padding="1.25rem">
        <Box
          background="var(--grey-50)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
        >
          <Box mb="1rem">
            <Input
              label="Name"
              isRequired={true}
              type="text"
              maxW="lg"
              error={nameError}
              value={template?.name}
              onChange={(e: any) => handleNameChange(e.target.value)}
            />
          </Box>
          <Box mb="1rem">
            <Input
              label="Template Body"
              isTextarea={true}
              placeholder="Type Here.."
              maxW="lg"
              value={template?.body}
              onChange={(e: any) => handleBodyChange(e.target.value)}
              isRequired={true}
              error={bodyError}
            />
            <AttachmentBar
              setBody={(text, concatText, type = "text") => {
                if (type === "text") {
                  const messagesText = `${template?.body} ${
                    concatText ? `${concatText}: ` : ""
                  }${text}`;
                  handleBodyChange(messagesText);
                } else {
                  setAttachmentUrls((prev: any) => [...prev, text]);
                }
              }}
              handleTemplate={(value, body = "") => {
                handleNameChange(value);
                handleBodyChange(body);
              }}
              parentMenu={"global"}
            />
            <AttachmentViewer
              attachments={attachmentUrls}
              setAttachments={setAttachmentUrls}
            />
          </Box>
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" size="xl" onClick={handleSubmit}>
            Confirm
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};

export default EditSMSTemplate;
