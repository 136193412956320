import { Icon } from "@chakra-ui/react";

export default function FilterIcon(props: any) {
  return (
    <Icon viewBox="0 0 16 16" {...props} fill="none">
      <path
        d="M3.66674 3.33203H12.3334C12.528 3.40027 12.68 3.5547 12.7452 3.75031C12.8104 3.94592 12.7815 4.1607 12.6667 4.33203L9.33341 7.9987V12.6654L6.66674 10.6654V7.9987L3.33341 4.33203C3.21869 4.1607 3.18971 3.94592 3.25492 3.75031C3.32012 3.5547 3.47217 3.40027 3.66674 3.33203"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
