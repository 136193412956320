import React, { ReactNode } from "react";
import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  SlideDirection,
} from "@chakra-ui/react";
import Loader from "./Spinner";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  children: ReactNode;
  placement?: SlideDirection;
  hasFooter?: boolean;
  isLoading?: boolean;
  // customHeader: boolean;
}

const CustomDrawer = ({
  isOpen,
  onClose,
  title,
  children,
  placement = "right",
  hasFooter = true,
  isLoading = false,
}: // customHeader = false,
DrawerProps) => {
  return (
    <Box h="clac(100vh - 68px)">
      <ChakraDrawer
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        placement={placement}
      >
        <DrawerOverlay h={"calc(100vh - 68px)"} bottom="0" top="unset" />
        <DrawerContent
          h={"calc(100vh - 68px)"}
          bottom="0"
          top="unset !important"
        >
          <DrawerCloseButton top="1.15rem" />

          <DrawerHeader
            borderTop="2px solid var(--grey-200)"
            borderBottom="1px solid var(--grey-200)"
            padding="1.25rem"
            color="var(--grey-900)"
            fontSize="1.125rem"
            fontWeight="600"
            lineHeight="1.5rem"
          >
            {title}
          </DrawerHeader>

          <DrawerBody
            padding="0"
            height={hasFooter ? "calc(100vh - 200px)" : "100%"}
            overflow="auto"
            marginBottom={hasFooter ? "72px" : "0"}
          >
            {children}
          </DrawerBody>
          {isLoading && <Loader />}
        </DrawerContent>
      </ChakraDrawer>
    </Box>
  );
};

export default CustomDrawer;
