import React, { useState } from "react";
import { Box, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";

interface SelectRadioProps {
  options: SelectOptionProps[] | SelectIconOptionProps[];
  handleStep?: () => void;
  onChange?: (value: any | any[]) => void;
  defaultValue?: SelectOptionProps | SelectIconOptionProps;
  label?: string;
  isStepper?: boolean;
  labelDark?: boolean;
  hasIcon?: boolean;
  hasFullWidth?: boolean;
  gap?: string;
}

const SelectRadio = ({
  options,
  handleStep,
  defaultValue,
  onChange,
  isStepper = true,
  hasIcon = false,
  label,
  labelDark = false,
  hasFullWidth = false,
  gap = "sm",
}: SelectRadioProps) => {
  const [selectedOption, setSelectedOption] = useState<
    SelectOptionProps | SelectIconOptionProps
  >(defaultValue as any);
  const handleOptionSelect = (
    option: SelectOptionProps | SelectIconOptionProps
  ) => {
    setSelectedOption(option);
    if(onChange){
    onChange(option);}
    if (isStepper) {
      if (handleStep) handleStep();
    }
  };

  return (
    <VStack alignItems="flex-start" w="100%" gap="1.25rem">
      {label && (
        <FormLabel fontSize="1rem" fontWeight={labelDark ? "500" : "400"} m="0">
          {label}
        </FormLabel>
      )}
      {hasIcon ? (
        <HStack w="100%" gap="0.88rem" flexWrap="wrap">
          {options.map((option: SelectIconOptionProps, index: number) => (
            <VStack
              key={index}
              // padding="1.3rem"
              justifyContent="center"
              borderRadius="0.5rem"
              w={hasFullWidth ? "auto" : "8.3rem"}
              h="6.3rem"
              cursor="pointer"
              textAlign="center"
              sx={{
                svg: {
                  width: "2.6rem",
                  height: "2.6rem",
                },
              }}
              border="1px solid var(--grey-300)"
              borderColor={
                selectedOption && selectedOption.value === option.value
                  ? "var(--secondary-600)"
                  : "var(--grey-300)"
              }
              _hover={{
                borderColor: "secondary.600",
              }}
              onClick={() => handleOptionSelect(option)}
            >
              {option?.icon}
              <Text textStyle="h6" fontWeight="500">
                {option.label}
              </Text>
            </VStack>
          ))}
        </HStack>
      ) : (
        <HStack
          w="100%"
          gap={gap === "sm" ? "0.13rem" : gap === "md" ? "0.5rem" : "0.69rem"}
          flexWrap="wrap"
        >
          {options?.map((option: SelectOptionProps, index: number) => (
            <Box
              key={index}
              padding="0.78rem 1.62rem"
              borderRadius="md"
              cursor="pointer"
              w="auto"
              whiteSpace={hasFullWidth ? "nowrap" : "normal"}
              flexBasis="24.4%"
              textAlign="center"
              fontSize="0.75rem"
              fontWeight="500"
              // w="24%"
              _hover={{
                bg: "secondary.600",
                color: "white",
              }}
              backgroundColor={
                selectedOption && selectedOption.value === option.value
                  ? "secondary.600"
                  : "grey.100"
              }
              color={
                selectedOption && selectedOption.value === option.value
                  ? "white"
                  : "grey.900"
              }
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </Box>
          ))}
        </HStack>
      )}
    </VStack>
  );
};

export default SelectRadio;
