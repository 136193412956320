"use client";

import { PaginatedTable, Spinner } from "../../../components/common";
import { RefreshIcon } from "../../../components/icons/svg";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchLiveTracking } from "../../../utils/api/live-tracking";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const STATUS_BUBBLE: Record<string, JSX.Element> = {
  Online: (
    <Text
      textStyle="h6"
      w="5.875rem"
      color="var(--secondary-600)"
      background="var(--secondary-50)"
      textAlign="center"
      padding="0.25rem 0.62rem"
      borderRadius="2.5rem"
    >
      Online
    </Text>
  ),
  Offline: (
    <Text
      textStyle="h6"
      w="5.875rem"
      color="var(--red-600)"
      background="var(--red-50)"
      textAlign="center"
      padding="0.25rem 0.62rem"
      borderRadius="2.5rem"
    >
      Offline
    </Text>
  ),
  "On Call": (
    <Text
      textStyle="h6"
      w="5.875rem"
      color="var(--primary-600)"
      background="var(--primary-50)"
      textAlign="center"
      padding="0.25rem 0.62rem"
      borderRadius="2.5rem"
    >
      On Call
    </Text>
  ),
};
interface LiveTracking {
  username: string;
  status: string;
  status_duration: string;
  last_login: string;
  id?: string;
}

export default function LiveTracking() {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const tableHeader = [
    {
      id: 1,
      value: "User",
      label: "user",
    },
    {
      id: 2,
      value: "Status",
      label: "status",
    },
    {
      id: 3,
      value: "Status Duration",
      label: "statusDuration",
    },
    {
      id: 4,
      value: "Last Login",
      label: "lastLogin",
    },
  ];
  const dId = useUserInfo("dealership")?.id;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["Live Tracking", dId, page, perPage],
    queryFn: fetchLiveTracking,
    refetchOnWindowFocus: false,
    retry: false,
  });
  console.log("data?.data?.data", data?.data?.data)
  const tableData = data?.data?.data?.map?.((item: LiveTracking) => ({
    user: <VStack alignItems="flex-start">
      <Text>{item?.id? `(${item?.id}) `: ''} {item?.username}</Text>
    </VStack>,
    statusDuration: item?.status_duration,
    lastLogin: item?.last_login,
    status: STATUS_BUBBLE[item?.status],
  }));

  return (
    <Box padding="1.5rem">
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem"
        background="white"
        pt="30px"
      >
        <HStack gap="1rem">
          <Text textStyle="h6" color="var(--grey-600)">
            Live tracking for today {data?.data?.current_date}
          </Text>
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.5rem"
            sx={{
              svg: {
                path: {
                  fill: "var(--primary-600)",
                },
              },
            }}
            onClick={() => refetch()}
          >
            <RefreshIcon />
            <Text textStyle="h6" color="var(--primary-600)">
              Refresh to update results
            </Text>
          </Button>
        </HStack>
        <PaginatedTable
          data={tableData}
          header={tableHeader}
          hasPadding={false}
          isPaginated={true}
          currentPage={page}
          setCurrentPage={setPage}
          itemsPerPage={perPage}
          rowCount={data?.data?.total_count}
          perPageChange={(value) => {
            setPage(1);
            setPerPage(Number(value?.label));
          }}
        />
      </VStack>
      {isLoading && <Spinner />}
    </Box>
  );
}
