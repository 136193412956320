import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchRows = async (dId: any, isImport: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/${
      isImport ? "imports" : "exports"
    }`
  );
};

export const downloadFileAPI = async (dId: any, id: any, isImport: boolean) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/${
      isImport ? "imports" : "exports"
    }/${id}`,
    {
      responseType: "blob",
    }
  );
};

export const deleteRow = async (payload: any) => {
  return await axios.delete(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.dId}/${
      payload.isImport ? "imports" : "exports"
    }/${payload.id}`
  );
};
