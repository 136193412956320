import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const field = {
  padding: "0.75rem 0.875rem",
  borderRadius: "0.5rem",
  border: "1px solid var(--grey-300, #E0E0E0)",
  background: "var(--white, #FFF)",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.25rem",
  height: "2.75rem",
  color: "var(--grey-900, #212121)",

  width: "100%",
  // height: [{ base: 8, Tablet: 14 }],
  // fontSize: [{ base: "sm", Tablet: "md", Desktop: "lg" }],
  _placeholder: {
    color: "var(--grey-600)",
  },
};

const gray = definePartsStyle({
  field,
});
const edit = definePartsStyle({
  field: {
    ...field,
    maxWidth: "max-content",
    padding: "0.25rem 0.32rem",
    height: "auto",
    borderColor: "primary.600",
    fontSize: "0.875rem",
  },
});

const dark = definePartsStyle({
  field: {
    ...field,
    background: "dark01",
    border: "1px solid #343434",
    _placeholder: {
      color: "grey.600",
    },
  },
});
const file = definePartsStyle({
  field: {
    display: "block",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    opacity: "0",
    cursor: "pointer",
    _disabled: {
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      opacity: "0",
      cursor: "not-allowed",
    },
  },
});

export default defineMultiStyleConfig({
  variants: { gray, dark, file, edit },
  defaultProps: {
    variant: "gray",
  },
});
