import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ResponsiveValue,
} from "@chakra-ui/react";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  contentMaxW?: string;
  hideCloseButton?: boolean;
  size?: ResponsiveValue<string>;
}

const CustomModal = ({
  isOpen,
  onClose,
  title,
  children,
  contentMaxW,
  hideCloseButton = false,
  size = "xl",
}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={size}>
      <ModalOverlay />
      <ModalContent maxW={contentMaxW}>
        {title && (
          <ModalHeader
            padding="1.25rem"
            color="var(--grey-900)"
            fontSize="1.125rem"
            fontWeight="700"
            lineHeight="1.5rem"
          >
            {title}
          </ModalHeader>
        )}
        <ModalCloseButton style={{ display: hideCloseButton ? "none" : "" }} />
        <ModalBody padding="0">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
