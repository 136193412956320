import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const createPowerDialer = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/power_dialer_campaigns`,
    params.payload
  );

export const updatePowerDialer = async (params: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/power_dialer_campaigns/${params.id}`,
    params.payload
  );

export const lockPowerDialer = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.campaignId}/toggle_lock?lock_status=${params.status}&user_id=${params.userId}`,
    params.payload
  );

export const togglePWCampaign = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.id}/pause_resume_dialer?status=${params.status}`,
    params.payload
  );

export const getPowerDialerCampaigns = async (
  params: any
): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns?status=${params.status}&page=${params.page}&per_page=${params.perPage}`
  );

export const getPowerDialerCampaign = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.id}`
  );

export const removePWContact = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.id}/remove_dialer_contact?contact_id=${params.contactId}`
  );

export const skipPWContact = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.id}/skip_contact?contact_id=${params.contactId}`
  );

export const getPowerDialerContacts = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/power_dialer_campaigns/${params.id}/power_dialer_contacts?page=${params.page}&per_page=${params.perPage}`
  );

export const getPWCommunicationLogs = async (
  params: any
): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/power_dialer_campaigns/${params.id}/dialer_logs?page=${params.page}&per_page=${params.perPage}`
  );
