import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMutation } from "@tanstack/react-query";
import {
  markAllGlobalNotificationAsRead,
  markAllReminderAsRead,
} from "../../../utils/api/header-notifications.api";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import NotificationCard from "./NotificationCard";

interface NotificationProps {
  onOpen: (val: string, count: number) => void;
  onClose: () => void;
  count: number;
  api?: any;
}

const Notifications = ({ onOpen, onClose, count, api }: NotificationProps) => {
  const dealerId = useUserInfo("dealership");
  const isImpersonating = !!useUserInfo("user")?.previousUserId;
  const mutation = useMutation(
    api ? markAllGlobalNotificationAsRead : markAllReminderAsRead
  );
  const toast = useToast();
  const { refetchNotifications } = useNotificationContext();
  const handleMarkAllAsRead = () => {
    mutation.mutate(
      { dId: dealerId?.id, payload: { general: true } },
      {
        onSuccess: (data: any) => {
          onClose();
          refetchNotifications(); // This will trigger the refetch of the parent's query
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack w="100%" alignItems="flex-start" gap="0">
      <HStack w="100%" justifyContent="space-between" padding="1rem">
        <Text textStyle="h5" fontWeight="500">
          All Notifications ({count})
        </Text>
        <HStack alignItems="center">
          {isImpersonating && (
            <Text
              textStyle="h6"
              color="var(--primary-600)"
              fontWeight="500"
              cursor="pointer"
              onClick={() => handleMarkAllAsRead()}
            >
              Mark all as read
            </Text>
          )}
          <Button
            variant="none"
            onClick={() => onOpen("Notification", count)}
            display="flex"
          >
            <FullscreenIcon />
          </Button>
        </HStack>
      </HStack>
      <NotificationCard page={1} perPage={5} onClose={onClose} api={api} />
    </VStack>
  );
};

export default Notifications;
