import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "../common";
import moment from "moment";

type StackedColumnChartProps = {
  isLoading: boolean;
  series?: any;
  categories?: any;
  yaxis?: any;
  options?: any;
  style?: {};
  type?: string
};

export default function StackedColumnChart({
  isLoading,
  series,
  categories,
  yaxis,
  options: parentOptions,
  style = {},
  type='bar'
}: StackedColumnChartProps) {
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (parentOptions) {
      setOptions(parentOptions);
    }else{
      setOptions({
        grid: {
          show: true,
          borderColor: "#E0E0E0",
          strokeDashArray: 3,
        },
        plotOptions: {
          bar: {
            // horizontal: true,
            columnWidth: "10%",
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#64b5f6", "#81c784", "#e57373", "#fff176"],
        series: series ?? [],
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            style: {
              colors: "#212121",
              fontSize: "14px",
            },
            format: "dd/MM",
          },
          categories: categories ?? [],
        },
        yaxis: yaxis
          ? yaxis
          : {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: false,
                color: "#FF1654",
              },
              labels: {
                show: true,
                style: {
                  colors: "#212121",
                },
              },
            },
  
        tooltip: {
          show: true,
          shared: false,
          intersect: true,
          x: {
            show: true,
          },
        },
  
        legend: {
          show: false,
        },
        markers: {
          show: false,
          size: 0,
          style: "hollow",
        },
      });
    }
  }, [categories, parentOptions, series, yaxis]);

  return (
    <>
      <ReactApexChart
        options={options}
        series={series || []}
        type={type as any}
        // height={230}
        // width={570}
        {...style}
      />
      {isLoading && <Spinner />}
    </>
  );
}
