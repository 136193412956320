import { Box, HStack, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { Menu } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import Avatar from "../../assets/avatar.svg";
import { fetchHome } from "../../utils/api/guest-mode";
import TooltipIfOverflow from "./TooltipIfOverflow";

interface HeaderProps {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuestModeHeader = ({ setIsDrawerOpen }: HeaderProps) => {
  const location = useLocation();
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const { id, dId } = useParams();

  const { data } = useQuery({
    queryKey: ["fetchHome", { contact_uuid: id }, dId],
    queryFn: fetchHome,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <HStack
      w="100%"
      position="fixed"
      top="0"
      left="0"
      px={{ md: "1.5rem", base: "0.5rem" }}
      height="4.3rem"
      background="white"
      borderBottom="1px solid var(--grey-300)"
      justify="flex-end"
      zIndex="10"
    >
      {/* <Image
        src={Logo}
        alt="SimpSocial Logo"
        width="7.625rem"
        height="1.375rem"
      /> */}
      <Box display={{ base: "block", md: "none" }}>
        <IconButton
          aria-label="Open sidebar"
          icon={<Menu />}
          onClick={handleDrawerOpen}
          variant="ghost"
        />
      </Box>
        <HStack h="100%" gap="0" display={{ md: "flex", base: "none" }}>
          <HStack
            position="relative"
            paddingRight={{ md: "1.4rem", base: "0.5rem" }}
          >
            <Image
              src={Avatar}
              style={{
                width: "36px",
                height: "36px",
              }}
              alt="avatar"
            />
            <VStack gap="0" align="flex-start">
              <Text
                textStyle={{ md: "h6", base: "bodySmall" }}
                fontWeight={{ md: "700", base: "500" }}
                color="var(--grey-900)"
              >
                <TooltipIfOverflow>
                  {data?.data?.dealership_name}
                </TooltipIfOverflow>
              </Text>
            </VStack>
          </HStack>
        </HStack>
    </HStack>
  );
};

export default GuestModeHeader;
