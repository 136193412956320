import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Popup from "../../../../components/Charts/Popup";
import SearchAndFilterDrawer from "../../../../components/Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../../../components/Drawers/SearchAndFilterDrawer/utils";
import {
  CustomRadio,
  Drawer,
  Input,
  Select,
  TagsInput,
} from "../../../../components/common";
import Loader from "../../../../components/common/Spinner";
import SwitchToggle from "../../../../components/common/Switch";
import {
  FilterIcon,
  SearchIcon,
  TimeIcon,
} from "../../../../components/icons/svg";
import { getTransformedFilters } from "../../../../utils";
import {
  checkLeadSource,
  createBDCcampaign,
  getCategories,
} from "../../../../utils/api/bdc-campaign.api";
import { fetchContacts } from "../../../../utils/api/contact-details";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import ContactListing from "../../leads/lead-details/ContactListing";
import {
  CreateBDCCampaignForm,
  CreateBDCCampaignFormSchema,
  defaultValues,
  loadDispositionSettings,
  loadLeadSource,
  loadOutboundLine,
} from "./utils";

type CreateBdcCampaignProps = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  is_ro_tradeup: boolean;
  apiSet: any;
  dealership_id: any;
};
export const CreateBdcCampaign = ({
  isOpen,
  onClose,
  refetch,
  is_ro_tradeup = false,
  apiSet,
  dealership_id,
}: CreateBdcCampaignProps) => {
  const toast = useToast();
  const [searchStr, setSearchStr] = useState("");
  const id = useUserInfo("dealership")?.id ?? dealership_id;
  const mutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.createBDCcampaign : createBDCcampaign
  );
  const mutationCheck = useMutation<any, Error, any>(
    apiSet ? apiSet?.checkLeadSource : checkLeadSource
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState<any>({
    name: "",
    description: "",
    value: {},
  });
  const { data, isLoading } = useQuery<any>({
    queryKey: ["categories", id, is_ro_tradeup],
    queryFn: apiSet ? apiSet?.getCategories : getCategories,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const sendOptions = [
    { label: " Default Business Hours", value: "default" },
    { label: " Custom Campaign Hours", value: "custom" },
  ];

  const { handleSubmit, control, watch, setValue } =
    useForm<CreateBDCCampaignForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(CreateBDCCampaignFormSchema) as any,
    });
  const handleFormSubmit = (values: CreateBDCCampaignForm) => {
    if (id) {
      mutationCheck.mutate(
        {
          id: id,
          payload: {
            lead_source_ids: values?.lead_form_source?.map(
              (item: any) => item?.value
            ),
          },
        },
        {
          onSuccess(data, variables, context) {
            if (data?.data?.length === 0) {
              apiCallCreation(values, false);
            } else {
              setPopup({
                name: "Are you sure?",
                description:
                  "Some of the selected Leads Source are part of some other campaigns too. Do you want to disengage them from there before engaging here or not?",
                value: values,
              });
              onOpenPopupOpen();
            }
          },
        }
      );
    } else {
      apiCallCreation(values, false);
    }
  };
  const apiCallCreation = (
    values: CreateBDCCampaignForm,
    disengage: boolean
  ) => {
    const campaign_hours: any = {};
    campaign_hours["run_on_nation_holidays"] = values?.run_on_nation_holidays;
    campaign_hours["bdc_campaign_hours"] = values?.bdc_campaign_hours;
    values?.days.forEach((day) => {
      if (day.checked) {
        campaign_hours[day.label] = {
          start_time: day.startTime,
          end_time: day.endTime,
        };
      }
    });
    mutation.mutate(
      {
        id: id,
        payload: {
          bdc_campaign: {
            name: values?.name,
            description: values?.description,
            // throttle: values?.throttle,
            lead_form_ids: values?.lead_form_IDs?.map(
              (item: any) => item?.value
            ),
            line_id: values?.outbound_line?.value,
            category_id: values?.category,
            campaign_hours: campaign_hours,
            lead_source_ids: values?.lead_form_source?.map(
              (item: any) => item?.value
            ),
            contact_disposition_ids: values?.dispositions?.map(
              (item: any) => item?.value
            ),
            disenrolls_settings: {
              disposition_change_to_appointment_set:
                values?.disenroll_if_disposition_changed_to_appointment_set,
              disposition_change_to_sold:
                values?.disenroll_if_disposition_changed_to_sold,
              contact_responds: values?.disenroll_if_the_contact_responds,
            },
            filter_and_sort: filterApplied
              ? getTransformedFilters(values.filters_and_sort)
              : undefined,
            removed_contacts: filterApplied ? removedContacts : undefined,
            disengage_from_other_campaigns: disengage,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          onClosePopupOpen();
          toast({
            description: "Campaign created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const [contacts, setContacts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose,
  } = useDisclosure();
  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();
  const filterApplied = useWatch({ control, name: "filterApplied" });
  const filters = useWatch({ control, name: "filters_and_sort" });
  const { data: filtersData } = useQuery({
    queryKey: ["fetchContacts", page, filters, searchStr],
    queryFn: () => {
      const params: any = {};

      if (!searchStr) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchStr;
      }

      if (filters) {
        const tempFilters = getTransformedFilters(filters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        params.filter_and_sort = tempFilters;
      }
      return fetchContacts(id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: filterApplied,
    onSuccess(data) {
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
    },
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Create BDC Campaign">
      <Box padding="1.25rem">
        {id && (
          <Box mb="1rem">
            <Button
              variant="outlineBlue"
              leftIcon={<FilterIcon />}
              rightIcon={<ChevronRightIcon />}
              justifyContent="space-between"
              color="var(--grey-900)"
              width="100%"
              size="xl"
              onClick={onFilterOpen}
            >
              <Box as="span" mr="auto">
                Filter & Sort
              </Box>
            </Button>
          </Box>
        )}

        {filterApplied && (
          <Box mb="1rem">
            <Button
              variant="outlineBlue"
              rightIcon={<ChevronRightIcon />}
              justifyContent="space-between"
              color="var(--grey-900)"
              width="100%"
              size="xl"
              onClick={onContactsOpen}
            >
              <Box as="span" mr="auto">
                {(filtersData?.data?.contacts_count || 0) -
                  removedContacts?.length}{" "}
                Contacts Selected
              </Box>
            </Button>
          </Box>
        )}

        <Box
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="var(--grey-50)"
          mb="1rem"
        >
          <Text
            textStyle="h4"
            fontWeight="500"
            background="var(--grey-200)"
            padding="0.92rem"
            border="1px solid var(--grey-300)"
          >
            Add Details
          </Text>
          <Box padding="1.3rem">
            <Box mb="0.5rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Type Name"
                      label="Name"
                      hasIcon={false}
                      error={error?.message}
                      onChange={onChange}
                      type="text"
                      maxW="lg"
                    />
                  );
                }}
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Text textStyle="h6" color="var(--grey-600)" mt="0.25rem">
                Specify a name for this automated BDC Campaign
              </Text>
            </Box>
            <Box mb="0.5rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Enter a Description..."
                      label="Description"
                      hasIcon={false}
                      isTextarea={true}
                      error={error?.message}
                      onChange={onChange}
                      type="text"
                      maxW="lg"
                    />
                  );
                }}
                name="description"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box mb="0.5rem" position="relative" zIndex="3">
              <Text textStyle="h5" fontWeight="700" mb="0.62rem">
                Select Category
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      onChange={(value: any) => {
                        onChange(value?.value);
                      }}
                      error={error?.message}
                      placeholder="Select Option"
                      options={
                        data?.data?.categories?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        })) ?? []
                      }
                      value={
                        is_ro_tradeup ? data?.data?.categories[0]?.id : value
                      }
                      variant="default"
                      w="100%"
                    />
                  );
                }}
                name="category"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
           {id&&<> <Box mb="1rem">
              <Text textStyle="h5" fontWeight="700" mb="0.62rem">
                Outbound Line
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <AsyncSelect
                      loadOptions={(searchStr) =>
                        loadOutboundLine(searchStr, id)
                      }
                      defaultOptions
                      value={value}
                      onChange={(val) => onChange(val)}
                      placeholder="Select what line to use to send outbound calls and messages from"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100%",
                          marginBottom: "0.5rem",
                        }),
                      }}
                    />
                  );
                }}
                name="outbound_line"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box mb="0.5rem">
              <Text textStyle="h5" fontWeight="700" mb="0.62rem">
                FB Lead Form IDs
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TagsInput
                      value={value as any}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                      minLength={1}
                      checkDuplicate
                      onAdd={(newValue: string) =>
                        setValue("lead_form_IDs", [
                          ...value,
                          { id: Date.now(), value: newValue },
                        ])
                      }
                      onRemove={(id: number) =>
                        setValue(
                          "lead_form_IDs",
                          value.filter((value) => value.id !== id)
                        )
                      }
                    />
                  );
                }}
                name="lead_form_IDs"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box mb="0.5rem">
              <Text textStyle="h5" fontWeight="700" mb="0.62rem">
                Lead Sources
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <AsyncSelect
                      loadOptions={(searchStr) => loadLeadSource(searchStr, id)}
                      defaultOptions
                      value={value}
                      onChange={onChange}
                      isMulti
                      placeholder="Select Option"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100%",
                        }),
                      }}
                    />
                  );
                }}
                name="lead_form_source"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Text textStyle="h6" color="var(--grey-600)" mt="0.25rem">
              Triggers when lead source sends a lead
              </Text>
            </Box>
            <Box>
              <Text textStyle="h5" fontWeight="700" mb="0.62rem">
                Dispositions
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <AsyncSelect
                      loadOptions={(searchStr) =>
                        loadDispositionSettings(searchStr, id)
                      }
                      defaultOptions
                      value={value}
                      onChange={onChange}
                      isMulti={true}
                      placeholder="Select Disposition Status"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100%",
                        }),
                      }}
                    />
                  );
                }}
                name="dispositions"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box></>}
          </Box>
        </Box>
        <Box
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="var(--grey-50)"
          mb="1rem"
        >
          <Text
            textStyle="h4"
            fontWeight="500"
            background="var(--grey-200)"
            padding="0.92rem"
            border="1px solid var(--grey-300)"
          >
            What Disenrolls a Contact
          </Text>
          <Box padding="1.3rem">
            <Box padding="0.75rem 1.12rem" background="white" mb="0.56rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      label={
                        <Stack direction="column" gap="0.38rem">
                          <Text textStyle="h5" fontWeight="700">
                            Disenroll if Disposition changed to "Appointment
                            Set"
                          </Text>
                        </Stack>
                      }
                      id="#remove"
                      defaultChecked={value}
                      onChange={onChange}
                    />
                  );
                }}
                name="disenroll_if_disposition_changed_to_appointment_set"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box padding="0.75rem 1.12rem" background="white" mb="0.56rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      label={
                        <Stack direction="column" gap="0.38rem">
                          <Text textStyle="h5" fontWeight="700">
                            Disenroll if Disposition changed to "Sold"
                          </Text>
                        </Stack>
                      }
                      defaultChecked={value}
                      onChange={onChange}
                      id="#remove"
                    />
                  );
                }}
                name="disenroll_if_disposition_changed_to_sold"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Box padding="0.75rem 1.12rem" background="white" mb="0.56rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      label={
                        <Stack direction="column" gap="0.38rem">
                          <Text textStyle="h5" fontWeight="700">
                            Disenroll if the contact responds
                          </Text>
                        </Stack>
                      }
                      id="#remove"
                      defaultChecked={value}
                      onChange={onChange}
                    />
                  );
                }}
                name="disenroll_if_the_contact_responds"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="var(--grey-50)"
        >
          <Text
            textStyle="h4"
            fontWeight="500"
            background="var(--grey-200)"
            padding="0.92rem"
            border="1px solid var(--grey-300)"
          >
            BDC Campaign Hours
          </Text>
          <Box padding="1.3rem">
            <Box mb="1rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <CustomRadio
                      options={sendOptions}
                      value={value}
                      setValue={onChange}
                    />
                  );
                }}
                name="bdc_campaign_hours"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
            {watch("bdc_campaign_hours") === "custom" &&
              watch("days").map((item, index) => (
                <Stack mb="1rem" direction="row" alignItems="center">
                  <VStack mt="18px" minW="104px" alignItems="flex-start">
                    <Controller
                      name={`days.${index}.checked`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          size="lg"
                          variant="whiteCheck"
                          onChange={onChange}
                          isChecked={value}
                        >
                          {item?.label}
                        </Checkbox>
                      )}
                    />
                  </VStack>

                  <Controller
                    name={`days.${index}.startTime`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={onChange}
                        label="Start Time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        type="time"
                        maxW="lg"
                        error={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`days.${index}.endTime`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        onChange={onChange}
                        label="End Time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        type="time"
                        maxW="lg"
                        error={error?.message}
                      />
                    )}
                  />
                </Stack>
              ))}
            <Box padding="0.75rem 1.12rem" background="white">
              <Text textStyle="h4" fontWeight="500" lineHeight="1.5rem">
                Run on Federal Holidays
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      label="Enabling this will make this Campaign run on (US) holidays"
                      defaultChecked={value}
                      onChange={onChange}
                    />
                  );
                }}
                name="run_on_nation_holidays"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Box>
          </Box>
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Save And Continue
          </Button>
        </DrawerFooter>
      </Box>
      {isFilterOpen && (
        <SearchAndFilterDrawer
          isOpen={isFilterOpen}
          onClose={onFilterClose}
          setFilters={(searchAndFilters) => {
            setValue("filters_and_sort", searchAndFilters);
            setValue("filterApplied", true);
          }}
          filters={filters}
          pageReset={() => {
            setValue(
              "filters_and_sort",
              defaultFilterValues["filter_and_sort"]
            );
            setValue("filterApplied", false);
          }}
          dealership_id={dealership_id}
        />
      )}

      <Drawer
        isOpen={isContactsOpen}
        onClose={onContactsClose}
        title="Contacts"
      >
        <Box padding="0 20px" marginTop="10px">
          <Input
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Contacts..."
            onChange={({ target: { value } }) => {
              setSearchStr(value);
              setPage(1);
              setContacts([]);
            }}
            width="100%"
            value={searchStr}
          />
        </Box>
        <div
          id="bulkMessageDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={filtersData?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="bulkMessageDiv"
          />
        </div>
      </Drawer>
      {(mutation?.isLoading || mutationCheck?.isLoading) && <Loader />}

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => apiCallCreation(popup?.value, false)}
          onConfirm={() => apiCallCreation(popup?.value, true)}
          message={popup.description}
          loading={mutation?.isLoading}
          title={popup?.name}
        />
      )}
    </Drawer>
  );
};
