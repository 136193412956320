import {
  Button,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import LGHeatMapChart from "../../../../components/Charts/LGHeatMapChart";
import ColorKey from "../../../../components/GlobalDashboard/ColorKey/ColorKey";
import DatePicker from "../../../../components/common/DateRangePicker";
import { EmptyIcon, ExpandIcon } from "../../../../components/icons/svg";
import {
  convertUnderscoreToLabel,
  convertUnderscoreToLabelArray,
} from "../../../../utils";
import { fetchHeatMapStats } from "../../../../utils/api/global-dashboard";
import { getDashboardStats } from "../../../../utils/api/lead.api";
import DealershipDropdown from "./DealershipDropdown";

const MiddlePane = (props: any) => {
  const {
    setIsback,
    selectionRange,
    setSelectionRange,
    dealershipCom,
    setDealershipCom,
    dealerships,
    setDealerships,
  } = props;

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ["fetchHeatMapStats", selectionRange, dealershipCom],
    queryFn: () =>
      fetchHeatMapStats({
        start_date: selectionRange?.selected ? selectionRange?.startDate : null,
        end_date: selectionRange?.selected ? selectionRange?.endDate : null,
        dealership_id: dealershipCom?.value,
      }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled:
      dealershipCom !== undefined &&
      selectionRange?.startDate !== selectionRange?.endDate,
  });

  const { data: respTime, isFetching: isFetchingResp } = useQuery({
    queryKey: ["dashboardStats", dealerships],
    queryFn: () => getDashboardStats({ dealershipId: dealerships?.value }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: dealerships !== undefined,
  });

  return (
    <HStack w="100%" alignItems="normal" gap="1rem">
      <VStack
        alignItems="flex-start"
        h="100%"
        border="1px solid var(--grey-300)"
        borderRadius="0.75rem"
        w="50%"
      >
        <HStack
          padding="1rem"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          fontWeight="700"
          justifyContent="space-between"
        >
          <SkeletonText isLoaded={!isFetchingResp}>
            <Text textStyle="h5">Response Time Details</Text>
          </SkeletonText>
          <HStack>
            <VStack>
              {/* <DatePicker
                value={selectionRespTime}
                onChange={(value) => {
                  setSelectionRespTime(value);
                }}
              /> */}
            </VStack>
            <Skeleton isLoaded={!isFetchingResp}>
              <DealershipDropdown
                placeholder="Select dealership"
                dealerships={dealerships}
                setDealerships={setDealerships}
              />
            </Skeleton>
          </HStack>
        </HStack>
        <HStack
          w="100%"
          alignItems="unset"
          justifyContent="space-between"
          minH="370px"
        >
          {dealerships !== undefined ? (
            <VStack w="100%" gap="10px" alignItems="flex-start">
              <VStack
                borderBottom="1px solid var(--grey-300)"
                w="100%"
                pb="2rem"
                pt="2.5rem"
                pl="1rem"
                alignItems="flex-start"
              >
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text textStyle="h4" w="100%">
                    Avg New Lead Response Time
                  </Text>
                </SkeletonText>
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text
                    textStyle="h4"
                    color="var(--primary-600)"
                    w="241px"
                    whiteSpace="nowrap"
                  >
                    {respTime?.data?.leads?.avg_new_lead_response_time ||
                      `0 seconds`}
                  </Text>
                </SkeletonText>
              </VStack>

              <VStack
                borderBottom="1px solid var(--grey-300)"
                w="100%"
                pb="2rem"
                pt="2rem"
                pl="1rem"
                alignItems="flex-start"
              >
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text textStyle="h4">
                    Avg Inbound Communication Response Time
                  </Text>
                </SkeletonText>
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text
                    textStyle="h4"
                    color="var(--secondary-600)"
                    w="241px"
                    whiteSpace="nowrap"
                  >
                    {respTime?.data?.leads
                      ?.avg_inbound_communication_response_time || `0 seconds`}
                  </Text>
                </SkeletonText>
              </VStack>

              <VStack
                w="100%"
                pb="2rem"
                pt="2rem"
                pl="1rem"
                alignItems="flex-start"
              >
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text textStyle="h4">
                    Average Inbound Email Response Time
                  </Text>
                </SkeletonText>
                <SkeletonText
                  isLoaded={!isFetchingResp}
                  noOfLines={1}
                  skeletonHeight="24px"
                >
                  <Text
                    textStyle="h4"
                    color="var(--secondary-600)"
                    w="241px"
                    whiteSpace="nowrap"
                  >
                    {respTime?.data?.leads?.avg_inbound_email_response_time ||
                      `0 seconds`}
                  </Text>
                </SkeletonText>
              </VStack>
            </VStack>
          ) : (
            <>
              <VStack w="100%" justifyContent="center" alignItems="center">
                <EmptyIcon />
                <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
                  Please select a dealership.
                </Text>
              </VStack>
            </>
          )}
        </HStack>
      </VStack>
      <VStack
        alignItems="flex-start"
        h="100%"
        border="1px solid var(--grey-300)"
        borderRadius="0.75rem"
        w="50%"
      >
        <HStack
          padding="1rem"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          fontWeight="700"
          justifyContent="space-between"
        >
          <SkeletonText isLoaded={!isFetching}>
            <Text textStyle="h5">Communication Heatmap</Text>
          </SkeletonText>
          <HStack>
            <Skeleton isLoaded={!isFetching}>
              <DatePicker
                value={selectionRange}
                onChange={(value) => {
                  setSelectionRange(value);
                }}
              />
            </Skeleton>
            <Skeleton isLoaded={!isFetching}>
              <DealershipDropdown
                placeholder="Select dealership"
                dealerships={dealershipCom}
                setDealerships={setDealershipCom}
              />
            </Skeleton>
            <Skeleton isLoaded={!isFetching}>
              <Button variant="none" onClick={() => setIsback(false)}>
                <ExpandIcon />
              </Button>
            </Skeleton>
          </HStack>
        </HStack>
        {isSuccess ? (
          <>
            <LGHeatMapChart
              key={`${dealershipCom?.value}-${selectionRange.startDate}-${selectionRange.endDate}`}
              isLarge={false}
              categories={
                data?.data?.group_count
                  ? convertUnderscoreToLabelArray(data?.data?.group_count)
                  : {}
              }
              resp={data?.data?.group_count ?? {}}
            />
            <HStack position="relative" w="100%" padding="1rem" mt="-3rem">
              <ColorKey isLarge={false} />
              <VStack
                w="8.6rem"
                p="0.5rem"
                border="1px solid var(--grey-300)"
                position="absolute"
                right="1rem"
                bottom="1rem"
                borderRadius="0.5rem"
              >
                {Object.keys(data?.data?.total_count)?.map((item: string) => (
                  <HStack w="100%" key={item} justifyContent="space-between">
                    <Text textStyle="captionSmall">
                      {convertUnderscoreToLabel(item)}
                    </Text>
                    <Text textStyle="captionSmall">
                      {data?.data?.total_count[item]}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </HStack>
          </>
        ) : (
          <>
            <VStack
              w="100%"
              justifyContent="center"
              alignItems="center"
              h="100%"
            >
              <EmptyIcon />
              <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
                Please select a dealership.
              </Text>
            </VStack>
          </>
        )}
      </VStack>
      {/* {(isFetching || isFetchingResp) && <Spinner />} */}
    </HStack>
  );
};
export default MiddlePane;
