import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from "@giphy/react-components";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { uploadFile } from "../../utils/api/announcements";
import { uploadSmsImage } from "../../utils/api/lead.api";
import { uploadAttachment } from "../../utils/api/social-talk.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Modal } from "../common";
import Loader from "../common/Spinner";

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleImage: (text: string) => void;
  api?: boolean;
  parentMenu?: "global" | null;
};

const fileTypes = ["JPEG", "PNG"];

function ImageModal({
  isOpen,
  onClose,
  handleImage,
  api,
  parentMenu = null,
}: ImageModalProps) {
  const mutation = useMutation(uploadFile);
  const user = useUserInfo("user");
  const { dealership } = user || {};
  // configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
  const toast = useToast();

  const [file, setFile] = useState(null);

  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      if (api) {
        try {
          const response = await uploadAttachment({
            data: payload,
            id: dealership?.id,
          });
          handleImage(response?.data);
          return response.data.url;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await uploadSmsImage({ payload });
          handleImage(response.data);
          return response.data.url;
        } catch (error) {
          throw error;
        }
      }
    },
  });

  const handleChange = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dealership_id", dealership.id);
    // e.target.value = "";
    setFile(file);

    if (parentMenu === "global") {
      mutation.mutate(
        {
          payload: formData,
        },
        {
          onSuccess: (data) => {
            handleImage(data?.data);
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    } else {
      await attachmentMutation.mutateAsync(formData);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Pick Image or GIF">
      <Tabs>
        <TabList>
          <Tab>GIF</Tab>
          <Tab>Image</Tab>
        </TabList>

        <TabPanels height={"500px"} overflowY={"scroll"} padding={"20px"}>
          <TabPanel>
            <SearchContextManager apiKey={"AsPaF5U6JeF114fd4Zgj4kAWblmTDMHF"}>
              <GIFComponent handleImage={handleImage} />
            </SearchContextManager>
          </TabPanel>
          <TabPanel>
            {attachmentMutation.isLoading && <Loader />}
            <FileUploader
              //   multiple={true}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />
            <p>
              {file
                ? `File name: ${(file as any).name}`
                : "no file uploaded yet"}
            </p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  );
}

const GIFComponent = ({
  handleImage,
}: {
  handleImage: (text: any) => void;
}) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <>
      <Box className="gif-searchbar">
        <SearchBar />
      </Box>

      <Grid
        key={searchKey}
        width={500}
        columns={3}
        fetchGifs={fetchGifs}
        noLink
        onGifClick={(event) =>
          handleImage({ url: event.images.original.url, filename: event.title })
        }
        className="gifPicker"
        hideAttribution
      />
    </>
  );
};

export default ImageModal;
