import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const validateFile = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/import/contact/validate_excel`,
    payload.payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const importContacts = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/import/contact/import_contacts/${payload.fileId}`,
    payload.payload
  );
};

export const downloadSample = async (dId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/import/contact/download_sample`,
    {
      responseType: "blob",
    }
  );
};
export const downloadAttachment = async (dId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/import/contact/download_attachment`,
    {
      responseType: "blob",
    }
  );
};
