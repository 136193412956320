import { extendBaseTheme, extendTheme } from "@chakra-ui/react";
import {
  Button,
  Input,
  Link,
  Checkbox,
  Radio,
  Menu,
  SwitchTheme,
  Popover,
  Tabs,
  Stepper,
  CustomSelect,
} from "./components";
import { Colors, Text } from "./foundations";
import GloblStyles from "./styles";

const theme = extendTheme({
  colors: Colors,
  textStyles: Text,
  styles: GloblStyles,
  fonts: {
    heading: `Red Hat Display Variable`,
    body: `Red Hat Display Variable`,
  },
  components: {
    Button,
    Input,
    Link,
    Checkbox,
    Radio,
    Menu,
    Popover,
    Tabs,
    Stepper,
    CustomSelect,
    Switch: SwitchTheme,
  },
});

export default theme;
