import React, { useEffect } from "react";
import { Drawer, Select, Input } from "../common";
import {
  Button,
  DrawerFooter,
  VStack,
  HStack,
  Text,
  Stack,
  Box,
} from "@chakra-ui/react";
import { TimeIcon, CalendarIcon, InfoIcon } from "../icons/svg";
import SelectRadio from "../common/SelectRadio";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  CreateAppointmentForm,
  defaultValues,
  createAppointmentValidation,
  createReminderValidation,
  ReminderOptions,
  LeadAppointmentOptions,
} from "./util";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDealershipUsers } from "../../utils/api/line-rings.api";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import {
  createMyAppointment,
  createDealershipAppointment,
  editAppointment,
  createContactAppointment,
  createContactReminder,
  editContactReminder,
} from "../../utils/api/meeting.api";
import { convertDateTime } from "../../utils";
import Loader from "../common/Spinner";
import { useToast } from "@chakra-ui/react";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
interface Type {
  value?: any;
  // Other properties if any
}
export const CreateAppointment = ({
  isOpen,
  onClose,
  slotData,
  apiCall,
  myCalendar,
  editMode,
  contactid,
  leadReminder,
}: any) => {
  const toast = useToast();
  const dealership = useUserInfo("dealership");
  const userId = useUserInfo("id");
  const { openAppointment } = useTwilioState();
  const dispatch = useTwilioDispatch();
  const { data, isLoading } = useQuery({
    queryKey: ["Appointment-users", dealership?.id, ""],
    queryFn: getDealershipUsers,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const mutation = useMutation(
    contactid && !leadReminder
      ? createContactAppointment
      : leadReminder
      ? createContactReminder
      : myCalendar
      ? createMyAppointment
      : createDealershipAppointment
  );
  const editmutation = useMutation(
    leadReminder ? editContactReminder : editAppointment
  );
  const { handleSubmit, control, setValue, reset } =
    useForm<CreateAppointmentForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(
        myCalendar ? createReminderValidation : createAppointmentValidation
      ) as any,
    });
  const handleFormSubmit = (values: CreateAppointmentForm) => {
    let newdate = moment(values.date).format("yyyy-MM-DD");
    mutation.mutate(
      {
        id: dealership.id,
        userId: userId,
        contactid: contactid,
        data: {
          event: {
            title: values.title,
            start_time: convertDateTime(newdate, values.start_time),
            event_type: (values?.type as Type)?.value,
            participant_ids: myCalendar
              ? undefined
              : values?.userid?.map((item) => item.value),
            // status: "set_appointment",
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          handleClose();
          apiCall();
          toast({
            description: "Event created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleEditFormSubmit = (values: CreateAppointmentForm) => {
    let newdate = moment(values.date).format("yyyy-MM-DD");
    editmutation.mutate(
      {
        id: dealership.id,
        meetingId: slotData?.id,
        contactid: contactid,
        data: {
          event: {
            title: values.title,
            start_time: convertDateTime(newdate, values.start_time),
            event_type: (values?.type as Type)?.value,
            participant_ids: myCalendar
              ? undefined
              : values?.userid?.map((item) => item.value),
            // status: "set_appointment",
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          handleClose();
          apiCall();
          toast({
            description: "Event editted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  // Set default values for start time, end time, and date based on slotData
  useEffect(() => {
    if (slotData) {
      reset(slotData);
    }
  }, [slotData]);

  const handleClose = () => {
    dispatch({ type: "OPEN_APPOINTMENT", payload: { openAppointment: false } });
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen || openAppointment}
      onClose={handleClose}
      title={leadReminder ? "Schedule a Reminder" : "Schedule"}
    >
      <VStack padding="1rem 1.3rem" alignItems="flex-start" w="100%">
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Input
                label={myCalendar ? "Reminder Name" : "Title"}
                value={value}
                onChange={onChange}
                error={error?.message}
                isRequired
                type="text"
                maxW="100%"
              />
            );
          }}
          name="title"
          control={control}
          rules={{
            required: true,
          }}
        />
        {!myCalendar && (
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Select User"
                  isMulti={true}
                  onChange={onChange}
                  value={value}
                  placeholder=""
                  options={
                    data?.data?.users?.map((user: any) => ({
                      label: user?.full_name,
                      value: user?.id,
                    })) ?? []
                  }
                  variant="default"
                  w="100%"
                  error={error?.message}
                />
              );
            }}
            name="userid"
            control={control}
            rules={{
              required: true,
            }}
          />
        )}

        <Box minW={"100%"} className="calenderDatePicker">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={(date) => {
                  console.log(date);
                  onChange(date);
                }}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <Input
                    label="Select Date"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<CalendarIcon />}
                    maxW="100%"
                    minW={"100%"}
                    error={error?.message}
                    placeholder={"dd/mm/yyyy"}
                  />
                }
              />
            )}
            name="date"
            control={control}
            rules={{ required: true }}
          />
        </Box>

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                label="Select Time"
                hasIcon={true}
                isIconRight={true}
                icon={<TimeIcon />}
                type="time"
                maxW="100%"
                error={error?.message}
                isRequired
              />
            );
          }}
          name="start_time"
          control={control}
          rules={{
            required: true,
          }}
        />

        {contactid && !leadReminder && (
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <SelectRadio
                  options={
                    contactid && !leadReminder
                      ? LeadAppointmentOptions
                      : ReminderOptions
                  }
                  hasIcon={true}
                  isStepper={false}
                  labelDark={true}
                  label="Select Appointment Type"
                  onChange={onChange}
                  defaultValue={value}
                />
              );
            }}
            name="type"
            control={control}
            rules={{
              required: true,
            }}
          />
        )}
        {myCalendar && (
          <Stack direction="row">
            <InfoIcon color="var(--primary-600)" />
            <Text textStyle="h6">
              Please note that Meeting Appointments and Service Appointments
              must be scheduled within an individual contact's profile.
            </Text>
          </Stack>
        )}

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl">
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              editMode
                ? handleSubmit((values) => handleEditFormSubmit(values))()
                : handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            {editMode ? "Edit" : "Create"}
          </Button>
        </DrawerFooter>
      </VStack>
      {(mutation?.isLoading || editmutation?.isLoading) && <Loader />}
    </Drawer>
  );
};
