import React, { useEffect, useState } from "react";
import { Drawer, Input } from "../common";
import { Button, DrawerFooter, VStack, useToast, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { createNote } from "../../utils/api/notes";

type NotesDrawerProps = {
  onClose: () => void;
  isOpen: boolean;
  note?: string;
  contactId?: string;
  isPlainNotes?: boolean;
};

function NotesDrawer({
  onClose,
  isOpen,
  note,
  contactId,
  isPlainNotes,
}: NotesDrawerProps) {
  const [addNote, setAddNote] = useState("");
  const user = useUserInfo("user");
  const toast = useToast();

  // useEffect(() => {
  //   setAddNote(note || "");
  // }, [note]);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createNote({
          dId: user?.dealership?.id,
          id: contactId,
          payload: { note: { content: addNote, to_note: !isPlainNotes } },
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Note Added Successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleAddNote = () => {
    mutation.mutate({});
  };

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      title={isPlainNotes ? "Add Notes" : "Did you conduct a T.O."}
    >
      <VStack w="100%" padding="1.5rem" alignItems="flex-start">
        <Input
          hasIcon={false}
          label="Add Notes"
          isIconRight={false}
          placeholder="Type Here.."
          isTextarea={true}
          maxW="100%"
          value={addNote}
          onChange={({ target: { value } }) => setAddNote(value)}
        />

        <VStack
          height="calc(100vh - 300px)"
          overflowY="scroll"
          alignItems="flex-start"
          width="100%"
        >
          {!!note?.length &&
            (note as any).map((noteValue: string) => {
              return (
                <Text
                  p="0.5rem"
                  background="var(--grey-200)"
                  borderRadius="0.375rem"
                  mt={15}
                  maxW="100%"
                >
                  {noteValue}
                </Text>
              );
            })}
        </VStack>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleAddNote}>
          Add Notes
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default NotesDrawer;
