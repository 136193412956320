import { Controller } from "react-hook-form";
import { Input } from ".";

const NameInput = ({ control }: any) => (
  <Controller
    name="name"
    control={control}
    render={({ field, fieldState: { error } }) => (
      <Input
        {...field}
        placeholder="Enter Name"
        label="Name"
        type="text"
        error={error?.message || ""}
        isRequired
        maxLength={200}
      />
    )}
  />
);

export default NameInput;
