import { Button, DrawerFooter, VStack, Text, useToast } from "@chakra-ui/react";
import { Drawer } from "../../common";
import { useMutation } from "@tanstack/react-query";
import { updateDealership, updateUser } from "../../../utils/api/users.api";
import Loader from "../../common/Spinner";

type data = {
  isOpen: boolean;
  onClose: any;
  userId: number;
  refetchParent: any;
  isFromDealershipMenu?: boolean;
};
interface ApiResult {
  data: any;
}
interface ApiRequestDealership {
  userId: number;
  payload: {
    dealership: {
      status: string;
    };
  };
}
interface ApiRequest {
  userId: number;
  payload: {
    user: {
      status: string;
    };
  };
}

const SuspendUser = (props: data) => {
  const { isOpen, onClose, userId, refetchParent, isFromDealershipMenu } =
    props;
  const toast = useToast();

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request: ApiRequest): Promise<ApiResult> => {
      try {
        const response = await updateUser(userId, request.payload, {
          menu: "User",
          "Sub-Menu": "",
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "User suspended successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error suspending user: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const mutationDealership = useMutation<
    ApiResult,
    Error,
    ApiRequestDealership,
    unknown
  >({
    mutationFn: async (request: ApiRequestDealership): Promise<ApiResult> => {
      try {
        const response = await updateDealership(userId, request.payload, {
          menu: "Dealership",
          "Sub-Menu": "Settings/Users",
          Dealership: userId,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Dealership suspended successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error suspending dealership: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (isFromDealershipMenu) {
      const requestData: ApiRequestDealership = {
        userId: userId,
        payload: {
          dealership: {
            status: "suspended",
          },
        },
      };

      mutationDealership.mutate(requestData);
    } else {
      const requestData: ApiRequest = {
        userId: userId,
        payload: {
          user: {
            status: "suspended",
          },
        },
      };

      mutation.mutate(requestData);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={isFromDealershipMenu ? "Suspend Dealership" : "Suspend User"}
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to suspend the{" "}
          {isFromDealershipMenu ? "dealership" : "user"}?
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>

      {(mutation.isLoading || mutationDealership.isLoading) && <Loader />}
    </Drawer>
  );
};

export default SuspendUser;
