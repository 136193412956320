import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { EditNote } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SearchIcon } from "../../../components/icons/svg";
import { fetchLeadSource } from "../../../utils/api/global-config.api";
import { Dropdown, Input, PaginatedTable, Spinner } from "../../common";
import CreateLeadSource from "../CreateLeadSource/CreateLeadSource";
import DeleteLeadSource from "../DeleteLeadSource/DeleteLeadSource";
import EditLeadSource from "../EditLeadSource/EditLeadSource";

const GlobalLeadSource = () => {
  const { id: dealerId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditNote />
          Edit
        </Box>
      ),
      value: "edit",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchLeadSources", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};
      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchLeadSource(params, dealerId);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setCount(data?.lead_sources_count ?? 0);
    },
  });

  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "Number of Contacts", label: "numberOfContacts" },
    { value: "Actions", label: "actions" },
  ];

  const tableData = data?.lead_sources?.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
      numberOfContacts: item?.contact_count,
      phoneNumber: "+1 234 567 89",
      dataType: "Single file",
      notes: "-",
      actions: <Dropdown options={options} onSelect={handleSelect} />,
    };
  });

  return (
    <Box>
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1rem"
        background="white"
      >
        <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
          <Input
            maxW="20rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </HStack>
        <Button onClick={onOpen} padding="0 2rem">
          Create Lead Sources
        </Button>
      </HStack>

      <Text
        textStyle="caption"
        padding="1.5rem 1rem 0.75rem"
        w="100%"
        color="var(--grey-600)"
      >
        Total Lead Sources ({count})
      </Text>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        itemsPerPage={perPage}
        maxPageNumbers={3}
        isPaginated={true}
        lastCenter={false}
        hasMultiBody={false}
        rowOnClick={(rowItem) => {
          setClickedRow(rowItem);
        }}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={count}
      />

      {isOpen && (
        <CreateLeadSource
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        ></CreateLeadSource>
      )}

      {activeModal === "edit" && (
        <EditLeadSource
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "delete" && (
        <DeleteLeadSource
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {isLoading && <Spinner />}
    </Box>
  );
};

export default GlobalLeadSource;
