import { Box, HStack, Text } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import SwitchToggle from "../../common/Switch";
import DatePicker from "../../common/DateRangePicker";

type ContactDateProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  prefix?: string;
};

function ContactDate({ control, setValue, prefix = "" }: ContactDateProps) {
  const allTime = useWatch({
    control,
    name: `${prefix}filter_and_sort.all_time`,
  });
  const dateRange = useWatch({
    control,
    name: `${prefix}filter_and_sort.date_range`,
  });
  return (
    <>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box padding="1rem">
              <SwitchToggle
                label={"All Time"}
                id={`#all_time_toggle}`}
                defaultChecked={value}
                onChange={(checked: boolean) =>
                  setValue(`${prefix}filter_and_sort.all_time`, checked)
                }
                disabled={dateRange?.start_date}
              />
            </Box>
          );
        }}
        name={`${prefix}filter_and_sort.all_time`}
        control={control}
        rules={{
          required: true,
        }}
      />

      <HStack w="100%" gap="1.5rem" padding="1.25rem">
        <Box
          as="span"
          width="100%"
          height="1px"
          background="var(--grey-300)"
        ></Box>
        <Box
          as="span"
          fontSize="1.125rem"
          fontWeight="600"
          color="var(--secondary-600)"
          borderRadius="6.25rem"
          background="var(--secondary-50)"
          padding="0.31rem 1.19rem"
        >
          Or
        </Box>

        <Box
          as="span"
          width="100%"
          height="1px"
          background="var(--grey-300)"
        ></Box>
      </HStack>

      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box padding="1rem">
              <DatePicker
                label="Select Date Range"
                value={{
                  startDate: value.start_date as Date,
                  endDate: value.end_date as Date,
                }}
                onChange={(range: { startDate: Date; endDate: Date }) => {
                  setValue(`${prefix}filter_and_sort.date_range`, {
                    start_date: range?.startDate,
                    end_date: range?.endDate,
                  });
                  setValue(
                    `${prefix}filter_and_sort.datePicked`,
                    !!range?.startDate
                  );
                }}
                w="100%"
                shouldShowClear
                isRange
                isDisabled={allTime}
              />
            </Box>
          );
        }}
        name={`${prefix}filter_and_sort.date_range`}
        control={control}
        rules={{
          required: true,
        }}
      />

      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box padding="1rem">
              <SwitchToggle
                label={"Created Date"}
                id={`#created_date}`}
                defaultChecked={value}
                onChange={(checked: boolean) =>
                  setValue(`${prefix}filter_and_sort.created_date`, checked)
                }
              />
            </Box>
          );
        }}
        name={`${prefix}filter_and_sort.created_date`}
        control={control}
        rules={{
          required: true,
        }}
      />
    </>
  );
}

export default ContactDate;
