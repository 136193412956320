import React, { useState, useRef } from "react";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import { PlayIcon, VoiceRecordIcon } from "../icons/svg";

interface AudioPlayerProps {
  transcribed: boolean | undefined;
  handleTranscribed: () => void;
  handleAudioData: (src: string) => void;
  variant?: string;
  isDisabled?: boolean;
}

const VoiceRecorder = ({
  transcribed,
  handleTranscribed,
  handleAudioData,
  variant = "default",
  isDisabled,
}: AudioPlayerProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [audioUrl, setAudioUrl] = useState<string | null>();

  const startRecording = () => {
    audioChunks.current = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);

        mediaRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };

        mediaRecorder.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          handleAudioData(audioUrl);
          stream.getTracks().forEach((track) => track.stop());
        };

        mediaRecorder.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
      });
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsPaused(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (
        mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused"
      ) {
        mediaRecorder.current.stop();
      }
      setIsRecording(false);
      setIsPaused(false);
      handleTranscribed();
    }
  };

  return (
    <VStack
      position="relative"
      height={variant === "default" ? "100%" : "auto"}
      width="100%"
      padding={variant === "default" ? "1rem" : "0"}
      gap="1rem"
      textAlign="center"
      borderRight={variant === "default" ? "1px solid var(--grey-300)" : "none"}
    >
      {variant === "default" && (
        <>
          <Text textStyle="h6" fontWeight="500">
            Record an audio file
          </Text>

          <HStack>
            {isRecording ? (
              <Button
                onClick={isPaused ? resumeRecording : pauseRecording}
                variant="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="5.5rem"
                minH="5.5rem"
                borderRadius="50%"
                background="var(--grey-200)"
              >
                {isPaused ? (
                  <PlayIcon color="var(--primary-600)" />
                ) : (
                  <PauseIcon
                    style={{
                      color: "var(--primary-600)",
                    }}
                  />
                )}
              </Button>
            ) : (
              <Button
                onClick={startRecording}
                isDisabled={transcribed || isDisabled}
                variant="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="5.5rem"
                minH="5.5rem"
                borderRadius="50%"
                background="var(--grey-200)"
              >
                <MicIcon
                  style={{
                    color: "var(--primary-600)",
                  }}
                />
              </Button>
            )}
            {isRecording && (
              <Button
                ml={4}
                onClick={stopRecording}
                variant="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="5.5rem"
                minH="5.5rem"
                borderRadius="50%"
                background="var(--grey-200)"
              >
                <StopIcon
                  style={{
                    color: "var(--primary-600)",
                  }}
                />
              </Button>
            )}
          </HStack>

          <Text textStyle="h6" fontWeight="400" color="var(--grey-700)">
            Press to record your voice
          </Text>
        </>
      )}
      {variant === "settings" && (
        <>
          <Box
            as="span"
            w="5.5rem"
            h="5.5rem"
            border="1px dashed var(--grey-300)"
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <VoiceRecordIcon />
          </Box>
          <Text textStyle="h6" fontWeight="400" color="var(--grey-900)">
            Click on the button to record your voice
          </Text>
          <Box display="flex" justifyContent="center" alignItems="center">
            {isRecording ? (
              <Button onClick={isPaused ? resumeRecording : pauseRecording}>
                {isPaused ? "Resume" : "Pause"}
              </Button>
            ) : (
              <Button onClick={startRecording}>Get Started</Button>
            )}
            {isRecording && (
              <Button ml={4} onClick={stopRecording}>
                Stop
              </Button>
            )}
          </Box>
        </>
      )}
    </VStack>
  );
};

export default VoiceRecorder;
