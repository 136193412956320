import { HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { CallHistory } from "../../../../../../utils/types";

type TwoLeggedCardProps = {
  item: CallHistory;
};

function TwoLeggedCard({ item }: TwoLeggedCardProps) {
  return (
    <>
      <VStack alignItems="flex-start" gap="0.25rem">
        <HStack gap="0.25rem">
          <Text textStyle="h6" fontWeight="400">
            From:
          </Text>
          <Text color="var(--primary-800)" textStyle="h6" fontWeight="500">
            {item?.details?.from}
          </Text>
        </HStack>

        {item?.details?.delership_name && (
          <Text textStyle="h6" fontWeight="400">
            {item?.details?.delership_name}
          </Text>
        )}
        <HStack gap="0.25rem">
          <Text textStyle="h6" fontWeight="400">
            Dealer Number:
          </Text>
          <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
            {item?.details?.to}
          </Text>
        </HStack>

        <HStack gap="0.25rem">
          <Text textStyle="h6" fontWeight="400">
            Customer Number:
          </Text>
          <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
            {item?.details?.two_legged_reciever}
          </Text>
        </HStack>

        {item?.details?.summary && (
          <HStack gap="0.25rem">
            <Text textStyle="h6" fontWeight="400">
              Summary:
            </Text>
            <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
              {item?.details?.summary}
            </Text>
          </HStack>
        )}
      </VStack>
    </>
  );
}

export default TwoLeggedCard;
