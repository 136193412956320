import  { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import WebChatComponent from "./MessengerComponent";
import { checkAuthentication } from "../../utils/api/social-talk.api";
import { Spinner, Box, Center, VStack, Icon, Text } from "@chakra-ui/react";
import ChatIcon from "@mui/icons-material/Chat";

export const MessengerAuthentication = () => {
  const dealership_id = useUserInfo("dealership")?.id;
  const [authenticated, setAuthenticated] = useState(false);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["authentication", dealership_id],
    queryFn: checkAuthentication,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      if (data?.data?.success === true) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    },
  });
  return (
    <>
      {authenticated ? (
        <WebChatComponent />
      ) : (
        <Center height="calc(100vh - 300px)">
          <VStack spacing={4}>
            <ChatIcon sx={{ color: "#1E88E5", fontSize: "40px" }} />
            <Text fontSize="xl" textAlign="center">
            It looks like you need to authenticate your messenger account first. Please reach out to our support team for assistance.
            </Text>
          </VStack>
        </Center>
      )}
      {isFetching && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Spinner size="xl" />
        </Box>
      )}
    </>
  );
};
