import React, { ReactNode } from "react";
import {
  Button,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

interface PopoverProps {
  trigger: any;
  children: ReactNode;
  variant?: string;
  btnVariant?: string;
  contentStyle: any;
  btnStyle?: any;
  placement?: PlacementWithLogical;
  onOpen?:any;
  isOpen?:any;
  onClose?:any;
}

const CustomPopover = ({
  trigger,
  children,
  variant,
  btnVariant,
  contentStyle,
  btnStyle,
  placement = "bottom-end",
  onOpen,
  isOpen,
  onClose
}: PopoverProps) => {
  return (
    <Popover variant={variant} placement={placement} onOpen={onOpen} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant={btnVariant} style={btnStyle}>
          {trigger}
        </Button>
      </PopoverTrigger>
      <PopoverContent style={contentStyle} >
        <PopoverBody padding="0">{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomPopover;
