import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Drawer, Input } from "../../../components/common";
import { fetchContacts } from "../../../utils/api/leadwar.api";
import { createLeadWar, editLeadWar } from "../../../utils/api/leadwar.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import ContactListing from "../leads/lead-details/ContactListing";
import { SearchIcon } from "../../../components/icons/svg";

export const ContactsDrawer = (props: any) => {
  const {
    onClose,
    refetch,
    id = null,
    isContactsOpen,
    onContactsClose,
    formData,
    attachmentUrls,
    isSms,
  } = props;

  const [searchStr, setSearchStr] = useState("");

  const toast = useToast();
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);

  const mutation = useMutation(createLeadWar);
  const mutationEdit = useMutation(editLeadWar);
  const filterData = {
    year: formData?.year,
    make: formData?.make,
    model: formData?.model,
    exterior_color: formData?.exterior_color,
    lead_source_ids: formData?.lead_sources?.map((item: any) => item?.value),
    disposition_ids: formData?.disposition_status?.map(
      (item: any) => item?.value
    ),
    start_date: formData?.start_date,
    end_date: formData?.end_date,
  };
  const { data: contactsData, isLoading } = useQuery({
    queryKey: ["fetchContactsForLeadwar", dId, page, filterData,props?.formData, searchStr],
    queryFn: () =>
      fetchContacts(dId, {
        page,
        per_page: 12,
        filter_and_sort: filterData,
        q: searchStr,
      }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isContactsOpen,
    onSuccess(data) {
      if (data?.data?.contacts) {
        setContacts((prev: any) => {
          const idMap = new Map(prev.map((item: any) => [item.id, item]));
          data?.data?.contacts?.forEach((user: any) => {
            if (!idMap.has(user.id)) {
              idMap.set(user.id, user);
            }
          });
          return Array.from(idMap.values());
        });
      }
    },
  });
  const onSubmit = (type: "save" | "email" | "sms") => {
    const mutationFunction = id ? mutationEdit.mutate : mutation.mutate;
    mutationFunction(
      {
        dId,
        id,
        payload: {
          digital_lead_war: {
            name: formData?.name,
            filters: filterData,
            contact_ids: removedContacts,
            subject: formData?.subject,
            content: formData?.content,
            line_id: formData?.line_id ? formData?.line_id?.value : undefined,
            media_urls: attachmentUrls?.map(
              (attachment: any) => attachment.url
            ),
          },
          campaign_type: type,
        },
      },
      {
        onSuccess: () => {
          refetch();
          onClose();
          toast({
            description: id
              ? "Digital lead war campaign edited successfully."
              : "Digital lead war campaign created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error ${
              id ? "updating" : "creating"
            } Digital lead war campaign: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <Drawer
      isOpen={isContactsOpen}
      onClose={onContactsClose}
      title="Contacts"
      isLoading={isLoading || mutation.isLoading || mutationEdit.isLoading}
    >
      <Box padding="0 20px" marginTop="10px">
        <Input
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Search Contacts..."
          onChange={({ target: { value } }) => {
            setSearchStr(value);
            setPage(1);
            setContacts([]);
          }}
          width="100%"
          value={searchStr}
        />
      </Box>
      <Box>
        <div
          id="contactsDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={contactsData?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="contactsDiv"
          />
        </div>
      </Box>

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
        background="white"
      >
        <Button
          w="100%"
          size="xl"
          type="submit"
          onClick={() => onSubmit("save")}
          isDisabled={
            contacts?.length === 0 ||
            contactsData?.data?.contacts_count - removedContacts?.length === 0
          }
        >
          {id ? "Edit" : "Save"}
        </Button>
        {!isSms ? (
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={() => onSubmit("email")}
            isDisabled={
              contacts?.length === 0 ||
              contactsData?.data?.contacts_count - removedContacts?.length ===
                0 ||
              !formData?.content
            }
          >
            Email Blast Now
          </Button>
        ) : (
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={() => onSubmit("sms")}
            isDisabled={
              contacts?.length === 0 ||
              contactsData?.data?.contacts_count - removedContacts?.length ===
                0 ||
              !formData?.content
            }
          >
            Broadcast Message Now
          </Button>
        )}
      </DrawerFooter>
    </Drawer>
  );
};
