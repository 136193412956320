import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { Drawer } from "../../../../common";

import { useState } from "react";
import AddEmpInfo from "./AddEmpInfo";
import PersonalInfo from "./PersonalInfo";

interface ContactSettingsProps {
  isCPDrawerOpen: boolean;
  onCPClose: () => void;
  id: string;
  refetchParent: () => void;
}

const ContactSettings = ({
  isCPDrawerOpen,
  onCPClose,
  id,
  refetchParent,
}: ContactSettingsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoadingChild, setIsLoadingChild] = useState(false);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  return (
    <Drawer
      isOpen={isCPDrawerOpen}
      onClose={onCPClose}
      title="Contact Settings"
      hasFooter={false}
      isLoading={isLoadingChild}
    >
      <VStack alignItems="flex-start" w="100%" padding="1rem" h="100%">
        <Tabs
          variant="primary"
          w="100%"
          h="calc(100% - 3rem)"
          onChange={handleTabChange}
          defaultIndex={0}
        >
          <TabList>
            <Tab w="100%">Personal Info</Tab>
            <Tab w="100%">Address / Employee Info</Tab>
          </TabList>
          <TabPanels h="100%">
            <TabPanel h="100%">
              {activeTab === 0 && (
                <PersonalInfo
                  {...{ id, onCPClose, refetchParent, setIsLoadingChild }}
                />
              )}
            </TabPanel>
            <TabPanel>
              {activeTab === 1 && (
                <AddEmpInfo
                  {...{ id, onCPClose, refetchParent, setIsLoadingChild }}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Drawer>
  );
};

export default ContactSettings;
