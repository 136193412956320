export const GuestModeSidebarMenu = [
  {
    id: 1,
    name: "Home",
    path: "/guest_mode/dealership/:dId/contact/:id",
    key: "home",
  },
  {
    id: 2,
    name: "My Profile",
    path: "/guest_mode/dealership/:dId/contact/:id/my_profile",
    key: "my_profile",
  },

  {
    id: 3,
    name: "My Garage",
    path: "/guest_mode/dealership/:dId/contact/:id/my_garage",

    key: "my_garage",
  },
  {
    id: 4,
    name: "Schedule Visit",
    path: "/guest_mode/dealership/:dId/contact/:id/schedule_a_visit",
    key: "schedule_visit",
  },

  {
    id: 5,
    name: "Apply for Credit",
    path: "/guest_mode/dealership/:dId/contact/:id/apply_for_credit",
    key: "Apply_for_credit",
  },
  {
    id: 6,
    name: "Browse Inventory",
    path: "/guest_mode/dealership/:dId/contact/:id/browse_inventory",
    key: "browser_inventory",
  },
  {
    id: 7,
    name: "My Documents",
    path: "/guest_mode/dealership/:dId/contact/:id/my_document",
    key: "my_documents",
  },
];
