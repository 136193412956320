import React, { useEffect, useState } from "react";
import { Box, Text, Flex, FormLabel, BoxProps } from "@chakra-ui/react";
import Select, { MenuPlacement, components } from "react-select";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomSelect } from "../../theme/components";

interface ChakraSelectProps extends BoxProps {
  label?: string | React.ReactNode;
  options: SelectOptionProps[];
  value?: any | any[];
  placeholder?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  variant?: string;
  onChange?: (value: any | any[]) => void;
  maxW?: string;
  w?: string;
  isRequired?: boolean;
  error?: string;
  placement?: MenuPlacement;
  isClearable?: boolean;
  parentStyles?: any;
  boxStyles?: any;
}

const ChakraSelect = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  isDisabled,
  isMulti = false,
  isSearchable,
  variant,
  maxW = "100%",
  w = "max-content",
  placement = "auto",
  isRequired = false,
  error,
  isClearable,
  parentStyles = {},
  boxStyles = {},
  ...rest
}: ChakraSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    isMulti ? value || [] : value ? [value] : []
  );

  useEffect(() => {
    if (isMulti) {
      setSelectedOptions(value || []);
    } else {
      setSelectedOptions(
        value ? [options?.find((option) => option.value === value)] : []
      );
    }
  }, [value, isMulti, options]);

  const handleOptionChange = (selectedOptions: any) => {
    let updatedValue: any[] = [];

    if (isMulti) {
      updatedValue = Array.isArray(value) ? [...value] : [];
      const selectedOptionValues = selectedOptions?.map(
        (option: any) => option.value
      );
      selectedOptionValues.forEach((selectedOptionValue: any) => {
        const index = updatedValue.indexOf(selectedOptionValue);
        if (index !== -1) {
          updatedValue.splice(index, 1);
        } else {
          updatedValue.push(selectedOptionValue);
        }
      });
    } else {
      updatedValue = (selectedOptions as any)?.value;
    }
    const selectedOptionObject = isMulti
      ? selectedOptions?.map((option: any) => ({
          label: option.label,
          value: option.value,
        }))
      : {
          label: selectedOptions?.label,
          value: selectedOptions?.value,
        };

    onChange?.(isMulti ? selectedOptionObject : selectedOptionObject);
    setSelectedOptions(isMulti ? selectedOptions : [selectedOptions as any]);
  };

  const handleRemoveOption = (optionValue: any) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);

    onChange?.(
      updatedOptions.map((option: any) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };
  const renderPills = () => {
    return selectedOptions.map((option) => (
      <Flex
        key={option.value}
        alignItems="center"
        padding="0.125rem 0.3125rem"
        gap="0.5rem"
        borderRadius="1.5rem"
        background="var(--grey-200)"
        mt={2}
      >
        <Text
          color="var(--grey-900)"
          fontSize="0.875rem"
          font-weight="400"
          lineHeight="1.25rem"
          mr={1}
        >
          {option.label}
        </Text>
        <Box
          as="span"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => handleRemoveOption(option.value)}
        >
          <CancelIcon
            style={{
              color: "var(--bluegray-300)",
              width: "1rem",
              height: "1rem",
            }}
          />
        </Box>
      </Flex>
    ));
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDownIcon
          style={{
            color: "var(--grey-900)",
          }}
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <Box maxW={maxW} w={w} {...boxStyles}>
      {label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          {label}
          {isRequired && (
            <Box as="span" color="var(--red-600)">
              *
            </Box>
          )}
        </FormLabel>
      )}
      <Select
        isMulti={isMulti}
        options={options}
        styles={{
          ...CustomSelect?.styles?.customSelect?.[variant ? variant : ""],
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            ...parentStyles,
            // borderRadius: "0.5rem",
            ...CustomSelect?.styles?.customSelect?.default,
          }),
        }}
        components={{ DropdownIndicator }}
        value={selectedOptions}
        isDisabled={isDisabled}
        placeholder={placeholder}
        menuPlacement={placement}
        onChange={handleOptionChange}
        controlShouldRenderValue={isMulti ? false : true}
        isSearchable={isSearchable ? false : true}
        isClearable={isClearable}
        borderColor={error ? "var(--red-600)" : "var(--grey-300)"}
        {...rest}
      />
      {isMulti && (
        <Box display="flex" gap="0.31rem" alignItems="center" flexWrap="wrap">
          {renderPills()}
        </Box>
      )}
      <Text textStyle="captionSmall" color="var(--red-600)" marginLeft="1px">
        {error}
      </Text>
    </Box>
  );
};

export default ChakraSelect;
