import React, { useEffect ,useState} from "react";
import { Spinner } from "../../../components/common";
import { getUser } from "../../../utils/api/login.api";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
const RedirectPage = () => {
    const dispatch = useAuthDispatch()
    const[call,setCall]=useState('')
    const toast=useToast()
    const navigate=useNavigate()
      const currentUrl = window.location.href;
      const isGoogleInUrl = currentUrl.includes('google');

      const { data,isFetching: isFetchingClients } = useQuery(
        ["getUsersData",call,isGoogleInUrl?'google':'facebook'],
        getUser,
        {
          onSuccess: (response) => {getUserDetail(response)},
          onError: (error:any) => handleError(error),
          retry: false,
          enabled:call!=='',
        }
      );
      const  getUserDetail=async (response:any)=>{
        const authorizationHeader = response.headers.get('Authorization')
        const token = authorizationHeader.split(' ')[1];
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data,token:token });
        let data = response?.data?.status?.data?.user;
  
        // Append token to user data
        data.token = token;
  
        // Store the updated user data in localStorage
        localStorage.setItem('currentUser', JSON.stringify(data));        
        
    }
const handleError=(error:any)=>{
    toast({
       description: error?.response?.data?.error??"Something went wrong",
       status: "error",
       duration: 3000,
       isClosable: true,
       position:'top',
       onCloseComplete() {
         navigate('/login')
       },
     
     }); 

}
  useEffect(() => {
    const fragmentIdentifier = window.location.hash;
    const accessTokenMatch = fragmentIdentifier.match(/access_token=([^&]+)/);
    const accessToken = accessTokenMatch ? accessTokenMatch[1] : null;
    const decodedAccessToken = accessToken ? decodeURIComponent(accessToken) : null;

    if(decodedAccessToken){
        setCall(decodedAccessToken)
    }
  }, []);

  return <Spinner />;
};

export default RedirectPage;
