import { useStopwatch } from "react-timer-hook";
import { addZeroIfNeeded } from "../../utils";

export default function Stopwatch({ style }: { style?: any }) {
  const { seconds, minutes } = useStopwatch({ autoStart: true });

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "30px", ...style }}>
        <span>{addZeroIfNeeded(minutes)}</span>:<span>{addZeroIfNeeded(seconds)}</span>
      </div>
    </div>
  );
}
