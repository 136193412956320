import { Call, Device } from "@twilio/voice-sdk";
import moment from "moment";
import { TwilioAction, TwilioDevice } from "./reducer";
import { removePWContact, skipPWContact } from "../../api/power-dialer.api";

export function updateCurrentDevice(
  dispatch: React.Dispatch<TwilioAction>,
  device: {
    device: Device | null;
    phoneNumber: string;
    label: string;
    is_default?: boolean;
  }
) {
  dispatch({
    type: "UPDATE_CURRENT_DEVICE",
    payload: { currentDevice: device },
  });
}

export function setAllDevices(
  dispatch: React.Dispatch<TwilioAction>,
  devices: TwilioDevice[]
) {
  dispatch({ type: "SET_DEVICES", payload: { devices } });
}

const handleCallAccept = (dispatch: React.Dispatch<TwilioAction>) => {
  console.log("Call in progress ...");
  dispatch({
    type: "SET_START_CALL_TIMESTAMP",
    payload: { startCallTimestamp: moment().toISOString() },
  });
  // setCallEnabled(true);
  // bindVolumeIndicators(call);
};

const handleCallDisconnect = async (
  dispatch: React.Dispatch<TwilioAction>,
  params?: any
) => {
  console.log("Call disconnected.");
  // setCallEnabled(false);
  dispatch({
    type: "SET_START_CALL_TIMESTAMP",
    payload: { startCallTimestamp: "" },
  });
  dispatch({ type: "SET_CALL", payload: { call: null } });
  if (params.campaignId) {
    await removePWContact({
      dealership_id: params.dealershipId,
      id: params.campaignId,
      contactId: params.contactId,
    });

    // Use the above commented code, below one is just for testing
    // await skipPWContact({
    //   dealership_id: params.dealershipId,
    //   id: params.campaignId,
    //   contactId: params.contactId,
    // });
    dispatch({
      type: "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS",
      payload: { shouldUpdateCampaignContacts: true },
    });
  }
};

export async function makeOutgoingCall(
  dispatch: React.Dispatch<TwilioAction>,
  currentDevice: Device | null,
  phoneNumber: string,
  from?: string,
  user?: any,
  contactId?: string,
  source?: string,
  campaignId?: string,
  dealershipId?: string
) {
  var params = {
    // get the phone number to call from the DOM
    To: phoneNumber,
    From: from || "",
    userName: user?.full_name || "",
    contactId: contactId || "",
    source: source || "",
    userId: user?.id,
    campaignId: campaignId || "",
    dealershipId: dealershipId || "",
  };
  try {
    if (currentDevice) {
      console.log(`Attempting to call ${params.To} ...`);

      // Twilio.Device.connect() returns a Call object
      const call = await currentDevice.connect({ params });

      // add listeners to the Call
      // "accepted" means the call has finished connecting and the state is now "open"
      call.on("accept", () => handleCallAccept(dispatch));
      call.on("disconnect", () => handleCallDisconnect(dispatch, params));
      call.on("cancel", () => handleCallDisconnect(dispatch, params));

      dispatch({ type: "SET_CALL", payload: { call: call } });
    } else {
      console.log("Unable to make call.");
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const handleHangUp = (
  dispatch: React.Dispatch<TwilioAction>,
  call: Call | null,
  startCallTimestamp: string,
  isIncoming = false
) => {
  console.log("Hanging up ...");
  call?.disconnect();
  dispatch({
    type: isIncoming ? "SET_INCOMING_CALL" : ("SET_CALL" as any),
    payload: isIncoming ? { incomingCall: null } : ({ call: null } as any),
  });
  dispatch({
    type: "SET_START_CALL_TIMESTAMP",
    payload: { startCallTimestamp: "" },
  });
};

export const acceptIncomingCall = (
  dispatch: React.Dispatch<TwilioAction>,
  call: Call | null
) => {
  call?.accept();
  dispatch({
    type: "SET_START_CALL_TIMESTAMP",
    payload: { startCallTimestamp: moment().toISOString() },
  });
};

export const rejectIncomingCall = (
  dispatch: React.Dispatch<TwilioAction>,
  call: Call | null
) => {
  call?.ignore();
  dispatch({
    type: "SET_START_CALL_TIMESTAMP",
    payload: { startCallTimestamp: "" },
  });
  dispatch({
    type: "SET_INCOMING_CALL",
    payload: { incomingCall: null },
  });
  dispatch({
    type: "SET_CALLER",
    payload: { caller: "" },
  });
  console.log("Rejected incoming call");
};
