import {
  Box,
  Button,
  HStack,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  FormLabel,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "../../../../../assets/avatar.svg";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { loadDealershipUsers } from "../../../../Leads/utils";
import AsyncSelect from "react-select/async";
import {
  assignPointPerson,
  getPointPerson,
} from "../../../../../utils/api/lead-deatail.api";
import Popup from "../../../../Charts/Popup";
import Loader from "../../../../common/Spinner";
import { dealershipAdminChecker ,simpAdminChecker} from "../../../../../utils";
import NoItem from "../History/NoItem";

const Assign = ({ clickedRow }: any) => {
  const dealership = useUserInfo("dealership");
  const role = useUserInfo("role");
  let impSSRole = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
  : "";
  const [selectedTab, setSelectedTab] = useState(0); // 0: Sales Person, 1: BDC Agent, 2: Manager
  const [popup, setPopup] = useState(false);
  const filterSetter = () => {
    if (selectedTab === 0) {
      return "sales_person";
    }
    if (selectedTab === 1) {
      return "bdc_agent";
    }
    if (selectedTab === 2) {
      return "manager";
    } else return "sales_person";
  };
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [
      "pointperson",
      dealership?.id,
      clickedRow,
      filterSetter(),
      selectedTab,
    ],
    queryFn: getPointPerson,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const mutation = useMutation(assignPointPerson);
  const toast = useToast();
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const [pointPerson, setPointPerson] = useState<any>({
    sales_person_id: "",
    manager_id: "",
    bdc_agent_id: "",
  });

  const handleTabChange = (index: any) => {
    setSelectedTab(index);
    setIsAssignOpen(false);
  };
  const labelSetter = () => {
    if (selectedTab === 0) {
      return "Sales Person";
    }
    if (selectedTab === 1) {
      return "BDC Agent";
    }
    if (selectedTab === 2) {
      return "Manager/Admin";
    } else return "Sales Person";
  };
  const keySetter = () => {
    if (selectedTab === 0) {
      return "sales_person_id";
    }
    if (selectedTab === 1) {
      return "bdc_agent_id";
    }
    if (selectedTab === 2) {
      return "manager_id";
    } else return "sales_person_id";
  };
  const handleSelectChange = (selectedOption: any) => {
    if (selectedTab === 0) {
      setPointPerson((prevPointPerson: any) => ({
        ...prevPointPerson,
        sales_person_id: selectedOption,
      }));
    } else if (selectedTab === 2) {
      setPointPerson((prevPointPerson: any) => ({
        ...prevPointPerson,
        manager_id: selectedOption,
      }));
    } else if (selectedTab === 1) {
      setPointPerson((prevPointPerson: any) => ({
        ...prevPointPerson,
        bdc_agent_id: selectedOption,
      }));
    }
  };
  const handleFormSubmit = (payload?: any) => {
    mutation.mutate(
      payload
        ? {
            id: dealership.id,
            contact_id: clickedRow,
            data: {
              contact: payload,
            },
          }
        : {
            id: dealership.id,
            contact_id: clickedRow,
            data: {
              contact: {
                sales_person_id: pointPerson?.sales_person_id?.value,
                manager_id: pointPerson?.manager_id?.value,
                bdc_agent_id: pointPerson.bdc_agent_id?.value,
              },
            },
          },
      {
        onSuccess(data, variables, context) {
          setPopup(false);
          refetch();
          toast({
            description: payload
              ? "Point person unassigned successfully."
              : "Point person assigned successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setIsAssignOpen(false)
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleUnassign = () => {
    let payload = { [keySetter()]: "" };
    handleFormSubmit(payload);
  };
  const getDisableValue = () => {
    if (impSSRole) {
      return !simpAdminChecker(impSSRole);
    }
    return !dealershipAdminChecker(role);
  };
  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="1rem"
        w="100%"
      >
        <Tabs
          variant="primary"
          w="100%"
          index={selectedTab}
          onChange={handleTabChange}
        >
          <TabList mb="1rem">
            <Tab>Sales Person</Tab>
            <Tab>BDC Agent</Tab>
            <Tab>Manager/Admin</Tab>
          </TabList>
        </Tabs>
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Assign Point Person
          </Text>
          {!getDisableValue()&&<Button
            variant="outline"
            padding="0.5rem"
            minW="auto"
            onClick={() => setIsAssignOpen(!isAssignOpen)}
            isDisabled={!dealershipAdminChecker(role)}
          >
            {!isAssignOpen ? (
              <AddIcon
                style={{ color: "var(--grey-700)", fontSize: "1.5rem" }}
              />
            ) : (
              <Close style={{ color: "var(--grey-700)", fontSize: "1.5rem" }} />
            )}
          </Button>}
        </HStack>
        {isAssignOpen && (
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            background="var(--grey-50)"
            border="1px solid var(--grey-300)"
            mt="1rem"
            gap="1rem"
          >
            <Text textStyle="h5" fontWeight="700">
              Assign new point person
            </Text>
            <FormLabel
              mb="0.31rem"
              color="var(--grey-900)"
              fontSize="0.875rem"
              fontWeight="600"
              lineHeight="1rem"
            >
              {labelSetter()}
            </FormLabel>
            <AsyncSelect
              loadOptions={(searchStr) =>
                loadDealershipUsers(searchStr, labelSetter(), dealership?.id)
              }
              defaultOptions
              value={pointPerson[keySetter()]}
              onChange={handleSelectChange}
              isClearable={true}
              placeholder="Select Agent"
              styles={{
                control: (provided) => ({
                  ...provided,
                  maxWidth: "100%",
                  width: "100%",
                }),
                container: (provided) => ({
                  ...provided,
                  maxWidth: "100%",
                  width: "100%",
                }),
              }}
            />

            <Button w="100%" onClick={() => handleFormSubmit()}
            isDisabled={!pointPerson?.sales_person_id?.value&& !pointPerson?.manager_id?.value&&
             !pointPerson.bdc_agent_id?.value}>
              Assign
            </Button>
          </VStack>
        )}
        <VStack
          w="100%"
          gap="1rem"
          mt="1rem"
          h={`calc(100vh - ${!isAssignOpen ? "288px" : "495px"})`}
          overflow="auto"
        >
          {data?.data?.point_persons?.map((item: any) => (
            <HStack
              w="100%"
              padding="1rem"
              border={`1px solid ${
                item % 2 === 0 ? "var(--grey-300)" : "var(--secondary-600)"
              }`}
              borderRadius="0.5rem"
              alignItems="flex-start"
              gap="0.5rem"
              justifyContent="space-between"
            >
              <HStack alignItems="flex-start">
                <Image width={38} height={38} src={Avatar} alt="avatar" />
                <VStack gap="0.25rem" alignItems="flex-start">
                  <HStack>
                    <Text
                      textStyle="h5"
                      color="var(--grey-800)"
                      fontWeight="600"
                    >
                      {item?.full_name}
                    </Text>
                    {item.current && (
                      <Box
                        as="span"
                        background="var(--secondary-600)"
                        padding="0.25rem"
                        borderRadius="50%"
                      />
                    )}
                  </HStack>
                  <Text textStyle="h5" color="var(--grey-700)" fontWeight="500">
                    {item?.phone}
                  </Text>
                </VStack>
              </HStack>
              <VStack alignItems="flex-end">
                {/* <HStack gap="0.5rem">
                  <SimpleDateIcon />
                  <Text textStyle="h6" fontWeight="600">
                    Thursday 3 August 23 • 6:06 pm
                  </Text>
                </HStack> */}
                {(item.current &&!getDisableValue()) && (
                  <Button
                    onClick={() => setPopup(true)}
                    isDisabled={!dealershipAdminChecker(role)}
                  >
                    Unassign
                  </Button>
                )}
              </VStack>
            </HStack>
          ))}
          {data?.data?.point_persons?.length === 0 &&
            !isFetching &&
            !isLoading && <NoItem />}
        </VStack>
      </VStack>
      {popup && (
        <Popup
          isOpen={true}
          onClose={() => setPopup(false)}
          onConfirm={handleUnassign}
          title="Unassign Point Person Confirmation"
          message="Are you sure you want to unassign this point person?"
        />
      )}
      {(mutation.isLoading || isLoading) && <Loader />}
    </VStack>
  );
};

export default Assign;
