export const OPT_OUT_KEYWORDS = [
  { value: "STOP", label: "STOP" },
  { value: "STOPALL", label: "STOPALL" },
  { value: "UNSUBSCRIBE", label: "UNSUBSCRIBE" },
  { value: "CANCEL", label: "CANCEL" },
  { value: "END", label: "END" },
  { value: "QUIT", label: "QUIT" },
];

export const OPT_IN_KEYWORDS = [
  { value: "START", label: "START" },
  { value: "BEGIN", label: "BEGIN" },
];

export const HELP_KEYWORDS = [
  { value: "HELP", label: "HELP" },
  { value: "INFO", label: "INFO" },
];

export const US_APP_TO_PERSON = [
  { value: "MARKETING", label: "MARKETING" },
  { value: "2FA", label: "2FA" },
  { value: "ACCOUNT_NOTIFICATION", label: "ACCOUNT NOTIFICATION" },
  { value: "AGENTS_FRANCHISES", label: "AGENTS FRANCHISES" },
  { value: "CHARITY", label: "CHARITY" },
  { value: "PROXY", label: "PROXY" },
  { value: "CUSTOMER_CARE", label: "CUSTOMER CARE" },
  {
    value: "PUBLIC_SERVICE_ANNOUNCEMENT",
    label: "PUBLIC SERVICE ANNOUNCEMENT",
  },
  { value: "DELIVERY_NOTIFICATION", label: "DELIVERY NOTIFICATION" },
  { value: "MIXED", label: "MIXED" },
  { value: "EMERGENCY", label: "EMERGENCY" },
  { value: "FRAUD_ALERT", label: "FRAUD ALERT" },
  { value: "SOCIAL", label: "SOCIAL" },
  { value: "SECURITY_ALERT", label: "SECURITY ALERT" },
  { value: "POLLING_VOTING", label: "POLLING VOTING" },
  { value: "POLITICAL", label: "POLITICAL" },
  { value: "K12_EDUCATION", label: "K12 EDUCATION" },
  { value: "SWEEPSTAKE", label: "SWEEPSTAKE" },
];
