import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input } from "../../../../components/common";
import {
  createEmailTemplate,
  createGlobalEmailTemplate,
  getDealershipTemplate,
  getGlobalTemplate,
  updateEmailTemplate,
  updateGlobalEmailTemplate,
} from "../../../../utils/api/template.api";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import AttachmentBar from "../../../AttachmentBar";
import AttachmentViewer from "../../../common/AttachmentViewer";
import Loader from "../../../common/Spinner";
import TextEditor from "../../../common/TextEditor";
import {
  CreateTemplateForm,
  createTemplateValidation,
  defaultValues,
} from "./utils";
import { Dealership_Roles } from "../../../../utils/roles";

const CreateTemplate = () => {
  const navigate = useNavigate();
  const editorRef = useRef();
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const dealershipId = searchParams.get("dealership_id");

  const toast = useToast();

  const { handleSubmit, control, setValue } = useForm<CreateTemplateForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(createTemplateValidation) as any,
  });

  const user = useUserInfo("user");

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (!id
          ? type === "global"
            ? createGlobalEmailTemplate
            : createEmailTemplate
          : type === "global"
          ? updateGlobalEmailTemplate
          : updateEmailTemplate)({
          payload,
          dealership_id: user?.dealership?.id || dealershipId,
          id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: `Template ${id ? "updated" : "created"} successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      navigate(-1);
    },
    onError: (error: any) => {
      toast({
        description: `Error ${id ? "updating" : "creating"} template: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { isFetching } = useQuery({
    queryKey: ["fetchTemplate", id],
    queryFn: () =>
      type === "global"
        ? getGlobalTemplate({ id })
        : getDealershipTemplate({
            id,
            dealership_id: user?.dealership?.id || dealershipId,
          }),
    retry: false,
    enabled: !!id,
    onSuccess: (data: any) => {
      setValue("subject", data?.data?.subject);
      setValue("messageBody", data?.data?.body);
      setAttachmentUrls(data?.data?.media_urls || []);
    },
  });

  const handleFormSubmit = (values: CreateTemplateForm) => {
    mutation.mutate({
      email_template: {
        subject: values.subject,
        body: values.messageBody,
        template_type:
          type === "global"
            ? "simpsocial_provided"
            : type === "dealership"
            ? "store_template"
            : "user_template",
        status: "active",
        attachment_urls: attachmentUrls.map(
          (attachment: any) => attachment.url
        ),
      },
    });
  };

  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      editor.insertText(range.index, text, "user");
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;

        // Insert the emoji at the current cursor position
        editor.insertText(cursorPosition, text);

        // Move the cursor to the end of the inserted emoji
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };

  return (
    <>
      {(isFetching || mutation.isLoading) && <Loader />}
      <Box p="1.5rem" h="100%">
        <VStack
          w="100%"
          alignItems="flex-start"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="white"
          padding="1rem"
          h="100%"
        >
          <HStack justifyContent="space-between" w="100%">
            <Button
              variant="none"
              display="flex"
              alignItems="center"
              gap="0.63rem"
              fontWeight="500"
              onClick={() => navigate(-1)}
            >
              <Box
                as="span"
                display="flex"
                alignItems="center"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                padding="0.38rem"
              >
                <ChevronLeftIcon
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    color: "black",
                  }}
                />
              </Box>
              Back
            </Button>
          </HStack>

          <Box h="100%" w="100%">
            <HStack w="100%" justifyContent="space-between" mb="1rem">
              <Text textStyle="h4" fontWeight="500">
                {id ? "Edit" : "Create"} Email Template
              </Text>
            </HStack>

            <Box
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              h="calc(100vh - 310px)"
              width="calc(100vw - 325px)"
            >
              <VStack gap="0" h="100%">
                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text textStyle="h6" padding="1rem" w="20%" fontWeight="500">
                    Enter Subject
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          value={value}
                          onChange={onChange}
                          maxW="100%"
                          border="0"
                          borderLeft="1px solid var(--grey-300)"
                          height="48px"
                          borderRadius="0"
                          fontWeight="500"
                          error={error?.message || ""}
                          borderBottom={
                            error?.message ? "1px solid var(--grey-300)" : "0"
                          }
                          errorStyle={{
                            borderLeft: "1px solid var(--grey-300)",
                            paddingTop: "6px",
                            paddingLeft: "8px",
                          }}
                        />
                      );
                    }}
                    name="subject"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                <AttachmentBar
                  setBody={handleSetBody}
                  type="email"
                  handleTemplate={(value, body = "") => {
                    setValue("subject", value);
                    setValue("messageBody", body);
                  }}
                  parentMenu={
                    Dealership_Roles.includes(user.role) || type !== "global"
                      ? null
                      : "global"
                  }
                />

                <Controller
                  render={({ field: { value }, fieldState: { error } }) => {
                    return (
                      <div
                        style={{ width: "100%", height: "calc(100vh - 500px)" }}
                      >
                        <TextEditor
                          value={value}
                          onChange={(value) => setValue("messageBody", value)}
                          error={error?.message}
                          style={{ height: "280px" }}
                          editorRef={editorRef}
                        />
                      </div>
                    );
                  }}
                  name="messageBody"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />

                <AttachmentViewer
                  attachments={attachmentUrls}
                  setAttachments={setAttachmentUrls}
                />
              </VStack>
            </Box>
            <HStack justifyContent="flex-end" w="100%" mt="2rem">
              <Button
                w="10rem"
                onClick={() =>
                  handleSubmit((values) => handleFormSubmit(values))()
                }
              >
                Save
              </Button>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CreateTemplate;
