import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";

interface CallLineProps {
  answered: number;
  missed: number;
  width?: string;
  answeredTitle: string;
  missedTitle: string;
  answredColor: string;
  missedColor: string;
  callTitle: string;
  totalCall: number;
  totalCallText: string;
  totalCallNumber: number;
}

const CallLine = ({
  answered,
  missed,
  answeredTitle,
  missedTitle,
  answredColor,
  missedColor,
  totalCall,
  callTitle,
  totalCallText,
  totalCallNumber,
}: CallLineProps) => {
  const totalCalls = answered + missed;
  const answeredPercentage = (answered / totalCalls) * 100;
  const missedPercentage = (missed / totalCalls) * 100;

  return (
    <Box width={"100%"}>
      <HStack justifyContent="space-between" w="100%">
        <Text textStyle="h4" fontWeight={600} color="#3E3E3E">
          {callTitle}
        </Text>
        <Text textStyle="h3" fontWeight={700} color="var(--grey-900)">
          {totalCall}
        </Text>
      </HStack>

      <Box
        width="100%"
        height="12px"
        borderRadius="10px"
        overflow="hidden"
        mt="0.25rem"
      >
        <HStack height="100%" gap="0px">
          <Box
            width={`${answeredPercentage}%`}
            backgroundColor={answredColor}
            height="100%"
            position="relative"
            zIndex="1"
            borderRadius="10px"
          />

          <Box
            width={`calc(${missedPercentage}% + 4px)`}
            backgroundColor={missedColor}
            height="100%"
            borderRadius="0 10px 10px 0"
            position="relative"
            right="4px"
          />
        </HStack>
      </Box>
      <HStack
        justifyContent="space-between"
        w="100%"
        alignItems="flex-start"
        mt="0.5rem"
      >
        <VStack gap="0.2rem" alignItems="flex-start">
          <HStack>
            <Box
              height="10px"
              width="10px"
              backgroundColor={answredColor}
              borderRadius="50%"
            />
            <Text
              color="var(--grey-900)"
              textStyle="captionSmall"
              fontWeight={600}
              // whiteSpace="nowrap"
            >
              {answeredTitle}: {answered}
            </Text>
          </HStack>
          <HStack>
            <Box
              height="10px"
              width="10px"
              backgroundColor={missedColor}
              borderRadius="50%"
            />
            <Text
              color="var(--grey-900)"
              textStyle="captionSmall"
              fontWeight={600}
              // whiteSpace="nowrap"
            >
              {missedTitle}: {missed}
            </Text>
          </HStack>
        </VStack>
        <Text
          textStyle="captionSmall"
          color="var(--primary-600)"
          fontWeight={600}
          // whiteSpace="nowrap"
        >
          {totalCallText} {totalCallNumber}
        </Text>
      </HStack>
    </Box>
  );
};

export default CallLine;
