import { Box, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { ChatIcon } from "../../icons/svg";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getMessageNotification,
  markMessageAsRead,
} from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";

interface MessagesProps {
  page?: number;
  perPage?: number;
  refetch?: boolean;
  onClose?: () => void;
}

export const MessagesCard = ({
  page,
  perPage,
  refetch,
  onClose,
}: MessagesProps) => {
  const navigate = useNavigate();
  const dealerId = useUserInfo("dealership");
  const mutation = useMutation(markMessageAsRead);
  const { refetchNotifications } = useNotificationContext();
  const {
    data,
    isLoading,
    refetch: refetchapi,
  } = useQuery({
    queryKey: ["messagesNotification", dealerId?.id, page, perPage, refetch],
    queryFn: getMessageNotification,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const toast = useToast();
  const handleMarkAsRead = (item: any) => {
    if (!item?.is_read) {
      mutation.mutate(
        { dId: dealerId?.id, id: item?.id },
        {
          onSuccess: (data: any) => {
            refetchapi();
            refetchNotifications(); // This will trigger the refetch of the parent's query
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }

    if (onClose) {
      onClose();
    }

    if (item?.contact_id) {
      navigate(`/leads/lead-details`, {
        state: {
          contact_id: item?.contact_id,
          activeKey: 2,
          activeIndex: 0,
        },
      });
    }
  };

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0.59rem"
        maxH="calc(100vh - 450px)"
        overflow="auto"
        padding="1rem"
        pt="0"
      >
        {data?.data?.messages?.map((item: any) => (
          <HStack
            padding="1rem"
            w="100%"
            key={item}
            background={item?.is_read ? "white" : "var(--grey-100)"}
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            position="relative"
            onClick={() => {
              handleMarkAsRead(item);
            }}
            cursor="pointer"
          >
            {!item?.is_read && (
              <Box
                w="0.47rem"
                h="0.47rem"
                borderRadius="50%"
                background="#F44336"
                position="absolute"
                top="0.5rem"
                left="0.5rem"
              ></Box>
            )}
            <Box
              padding="0.5rem"
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              background="white"
              sx={{
                svg: {
                  path: {
                    fill: "var(--secondary-600)",
                  },
                },
              }}
            >
              <ChatIcon />
            </Box>
            <VStack gap="0.25rem" w="100%" alignItems="flex-start">
              <Text textStyle="h5" fontWeight="500">
                From:{" "}
                <Box as="span" color="var(--primary-600)">
                  {item?.from}
                </Box>
              </Text>
              <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
                <TooltipIfOverflow>{item?.content}</TooltipIfOverflow>
              </Text>
              <Text textStyle="captionSmall" fontWeight="500">
                {moment(item?.created_at?.replace("Z", ""))?.format(
                  "dddd D MMMM"
                )}
                <Box as="span" color="var(--primary-600)">
                  •{" "}
                  {moment(item?.created_at?.replace("Z", ""))?.format("h:mm a")}
                </Box>
              </Text>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
};
