import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import moment from "moment";

const UptimeBars = (props: any) => {
  const { data, selectedMonth, details } = props;

  const currentDate = moment();
  const isCurrentMonth = moment(selectedMonth)?.isSame(currentDate, "month");

  const getLabel = (status: boolean, index: number) => {
    return (
      <VStack alignItems="flex-start" gap="0.32rem">
        <Text textStyle="h6" color="white" fontWeight="700">
          {index + 1} {moment(selectedMonth)?.format("MMMM")}
        </Text>
        {status ? (
          <Text textStyle="h6" color="white" fontWeight="400">
            No downtime recorded all day
          </Text>
        ) : (
          <>
            <Text textStyle="h6" color="white" fontWeight="400">
              Downtime recorded:
            </Text>
            {details[index]?.map((detail: any, i: number) => (
              <Text key={i} textStyle="h6" color="white" fontWeight="400">
                {moment(detail?.start_time)?.format("hh:mma")} -{" "}
                {moment(detail?.end_time)?.format("hh:mma")}
              </Text>
            ))}
          </>
        )}
      </VStack>
    );
  };

  return (
    <HStack spacing="0.5rem" align="flex-end" overflowX="auto">
      {data?.map((status: any, index: number) => {
        const day = index + 1;
        const isFutureDate = isCurrentMonth && day > currentDate.date();
        const backgroundColor = isFutureDate
          ? "gray"
          : status
          ? "var(--primary-500)"
          : "var(--red-800)";

        return (
          <Tooltip
            key={index}
            label={isFutureDate ? "" : getLabel(status, index)}
            background="var(--bluegray-900)"
            border="none"
            borderRadius="0.5rem"
            padding="0.75rem"
            hasArrow
            placement="top"
          >
            <Box
              w="0.5rem" // Fixed width for bars
              h="2.4375rem"
              background={backgroundColor}
            />
          </Tooltip>
        );
      })}
    </HStack>
  );
};

export default UptimeBars;
