import { CustomRadio, Input, Select } from "../../../components/common";
import SwitchToggle from "../../../components/common/Switch";
import { AwesomeIcon, TextToSpeechIcon } from "../../../components/icons/svg";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useQuery,useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getLineSettings ,modifyLineSettings,postRecording} from "../../../utils/api/line-rings.api";

const CNAM = () => {
  const {id}=useParams()
  const mutation=useMutation(modifyLineSettings)
  const { data, isLoading,refetch } = useQuery({
    queryKey: ["CNAM", id,'canm_settings'],
    queryFn: getLineSettings,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [sendOption, setSendOption] = useState("send-now");
  const [showVariable, setShowVariable] = useState<boolean>(false);

  const options: SelectOptionProps[] = [
    { label: "Settings", value: "settings" },
    { label: "Steps", value: "steps" },
    { label: "Trigger", value: "trigger" },
    { label: "Leads", value: "leads" },
    { label: "Ledger", value: "ledger" },
    { label: "Create Copy", value: "create-copy" },
  ];
  const textOptions: SelectOptionProps[] = [
    {
      label: (
        <HStack>
          <TextToSpeechIcon />
          <Text textStyle="h6" fontWeight="600">
            Text To Speech
          </Text>
        </HStack>
      ),
      value: "1",
    },
    {
      label: (
        <HStack>
          <TextToSpeechIcon />
          <Text textStyle="h6" fontWeight="600">
            Text To Recording
          </Text>
        </HStack>
      ),
      value: "2",
    },
  ];
  const sendOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "2" },
    { label: "Route to another line", value: "3" },
    { label: "Forward externally", value: "4" },
    { label: "Doing nothing and hang up", value: "5" },
  ];
  return (
    <Box paddingTop="0.25rem" height="calc(100vh - 235px)" overflow="auto">
      <VStack gap="1.5rem" alignItems="flex-start" marginTop="1.5rem">
        <HStack alignItems="flex-start" w="100%" position="relative">
          <VStack
            w="25%"
            alignItems="flex-start"
            position="relative"
            height="100%"
            gap="1.25rem"
          >
            <VStack alignItems="flex-start">
              <Text textStyle="h5" fontWeight="700">
                Caller ID - CNAM{" "}
                <Box as="span" color="var(--grey-700)">
                  (Optional)
                </Box>
              </Text>
              <Text textStyle="h6" color="var(--grey-700)" maxW="18rem">
                Caller ID (CNAM) is a personal or business name associated with
                this line that displays on a contact’s phone when you call them.
              </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text textStyle="h5" fontWeight="700">
                Current Status
              </Text>
              <Box
                w="max-content"
                textAlign="center"
                borderRadius="2.5rem"
                padding="0.25rem 0.62rem"
                border="1px solid var(--orange-900)"
              >
                <Text textStyle="h6" color="var(--orange-900)">
                  Unsubmitted
                </Text>
              </Box>
            </VStack>
          </VStack>
          <VStack alignItems="flex-start" width="50%" gap="1.25rem">
            <VStack
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
              padding="1.44rem 1.25rem"
              w="100%"
              gap="1.5rem"
            >
              <VStack w="100%" alignItems="flex-start">
                <Input
                  type="text"
                  placeholder="Add your id here"
                  label="Enter Caller ID"
                />
                <Button>Submit</Button>
              </VStack>
              <VStack w="100%" alignItems="flex-start">
                <Text
                  textStyle="h6"
                  color="var(--primary-600)"
                  fontWeight="500"
                >
                  Special Notes:
                </Text>
                <UnorderedList>
                  <ListItem>
                    For the time being, you can only submit the Caller ID (CNAM)
                    once per line.
                  </ListItem>
                  <ListItem>
                    CNAM should begin with a letter and can only contain
                    letters, numbers, periods, commas and spaces.
                  </ListItem>
                  <ListItem>
                    Caller ID (CNAM) must be relevant to your business and
                    business name; No impersonation allowed
                  </ListItem>
                  <ListItem>
                    Caller ID (CNAM) can take up to 48 hours to appear
                  </ListItem>
                  <ListItem>Some carriers may not show this Caller ID</ListItem>
                  <ListItem>
                    Some mobile numbers may need to turn a setting on or
                    subscribe to see Caller IDs
                  </ListItem>
                  <ListItem>
                    No guarantee this Caller ID will work for Canada or Puerto
                    Rico numbers
                  </ListItem>
                </UnorderedList>
              </VStack>
            </VStack>
            <VStack
              alignItems="flex-start"
              width="100%"
              gap="0"
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
            >
              <Box
                padding="1rem 1.25rem 0.62rem"
                borderBottom="1px solid var(--grey-300)"
                w="100%"
              >
                <Text textStyle="h5" fontWeight="700">
                  Status Explanations
                </Text>
              </Box>
              <HStack w="100%" borderBottom="1px solid  var(--grey-300)">
                <Box
                  w="25%"
                  padding="0.75rem 1.25rem"
                  borderRight="1px solid  var(--grey-300)"
                >
                  <Box
                    w="max-content"
                    textAlign="center"
                    borderRadius="2.5rem"
                    padding="0.25rem 0.62rem"
                    border="1px solid var(--orange-900)"
                  >
                    <Text textStyle="h6" color="var(--orange-900)">
                      Unsubmitted
                    </Text>
                  </Box>
                </Box>
                <Box px="1.25rem">
                  <Text
                    textStyle="captionSmall"
                    color="var(--grey-900)"
                    fontWeight="500"
                  >
                    If Unsubmitted - You need to fill out and submit the form.
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" borderBottom="1px solid  var(--grey-300)">
                <Box
                  w="25%"
                  padding="0.75rem 1.25rem"
                  borderRight="1px solid  var(--grey-300)"
                >
                  <Box
                    w="max-content"
                    textAlign="center"
                    borderRadius="2.5rem"
                    padding="0.25rem 0.62rem"
                    border="1px solid var(--yellow-900)"
                  >
                    <Text textStyle="h6" color="var(--yellow-900)">
                      Pending
                    </Text>
                  </Box>
                </Box>
                <Box px="1.25rem">
                  <Text
                    textStyle="captionSmall"
                    color="var(--grey-900)"
                    fontWeight="500"
                  >
                    If Pending - May take up to 48 hours to process.
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" borderBottom="1px solid  var(--grey-300)">
                <Box
                  w="25%"
                  padding="0.75rem 1.25rem"
                  borderRight="1px solid  var(--grey-300)"
                >
                  <Box
                    w="max-content"
                    textAlign="center"
                    borderRadius="2.5rem"
                    padding="0.25rem 0.62rem"
                    border="1px solid var(--secondary-600)"
                  >
                    <Text textStyle="h6" color="var(--secondary-600)">
                      Approved
                    </Text>
                  </Box>
                </Box>
                <Box px="1.25rem">
                  <Text
                    textStyle="captionSmall"
                    color="var(--grey-900)"
                    fontWeight="500"
                  >
                    If Approved - Your request is approved and no further action
                    is requeired.
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" borderBottom="1px solid  var(--grey-300)">
                <Box
                  w="25%"
                  padding="0.75rem 1.25rem"
                  borderRight="1px solid  var(--grey-300)"
                >
                  <Box
                    w="max-content"
                    textAlign="center"
                    borderRadius="2.5rem"
                    padding="0.25rem 0.62rem"
                    border="1px solid var(--red-700)"
                  >
                    <Text textStyle="h6" color="var(--red-700)">
                      Failed
                    </Text>
                  </Box>
                </Box>
                <Box px="1.25rem">
                  <Text
                    textStyle="captionSmall"
                    color="var(--grey-900)"
                    fontWeight="500"
                  >
                    If Failed - Our Carrier will provide a rejection reason,
                    Action needed.
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default CNAM;
