import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Drawer, Input } from "../../../components/common";
import CaptureImage from "../../../components/LeadCenter/LeadDetails/DetailTabs/Documents/CaptureImage";
import {
  UploadMediaIcon,
  CloseIcon,
  CameraIcon,
} from "../../../components/icons/svg";
import Loader from "../../../components/common/Spinner";
import { addDocument } from "../../../utils/api/guest-mode";
import { useParams } from "react-router-dom";
import TooltipIfOverflow from "../../../components/common/TooltipIfOverflow";
export const AddDocument = ({ isOpen, onClose, refetch }: any) => {
  const { id, dId } = useParams();
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [capturedImage, setCapturedImage] = useState<any>(null);
  const mutation = useMutation(addDocument);
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const uploadFileValidation = (file: any) => {
    if (file) {
      const allowedExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".doc",
        ".docx",
        ".csv",
        ".pdf",
        ".xls",   // Add XLS extension
        ".xlsx",
      ];
      const maxFileSize = 20 * 1024 * 1024; // 20MB

      // Extract file extension
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      // Check if the file extension is allowed and file size is within limits
      if (
        allowedExtensions.includes("." + fileExtension) &&
        file.size <= maxFileSize
      ) {
        setFile(file);
      } else {
        toast({
          title: "Invalid File",
          description:
            "Please upload a valid file with allowed extensions and size less than 20MB.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    const blob = await fetch(capturedImage).then((res) => res.blob());

    if (capturedImage) {
      const blob = await fetch(capturedImage).then((res) => res.blob());
      formData.append("file", blob); // 'captured-image.jpeg' is the file name
      formData.append("name", title);
    }

    mutation.mutate(
      {
        id,
        dId,
        data: {
          document: {
            name: title,
            file: capturedImage ? blob : file,
          },
        },
      },
      {
        onSuccess: (data) => {
          refetch();
          onClose();
          toast({
            description: "Document added successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} title="Add Document">
        <VStack w="100%" alignItems="flex-start" gap="1rem" padding="1rem">
          <HStack
            background="var(--grey-100)"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="1rem"
            w="100%"
          >
            <Input
              label="Document Name"
              onChange={(e) => setTitle(e.target.value)}
            />
          </HStack>
          <VStack
            background="var(--grey-100)"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="1.5rem 1rem"
            w="100%"
            justifyContent="center"
            gap="0.75rem"
          >
            <HStack
              padding="1rem"
              background="white"
              borderRadius="50%"
              onDrop={(event: any) => {
                event.preventDefault();
                if (!file) {
                  const droppedFile = event.dataTransfer.files[0];
                  uploadFileValidation(droppedFile);
                }
                // clear the file so if we upload the same file it appears instead of going blank
                event.target.value = "";
              }}
              onDragOver={(event) => event.preventDefault()}
            >
              <UploadMediaIcon color="var(--secondary-600)" />
            </HStack>
            <VStack gap="0.25rem">
              <Text textStyle="h5" fontWeight="500">
                Drop your file here or
              </Text>
              <Text textStyle="captionSmall">  JPG, JPEG, PNG, PDF, DOC, DOCX, CSV, XLS, XLSX </Text>
              <Input
                id="fileInput"
                type="file"
                onChange={(event: ChangeEvent) => {
                  const file = event.target.files[0];
                  uploadFileValidation(file);
                  event.target.value = "";
                }}
                style={{ display: "none" }}
              />
            </VStack>
            <Button
              variant="outline"
              color="var(--secondary-600)"
              borderColor="var(--secondary-600)"
              isDisabled={file || capturedImage ? true : false}
              onClick={() => {
                if (document?.getElementById)
                  document?.getElementById("fileInput")?.click();
              }}
            >
              Upload From Computer
            </Button>
            <HStack>
              {file && (
                <>
                  <Text textStyle="h5"><TooltipIfOverflow>{file["name"]}</TooltipIfOverflow></Text>
                  <Button variant="none" onClick={() => setFile(null)}>
                    <CloseIcon />
                  </Button>
                </>
              )}
            </HStack>
          </VStack>
          <HStack w="100%" gap="1.5rem">
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
            <Box
              as="span"
              fontSize="1.125rem"
              fontWeight="600"
              color="var(--secondary-600)"
              borderRadius="6.25rem"
              background="var(--secondary-50)"
              padding="0.31rem 1.19rem"
            >
              Or
            </Box>
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
          </HStack>
          <VStack
            background="var(--grey-100)"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="1.5rem 1rem"
            w="100%"
            justifyContent="center"
          >
            {capturedImage ? (
              <VStack position="relative">
                <Button
                  variant="none"
                  position="absolute"
                  right="-8px"
                  top="-8px"
                  padding="0.1rem"
                  background="var(--red-400)"
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                  borderRadius="50%"
                  onClick={() => setCapturedImage(null)}
                >
                  <CloseIcon />
                </Button>
                <Image src={capturedImage} alt="Captured" />
                {/* Additional UI or options to save/upload the image */}
              </VStack>
            ) : (
              <>
                <HStack padding="1rem" background="white" borderRadius="50%">
                  <CameraIcon />
                </HStack>
                <Text textStyle="h5" fontWeight="500">
                  Do you want to take a photo?
                </Text>
                <Button
                  variant="outline"
                  color="var(--secondary-600)"
                  borderColor="var(--secondary-600)"
                  w="11.4rem"
                  onClick={() => setShowCameraPopup(true)}
                  isDisabled={file || capturedImage ? true : false}
                >
                  Click Here
                </Button>
              </>
            )}
          </VStack>
        </VStack>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl">
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={handleSubmit}
            isDisabled={(!file && !capturedImage) || !title}
          >
            Save Document
          </Button>
        </DrawerFooter>
        {mutation.isLoading && <Loader />}
      </Drawer>
      {showCameraPopup && (
        <CaptureImage
          onClose={() => setShowCameraPopup(false)}
          isOpen={showCameraPopup}
          setCapturedImage={setCapturedImage}
        />
      )}
    </>
  );
};
