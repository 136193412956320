import { Box, Button, HStack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "../common";
import DatePicker from "../common/DateRangePicker";
import SwitchToggle from "../common/Switch";
import moment from "moment";

type PauseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handlePause: ({
    scheduleDate,
    isIndefinitePeriod,
  }: {
    scheduleDate: string;
    isIndefinitePeriod: boolean;
  }) => void;
};

function PauseModal({ isOpen, onClose, handlePause }: PauseModalProps) {
  const [isIndefinitePeriod, setIsIndefinitePeriod] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");

  const toast = useToast();

  const handleMessagePause = () => {
    if (!(isIndefinitePeriod || scheduleDate)) {
      return toast({
        description: `Please select any of schedule date or indefinite period toggle.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    if (!isIndefinitePeriod && scheduleDate && moment(scheduleDate).isBefore()) {
      return toast({
        description: `Schedule date must be greater than current time.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    handlePause({ scheduleDate, isIndefinitePeriod });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Pause Campaign">
        <Box display="flex" gap={5} flexDirection="column">
        <Box padding="0 20px">
          <SwitchToggle
            label="Pause for a indefinite period"
            id="#IndefinitePeriod"
            defaultChecked={isIndefinitePeriod}
            onChange={(isChecked: boolean) => setIsIndefinitePeriod(isChecked)}
            disabled={!!scheduleDate}
          />
        </Box>
        <Box padding="0 20px">
          <DatePicker
            label="Select Schedule Date"
            date={scheduleDate as any}
            onDateChange={(range: any) => {
              setScheduleDate(range);
            }}
            w="100%"
            shouldShowClear
            isRange={false}
            isDisabled={isIndefinitePeriod}
          />
        </Box>

        <HStack w="100%" justifyContent="flex-end" borderTop="1px solid var(--bluegray-100)" padding="10px">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={() => handleMessagePause()}>Save</Button>
        </HStack>
        </Box>
        
      </Modal>
    </>
  );
}

export default PauseModal;
