import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useParams } from "react-router-dom";
import { fetchContact, updateContact } from "../../../utils/api/guest-mode";
import { Input, PhoneInput, Select, Spinner } from "../../../components/common";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .required("First name is required")
    .matches(/^[A-Za-z\s]+$/, "First name must contain only alphabets"),
  middle_name: yup
    .string()
    .nullable()
    .nullable()
    .matches(/^[A-Za-z\s]+$/, "Middle name must contain only alphabets"),
  last_name: yup
    .string()
    .nullable()
    .required("Last name is required")
    .matches(/^[A-Za-z\s]+$/, "Last name must contain only alphabets"),
  prefix: yup.string().nullable().required("Prefix is required"),
  company_name: yup.string().nullable().required("Company name is required"),
  home_number: yup
    .string()
    .nullable()
    .test("is-valid-phone-number", "Invalid phone number", (value) => {
      if (!value || value === "+1") {
        return true;
      } else {
        return isValidPhoneNumber(value);
      }
    }),
  work_number: yup
    .string()
    .nullable()
    .test("is-valid-phone-number", "Invalid phone number", (value) => {
      if (!value || value === "+1") {
        return true;
      } else {
        return isValidPhoneNumber(value);
      }
    }),
  email: yup
    .string()
    .nullable()
    .required("Email is required")
    .email("Invalid email"),
});

const PersonalInfo = () => {
  const { id, dId } = useParams();
  const toast = useToast();

  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { isFetching } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess(data: any) {
      if (data?.data?.contacts) {
        Object.keys(data?.data?.contacts).forEach((key: any) => {
          setValue(key, data?.data?.contacts[key]);
        });
      }
    },
  });

  const prefixes = [
    "Ms.",
    "Miss",
    "Mrs.",
    "Mr.",
    "Master",
    "Rev.",
    "Fr.",
    "Dr.",
    "Atty.",
    "Prof.",
    "Hon.",
    "Pres.",
    "Gov.",
    "Coach",
    "Ofc.",
  ];

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    if (!data.home_number) {
      data.home_number = "";
    }
    if (!data.work_number) {
      data.work_number = "";
    }
    mutation.mutate(
      { contact_uuid: id, dId, payload: { contact: data } },
      {
        onSuccess: () => {
          toast({
            description: "My profile updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error updating My profile: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack position="relative">
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem 0 4rem"
        gap="0.5rem"
        h={{ md: "calc(100vh - 140px)", base: "calc(100vh - 220px)" }}
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            User Information
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <HStack
              w="100%"
              alignItems="flex-start"
              flexWrap={{ md: "nowrap", base: "wrap" }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="First Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="middle_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Middle Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Last Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-start">
              <Controller
                name="prefix"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    label="Prefix"
                    placeholder="Prefix"
                    onChange={(value: any) => setValue("prefix", value?.value)}
                    options={prefixes?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%" alignItems="flex-end">
              <Controller
                name="company_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Company Name"
                    maxLength={200}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <Text fontWeight="700" textStyle="h5">
            Phone
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <VStack
              w="100%"
              alignItems="flex-start"
              flexDirection="row"
              flexWrap="wrap"
            >
              <Controller
                name="phone_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Mobile"
                    placeholder="Enter phone number"
                    disabled={true}
                  />
                )}
              />
              <Controller
                name="home_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Home (Optional)"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="work_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Work  (Optional)"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
            </VStack>
          </VStack>
        </VStack>

        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Email
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder=""
                  label="Email Address"
                  maxLength={320}
                  error={error?.message || ""}
                />
              )}
            />
          </VStack>
        </VStack>
      </VStack>
      <Button
        w="100%"
        marginTop="auto"
        position="absolute"
        left="0"
        bottom={{ md: "0", base: "30px" }}
        onClick={handleSubmit(onSubmit)}
        zIndex={5}
      >
        Save Settings
      </Button>
      {(isFetching || mutation?.isLoading) && <Spinner />}
    </VStack>
  );
};

export default PersonalInfo;
