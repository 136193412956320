import * as yup from "yup";
import { fetchLeadSource } from "../../../../utils/api/global-config.api";
import { fetchDispositionDS } from "../../../../utils/api/disposition-settings.api";
import { getOutbooundLine } from "../../../../utils/api/bdc-campaign.api";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../../../components/Drawers/SearchAndFilterDrawer/utils";
export type CreateBDCCampaignForm = {
  name: string;
  description: string;
  outbound_line: any;
  lead_intake_email: string;
  throttle: string;
  category: string;
  lead_form_IDs: { id: number; value: string }[];
  lead_form_source: Array<{
    value: number;
    label: string;
  }>;
  dispositions: Array<{
    value: number;
    label: string;
  }>;
  disenroll_if_disposition_changed_to_appointment_set: boolean;
  disenroll_if_disposition_changed_to_sold: boolean;
  disenroll_if_the_contact_responds: boolean;
  run_on_nation_holidays: boolean;
  bdc_campaign_hours: string;
  days: {
    label: string;
    checked: boolean;
    startTime: string;
    endTime: string;
  }[];
  filterApplied: boolean;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
};
export const throttlingOptions = [
  { label: "200 per hour", value: "200" },
  { label: "400 per hour", value: "400" },
  { label: "600 per hour", value: "600" },
  { label: "800 per hour", value: "800" },
  { label: "Or Max", value: "max" },
];
export const loadLeadSource = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchLeadSource({ q: searchStr }, id);
    const options = response?.lead_sources?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const loadOutboundLine = async (searchStr: string, id?: string) => {
  try {
    const response = await getOutbooundLine({
      dealership_id: id,
      q: searchStr,
    });
    const options = response?.data?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.phone_number,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const loadDispositionSettings = async (
  searchStr: string,
  id?: string
) => {
  try {
    const response = await fetchDispositionDS(id || "", { q: searchStr });
    const options = response?.contact_dispositions?.map(
      (contactDisposition: any) => ({
        value: contactDisposition.id,
        label: contactDisposition.name,
      })
    );

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const defaultValues = {
  name: "",
  description: "",
  outbound_line: "",
  lead_intake_email: "",
  throttle: "",
  category: "",
  lead_form_IDs: [],
  lead_form_source: [],
  dispositions: [],
  disenroll_if_disposition_changed_to_appointment_set: false,
  disenroll_if_disposition_changed_to_sold: false,
  disenroll_if_the_contact_responds: false,
  run_on_nation_holidays: false,
  bdc_campaign_hours: "default",
  filterApplied: false,
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  days: [
    { label: "Monday", checked: false, startTime: "", endTime: "" },
    { label: "Tuesday", checked: false, startTime: "", endTime: "" },
    { label: "Wednesday", checked: false, startTime: "", endTime: "" },
    { label: "Thursday", checked: false, startTime: "", endTime: "" },
    { label: "Friday", checked: false, startTime: "", endTime: "" },
    { label: "Saturday", checked: false, startTime: "", endTime: "" },
    { label: "Sunday", checked: false, startTime: "", endTime: "" },
  ],
};
const daySchema = yup.object().shape({
  label: yup.string().required(),
  checked: yup.boolean(),
  startTime: yup.string().when("checked", {
    is: true,
    then: yup.string().required("Start time is required when day is checked"),
    otherwise: yup.string().notRequired(),
  }),
  endTime: yup.string().when("checked", {
    is: true,
    then: yup.string().required("End time is required when day is checked"),
    otherwise: yup.string().notRequired(),
  }),
});
export const CreateBDCCampaignFormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  category: yup.string().required("Category is required"),
  days: yup.array().of(daySchema),

  //   outbound_line: yup.string().required("Outbound line is required"),
  //   throttle: yup
  //     .string()
  //     .required("Throttle is required"),
  //   lead_form_IDs: yup
  //     .array()
  //     .of(yup.object().shape({}))
  //     .required("At least one lead form ID is required"),
  //   lead_form_source: yup.array().of(
  //     yup.object().shape({
  //       value: yup
  //         .number()
  //         .positive("Value must be a positive number")
  //         .required("Value is required"),
  //       label: yup.string().required("Label is required"),
  //     })
  //   ),
  //   dispositions: yup.array().of(
  //     yup.object().shape({
  //       value: yup
  //         .number()
  //         .positive("Value must be a positive number")
  //         .required("Value is required"),
  //       label: yup.string().required("Label is required"),
  //     })
  //   ),
  //   disenroll_if_disposition_changed_to_appointment_set: yup
  //     .boolean()
  //     .required("This field is required"),
  //   disenroll_if_disposition_changed_to_sold: yup
  //     .boolean()
  //     .required("This field is required"),
  //   disenroll_if_the_contact_responds: yup
  //     .boolean()
  //     .required("This field is required"),
  //   run_on_nation_holidays: yup.boolean().required("This field is required"),
});
export const CreateBDCCampaignFormSettingsSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  days: yup.array().of(daySchema),
});
