import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    borderRadius: "0.5rem",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontWeight: 500,
    textAlign: "center",
    minWidth: "6.9375rem",
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    sm: {
      padding: "0.5rem 0.875rem",
      height: "2rem",
      minW: "6.4375rem",
    },
    md: {
      padding: "0 1rem",
      height: "2.25rem",
      minW: "6.6875rem",
    },
    lg: {
      padding: "0 1.125rem",
      height: "2.5rem",
    },
    xl: {
      padding: "0 1.25rem",
      height: "2.75rem",
      minW: "7.875rem",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      fontWeight: 500,
    },
    "2xl": {
      padding: "0 1.75rem",
      height: "3rem",
      minW: "8.875rem",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      fontWeight: 500,
    },
  },
  variants: {
    none: {
      all: "unset",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      _disabled: {
        opacity: "0.5",
      },
    },

    primary: {
      bg: "primary.default",
      border: "1px solid var(--primary-600, #1E88E5)",
      color: "var(--grey-white, #FFF)",
      fontSize: "0.875rem",
      _hover: {
        bg: "primary.700",
        _disabled: {
          bg: "primary.100",
          borderColor: "primary.100",
        },
      },
      _active: {
        bg: "primary.800",
      },
      _disabled: {
        bg: "primary.100",
        borderColor: "primary.100",
      },
    },
    danger: {
      background: "var(--red-600)",
      color: "white",
    },
    white: {
      bg: "var(--white, #fff)",
      border: "1px solid var(--grey-300, #E0E0E0)",
      color: "grey.900",
      _hover: {
        bg: "grey.50",
        _disabled: {
          bg: "primary.100",
          borderColor: "primary.100",
        },
      },
      _active: {
        bg: "grey.200",
      },
      _disabled: {
        bg: "grey.100",
        borderColor: "grey.100",
      },
    },
    outline: {
      bg: "var(--white, #fff)",
      border: "1px solid var(--primary-400, #42a5f5)",
      color: "var(--grey-900, #263238)",
      fontSize: "0.875rem",
      _hover: {
        bg: "grey.50",
        _disabled: {
          bg: "primary.100",
          borderColor: "primary.100",
        },
      },
      _active: {
        bg: "grey.200",
      },
      _disabled: {
        bg: "grey.100",
        borderColor: "grey.100",
      },
    },
    dateRange: {
      bg: "var(--white, #fff)",
      color: "var(--grey-900, #263238)",
      fontSize: "0.875rem",
      width: "18rem !important",
      background: "transparent",
      padding: "0",
      paddingLeft: "0 !important",
      _hover: {
        bg: "grey.50",
        _disabled: {
          bg: "primary.100",
          borderColor: "primary.100",
        },
      },
      _active: {
        bg: "grey.200",
      },
      _disabled: {
        bg: "grey.100",
        borderColor: "grey.100",
      },
    },
    outlineBlue: {
      bg: "var(--white, #fff)",
      border: "1px solid var(--primary-600)",
      color: "var(--primary-600)",
      borderColor: "var(--primary-600)",
      fontSize: "0.875rem",
      _hover: {
        bg: "grey.50",
        _disabled: {
          bg: "primary.100",
          borderColor: "primary.100",
        },
      },
      _active: {
        bg: "grey.200",
      },
      _disabled: {
        bg: "grey.100",
        borderColor: "grey.100",
      },
    },
    icon: {
      bg: "transparent",
      padding: "0.875rem",
      height: "2rem",
      minW: "2rem",
      border: "1px solid var(--grey-300, #E0E0E0)",

      // background: "var(--grey-100, #F5F5F5)",
    },
  },
  defaultProps: {
    size: "lg",
    variant: "primary",
  },
});
