import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input } from "../../../components/common";
import AudioPlayer from "../../../components/common/AudioPlayer";
import FileUploader from "../../../components/common/FileUploader";
import SwitchToggle from "../../../components/common/Switch";
import VoiceRecorder from "../../../components/common/VoiceRecorder";
import {
  getCallSetting,
  postDealershipRecording,
  updateCallSetting,
} from "../../../utils/api/line-rings.api";
import { textToSpeech } from "../../../utils/api/users.api";
import Loader from "../../common/Spinner";
import { InsertVariableDrawer } from "../../Drawers/InsertVariableDrawer";
const CallingSettings = () => {
  const toast = useToast();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["callsetting", id],
    queryFn: getCallSetting,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setPayload(data?.data?.settings_hash);
      setAudioData(data?.data?.settings_hash?.recording_pre_signed_url ?? "");
      setTranscribed(
        data?.data?.settings_hash?.recording_pre_signed_url ? true : false
      );
    },
  });
  const [openInsertVariable, setOpenInsertVariable] = useState(false);
  const [payload, setPayload] = useState(data?.data?.settings_hash);
  const handleInputChange = (field: string, value: any) => {
    setPayload((prevData: any) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const mutation = useMutation(updateCallSetting);
  const uploadMutation = useMutation(postDealershipRecording);
  const handleSubmit = async () => {
    if (audioData !== payload?.recording_pre_signed_url) {
      const response = await fetch(audioData);
      const blob = await response.blob();
      let formData = new FormData();
      formData.append("audio_file", blob); // Append the File
      formData.append("attachment_type", "incoming_call"); // Append other data as string
      uploadMutation.mutate(
        {
          id: id,
          data: formData,
        },
        {
          onSuccess: (data) => {
            handleInputChange(
              "recording_pre_signed_url",
              data?.data?.presigned_url
            );
            dataSubmit({
              ...payload,
              recording_pre_signed_url: data?.data?.presigned_url,
            });
            setAudioUpload(true);
          },
        }
      );
    } else {
      dataSubmit();
    }
  };

  const dataSubmit = (payloadParams?: any) => {
    mutation.mutate(
      {
        id: id,
        data: {
          key: "dealership_call_settings",
          settings_hash: payloadParams || payload,
        },
      },
      {
        onSuccess: (data) => {
          setAudioUpload(false);
          toast({
            description: "Call settings updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const [audioData, setAudioData] = useState<any>(
    payload?.recording_pre_signed_url ?? ""
  );
  const [audioUpload, setAudioUpload] = useState(false);
  const [transcribed, setTranscribed] = useState<boolean | undefined>(
    payload?.recording_pre_signed_url ? true : false
  );
  useEffect(() => {
    if (audioUpload) {
      dataSubmit();
    }
  }, [audioUpload]);
  const options: SelectOptionProps[] = [
    { label: "Settings", value: "settings" },
    { label: "Steps", value: "steps" },
    { label: "Trigger", value: "trigger" },
    { label: "Leads", value: "leads" },
    { label: "Ledger", value: "ledger" },
    { label: "Create Copy", value: "create-copy" },
  ];

  const textToSpeechMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await textToSpeech(payload.text, "Call Settings");
        return response;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleTranscribe = async () => {
    const res = await textToSpeechMutation.mutateAsync({
      text: payload.recording_message,
    });

    setTranscribed(true);
    setAudioData(res.url);
  };

  return (
    <VStack gap="0.62rem" alignItems="flex-start">
      <VStack
        padding="1rem"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        w="100%"
        alignItems="flex-start"
        gap="1rem"
      >
        <Text textStyle="h4" fontWeight="500">
          Force Users to Always Available
        </Text>
        <SwitchToggle
          id="1"
          onChange={(check: boolean) =>
            handleInputChange("force_user_to_visible", check)
          }
          label="Enable force users to always available"
          defaultChecked={payload?.force_user_to_visible}
        />
        <Text textStyle="h6" fontWeight="400">
          Set all users' statuses toAvailableRemove users' ability to manually
          change their status toBusy / BreakAdmins can still manually change
          agents' statuses in Wallboard
        </Text>
      </VStack>

      <Box
        padding="1rem"
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        alignItems="flex-start"
      >
        <Text textStyle="h6" fontWeight="600" mb="1rem">
          {/* You can record or upload an audio file for incoming phone call
            notification. */}
        </Text>
        <Tabs
          variant="primary"
          index={payload?.record_or_upload === "text_to_speech" ? 0 : 1}
        >
          <TabList mb="1rem" background="white">
            <Tab
              onClick={() =>
                handleInputChange("record_or_upload", "text_to_speech")
              }
            >
              Text To Speech
            </Tab>
            <Tab
              onClick={() =>
                handleInputChange("record_or_upload", "play_recording")
              }
            >
              Play Recording
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel mb="1rem">
              <Input
                label="Recording Message"
                type="text"
                placeholder="Write here.."
                onChange={(e) =>
                  handleInputChange("recording_message", e.target.value)
                }
                value={payload?.recording_message}
                mb="10px"
                isDisabled={transcribed}
              />

              {!openInsertVariable && (
                <Button
                  onClick={() => setOpenInsertVariable(true)}
                  isDisabled={transcribed}
                >
                  Add Variable
                </Button>
              )}
              <Button
                marginTop="1rem"
                marginBottom="1rem"
                width={"100%"}
                variant="outlineBlue"
                isDisabled={!payload?.recording_message}
                onClick={() => handleTranscribe()}
              >
                Transcribe
              </Button>
              {openInsertVariable && (
                <InsertVariableDrawer
                  isOpen={openInsertVariable}
                  isOnRight={true}
                  onClose={() => setOpenInsertVariable(false)}
                  onVariableSelect={(variable: any) =>
                    handleInputChange(
                      "recording_message",
                      `${payload.recording_message} {{${variable?.name}}}`
                    )
                  }
                />
              )}
              {audioData && transcribed && (
                <AudioPlayer
                  audioSrc={audioData}
                  transcribed={transcribed}
                  handleTranscribed={() => {
                    setTranscribed(false);
                    setAudioData("");
                  }}
                />
              )}
            </TabPanel>
            <TabPanel>
              <Box
                mb="1rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
              >
                <VoiceRecorder
                  transcribed={transcribed}
                  handleTranscribed={() => setTranscribed(true)}
                  handleAudioData={(src: string) => setAudioData(src)}
                />
                <FileUploader
                  transcribed={transcribed}
                  handleTranscribed={() => setTranscribed(true)}
                  handleAudioData={(src: string) => setAudioData(src)}
                />
              </Box>
              {transcribed && audioData && (
                <AudioPlayer
                  audioSrc={audioData}
                  transcribed={transcribed}
                  handleTranscribed={() => {
                    setTranscribed(false);
                    setAudioData("");
                  }}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
      >
        <Button width="8rem" onClick={handleSubmit}>
          Save
        </Button>{" "}
      </HStack>
      {(mutation?.isLoading || uploadMutation.isLoading || isLoading) && (
        <Loader />
      )}
    </VStack>
  );
};

export default CallingSettings;
