import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { fetchData } from "../../../utils/api/sms-templates.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Dropdown, PaginatedTable, Spinner } from "../../common";
import { EditIcon } from "../../icons/svg";
import CreateSMSTemplate from "./CreateSMSTemplate";
import DeleteSMSTemplate from "./DeleteSMSTemplate";
import EditSMSTemplate from "./EditSMSTemplate";

const StoreTemplates = () => {
  const user = useUserInfo("user");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);

  const [count, setCount] = useState(0);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const [clickedRow, setClickedRow] = useState({
    id: 0,
    name: "",
    body: "",
  });

  const [templates, setTemplates] = useState([
    {
      id: 0,
      name: "",
      body: "",
    },
  ]);
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];
  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };
  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "Body", label: "body" },
    { value: "Actions", label: "actions" },
  ];

  const dealerId = useUserInfo("dealership");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchStoreTemplate", dealerId, page, perPage],
    queryFn: () => {
      const params: any = {
        page: page,
        per_page: perPage,
      };

      const endpoint = `/api/dealerships/${dealerId?.id}/sms_templates`;
      return fetchData(endpoint, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setTemplates(data?.sms_templates);
      setCount(data?.sms_templates_count ?? 0);
    },
  });

  const tableData = templates?.map((item: any) => ({
    id: item.id,
    name: item.name,
    body: item.body,
    actions: (
      <Dropdown
        options={options}
        disabled={
          !(user?.role === "Owner/GM" || user?.role === "Manager/Admin")
        }
        onSelect={handleSelect}
      />
    ),
  }));

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="15px"
      >
        <Text textStyle="caption" color="var(--grey-600)">
          Total Templates ({count})
        </Text>
        {(user?.role === "Owner/GM" || user?.role === "Manager/Admin") && (
          <Button onClick={onOpen}>Create Template</Button>
        )}
      </Box>
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        itemsPerPage={perPage}
        maxPageNumbers={3}
        hasPadding={true}
        isPaginated={true}
        lastCenter={false}
        hasMultiBody={false}
        rowOnClick={(rowItem) => {
          setClickedRow(rowItem);
        }}
        perPageChange={(value) => {
          setPage(1);
          setPerPage(Number(value?.label));
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={count}
      />

      {isOpen && (
        <CreateSMSTemplate
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
          endpoint={`/api/dealerships/${
            Number(dealerId?.id) ?? -1
          }/sms_templates`}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dealerId?.id,
          }}
        ></CreateSMSTemplate>
      )}
      {selectedOption?.value === "edit" && (
        <EditSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/dealerships/${
            Number(dealerId?.id) ?? -1
          }/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dealerId?.id,
          }}
        />
      )}
      {selectedOption?.value === "delete" && (
        <DeleteSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/dealerships/${
            Number(dealerId?.id) ?? -1
          }/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dealerId?.id,
          }}
        />
      )}
      {isLoading && <Spinner />}
    </>
  );
};

export default StoreTemplates;
