export const h1 = {
  fontSize: "3rem",
  lineHeight: "3.75rem",
};

export const h2 = {
  fontSize: "2rem",
  lineHeight: "2.5rem",
  fontWeight: 700,
};

export const h3 = {
  fontSize: "1.5rem",
  lineHeight: "2rem",
};

export const h4 = {
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
};

export const h5 = {
  fontSize: "1rem",
  lineHeight: "1.25rem",
};

export const h6 = {
  fontSize: "0.875rem",
  lineHeight: "1rem",
};

export const body = {
  fontSize: "1rem",
  lineHeight: "1.25rem",
};

export const bodySmall = {
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
};

export const caption = {
  fontSize: "0.875rem",
};

export const captionSmall = {
  fontSize: "0.75rem",
};
export const captionXS = {
  fontSize: "0.625rem",
};
