import { Button, DrawerFooter, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { isValidName } from "../../../utils";
import { createDealership, fetchTimezones } from "../../../utils/api/users.api";
import { Drawer, Input, Select } from "../../common";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};

interface ApiResult {
  data: any;
}
interface ApiRequest {
  dealership: {
    name: string;
    time_zone?: string;
    country: string;
  };
}

const countryOptions = [
  { label: "United States", value: "United States" },
  { label: "Canada", value: "Canada" },
];

const CreateDealership = (props: data) => {
  const { isOpen, onClose, refetchParent } = props;
  const toast = useToast();
  //..........api calling..............
  const { data, isLoading } = useQuery({
    queryKey: ["timezones"],
    queryFn: () => fetchTimezones(),
  });

  const [name, setName] = useState("");
  const [timezoneOptions, setTimezoneOptions] = useState<SelectOptionProps[]>(
    []
  );
  const [country, setCountry] = useState("");

  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  //.......error states......
  const [nameError, setNameError] = useState("");
  const [timezoneError, setTimezoneError] = useState("");
  const [countryError, setCountryError] = useState("");

  useEffect(() => {
    setTimezoneOptions(
      data?.map(({ id, name }: any) => ({ label: id, value: name })) || []
    );
  }, [data]);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createDealership(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Dealership created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error creating dealership: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let validName = isValidName(name, setNameError, "Dealership Name");

    let validTimezone = false;
    if (!selectedOption) {
      setTimezoneError("Timezone cannot be empty");
      validTimezone = false;
    } else {
      validTimezone = true;
      setTimezoneError("");
    }

    let validCountry = false;
    if (!selectedOption) {
      setCountryError("Country cannot be empty");
      validCountry = false;
    } else {
      validCountry = true;
      setCountryError("");
    }

    if (!validTimezone || !validName || !validCountry) {
      return;
    }

    const requestData: ApiRequest = {
      dealership: {
        name: name,
        time_zone: selectedOption?.value,
        country,
      },
    };

    mutation.mutate(requestData);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Create Dealership"
      isLoading={isLoading || mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Enter Dealership Name"
          label="Dealership Name"
          onChange={(e: any) => {
            setNameError("");
            setName(e.target.value);
          }}
          value={name}
          isRequired={true}
          error={nameError}
          maxLength={100}
        />
        <Select
          onChange={(value: SelectOptionProps | null) => {
            setTimezoneError("");
            setSelectedOption(value);
          }}
          placeholder="Select timezone"
          options={timezoneOptions}
          variant="default"
          value={selectedOption?.value}
          label={
            <>
              Timezone<span style={{ color: "red" }}>*</span>
            </>
          }
          w="100%"
          error={timezoneError}
        />
        <Select
          onChange={(value: SelectOptionProps | null) => {
            setTimezoneError("");
            setCountry(value?.value || "");
          }}
          placeholder="Select Country"
          options={countryOptions}
          variant="default"
          value={country}
          label={
            <>
              Country<span style={{ color: "red" }}>*</span>
            </>
          }
          w="100%"
          error={countryError}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Create Dealership
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateDealership;
