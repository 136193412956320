// https://chakra-ui.com/docs/components/checkbox/theming
import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    w: "1rem",
    h: "1rem",
    borderRadius: "0.1875rem",
    borderColor: "grey.500",
    borderWidth: "1px",
  },
  icon: {
    color: "primary.default",
    fontSize: "0.5rem",
  },
});

const sizes = {
  sm: definePartsStyle({
    control: defineStyle({
      // boxSize: 5,
      // border: "1px solid red",
    }),
    label: defineStyle({
      fontSize: "0.875rem",
      // marginLeft: 3,
      fontWeight: 400,
    }),
  }),
  lg: definePartsStyle({
    control: defineStyle({
      boxSize: 6,
    }),
    label: defineStyle({
      fontSize: "0.875rem",
      // marginLeft: 4,
      // fontWeight: 500,
    }),
  }),
};
const whiteCheck = definePartsStyle({
  icon: {
    color: "white",
  },
});
const whiteCheckGreen = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "secondary.600",
      borderColor: "secondary.600",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckBabygreen = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "secondary.200",
      borderColor: "secondary.200",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckRed = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "red.400",
      borderColor: "red.400",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckYellow = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "yellow.default",
      borderColor: "yellow.default",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckOrange = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "orange.800",
      borderColor: "orange.800",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckLightorange = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "orange.300",
      borderColor: "orange.300",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckPrimary = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "primary.600",
      borderColor: "primary.600",
    },
  },
  label: {
    fontWeight: "600",
  },
});
const whiteCheckBabyBlue = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "primary.200",
      borderColor: "primary.200",
    },
  },
  label: {
    fontWeight: "600",
  },
});

const whiteCheckGray = definePartsStyle({
  icon: {
    color: "white",
  },
  control: {
    _checked: {
      background: "bluegray.600",
      borderColor: "bluegray.600",
    },
  },
  label: {
    fontWeight: "600",
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    whiteCheck,
    whiteCheckGreen,
    whiteCheckYellow,
    whiteCheckRed,
    whiteCheckPrimary,
    whiteCheckGray,
    whiteCheckOrange,
    whiteCheckBabyBlue,
    whiteCheckBabygreen,
    whiteCheckLightorange
  },
  sizes,
  defaultProps: { size: "sm" },
});
