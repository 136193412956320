import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
// import 'react-calendar/dist/Calendar.css';
import "../../../App.css";
import { scheduleAVisit } from "../../../utils/api/guest-mode";
import { useMutation } from "@tanstack/react-query";
import { convertDateTime } from "../../../utils";
import moment from "moment";
import Popup from "../../../components/Charts/Popup";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { BigCheckIcon } from "../../../components/icons/svg";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const times = [
  "09:00am",
  "09:15am",
  "09:30am",
  "09:45am",
  "10:00am",
  "10:15am",
  "10:30am",
  "10:45am",
  "11:00am",
  "11:15am",
  "11:30am",
  "11:45am",
  "12:00pm",
  "12:15pm",
  "12:30pm",
  "12:45pm",
  "01:00pm",
  "01:15pm",
  "01:30pm",
  "01:45pm",
  "02:00pm",
  "02:15pm",
  "02:30pm",
  "02:45pm",
  "03:00pm",
  "03:15pm",
  "03:30pm",
  "03:45pm",
  "04:00pm",
  "04:15pm",
  "04:30pm",
  "04:45pm",
  "05:00pm",
  "05:15pm",
  "05:30pm",
  "05:45pm",
  "06:00pm",
  "06:15pm",
  "06:30pm",
  "06:45pm",
  "07:00pm",
  "07:15pm",
  "07:30pm",
  "07:45pm",
  "08:00pm",
  "08:15pm",
  "08:30pm",
  "08:45pm",
  "09:00pm",
  "09:15pm",
  "09:30pm",
  "09:45pm",
  "10:00pm",
  "10:15pm",
  "10:30pm",
  "10:45pm",
  "11:00pm",
  "11:15pm",
  "11:30pm",
  "11:45pm",
];
const timesMapping: Record<string, string> = {
  "09:00am": "09:00",
  "09:15am": "09:15",
  "09:30am": "09:30",
  "09:45am": "09:45",
  "10:00am": "10:00",
  "10:15am": "10:15",
  "10:30am": "10:30",
  "10:45am": "10:45",
  "11:00am": "11:00",
  "11:15am": "11:15",
  "11:30am": "11:30",
  "11:45am": "11:45",
  "12:00pm": "12:00",
  "12:15pm": "12:15",
  "12:30pm": "12:30",
  "12:45pm": "12:45",
  "01:00pm": "13:00",
  "01:15pm": "13:15",
  "01:30pm": "13:30",
  "01:45pm": "13:45",
  "02:00pm": "14:00",
  "02:15pm": "14:15",
  "02:30pm": "14:30",
  "02:45pm": "14:45",
  "03:00pm": "15:00",
  "03:15pm": "15:15",
  "03:30pm": "15:30",
  "03:45pm": "15:45",
  "04:00pm": "16:00",
  "04:15pm": "16:15",
  "04:30pm": "16:30",
  "04:45pm": "16:45",
  "05:00pm": "17:00",
  "05:15pm": "17:15",
  "05:30pm": "17:30",
  "05:45pm": "17:45",
  "06:00pm": "18:00",
  "06:15pm": "18:15",
  "06:30pm": "18:30",
  "06:45pm": "18:45",
  "07:00pm": "19:00",
  "07:15pm": "19:15",
  "07:30pm": "19:30",
  "07:45pm": "19:45",
  "08:00pm": "20:00",
  "08:15pm": "20:15",
  "08:30pm": "20:30",
  "08:45pm": "20:45",
  "09:00pm": "21:00",
  "09:15pm": "21:15",
  "09:30pm": "21:30",
  "09:45pm": "21:45",
  "10:00pm": "22:00",
  "10:15pm": "22:15",
  "10:30pm": "22:30",
  "10:45pm": "22:45",
  "11:00pm": "23:00",
  "11:15pm": "23:15",
  "11:30pm": "23:30",
  "11:45pm": "23:45",
};

const pickSlotTimes = (times: string[]): string[] => {
  return times;
};

const ScheduleVisit = () => {
  const { id, dId } = useParams();
  const toast = useToast();
  const [bookingDate, setBookingDate] = useState<Date | null>(null);
  const [bookingTimes, setBookingTimes] = useState<string[]>([]);
  const timeSlotCacheRef = useRef<Map<string, string[]>>(new Map());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [isBack, setIsBack] = useState<boolean>(true);

  const mutation = useMutation(scheduleAVisit);

  const [disabledDates, setDisabledDates] = useState<Date[]>([
    new Date(2024, 4, 20), // May 20th, 2024
    new Date(2024, 4, 25), // May 25th, 2024
    // Add more dates as needed
  ]);

  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
  };

  const tileDisabled = ({ date }: { date: Date }) => {
    // Check if the date is in the disabledDates array
    return disabledDates.some(
      (disabledDate) =>
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
    );
  };
  useEffect(() => {
    if (!bookingDate) return;

    let newBookingTimes = timeSlotCacheRef.current.get(
      bookingDate.toDateString()
    );

    if (!newBookingTimes) {
      newBookingTimes = pickSlotTimes(times);
      timeSlotCacheRef.current.set(bookingDate.toDateString(), newBookingTimes);
    }

    setBookingTimes(newBookingTimes);
  }, [bookingDate]);

  const onDateChange = (date: Date | Date[]): void => {
    if (Array.isArray(date)) {
    } else {
      setBookingDate(date);
    }
  };

  const handleSubmit = () => {
    setIsBack(false);
    setIsOpen(false);

    mutation.mutate(
      {
        id,
        dId,
        payload: {
          appointment: {
            start_time: convertDateTime(
              moment(bookingDate).format("YYYY-MM-DD"),
              timesMapping[selectedTime ?? ""]
            ),
          },
        },
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
        },
        onError: (error: any) => {
          setIsSuccess(false);
        },
      }
    );
  };
  return (
    <VStack
      w="100%"
      mt={{ md: "2.5rem", base: "1rem" }}
      p={{ md: "0", base: "1rem" }}
    >
      <VStack
        w={{ base: "100%", md: "50%" }}
        background="#fff"
        borderRadius="0.5rem"
        p="1.5rem"
        h="100%"
      >
        {isBack ? (
          <HStack
            gap="2rem"
            justifyContent="center"
            alignItems="unset"
            h="100%"
            flexDirection={{ base: "column", md: "row" }}
          >
            <VStack w="100%" flex="2" className="schedule-visit-calendar">
              <Calendar
                value={bookingDate}
                onChange={(e: any) => onDateChange(e)}
                tileDisabled={tileDisabled}
              />
            </VStack>

            <VStack
              background="var(--grey-200)"
              maxHeight={{ md: "400px", base: "300px" }}
              overflowY="scroll"
              p="1rem"
              borderRadius="0.5rem"
              w={{ md: "40%", base: "100%" }}
              flex="1"
              alignItems="center"
              flexDirection={{ md: "column", base: "row" }}
              flexWrap={{ md: "nowrap", base: "wrap" }}
              textAlign="center"
              justifyContent={{ md: "flex-start", base: "center" }}
            >
              {bookingDate ? (
                bookingTimes.map((time) => (
                  <VStack key={time} alignItems="flex-start">
                    <HStack>
                      <Button
                        w={{
                          md: selectedTime === time ? "100px" : "200px",
                          base: selectedTime === time ? "150px" : "150px",
                        }}
                        variant={selectedTime === time ? "" : "outline"}
                        fontSize="0.875rem"
                        borderRadius="4px"
                        color={
                          selectedTime === time ? "#fff" : "var(--primary-600)"
                        }
                        bg={selectedTime === time ? "var(--grey-700)" : "#fff"}
                        border="1px solid var(--grey-300)"
                        onClick={() => handleTimeClick(time)}
                      >
                        {time}
                      </Button>
                      {selectedTime === time && (
                        <Button
                          borderRadius="4px"
                          w={{ md: "90px", base: "150px" }}
                          onClick={() => setIsOpen(true)}
                        >
                          Confirm
                        </Button>
                      )}
                    </HStack>
                  </VStack>
                ))
              ) : (
                <Text textStyle="h5">
                  Please select the day you would like to visit and my available
                  times will show here.
                </Text>
              )}
            </VStack>
          </HStack>
        ) : (
          <>
            <VStack
              w={{ md: "100%%", base: "100%" }}
              h={{ md: "400px", base: "300px" }}
            >
              {isSuccess ? (
                <VStack alignItems="center" justifyContent="center" h="100%">
                  <Text textStyle="h3" fontWeight="600">
                    Appointment created successfully!
                  </Text>
                  <VStack gap="0.5rem">
                    <BigCheckIcon color="var(--primary-600)" />
                  </VStack>
                </VStack>
              ) : (
                <VStack>
                  <Text textStyle="h3" fontWeight="600">
                    Error while creating appointment!
                  </Text>
                  <VStack gap="0.5rem">
                    <CancelIcon
                      style={{
                        width: "8rem",
                        height: "8rem",
                        color: "var(--red-600)",
                      }}
                    />
                  </VStack>
                </VStack>
              )}
              <HStack justifyContent="flex-start" w="100%">
                <Button
                  variant="none"
                  // border="1px solid var(--grey-300)"
                  // borderRadius="0.5rem"
                  justifyContent="center"
                  padding="0.55rem"
                  display="flex"
                  // w="40px"
                  onClick={() => {
                    setIsBack(true);
                    setSelectedTime(null);
                    setBookingDate(null);
                  }}
                >
                  <ArrowBackIcon sx={{ color: "var(--grey-900)" }} />
                </Button>
              </HStack>
            </VStack>
          </>
        )}
      </VStack>
      {isOpen && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpen(false)}
          onConfirm={handleSubmit}
          title="Confirmation Popup"
          message="Are you sure you want to schedule this appointment?"
        />
      )}
      {mutation?.isLoading && <Spinner />}
    </VStack>
  );
};

export default ScheduleVisit;
