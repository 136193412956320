export interface HistoryState {
  shouldRefetch: boolean;
}

export type HistoryAction = {
  type: "SET_SHOULD_REFETCH";
  payload: { shouldRefetch: boolean };
};

export const initialState: HistoryState = {
  shouldRefetch: false,
};

export const HistoryReducer = (
  state: HistoryState,
  action: HistoryAction
): HistoryState => {
  switch (action.type) {
    case "SET_SHOULD_REFETCH":
      return {
        ...state,
        shouldRefetch: action.payload.shouldRefetch,
      };
    default:
      const _exhaustiveCheck: HistoryAction = action;
      throw new Error(`Unhandled action type: ${_exhaustiveCheck}`);
  }
};
