import { Box, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  fetchMissedCalls,
  markReminderAsRead,
} from "../../../utils/api/header-notifications.api";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import moment from "moment";

interface MissedCallsProps {
  page?: number;
  perPage?: number;
  refetch?: boolean;
  onClose?: () => void;
}

const MissedCallCard = ({
  page,
  perPage,
  refetch,
  onClose,
}: MissedCallsProps) => {
  const { refetchNotifications } = useNotificationContext();
  const dealerId = useUserInfo("dealership");
  const navigate = useNavigate();
  const mutation = useMutation(markReminderAsRead);

  const { data } = useQuery({
    queryKey: ["missedCallNotification", dealerId?.id, page, perPage, refetch],
    queryFn: () =>
      fetchMissedCalls({ dealership_id: dealerId?.id, per_page: perPage, page }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: page !== undefined,
  });

  const toast = useToast();

  const handleMarkAsRead = async (item: any) => {
    if (!item?.read_status) {
      await mutation.mutateAsync(
        { dId: dealerId?.id, id: item?.id },
        {
          onSuccess: (data: any) => {
            refetchNotifications(); // This will trigger the refetch of the parent's query
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }

    if (onClose) {
      onClose();
    }

    if (item?.notification_object?.contact_id) {
      navigate(`/leads/lead-details`, {
        state: {
          contact_id: item?.notification_object?.contact_id,
          call_id: item?.notification_object?.call_history_id,
          activeKey: 1,
          activeIndex: 0,
        },
      });
    }
  };

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0.59rem"
        maxH="calc(100vh - 450px)"
        overflow="auto"
        padding="1rem"
        pt="0"
      >
        {data?.data?.notifications?.map((item: any) => (
          <HStack
            padding="1rem"
            w="100%"
            key={item}
            background={item?.read ? "white" : "var(--grey-100)"}
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            position="relative"
            onClick={() => {
              handleMarkAsRead(item);
            }}
            cursor="pointer"
          >
            {!item?.read && (
              <Box
                w="0.47rem"
                h="0.47rem"
                borderRadius="50%"
                background="#F44336"
                position="absolute"
                top="0.5rem"
                left="0.5rem"
              ></Box>
            )}
            <Box
              padding="0.5rem"
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              background="white"
              sx={{
                svg: {
                  path: {
                    fill: "var(--secondary-600)",
                  },
                },
              }}
            >
              <PhoneMissedIcon
                style={{
                  color: "white",
                  background: "var(--secondary-600)",
                  borderRadius: "0.1875rem",
                  padding: "0.25rem 0.35rem",
                  width: "1.75rem",
                  height: "1.5rem",
                }}
              />
            </Box>
            <VStack gap="0.25rem" w="100%" alignItems="flex-start">
              <Text textStyle="h5" fontWeight="500">
                From:{" "}
                <Box as="span" color="var(--primary-600)">
                  {item.notification_object.contact_name || item.notification_object.phone_number}
                </Box>
              </Text>
              <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
                Missed Call
              </Text>
              <Text textStyle="captionSmall" fontWeight="500">
                {moment(item.notification_object.date_time).format("dddd D MMM YY")}
                <Box as="span" color="var(--primary-600)">
                  • {moment(item.notification_object.date_time).format("hh:mm a")}
                </Box>
              </Text>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
};

export default MissedCallCard;
