import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";

type DataItem = {
  id: string;
  ip_address: string;
  user_name: string;
  dealership_name: string;
};

type InfiniteListProps = {
  data?: DataItem[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  count?: number;
  target?: string;
};

const InfiniteList: React.FC<InfiniteListProps> = ({
  data = [],
  setPage,
  count = 0,
  target = "scrollableDiv",
}) => {
  const hasMoreItems = data?.length < count;

  return (
    <InfiniteScroll
      dataLength={data?.length}
      next={() => setPage((prev) => prev + 1)}
      hasMore={hasMoreItems}
      loader={<h4>Loading...</h4>}
      scrollableTarget={target}
    >
      <VStack gap="0.5">
        {data?.map((item) => (
          <Box
            key={item.id}
            w="100%"
            padding="1rem"
            cursor="pointer"
            onClick={() => {}}
            borderRadius="md"
            boxShadow="md"
          >
            <HStack alignItems="flex-start" gap="0.75rem">
              <VStack gap="0.25rem" alignItems="flex-start">
                <Text textStyle="h5" fontWeight="600" color="var(--grey-800)">
                  {item?.ip_address}
                </Text>
                <Text textStyle="h5" fontWeight="600" color="var(--grey-800)">
                  {item?.user_name}
                </Text>
                <Text textStyle="h5" fontWeight="600" color="var(--grey-800)">
                  {item?.dealership_name}
                </Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </InfiniteScroll>
  );
};

export default InfiniteList;
