import {
  Button,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import DatePicker from "../../../../common/DateRangePicker";
import { RouteIcon } from "../../../../icons/svg";
import { Select } from "../../../../../components/common";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getFacebookPages,
  fbcheckoutUrl,
  checkSubscription,
  getForm,
  subscribePage,
  unsubscribePage,
  syncLeads,
} from "../../../../../utils/api/php-endpoints.api";
import { useState } from "react";
import Loader from "../../../../common/Spinner";
import moment from "moment";
import { useToast } from "@chakra-ui/react";
import SwitchToggle from "../../../../common/Switch";
const FacebookIntegration = ({ userId, id }: any) => {
  const toast = useToast();
  const [selectedPage, setSelectedPage] = useState<any>();
  const [lifetime, setLifetime] = useState<boolean>(false);
  const [range, setRange] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const [form, setForm] = useState<any>();
  const subscribe = useMutation(subscribePage);
  const unsubscribe = useMutation(unsubscribePage);
  const leadSync = useMutation(syncLeads);
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["facebookpages", id, userId],
    queryFn: getFacebookPages,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {} = useQuery({
    queryKey: [
      "checksubs",
      id,
      userId,
      selectedPage?.id,
      selectedPage?.access_token,
    ],
    queryFn: checkSubscription,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: selectedPage !== undefined,
  });
  const { data: forms ,refetch:refetchForm} = useQuery({
    queryKey: [
      "form",
      id,
      userId,
      selectedPage?.id,
      selectedPage?.access_token,
    ],
    queryFn: getForm,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: selectedPage !== undefined,
  });
  const subscribeapi = (val: any) => {
    !val
      ? subscribe.mutate(
          {
            id: id,
            userid: userId,
            page_id: selectedPage?.id,
            token: selectedPage?.access_token,
            name: selectedPage?.name,
          },
          {
            onSuccess(data) {
              refetch();
              refetchForm();
              toast({
                description: "Page associated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
              });
            },
            onError: (error: any) => {
              toast({
                description:
                  error?.response?.data?.message?.toString() ??
                  "Something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            },
          }
        )
      : unsubscribe.mutate(
          {
            id: id,
            userid: userId,
            page_id: selectedPage?.id,
            token: selectedPage?.access_token,
            name: selectedPage?.name,
          },
          {
            onSuccess(data) {
              refetchForm()
              refetch();
              toast({
                description: "Page  disassociated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
              });
            },
            onError: (error: any) => {
              toast({
                description:
                error?.response?.data?.message?.toString() ??
                "Something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            },
          }
        );
  };
  const syncApi = () => {
    leadSync.mutate(
      {
        id: id,
        userid: userId,
        page_id: selectedPage?.id,
        forms: form?.map((item: any) => item?.value),
        range:lifetime?undefined: [
          moment(range?.start_date).format("YYYY-MM"),
          moment(range?.end_date).format("YYYY-MM"),
        ],
      },
      {
        onSuccess(data) {
          setForm([]);
          toast({
            description: "Request submitted successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
            error?.response?.data?.message?.toString() ??
            "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack p="1rem 0" gap="1rem">
      <HStack w="100%" justifyContent="flex-start">
        {!data?.data?.data?.account_info && (
          <Button
            rightIcon={<RouteIcon />}
            sx={{
              svg: {
                path: {
                  fill: "white",
                },
              },
            }}
            onClick={() => window.location.replace(fbcheckoutUrl(id))}
          >
            {!data ? "Connect to Facebook" : "Reconnect to Facebook"}
          </Button>
        )}
      </HStack>
      {data?.data?.data?.account_info && (
        <VStack
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          w="100%"
          alignItems="flex-start"
          background="white"
          gap="0"
        >
          <HStack
            w="100%"
            padding="1rem"
            borderBottom="1px solid var(--grey-300)"
          >
            <Text textStyle="h5" fontWeight="600" w="100%">
              Connected User Information
            </Text>
            <Button
              rightIcon={<RouteIcon />}
              padding="0 2rem"
              sx={{
                svg: {
                  path: {
                    fill: "white",
                  },
                },
              }}
              onClick={() => window.location.replace(fbcheckoutUrl(id))}
            >
              {!data ? "Connect to Facebook" : "Reconnect to Facebook"}
            </Button>
          </HStack>
          <VStack padding="2rem" alignItems="flex-start" gap="2rem">
            <Image
              src={data?.data?.data?.account_info?.picture?.data?.url}
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
              }}
              alt="avatar"
            />
            <Text textStyle="h5" fontWeight="700">
              {data?.data?.data?.account_info?.name}
            </Text>
          </VStack>
        </VStack>
      )}
      {data?.data?.data?.pages && (
        <VStack
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          w="100%"
          alignItems="flex-start"
          background="white"
        >
          <Text
            textStyle="h5"
            fontWeight="600"
            padding="1rem"
            w="100%"
            borderBottom="1px solid var(--grey-300)"
          >
            Facebook Pages ({data?.data?.data?.pages?.length})
          </Text>
          <VStack padding="1rem" w="100%" gap="1rem">
            <HStack w="100%">
              <Select
                label=""
                options={
                  data?.data?.data?.pages?.map((page: any) => ({
                    label: page?.name,
                    value: page?.id,
                  })) ?? []
                }
                variant="default"
                w="100%"
                value={selectedPage?.id}
                onChange={(value) => {
                  setSelectedPage(
                    data?.data?.data?.pages?.find(
                      (page: any) => page.id === value.value
                    )
                  );
                }}
              />
              <Button
                background={
                  !data?.data?.data?.associated?.find(
                    (page: any) => page?.page_id === selectedPage?.id
                  )
                    ? "var(--secondary-600)"
                    : "var(--red-400)"
                }
                border="none"
                _hover={{
                  background: !data?.data?.data?.associated?.find(
                    (page: any) => page?.page_id === selectedPage?.id
                  )
                    ? "var(--secondary-600)"
                    : "var(--red-400)",
                }}
                w="25%"
                onClick={() =>
                  subscribeapi(
                    data?.data?.data?.associated?.find(
                      (page: any) => page?.page_id === selectedPage?.id
                    )
                  )
                }
                isDisabled={!selectedPage}
              >
                {!data?.data?.data?.associated?.find(
                  (page: any) => page?.page_id === selectedPage?.id
                )
                  ? "Associate with Simpsocial"
                  : "Disassociate with Simpsocial"}
              </Button>
            </HStack>
            {selectedPage && (
              <VStack alignItems="flex-start" w="100%">
                <Text
                  w="50%"
                  fontWeight="500"
                  textStyle="h6"
                  color="var(--primary-500)"
                  textDecoration="underline"
                  cursor="pointer"
                >
                  {selectedPage?.name}
                </Text>
                <Text
                  w="50%"
                  color="var(--grey-800)"
                  fontWeight="500"
                  textStyle="h6"
                >
                  {selectedPage?.id}
                </Text>
              </VStack>
            )}
            <VStack
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              w="100%"
              alignItems="flex-start"
              background="white"
              gap="0"
            >
              <Text
                textStyle="h5"
                fontWeight="600"
                padding="1rem"
                w="100%"
                borderBottom="1px solid var(--grey-300)"
              >
                Registered FB Pages/s
              </Text>
              <VStack w="100%" padding="1rem">
                <HStack
                  w="100%"
                  p="1rem"
                  borderBottom="1px solid var(--grey-300)"
                >
                  <Text
                    w="50%"
                    color="var(--grey-800)"
                    fontWeight="700"
                    textStyle="h6"
                  >
                    FB Page Link
                  </Text>
                  <Text
                    w="50%"
                    color="var(--grey-800)"
                    fontWeight="700"
                    textStyle="h6"
                  >
                    FB Page ID
                  </Text>
                </HStack>
                {data?.data?.data?.associated?.length === 0 && (
                  <HStack w="100%" p="1rem">
                    <Text
                      w="50%"
                      fontWeight="500"
                      textStyle="h6"
                      color="var(--primary-500)"
                    >
                      You do not have any page currently associated with this
                      dealership.
                    </Text>{" "}
                  </HStack>
                )}
                {data?.data?.data?.associated?.map((item: any) => (
                  <HStack
                    w="100%"
                    p="1rem"
                    borderBottom="1px solid var(--grey-300)"
                  >
                    <Text
                      w="50%"
                      fontWeight="500"
                      textStyle="h6"
                      color="var(--primary-500)"
                      textDecoration="underline"
                      cursor="pointer"
                    >
                      {item?.name}
                    </Text>
                    <Text
                      w="50%"
                      color="var(--grey-800)"
                      fontWeight="500"
                      textStyle="h6"
                    >
                      {item?.page_id}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </VStack>
            {data?.data?.data?.associated?.length > 0 && (
              <VStack
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                w="100%"
                alignItems="flex-start"
                background="white"
                gap="0"
                opacity={forms?.data?.data?.forms?.data?.length > 0 ? 1 : 0.5}
                pointerEvents={
                  forms?.data?.data?.forms?.data?.length > 0 ? "auto" : "none"
                }
              >
                <Text
                  textStyle="h5"
                  fontWeight="600"
                  padding="1rem"
                  w="100%"
                  borderBottom="1px solid var(--grey-300)"
                >
                  Lead Synchronization
                </Text>
                <VStack w="100%" p="1rem" alignItems="flex-start" gap="1rem">
                  <HStack w="100%" alignItems="flex-start">
                    <Select
                      label=""
                      variant="default"
                      w="100%"
                      isMulti
                      options={
                        forms?.data?.data?.forms?.data?.map((page: any) => ({
                          label: page?.name,
                          value: page?.id,
                        })) ?? []
                      }
                      value={form}
                      onChange={(val) => {
                        setForm(val);
                      }}
                    />
                  
                    <DatePicker
                      w="100%"
                      value={{
                        startDate: range?.start_date,
                        endDate: range?.end_date,
                      }}
                      onChange={(range: { startDate: Date; endDate: Date }) => {
                        setRange({
                          start_date: range?.startDate,
                          end_date: range?.endDate,
                        });
                      }}
                      isDisabled={lifetime}
                    />
                     
                  </HStack>
                  <VStack w="50%" gap="1.25rem" alignItems="flex-end">
            <HStack w="100%" gap="1.25rem" alignItems="flex-end">            
              <SwitchToggle
                id="1"
                label="Lifetime (Max past 90 days)"          
                defaultChecked={lifetime}
                onChange={(check: boolean) => {
                  setLifetime(check)
                }}
              />
              </HStack>
              </VStack>
                  {/* <Text>
                    Upon submission, the lead will be sent to Dealership CRM
                    Intake Email
                  </Text> */}
                  <HStack w="100%" justifyContent="flex-end">
                    <Button
                      background="var(--secondary-600)"
                      _hover={{
                        background: "var(--secondary-600)",
                      }}
                      isDisabled={!form || form?.length === 0}
                      onClick={syncApi}
                    >
                      Submit
                    </Button>
                  </HStack>
                </VStack>
              </VStack>
            )}
          </VStack>
        </VStack>
      )}

      {(subscribe?.isLoading ||
        unsubscribe?.isLoading ||
        leadSync?.isLoading) && <Loader />}
    </VStack>
  );
};

export default FacebookIntegration;
