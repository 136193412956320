import CalculateIcon from "@mui/icons-material/Calculate";
import ContactsIcon from "@mui/icons-material/Contacts";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  BroadcastIcon,
  DMSEquityIcon,
  DashboardIcon,
  DeskLogIcon,
  DigitalLeadWarIcon,
  EmailBlastIcon,
  MeetingCalendarIcon,
  PowerDialerIcon,
  ReportIcon,
  SarahAIIcon,
  SocialTalkIcon,
  TagsIcon,
} from "../../components/icons/svg";

export const SidebarMenu = [
  {
    id: 1,
    name: "Lead Center",
    path: "/leads" || "/leads/lead-details",
    icon: (
      <ContactsIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    allowed: true,
    breadCrum: "Lead Center",
  },
  {
    id: 2,
    name: "Broadcast Message",
    // path: "/leads" || "/leads/lead-details",
    path: "/broadcast", //commented for demo purposes. Don't forget to uncomment these and remove leads path after demo
    icon: <BroadcastIcon />,
    allowed: true,
  },
  {
    id: 3,
    name: "Power Dialer",
    // path: "/leads" || "/leads/lead-details",
    path: "/power-dialer",
    icon: <PowerDialerIcon />,
    allowed: true,
  },
  {
    id: 4,
    name: "BDC Campaign",
    // path: "/leads" || "/leads/lead-details",
    path: "/bdc-campaign",
    icon: <DashboardIcon />,
    allowed: true,
  },
  {
    id: 5,
    name: "Live Tracking",
    // path: "/leads" || "/leads/lead-details",
    path: "/live-tracking",
    icon: <DashboardIcon />,
    allowed: true,
  },
  {
    id: 6,
    name: "Sarah AI",
    // path: "/leads" || "/leads/lead-details",
    path: "/sarah",
    icon: <SarahAIIcon />,
    allowed: true,
  },
  {
    id: 7,
    name: "Calendar",
    path: "/meeting-calendar",
    icon: <MeetingCalendarIcon />,
    allowed: true,
    breadCrum: "Calendar",
  },
  {
    id: 8,
    name: "DMS Equity Mining",
    // path: "/leads" || "/leads/lead-details",
    path: "/dms-equity",
    icon: <DMSEquityIcon />,
    allowed: true,
  },
  {
    id: 9,
    name: "Digital Lead War",
    // path: "/leads" || "/leads/lead-details",
    path: "/digital-lead",
    icon: <DigitalLeadWarIcon />,
    allowed: true,
  },
  {
    id: 10,
    name: "Social Talk",
    // path: "/leads" || "/leads/lead-details",
    path: "/social-talk",
    icon: <SocialTalkIcon />,
    allowed: true,
  },
  {
    id: 11,
    name: "Emails",
    // path: "/leads" || "/leads/lead-details",
    path: "/email-blast",
    icon: <EmailBlastIcon />,
    allowed: true,
  },
  {
    id: 12,
    name: "Training",
    path: "/training",
    icon: (
      <LightbulbIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    allowed: true,
  },
  {
    id: 13,
    name: "Tags/Dispositions",
    // path: "/leads" || "/leads/lead-details",
    path: "/tags",
    icon: <TagsIcon />,
    allowed: true,
    breadCrum: "SimpSocial Tag Feature",
  },
  {
    id: 14,
    name: "Reports",
    // path: "/leads" || "/leads/lead-details",
    path: "/reports",
    icon: <ReportIcon />,
    allowed: true,
  },

  {
    id: 15,
    name: "Users",
    path: "/users",
    icon: (
      <PeopleAltIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    allowed: true,
    breadCrum: "Users",
  },
  {
    id: 16,
    name: "Desk Log",
    // path: "/leads" || "/leads/lead-details",
    path: "/desk-log",
    icon: <DeskLogIcon />,
    allowed: true,
  },
  {
    id: 16,
    name: "Auto Loan Calculator",
    path: "/auto-loan-calculator",
    icon: (
      <CalculateIcon
        sx={{
          width: "var(--icon-size)",
          height: "var(--icon-size)",
          color: "var(--grey-800)",
        }}
      />
    ),
    allowed: true,
  },
];
