import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchLiveTracking = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/dashboard/live_tracking`,
    {
      params: {
        page: queryKey[2],
        per_page: queryKey[3],
      },
    }
  );
