import { Controller } from "react-hook-form";
import { Input } from ".";

const MakeInput = ({ control }: any) => (
  <Controller
    name="make"
    control={control}
    render={({ field, fieldState: { error } }) => (
      <Input
        {...field}
        placeholder="Enter Make"
        label="Make"
        type="text"
        error={error?.message || ""}
        maxLength={200}
      />
    )}
  />
);

export default MakeInput;
