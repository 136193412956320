import {
  Button,
  Divider,
  ModalFooter,
  VStack
} from "@chakra-ui/react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from "react-dom";
import { Modal } from "../../common";

type ScheduleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSchedule: (date: Date)=>void;
};

const CalendarContainer = ({ children }: any) => {
  const el = document.getElementById("calendar-portal");

  return createPortal(children, el as Element);
};

function ScheduleModal({
  isOpen,
  onClose,
  handleSchedule
}: ScheduleModalProps) {
  const [date, setDate] = useState(new Date());

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Select Date and Time">
      <VStack overflowY={"scroll"} padding={10}>
        <DatePicker
          selected={date}
          onChange={(date: Date) => setDate(date)}
          showTimeSelect
          timeFormat="HH:mm a"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          inline
          popperContainer={CalendarContainer}
          minDate={new Date()}
          filterTime={(time) => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
          }}
        />
      </VStack>

      <Divider />

      <ModalFooter width={"100%"}>
        <Button variant="outline" mr={3} onClick={onClose}>
          Cancel
        </Button>

        <Button colorScheme="blue" mr={3} onClick={()=>handleSchedule(date)}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ScheduleModal;
