import {
    HStack,
    Text,
    VStack,
    useDisclosure,
  } from "@chakra-ui/react";
  import { useState } from "react";
  import { TiktokLogo,FacebookIcon} from "../../../../icons/svg";
import { useQuery } from "@tanstack/react-query";
import { getFeedUrlApi,getCarPreview } from "../../../../../utils/api/dealership-setting.api";
import { useParams } from "react-router-dom";
import { PreviewModal } from "./PreviewModal";
export const FeedUrls = () => {
    const { id } = useParams();
    const [type,setType]=useState('')
    const [media,setMedia]=useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: feedUrl } = useQuery({
        queryKey: ["urlapi", id],
        queryFn: getFeedUrlApi,
        refetchOnWindowFocus: false,
        retry: false,
      });
const openModal=(type:any,media:any)=>{
  setType(type)
  setMedia(media)
  onOpen()

}
  return (
    <>    <VStack
    alignItems="flex-start"
    background="var(--grey-50)"
    border="1px solid var(--grey-300)"
    padding="1rem"
    borderRadius="0.5rem"
    w="100%"
    gap="1.5rem"
  >
    <Text fontWeight="700" textStyle="h4">
      Inventory Feed URLs
    </Text>
    <VStack w="100%" gap="1.25rem" alignItems="flex-start">
      <HStack
        w="100%"
        gap="1.25rem"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <VStack w="50%" alignItems="flex-start">
          <HStack>
          <FacebookIcon/>
            <Text fontWeight="700">FB Inventory Feed URL</Text>
          </HStack>
         {feedUrl?.data[0]?.facebook?.used_cars_url&& <VStack gap="0.25rem" alignItems="flex-start">
            <Text
              textStyle="h6"
              fontWeight="599"
              textDecoration="underline"
              onClick={()=>openModal("used","fb")}
              cursor="pointer"
            >
              Used Cars Preview FB Feed
            </Text>
            <Text textStyle="h5" color="var(--grey-700)" onClick={()=>{window.open(feedUrl?.data[0]?.facebook?.used_cars_url)}}
              cursor="pointer">
              {feedUrl?.data[0]?.facebook?.used_cars_url??'-'}
            </Text>
          </VStack>}
        {feedUrl?.data[0]?.facebook?.new_cars_url&&  <VStack gap="0.25rem" alignItems="flex-start">
            <Text
              textStyle="h6"
              fontWeight="599"
              textDecoration="underline"
              onClick={()=>openModal("new","fb")}
              cursor="pointer"
            >
              New Cars Preview FB Feed
            </Text>
            <Text textStyle="h5" color="var(--grey-700)" onClick={()=>{window.open(feedUrl?.data[0]?.facebook?.new_cars_url)}}
              cursor="pointer">
              {feedUrl?.data[0]?.facebook?.new_cars_url??'-'}
            </Text>
          </VStack>}
         {feedUrl?.data[0]?.facebook?.products_url&& <VStack gap="0.25rem" alignItems="flex-start">
            <Text textStyle="h6" fontWeight="599">
              Products URL
            </Text>
            <Text textStyle="h5" color="var(--grey-700)" onClick={()=>{window.open(feedUrl?.data[0]?.facebook?.products_url)}}
              cursor="pointer">
            {feedUrl?.data[0]?.facebook?.products_url??'-'}
            </Text>
          </VStack>}
        </VStack>
        <VStack w="50%" alignItems="flex-start">
          <HStack>
          <TiktokLogo />
            <Text fontWeight="700"> Tiktok Inventory Feed URL</Text>
          </HStack>
         {feedUrl?.data[0]?.tiktok?.used_cars_url&& <VStack gap="0.25rem" alignItems="flex-start">
         <Text
              textStyle="h6"
              fontWeight="599"
              textDecoration="underline"
              onClick={()=>openModal("used","tiktok")}
              cursor="pointer"
            >
              Used Cars Preview Tiktok Feed
            </Text>
            <Text textStyle="h5" color="var(--grey-700)"onClick={()=>{window.open(feedUrl?.data[0]?.tiktok?.used_cars_url)}}
              cursor="pointer">
            {feedUrl?.data[0]?.tiktok?.used_cars_url??'-'}
            </Text>
          </VStack>}
          {feedUrl?.data[0]?.tiktok?.new_cars_url&&<VStack gap="0.25rem" alignItems="flex-start">
            <Text
              textStyle="h6"
              fontWeight="599"
              textDecoration="underline"
              onClick={()=>openModal("new","tiktok")}
              cursor="pointer"
            >
              New Cars Preview Tiktok Feed
            </Text>
            <Text textStyle="h5" color="var(--grey-700)" onClick={()=>{window.open(feedUrl?.data[0]?.tiktok?.new_cars_url)}}
              cursor="pointer">
              {feedUrl?.data[0]?.tiktok?.new_cars_url??'-'}
            </Text>
          </VStack>}
          {feedUrl?.data[0]?.tiktok?.products_url&&<VStack gap="0.25rem" alignItems="flex-start">
            <Text textStyle="h6" fontWeight="599">
              Products URL
            </Text>
            <Text textStyle="h5" color="var(--grey-700)"onClick={()=>{window.open(feedUrl?.data[0]?.tiktok?.products_url)}}
              cursor="pointer">
            {feedUrl?.data[0]?.tiktok?.products_url??'-'}
            </Text>
          </VStack>}
        </VStack>
      </HStack>
    </VStack>
  </VStack>  
  {isOpen&&<PreviewModal isOpen={isOpen}onClose={onClose} type={type} media={media}/>}

  </>)
}
