import { Controller } from "react-hook-form";
import DatePicker from "../../../components/common/DateRangePicker";

const DateInput = ({ control, label }: any) => (
  <Controller
    name="date"
    control={control}
    render={({ field }) => (
      <DatePicker {...field} shouldShowClear label={label} w="100%" />
    )}
  />
);

export default DateInput;
