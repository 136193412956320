import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Loader } from "@giphy/react-components";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getScripts } from "../../../utils/api/scripts.api";
import { useTwilioDispatch } from "../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import NoItem from "../../LeadCenter/LeadDetails/DetailTabs/History/NoItem";
import { Drawer } from "../../common";

type DialerSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
};

function DialerSettings({ isOpen, onClose }: DialerSettingsProps) {
  const [page, setPage] = useState<number>(1);
  const [scripts, setScripts] = useState<any>([]);

  const dispatch = useTwilioDispatch();

  const user = useUserInfo("user");

  const { data: scriptsData, isLoading } = useQuery({
    queryKey: ["scriptsapi", user?.dealership?.id, page, 10],
    queryFn: getScripts,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data: any) => {
      setScripts((prev: any) => [...prev, ...(data?.data?.scripts || [])]);
    },
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Scripts">
      <VStack padding="1rem" w="100%" alignItems="flex-start" gap="1rem">
        {isLoading && <Loader />}
        {scripts.length ? <></> : <NoItem />}
        <div
          id="powerDialerScripts"
          style={{
            height: "calc(100vh - 284px)",
            overflow: "auto",
            width: "100%",
          }}
        >
          <InfiniteScroll
            dataLength={scripts?.length || 0}
            next={() => {
              setPage((prev: any) => prev + 1);
            }}
            hasMore={
              (scripts.length || 0) < (scriptsData?.data?.total_records || 0)
            }
            loader={<h4>Loading...</h4>}
            scrollableTarget={"powerDialerScripts"}
          >
            <VStack gap="0">
              {scripts?.map((item: any, index: number) => (
                <Box
                  onClick={() => {
                    dispatch({
                      type: "SET_SCRIPT_PARAMS",
                      payload: {
                        scriptParams: {
                          description: item?.description,
                          title: item?.title,
                          open: true,
                        },
                      },
                    });
                  }}
                  color="var(--primary-600)"
                  fontWeight="500"
                  w="100%"
                  padding="1rem"
                  cursor="pointer"
                  borderBottom="1px solid var(--grey-300)"
                >
                  <HStack alignItems="flex-start" gap="0.75rem">
                    <Text
                      textStyle="h5"
                      fontWeight="600"
                      color="var(--grey-800)"
                    >
                      {item.title}
                    </Text>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </InfiniteScroll>
        </div>
      </VStack>
    </Drawer>
  );
}

export default DialerSettings;
