import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getScripts = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/scripts?page=${queryKey[2]}&per_page=${queryKey[3]}`
  );
  export const getScriptsCategory = async ({
    queryKey,
  }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/scripts/script_categories`
    );
export const createScript = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload?.id}/scripts`,
    payload.data
  );


export const editScript = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload?.id}/scripts/${payload?.script_id}`,
    payload.data
  );
export const deleteScript = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload?.id}/scripts/${payload?.script_id}`
  );
