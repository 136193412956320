import { Button, DrawerFooter, VStack, Text } from "@chakra-ui/react";
import { Drawer } from "../../common";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { deleteInventoryFeedProvider } from "../../../utils/api/global-config.api";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {}

const DeleteInventoryFeedProvider = (props: data) => {
  const { isOpen, onClose, refetchParent, id } = props;
  const toast = useToast();

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await deleteInventoryFeedProvider(id);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Inventory feed provider deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error deleting inventory feed provider: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {};

    mutation.mutate(requestData);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Inventory Feed Provider"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to delete the inventory feed provider?
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default DeleteInventoryFeedProvider;
