import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Input, Spinner } from "../../../components/common";
import SwitchToggle from "../../../components/common/Switch";
import {
  fetchSettings,
  updateSettings,
} from "../../../utils/api/dealership-setting.api";
import { TimeIcon } from "../../icons/svg";
import {
  BroadcastSettingsForm,
  broadcastSettingsValidation,
  defaultValues,
} from "./utils";

const BroadcastSettings = () => {
  const { id: userIdFromParam } = useParams();
  const toast = useToast();

  const { handleSubmit, control, setValue, reset } =
    useForm<BroadcastSettingsForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(broadcastSettingsValidation) as any,
    });


  const weekend_timings = useWatch({ control, name: "weekend_timings" });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateSettings(
          payload,
          userIdFromParam,
          "Dealership Settings/Broadcast Settings"
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const handleFormSubmit = (values: BroadcastSettingsForm) => {
    const requestData = {
      key: "broadcast_settings",
      settings_hash: {
        business_hours: values,
      },
    };

    mutation.mutate(requestData);
  };

  const { isLoading } = useQuery({
    queryKey: ["fetchBCSettings", userIdFromParam],
    queryFn: () => fetchSettings(userIdFromParam, "broadcast_settings"),
    retry: false,
    onSuccess: (data) => {
      reset(data?.settings_hash?.business_hours);
    },
  });

  return (
    <VStack
      position="relative"
      pb="73px"
      h="calc(100vh - 276px)"
      w="100%"
      alignItems="flex-start"
    >
      <HStack gap="1rem" alignItems="flex-start" w="100%">
        <VStack w="50%" gap="0.62rem">
          <Box
            padding="1rem"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500" mb="1rem">
              Choose start and end time
            </Text>
            <HStack
              gap="0.31rem"
              mb="1rem"
              alignItems="center"
              w="100%"
              height="5rem"
              sx={{
                ".input-wrapper": {
                  height: "5rem",
                },
              }}
            >
              <Text textStyle="h6" fontWeight="500" maxW="10rem" w="100%">
                Monday - Friday:
              </Text>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      error={error?.message}
                      label="Start Time"
                      onChange={onChange}
                      value={value}
                      type="time"
                      hasIcon={true}
                      isIconRight={true}
                      icon={<TimeIcon />}
                      maxW="15rem"
                    />
                  );
                }}
                name="week_start_time"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      error={error?.message || ""}
                      label="End Time"
                      onChange={onChange}
                      value={value}
                      type="time"
                      hasIcon={true}
                      isIconRight={true}
                      icon={<TimeIcon />}
                      maxW="15rem"
                    />
                  );
                }}
                name="week_end_time"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </HStack>
            <HStack
              gap="0.31rem"
              alignItems="center"
              width="100%"
              height="5rem"
              sx={{
                ".chakra-form-control": {
                  width: "100%",
                  maxWidth: "10rem",
                },
                ".input-wrapper": {
                  height: "5rem",
                },
              }}
            >
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      id="1"
                      label="Weekend Timings"
                      defaultChecked={value}
                      onChange={(check: boolean) =>
                        setValue("weekend_timings", check)
                      }
                    />
                  );
                }}
                name="weekend_timings"
                control={control}
                rules={{
                  required: true,
                }}
              />
              {weekend_timings && (
                <>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          error={error?.message}
                          label="Start Time"
                          onChange={onChange}
                          value={value}
                          type="time"
                          hasIcon={true}
                          isIconRight={true}
                          icon={<TimeIcon />}
                          maxW="15rem"
                        />
                      );
                    }}
                    name="weekend_start_time"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          error={error?.message}
                          label="End Time"
                          onChange={onChange}
                          value={value}
                          type="time"
                          hasIcon={true}
                          isIconRight={true}
                          icon={<TimeIcon />}
                          maxW="15rem"
                        />
                      );
                    }}
                    name="weekend_end_time"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </>
              )}
            </HStack>
          </Box>
        </VStack>
      </HStack>
      <HStack
        position="absolute"
        bottom="0"
        w="100%"
        right="0"
        background="white"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
      >
        <Button
          width="8rem"
          onClick={() => handleSubmit((values) => handleFormSubmit(values))()}
        >
          Save
        </Button>
      </HStack>
      {(isLoading || mutation?.isLoading) && <Spinner />}
    </VStack>
  );
};

export default BroadcastSettings;
