import { useAuthState } from "../context/AuthContext";

interface User {
  token: string;
  form: string;
  user: string;
  // Add other properties as needed
}

export const useUserInfo = (desiredStatus?: string): any | undefined => {
  let user = useAuthState();

  const localStorageUser = JSON.parse(
    localStorage.getItem("currentUser") || "{}"
  );

  const combinedAttributes = { ...((user?.user as any) || {}), ...(localStorageUser || {}) };

  user={ ...(user || {}), user: combinedAttributes  }

  return desiredStatus
    ? (user as any)[desiredStatus] ?? (combinedAttributes as any)?.[desiredStatus]
    : combinedAttributes;
};
