import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getBDCCampaign = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
  );
export const getBDCCampaignByID = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}`
  );
export const createCategories = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/categories`,
    payload.payload
  );
export const updateCategories = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/categories/${payload.category_id}`,
    payload.payload
  );

export const createBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns`,
    payload.payload
  );
export const updateBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
    payload.payload
  );
export const duplicateBDCcampaign = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/duplicate`,
    payload.payload
  );
export const getOutbooundLine = async (params: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/v1/lines/search`, {
    params,
  });
export const getCategories = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/categories?is_ro_tradeup=${queryKey[2]}`
  );
export const checkLeadSource = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/check_conflicting_lead_source`,
    payload.payload
  );
export const updateStatus = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.campaign_id}/toggle_active`
  );
export const createActivity = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities`,
    payload.payload
  );
export const getActivity = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/activities`
  );
export const deleteActivity = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`
  );
export const editActivity = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
    payload.payload
  );
export const getLedger = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/ledgers?event=${queryKey[3]}&time=${queryKey[4]}&q=${queryKey[5]}&page=${queryKey[6]}&per_page=${queryKey[7]}`
  );
export const getEnrolledContacts = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/enrolled_contacts?q=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}&type=${queryKey[6]}`
  );
export const getRingGroupUsers = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/v1/ring_groups/ring_groups_users?ids=${queryKey[1]}&page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );

// Global Dealership Settings APIs

export const adminDealershipAPIs = {
  createCategories: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/categories`,
      payload.payload
    ),
  getCategories: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/categories?is_ro_tradeup=${queryKey[2]}`
    ),
  updateCategories: async (payload: any): Promise<ApiResult> =>
    axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/categories/${payload.category_id}`,
      payload.payload
    ),
  getBDCCampaign: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
    ),
  createBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns`,
      payload.payload
    ),
  getBDCCampaignByID: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}`
    ),
  updateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}`,
      payload.payload
    ),
  duplicateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/duplicate`,
      payload.payload
    ),
  checkLeadSource: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/check_conflicting_lead_source`,
      payload.payload
    ),
  updateStatus: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.campaign_id}/toggle_active`
    ),
  createActivity: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities`,
      payload.payload
    ),
  getActivity: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/activities`
    ),

  deleteActivity: async (payload: any): Promise<ApiResult> =>
    axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`
    ),
  editActivity: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      payload.payload
    ),
  getLedger: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/ledgers?event=${queryKey[3]}&time=${queryKey[4]}&q=${queryKey[5]}&page=${queryKey[6]}&per_page=${queryKey[7]}`
    ),
  getEnrolledContacts: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/bdc_campaigns/${queryKey[2]}/enrolled_contacts?q=${queryKey[3]}&page=${queryKey[4]}&per_page=${queryKey[5]}&type=${queryKey[6]}`
    ),
};

// Global BDC campaign APIs
export const globalBDCApis = {
  createCategories: async (payload: any): Promise<ApiResult> =>
    axios.post(`${SERVICES.apiBaseUrl}/api/admin/categories`, payload.payload),
  getCategories: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/categories?is_ro_tradeup=${queryKey[2]}`
    ),
  updateCategories: async (payload: any): Promise<ApiResult> =>
    axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/categories/${payload.category_id}`,
      payload.payload
    ),
  getBDCCampaign: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}&is_ro_tradeup=${queryKey[5]}`
    ),
  createBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns`,
      payload.payload
    ),
  getBDCCampaignByID: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(`${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${queryKey[2]}`),
  updateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}`,
      payload.payload
    ),
  duplicateBDCcampaign: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/duplicate`,
      payload.payload
    ),
  updateStatus: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.campaign_id}/toggle_active`
    ),
  createActivity: async (payload: any): Promise<ApiResult> =>
    axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities`,
      payload.payload
    ),
  getActivity: async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${queryKey[2]}/activities`
    ),

  deleteActivity: async (payload: any): Promise<ApiResult> =>
    axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`
    ),
  editActivity: async (payload: any): Promise<ApiResult> =>
    axios.put(
      `${SERVICES.apiBaseUrl}/api/admin/bdc_campaigns/${payload.bdc_id}/activities/${payload.activity_id}`,
      payload.payload
    ),
};
