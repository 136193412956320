import Calendar from "react-calendar";
interface SmallCalendarProps {
  onChange?: any;
  value: any;
}

const SmallCalendar = ({ onChange, value }: SmallCalendarProps) => {

  return <Calendar onChange={onChange} value={value}/>;
};

export default SmallCalendar;
