import axios from "axios";
import { SERVICES } from "../../config";
import {
  DocumentApiRequest,
  DocumentRemoveApiRequest,
  NotesApiRequest,
} from "../../components/GlobalDashboard/BrandListing/utils";
import { CreateBrandForm } from "../../components/GlobalDashboard/CreateBrand/util";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//brand apis
export const getBrands = async (params: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/brands`, { params });

export const getBrand = async (id: string): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/brands/${id}`);

export const addBrandNotes = async (
  payload: NotesApiRequest
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/brands/${payload.id}/add_notes?notes=${payload.notes}`, undefined, 
    {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Brand Registration',
      }
    }
  );
export const removeBrandDocuments = async (
  payload: DocumentRemoveApiRequest
): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/brands/${payload.id}/remove_file?attachment_id=${payload.attachmentId}`, {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Brand Registration',
      }
    }
  );
export const addBrandDocuments = async (
  payload: DocumentApiRequest
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/brands/${payload.id}/attach_file`,
    payload.file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Brand Registration',
      },
    }
  );
export const updateBrand = async (
  payload: CreateBrandForm
): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/brands/${payload.id}`,
    {
      brand: payload,
    },
    {
      headers: {
        menu:'Brand and Campaign Registration',
        "Sub-Menu":'Brand Registration',
      },
    }
  );
