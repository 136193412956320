import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import React, { useRef } from "react";

interface ImageUploaderProps {
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  maxImages: number;
  isDisabled?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  images,
  setImages,
  maxImages,
  isDisabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);

    if (images?.length + files.length > maxImages) {
      alert(`You can only upload ${maxImages} images`);
      return;
    }

    const imageFiles = files.filter((file) => file.type.startsWith("image/"));

    if (imageFiles.length === 0) {
      alert("Please select valid image files.");
      return;
    }

    setImages([...images, ...imageFiles]);
  };

  return (
    <HStack width="100%">
      <input
        type="file"
        accept="image/*"
        multiple
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <Button
        width="100%"
        onClick={() => inputRef.current?.click()}
        isDisabled={isDisabled}
      >
        Select Images
      </Button>
    </HStack>
  );
};

export default ImageUploader;
