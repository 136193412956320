import { Box, FormLabel, Text, VStack } from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface PhoneNumberInputProps {
  label?: string;
  onChange: (value: any) => void;
  placeholder?: string;
  isRequired?: boolean;
  error?: string;
  value?: string;
  disabled?: boolean;
  isSmall?: boolean;
  styles?: any;
  containerStyles?: any;
  w?: string;
  padding?: string;
  onKeyDown?: (event: any) => void;
}

const PhoneNumberInput = ({
  label,
  onChange,
  placeholder = "Enter Phone No",
  isRequired = false,
  error,
  value,
  disabled,
  isSmall,
  styles = {},
  containerStyles = {},
  w = "100%",
  padding = "8px 14px",
  ...rest
}: PhoneNumberInputProps) => {
  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      sx={{
        ".PhoneInput": {
          padding: padding ? padding : isSmall ? "8px 8px" : "12px 14px",
          border: `1px solid ${error ? "var(--red-600)" : "var(--grey-300)"}`,
          borderRadius: "0.5rem",
          background: disabled ? "var(--grey-200)" : "white",
          width: w,
          ...styles,
        },
        ".PhoneInputInput:focus-visible": {
          outline: "none",
        },
        // Hides the flag
        ".PhoneInputCountry": {
          display: "none",
        },
      }}
      {...containerStyles}
    >
      {label && (
        <FormLabel
          m="0"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          {label}
          {isRequired && (
            <Box as="span" ml="0.25rem" color="var(--red-600)">
              *
            </Box>
          )}
        </FormLabel>
      )}
      <PhoneInput
        addInternationalOption={false}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        {...rest}
        defaultCountry="US" // Set default country to US
        countries={["US", "CA"]}
      />
      {error && (
        <Text textStyle="captionSmall" color="var(--red-600)" marginLeft="1px">
          {error}
        </Text>
      )}
    </VStack>
  );
};

export default PhoneNumberInput;
