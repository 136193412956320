import { Button, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import SlideRange from "../../../components/common/SlideRange";
import Loader from "../../../components/common/Spinner";
import {
  AttachIconSimple,
  SimpleDateIcon,
  UploadMediaIcon,
} from "../../../components/icons/svg";
import NoItem from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/NoItem";
import { fetchDocuments } from "../../../utils/api/guest-mode";
import { AddDocument } from "./AddDocument";

const GuestModeDocuments = () => {
  const { id, dId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [days, setDays] = useState(30);
  const [documentData, setDocumentData] = useState<any>([]);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["documents", dId, page, "10", days, id],
    queryFn: fetchDocuments,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess: (data: any) => {
      setDocumentData((prevData: any) => {
        const newData = data?.data?.documents;
        const existingIds = prevData?.map((item: any) => item.id);
        const filteredNew = newData?.filter(
          (item: any) => !existingIds.includes(item.id)
        );
        const mergedData = [...prevData, ...filteredNew];
        // Sort mergedData based on the created_at field in ascending order
        mergedData?.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        return mergedData;
      });
    },
  });

  const handleDownload = (documentUrl: any) => {
    fetch(documentUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<any>("a");
        link.href = url;
        const extension = documentUrl.split(".").pop().split("?")[0];
        link.setAttribute("download", `${"name"}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
        // Handle error
      });
  };
  const sliderChange = (val: any) => {
    setDays(val);
    setDocumentData([]);
    setPage(1);
  };
  return (
    <VStack w="100%">
      <VStack
        h="100%"
        // background="var(--grey-50)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-end"
        w="100%"
        maxW="1390px"
        borderTopRightRadius="1rem"
      >
        <VStack
          background="white"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
          alignItems="flex-start"
          gap="0"
          h="calc(100vh - 130px)"
          overflow="auto"
          w="100%"
        >
          <HStack
            w="100%"
            justifyContent="space-between"
            pb="1rem"
            borderBottom="1px solid var(--grey-300)"
          >
            <Text textStyle="h4" fontWeight="500">
              Documents
            </Text>
            <Button
              variant="outline"
              padding="0.5rem"
              minW="auto"
              sx={{
                svg: {
                  w: "1.25rem",
                  h: "1.25rem",
                },
              }}
              onClick={onOpen}
            >
              <UploadMediaIcon />
            </Button>
          </HStack>
          <HStack
            background="var(--grey-200)"
            padding={{ md: "1rem", base: "0.5rem" }}
            w="100%"
          >
            <SlideRange handleClick={sliderChange} rangeWidth={true} />
          </HStack>{" "}
          <VStack alignItems="flex-start" gap="1rem" w="100%" mt="1rem">
            <VStack alignItems="flex-start" gap="1rem" w="100%">
              <div
                id="scrollableDivDoc"
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100vh - 345px)",
                }}
              >
                <InfiniteScroll
                  dataLength={documentData?.length}
                  next={() => {
                    setPage((prev) => prev + 1);
                  }}
                  hasMore={documentData?.length < data?.data?.document_count}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDivDoc"
                >
                  <VStack>
                    {documentData?.map((item: any) => (
                      <VStack
                        w="100%"
                        alignItems="flex-start"
                        key={item}
                        border="1px solid var(--grey-300)"
                        borderRadius="0.5rem"
                        justifyContent="space-between"
                        minWidth={"100%"}
                      >
                        <HStack
                          w="100%"
                          justifyContent="space-between"
                          padding="1rem"
                          background="var(--primary-50)"
                        >
                          <Text textStyle="h5" fontWeight="600">
                            Document Name
                          </Text>
                        </HStack>
                        <HStack
                          w="100%"
                          justifyContent="space-between"
                          padding="1rem"
                          alignItems="flex-start"
                        >
                          <VStack alignItems="flex-start">
                            <HStack
                              gap="0.25rem"
                              onClick={() => handleDownload(item?.document_url)}
                              cursor="pointer"
                            >
                              <AttachIconSimple />
                              <Text textStyle="h6" color="var(--primary-600)">
                                {item?.name}
                              </Text>
                            </HStack>
                          </VStack>
                          <VStack alignItems="flex-end">
                            <HStack gap="0.5rem">
                              <SimpleDateIcon />
                              <Text textStyle="h6" fontWeight="600">
                                {moment(item?.created_at).format(
                                  "dddd D MMMM YY • h:mm a"
                                )}
                              </Text>
                            </HStack>
                          </VStack>
                        </HStack>
                      </VStack>
                    ))}
                  </VStack>
                </InfiniteScroll>
                {documentData?.length === 0 && !isFetching && !isLoading && (
                  <NoItem />
                )}
              </div>
            </VStack>
          </VStack>
        </VStack>
        {isOpen && (
          <AddDocument
            isOpen={isOpen}
            onClose={onClose}
            refetch={() => {
              setDocumentData([]);
              setPage(1);
              refetch();
            }}
          />
        )}
        {isFetching && <Loader />}
      </VStack>
    </VStack>
  );
};

export default GuestModeDocuments;
