import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    borderRadius: "0.5rem",
    color: "grey.900",
    p: "0.75rem 0.88rem",
    _hover: {
      borderColor: "primary.default",
    },
    _expanded: {
      bg: "grey.default",
    },
  },
  list: {
    borderRadius: "8px",
    border: "1px solid var(--grey-200)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    background: "white",
  },
  item: {
    color: "grey.900",
    borderBottomColor: "grey.200",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
    padding: "0.75rem",
    cursor: "pointer",
    borderRadius: "0.5px",
    borderBottom: "1px solid var(--grey-200)",
    _hover: {
      bg: "grey.50",
      borderLeft: "2px solid var(--primary-default)",
      cursor: "pointer",
    },
    _focus: {
      bg: "grey.100",
    },
    _last: {
      borderBottom: "none",
    },
  },
  //   groupTitle: {
  //     textTransform: "uppercase",
  //     color: "white",
  //     textAlign: "center",
  //     letterSpacing: "wider",
  //     opacity: "0.7",
  //   },
  //   command: {
  //     opacity: "0.8",
  //     fontFamily: "mono",
  //     fontSize: "sm",
  //     letterSpacing: "tighter",
  //     pl: "4",
  //   },
  //   divider: {
  //     my: "4",
  //     borderColor: "white",
  //     // borderBottom: "2px dotted",
  //   },
});

// define custom styles
const lg = defineStyle({
  fontSize: "md",
  my: "1",
});

const xl = defineStyle({
  fontSize: "lg",
  px: "4",
  py: "2",
});

const button = defineStyle({
  border: "1px solid var(--grey-300)",
});

// define custom sizes
const sizes = {
  // apply custom styles to parts
  lg: definePartsStyle({
    button,
    item: xl,
    groupTitle: lg,
    command: xl,
  }),
};

// define custom variants
const variants = {
  default: definePartsStyle({
    button: {
      border: "1px solid red",
    },
  }),
  //   roundLeft: definePartsStyle({
  //     button: {
  //       borderLeftRadius: "full",
  //       pl: "6",
  //     },
  //   }),
  //   roundRight: definePartsStyle({
  //     button: {
  //       borderRightRadius: "full",
  //       pr: "6",
  //     },
  //   }),
};

// export the component theme
export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    // define which size is applied by default
    // size: "lg",
    variant: "default",
  },
});
