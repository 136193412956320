import {
  HStack,
  Text,
  VStack,
  Image,
  Button,
  useToast,
  InputGroup,
  InputRightElement,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { Input, Spinner } from "../common";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useParams } from "react-router-dom";
import {
  fetchUserById,
  resetPassword,
  updateUserForProfileSettings,
} from "../../utils/api/users.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isValidName, validatePassword } from "../../utils";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HideViewIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { update, useAuthDispatch } from "../../utils/context/AuthContext";
import { fetchEmailById } from "../../utils/api/contact-details";
import { useBreadcrumb } from "../../utils/context/BreadCrumsContext/BreadcrumbContext";

interface ApiResult {
  data: any;
}
interface ApiRequest {
  user_id: number;
  password?: string;
  old_password: string;
}
interface ApiRequestSave {
  user: {
    first_name: string;
    last_name: string;
    image?: File | null;
  };
}

export const ProfileSetting = (props: any) => {
  const { id: userIdFromParam } = useParams();
  const { setBreadcrumb } = useBreadcrumb();
  const dispatch = useAuthDispatch();
  const userID = useUserInfo("id");
  const dealer = useUserInfo("dealership");
  const [passwordView, setPasswordView] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [reEnteredPass, setReEnteredPass] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  let user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : "";
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  //...........api handling...........
  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request) => {
      try {
        const response = await resetPassword(request, {
          menu: "User",
          "Sub-Menu": "Profile Settings",
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      setPasswordView(false);
      toast({
        description: "Password reset link sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      setPasswordView(true);
      toast({
        description: `Error reseting password: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["profilesettings", userID, userIdFromParam],
    queryFn: () => fetchUserById(userIdFromParam ?? userID),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setFirstName(data?.first_name);
      setLastName(data?.last_name);
      setImageUrl(data?.image_url);
      setBreadcrumb(data?.first_name+' '+data?.last_name);
    },
  });

  const { data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail", dealer?.id],
    queryFn: () => fetchEmailById(dealer?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};

  const handleSubmit = () => {
    if (!validatePassword(newPass) || !validatePassword(reEnteredPass)) {
      setErrorMsg(
        "Password must be a minimum of 8 characters long and should include both a letter and a number."
      );
      setPasswordsMatch(false);
    } else if (newPass !== reEnteredPass) {
      setErrorMsg("Passwords don't match.");
      setPasswordsMatch(false);
    } else {
      const requestData: ApiRequest = {
        user_id: Number(userIdFromParam ?? userID),
        password: reEnteredPass,
        old_password: oldPass,
      };

      mutation.mutate(requestData);
    }
  };

  const mutationSave = useMutation<ApiResult, Error, ApiRequestSave, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateUserForProfileSettings(
          userIdFromParam ?? userID,
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      user.first_name = firstName;
      user.last_name = lastName;
      localStorage.setItem("currentUser", JSON.stringify(user));
      update(dispatch, { key: "user", value: user });

      refetch();
      toast({
        description: "User edited successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error editing user: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const validateFileType = (file: File) => {
    const validImageTypes = ["image/jpeg", "image/png"];
    return validImageTypes.includes(file.type);
  };

  const validateFileSize = (file: File) => {
    return file.size <= 10 * 1024 * 1024;
  };
  const handleSubmitSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let fnameError = isValidName(firstName, setNameError, "First Name");
    let lnameError = isValidName(lastName, setLastNameError, "Last Name");

    if (!fnameError || !lnameError) {
      return;
    }

    if (thumbnailFile && !validateFileType(thumbnailFile)) {
      toast({
        description: "Invalid file type. Please upload a valid image file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (thumbnailFile && !validateFileSize(thumbnailFile)) {
      toast({
        description: "File size exceeds 10 MB limit.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const requestData: ApiRequestSave = {
      user: {
        first_name: firstName,
        last_name: lastName,
        image: thumbnailFile,
      },
    };

    mutationSave.mutate(requestData);
  };

  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (event: ChangeEvent) => {
    const file = event.target.files?.[0];
    if (file) {
      setThumbnailFile(file);
    }
  };
  return (
    <HStack
      gap="0.75rem"
      alignItems="flex-start"
      w="100%"
      position="relative"
      height="100%"
    >
      <VStack gap="1rem" w="50%" alignItems="flex-start">
        <VStack
          background="var(--grey-50)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
          gap="1rem"
          alignItems="flex-start"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500">
            User Information
          </Text>
          <VStack w="100%">
            <label htmlFor="fileInput">
             {(thumbnailFile||imageUrl)?<Image
                src={
                  thumbnailFile
                    ? URL.createObjectURL(thumbnailFile)
                    : imageUrl 
                }
                alt="avatar"
                width="4.3rem"
                height="4.3rem"
                borderRadius="50%"
                cursor="pointer"
              />:<AccountCircleIcon style={{ width: '4.3rem', height: '4.3rem' }}/>}
            </label>
            <Input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Input
              label="First Name"
              type="text"
              maxW="lg"
              minW="auto"
              isRequired={true}
              value={firstName}
              error={nameError}
              onChange={(e: any) => {
                setNameError("");
                setFirstName(e.target.value);
              }}
              maxLength={100}
            />
            <Input
              label="Last Name"
              type="text"
              maxW="lg"
              minW="auto"
              isRequired={true}
              value={lastName}
              error={lastNameError}
              onChange={(e: any) => {
                setLastNameError("");
                setLastName(e.target.value);
              }}
              maxLength={100}
            />

            <Input
              label="Email"
              type="text"
              maxW="lg"
              minW="auto"
              value={user.email}
              isDisabled
              maxLength={100}
            />

            {userEmail && <Input
              label="User Dealership Email"
              type="text"
              maxW="lg"
              minW="auto"
              value={userEmail}
              isDisabled
              maxLength={100}
            />}
          </VStack>
        </VStack>
      </VStack>
      <Box w="50%">
        <VStack w="100%" gap="1rem">
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            gap="1rem"
          >
            <Text textStyle="h4" fontWeight="500">
              Change Password:
            </Text>
            <Text textStyle="h6" fontWeight="600" color="var(--grey-800)">
              Do you want to change your password?
            </Text>
            {!passwordView && (
              <Button
                onClick={() => {
                  setErrorMsg("");
                  setReEnteredPass("");
                  setNewPass("");
                  setPasswordView(true);
                }}
                isDisabled={!props?.allowed}
              >
                Change Password
              </Button>
            )}
            {passwordView && (
              <VStack w="100%">
                {!passwordsMatch && (
                  <Text textStyle="caption" color="var(--red-500)">
                    {errorMsg}
                  </Text>
                )}
                <InputGroup>
                  <Input
                    type={showPasswordOld ? "text" : "password"}
                    label="Old Password"
                    placeholder="Enter Old Password"
                    maxW="100%"
                    onChange={(e) => setOldPass(e.target.value)}
                    value={oldPass}
                    maxLength={100}
                  />

                  <InputRightElement
                    onClick={() => setShowPasswordOld(!showPasswordOld)}
                    style={{ top: "20px" }}
                  >
                    {showPasswordOld ? (
                      <HideViewIcon sx={{ color: "var(--grey-500)" }} />
                    ) : (
                      <ViewIcon sx={{ color: "var(--grey-500)" }} />
                    )}
                  </InputRightElement>
                </InputGroup>

                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    placeholder="Enter New Password"
                    maxW="100%"
                    onChange={(e) => setNewPass(e.target.value)}
                    value={newPass}
                    maxLength={100}
                  />

                  <InputRightElement
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ top: "20px" }}
                  >
                    {showPassword ? (
                      <HideViewIcon sx={{ color: "var(--grey-500)" }} />
                    ) : (
                      <ViewIcon sx={{ color: "var(--grey-500)" }} />
                    )}
                  </InputRightElement>
                </InputGroup>

                <InputGroup>
                  <Input
                    type={showPasswordConfirm ? "text" : "password"}
                    label="Password Confirmation"
                    placeholder="Enter Password Again"
                    maxW="100%"
                    onChange={(e) => setReEnteredPass(e.target.value)}
                    value={reEnteredPass}
                    maxLength={100}
                  />
                  <InputRightElement
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    style={{ top: "20px" }}
                  >
                    {showPasswordConfirm ? (
                      <HideViewIcon sx={{ color: "var(--grey-500)" }} />
                    ) : (
                      <ViewIcon sx={{ color: "var(--grey-500)" }} />
                    )}
                  </InputRightElement>
                </InputGroup>

                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  alignSelf="flex-end"
                  isDisabled={!props?.allowed}
                >
                  Save New Password
                </Button>
              </VStack>
            )}
          </VStack>
        </VStack>
      </Box>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        zIndex="0"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Button
          width="8rem"
          onClick={handleSubmitSave}
          isDisabled={!props?.allowed}
        >
          Save
        </Button>
      </HStack>

      {(isLoading || mutation.isLoading || mutationSave.isLoading) && (
        <Spinner />
      )}
    </HStack>
  );
};
