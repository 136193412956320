"use client";

import { Drawer, Select } from "../../../components/common";
import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  useToast,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { assignContact, fetchTagById } from "../../../utils/api/tags";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { InfoIcon } from "../../../components/icons/svg";
import { useState } from "react";

const EnrollContact = (props: any) => {
  const { onClose, id } = props;
  const toast = useToast();
  const dealerId = useUserInfo("dealership");
  const { data, isLoading } = useQuery({
    queryKey: ["fetchTagById"],
    queryFn: () => fetchTagById(dealerId?.id, id),
  });
  const [campaign, setCampaign] = useState<SelectOptionProps | null>(null);
  const [isEnrolled, setIsEnrolled] = useState(false);

  const mutation = useMutation(assignContact);

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      isLoading={isLoading || mutation?.isLoading}
      title={
        <Box>
          Enrol Contacts{" "}
          <Text
            textStyle="h6"
            color="var(--grey-600)"
            fontWeight="400"
            mt="0.12rem"
          >
            Choose the sequence you want this tagged contacts to enrol.
          </Text>
        </Box>
      }
    >
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Select
            label="Select Campaign"
            onChange={(value: any) => setCampaign(value)}
            value={campaign}
            placeholder="Select"
            options={data?.data}
            variant="default"
            w="100%"
            isMulti={true}
          />
        </Box>
        <Stack direction="column" gap="1.25rem">
          <Checkbox
            variant="whiteCheck"
            defaultChecked={false}
            isChecked={isEnrolled}
            onChange={(event) => {
              setIsEnrolled(event.target.checked);
            }}
          >
            Contacts that are already enrolled in a sequence will not be
            enrolled.
          </Checkbox>
          <Stack direction="row">
            <InfoIcon color="var(--primary-600)" />
            <Text textStyle="h6">
              Contacts that has a DNC status will not be enrolled.
            </Text>
          </Stack>
        </Stack>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              mutation.mutate(
                {
                  dId: dealerId?.id,
                  tId: id,
                  payload: {
                    campaign: campaign,
                    is_distributed: isEnrolled,
                  },
                },
                {
                  onSuccess: (data: any) => {
                    onClose();
                    toast({
                      description: "Contacts enrolled successfully.",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                  onError: (error: any) => {
                    toast({
                      description: `Error enrolling contacts: ${
                        error?.response?.data?.errors?.toString() ??
                        "Something went wrong"
                      }`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                      position: "top",
                    });
                  },
                }
              );
            }}
          >
            Add Task
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};
export default EnrollContact;
