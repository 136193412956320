import { Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fileToDataUrl } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { attachFile, uploadImage } from "../../utils/api/lead.api";
import { RichTextEditorDocumentToolbar } from "../../config";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import Loader from "./Spinner";

type TextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  style?: any;
  toolbar?: any[][];
  editorRef?: any;
};

export default function TextEditor({
  value,
  onChange,
  error,
  style = {},
  toolbar = RichTextEditorDocumentToolbar,
  editorRef,
}: TextEditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);

  const user = useUserInfo("user");
  const dealership = user?.dealership;

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await attachFile({
          payload,
          dealershipId: dealership.id,
        });
        return response.data.url;
      } catch (error) {
        throw error;
      }
    },
  });

  const imageHandler = useCallback(async () => {
    try {
      const editor = quillRef?.current?.getEditor();
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      if (input) {
        input.onchange = async () => {
          const file = input?.files && (input?.files[0] as File);
          const base64 = await fileToDataUrl(file as File);
          if (/^image\//.test(file?.type as string)) {
            const formData = new FormData();
            formData.append("file", file as File);
            editor?.insertEmbed(
              editor?.getSelection()?.index as number,
              "image",
              base64
            );
            const res = await mutation.mutateAsync(formData);

            const imageElements = document.querySelectorAll(
              'img[src="' + base64 + '"]'
            );
            imageElements.forEach((imageElement) => {
              imageElement.setAttribute("src", res as string);
            });

            // editor?.setSelection({ index: 0, length: 0 }); // Clear the selection to prevent further image duplication
          } else {
            console.log("You can only upload images.");
          }
        };
      }
    } catch (error) {
      console.log(error, "Error uploading");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbar,
        handlers: {
          image: imageHandler,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageHandler]
  );

  useEffect(() => {
    if (quillRef.current) {
      if (editorRef) editorRef.current = quillRef;
    }
  }, [editorRef]);

  return (
    <>
      {mutation.isLoading && <Loader />}
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={modules}
        style={{
          width: "100%",
          minHeight: "70%",
          height: "100%",
          borderBottomRightRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          ...style,
        }}
      />
      {error && (
        <Text
          textStyle="captionSmall"
          color="var(--red-600)"
          style={{ display: "flex", alignItems: "flex-start", marginTop: 15 }}
        >
          {error}
        </Text>
      )}
    </>
  );
}
