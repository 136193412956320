import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
  fontWeight: 400,
  color: "primary.default",
  //   textStyle: "none",
});

export default defineStyleConfig({
  baseStyle,
});
