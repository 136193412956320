import React from "react";
import Box from "@mui/material/Box";

interface CircularProgressWithLabelProps {
  value: number; // Progress value (0-100)
  size: number; // Size of the progress circle
}

const ProgresBar: React.FC<CircularProgressWithLabelProps> = (props) => {
  const { value, size } = props;

  // Set a minimum size to ensure visibility
  const normalizedSize = Math.max(size, 50); // Minimum size of 50px
  const strokeWidth = normalizedSize * 0.1; // Stroke width as 10% of the size
  const radius = (normalizedSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = (value / size) * circumference;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: normalizedSize,
        height: normalizedSize,
      }}
    >
      <svg width={normalizedSize} height={normalizedSize}>
        <circle
          cx={normalizedSize / 2}
          cy={normalizedSize / 2}
          r={radius}
          stroke="#E3F2FD"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          cx={normalizedSize / 2}
          cy={normalizedSize / 2}
          r={radius}
          stroke="#4199F7"
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          strokeLinecap="round"
          transform={`rotate(-90 ${normalizedSize / 2} ${normalizedSize / 2})`}
        />
      </svg>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Uncomment if you want to show the value inside the circle */}
        {/* <Typography
          fontSize={normalizedSize * 0.25}
          fontWeight="700"
          component="div"
          color="#2C3E50"
        >
          {`${Math.round(value)}`}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ProgresBar;
