import { useEffect } from 'react';

const useIntercomScript = (appId) => {
  useEffect(() => {
    const loadIntercomScript = () => {
      if (window.Intercom) return; // Intercom is already loaded

      const script = document.createElement('script');
      script.src = `https://widget.intercom.io/widget/${appId}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.Intercom) {
          window.Intercom('boot', {
            app_id: appId,
          });
        }
      };

      script.onerror = () => {
        console.error('Failed to load Intercom script');
      };

      return () => {
        // Cleanup script if component unmounts
        document.body.removeChild(script);
      };
    };

    loadIntercomScript();
  }, [appId]);
};

export default useIntercomScript;
