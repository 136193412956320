import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Car from "../../assets/images/car.svg";
import { getDealershipInventory } from "../../utils/api/inventory-mapping.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Input, Modal } from "../common";
import { SearchIcon } from "../icons/svg";
import NoItem from "../LeadCenter/LeadDetails/DetailTabs/History/NoItem";
import { useSearchParams } from "react-router-dom";
import Loader from "../common/Spinner";

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleInventory: (text: string) => void;
};

function InventoryModal({ isOpen, onClose, handleInventory }: ImageModalProps) {
  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id } = dealership || {};

  const [searchParams] = useSearchParams();
  const dealershipId = id ? id : searchParams.get("dealership_id");
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [inventories, setInventories] = useState<any[]>([]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "dealershipInventoryApi",
      dealershipId,
      page,
      perPage,
      searchStr,
    ],
    queryFn: getDealershipInventory,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data: any) => {
      setInventories((prev: any) => [
        ...prev,
        ...(data?.data?.inventories || []),
      ]);
    },
  });

  const totalItems = data?.data?.total_count;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Pick Inventory">
        {!isLoading ? (
          <>
            <Box padding="0 20px">
              <Input
                type="text"
                hasIcon={true}
                isIconRight={false}
                icon={<SearchIcon />}
                placeholder="Search Inventories..."
                onChange={({ target: { value } }) => {
                  setSearchStr(value);
                  setPage(1);
                  setInventories([]);
                }}
                width="100%"
                value={searchStr}
              />
            </Box>
            <div
              id="inventoriesModal"
              style={{
                height: "calc(100vh - 415px)",
                overflow: "auto",
                padding: "20px",
                gap: 5,
              }}
            >
              <InfiniteScroll
                dataLength={totalItems}
                next={() => setPage((prev) => prev + 1)}
                hasMore={inventories?.length < totalItems}
                loader={<h4>Loading...</h4>}
                scrollableTarget="inventoriesModal"
              >
                {inventories.length
                  ? inventories?.map((item: any) => (
                      <HStack
                        key={item.id}
                        w="100%"
                        padding="1rem"
                        borderRadius="0.5rem"
                        border="1px solid var(--grey-300)"
                        alignItems="flex-start"
                        gap="1rem"
                        position="relative"
                        marginBottom="1rem"
                        cursor="pointer"
                        onClick={() => handleInventory(item.id)}
                      >
                        <VStack gap="0.5rem" mb="1rem" w="35%">
                          <Image
                            src={item?.images?.[0] || Car}
                            width={200}
                            height={150}
                            objectFit="cover"
                            alt="car Image"
                            style={{
                              borderRadius: "0.5rem",
                            }}
                          />
                        </VStack>
                        <VStack alignItems="flex-start" gap="0.5rem" w="65%">
                          <VStack
                            alignItems="flex-start"
                            w="100%"
                            gap="0.25rem"
                            pb="0.5rem"
                            borderBottom="1px solid var(--grey-300)"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <HStack paddingRight="1rem">
                                {item?.preferred && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    background="var(--flame-100)"
                                    color="var(--flame-600)"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Preferred
                                  </Text>
                                )}
                                {item?.status === "available" && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    color="#12c607"
                                    background="#d8f3d8"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Available
                                  </Text>
                                )}
                                {item?.status === "unavailable" && (
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    background="var(--flame-100)"
                                    color="var(--flame-600)"
                                    padding="0.25rem 0.75rem"
                                    borderRadius="200rem"
                                    mb="0.25rem"
                                  >
                                    Not Available
                                  </Text>
                                )}
                              </HStack>
                            </Box>

                            {!item?.inventory && (
                              <Text textStyle="h4" fontWeight="500">
                                {item.year} {item.make} - {item.model}
                              </Text>
                            )}
                            {item?.inventory && (
                              <Text textStyle="h4" fontWeight="500">
                                {item.inventory.year} {item.inventory.make} -{" "}
                                {item.inventory.model}
                              </Text>
                            )}
                            <HStack
                              alignItems="flex-end"
                              justifyContent="space-between"
                              w="100%"
                            >
                              <VStack alignItems="flex-start" gap="0">
                                <Text
                                  textStyle="captionSmall"
                                  fontWeight="500"
                                  color="var(--grey-500)"
                                >
                                  {item?.interior_color}
                                </Text>
                                <Text
                                  textStyle="captionSmall"
                                  fontWeight="500"
                                  color="var(--grey-500)"
                                >
                                  Mileage: {item?.mileage}
                                </Text>
                                <>
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    color="var(--grey-500)"
                                  >
                                    {item?.vin}
                                  </Text>
                                  <Text
                                    textStyle="captionSmall"
                                    fontWeight="500"
                                    color="var(--grey-500)"
                                  >
                                    Stock: {item?.stock_number}
                                  </Text>
                                </>
                              </VStack>
                            </HStack>
                          </VStack>
                        </VStack>
                      </HStack>
                    ))
                  : !isLoading && !isFetching && <NoItem />}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <>Loading...</>
        )}
      </Modal>
    </>
  );
}

export default InventoryModal;
