import { Controller } from "react-hook-form";
import { Input } from ".";

const ModelInput = ({ control }: any) => (
  <Controller
    name="model"
    control={control}
    render={({ field, fieldState: { error } }) => (
      <Input
        {...field}
        placeholder="Enter Model"
        label="Model"
        error={error?.message || ""}
        maxLength={200}
      />
    )}
  />
);

export default ModelInput;
