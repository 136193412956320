import { useQuery } from "@tanstack/react-query";
import { Call, Device } from "@twilio/voice-sdk";
import { useCallback, useEffect, useState } from "react";
import {
  setAllDevices,
  updateCurrentDevice,
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import { getDeviceTokens } from "../api/twilio.api";
import { useUserInfo } from "./useUserInfo";

export const useTwilio = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isIncomingCall, setIsIncomingCall] = useState({});
  const [logs, setLogs] = useState<string[]>([]);
  const [callAccepted, setCallAccepted] = useState(false);
  const [startCallTimestamp, setStartCallTimestamp] = useState<Date | String>(
    ""
  );

  const dealership = useUserInfo("dealership");

  // const { call, incomingCall }= useTwilioState()

  const dispatch = useTwilioDispatch();

  const { shouldRefetchIdentities } = useTwilioState();

  const { refetch } = useQuery({
    queryKey: ["deviceTokens"],
    queryFn: () => getDeviceTokens(dealership?.id),
    retry: false,
    // refetchOnWindowFocus: "always",
    onSuccess: (data) => {
      startupClient(data.data as any);
    },
  });

  const handleDisconnectedIncomingCall = useCallback(() => {
    log("Incoming call ended.");
    resetIncomingCallUI();
    dispatch({
      type: "SET_START_CALL_TIMESTAMP",
      payload: { startCallTimestamp: "" },
    });
    dispatch({
      type: "SET_INCOMING_CALL",
      payload: { incomingCall: null },
    });
    dispatch({
      type: "SET_CALLER",
      payload: { caller: "" },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIncomingCall = (call: Call) => {
    log(`Incoming call from ${call.parameters.From}`);
    const callerName = call.customParameters.get("FirstName")
      ? `${call.customParameters.get("FirstName")} ${call.customParameters.get(
          "LastName"
        )}`
      : "";

    dispatch({ type: "SET_INCOMING_CALL", payload: { incomingCall: call } });
    dispatch({ type: "SET_CALLER", payload: { caller: callerName } });

    // add event listener to call object
    call.on("cancel", handleDisconnectedIncomingCall);
    call.on("disconnect", handleDisconnectedIncomingCall);
    call.on("reject", handleDisconnectedIncomingCall);
  };

  const addDeviceListeners = async (device: Device) => {
    device.on("registered", function () {
      log("Twilio.Device Ready to make and receive calls!");
      // callControlsDiv.classList.remove("hide");
    });

    device.on("error", function (error) {
      log("Twilio.Device Error: " + error.message);
    });

    device.on("incoming", handleIncomingCall);
  };

  const initializeDevice = async (callToken: string) => {
    log("Initializing device");
    const device = new Device(callToken, {
      logLevel: 1,
      codecPreferences: ["opus", "pcmu"] as any,
    });

    await addDeviceListeners(device);

    device.register();
    return device;
  };

  const startupClient = useCallback(
    async (callTokens: Record<string, string>[]) => {
      // if(call || !incomingCall) return
      try {
        const newDevices = await Promise.all(
          callTokens.map(async (token: Record<string, string>) => {
            const device = await initializeDevice(token.token);
            return {
              device,
              label: token.identity,
              phoneNumber: token.phone_number,
              is_default: token.is_default as unknown as boolean,
            };
          })
        );

        setAllDevices(dispatch, newDevices);

        if (newDevices?.length) {
          const lineDevices = newDevices.filter((newDevice) =>
            newDevice.label.includes("line")
          );
          updateCurrentDevice(dispatch, {
            device: lineDevices?.[0]?.device,
            phoneNumber: lineDevices?.[0]?.phoneNumber,
            label: lineDevices?.[0]?.label,
            is_default: lineDevices?.[0]?.is_default,
          });
        }
      } catch (err) {
        console.log(err);
        log(
          "An error occurred. See your browser console for more information."
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (shouldRefetchIdentities) {
      refetch();
      dispatch({
        type: "SET_SHOULD_REFETCH_IDENTITIES",
        payload: { shouldRefetchIdentities: false },
      });
    }
  }, [dispatch, refetch, shouldRefetchIdentities]);

  const getMediaDevices = useCallback(async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
  }, []);

  useEffect(() => {
    getMediaDevices();
  }, [getMediaDevices]);

  const resetStartCallTimeStamp = () => {
    setStartCallTimestamp("");
  };

  // Activity log
  const log = (message: string) => {
    setLogs((logs: string[]) => [...logs, message]);
    const logDiv = document.getElementById("log");
    if (logDiv) logDiv.scrollTop = logDiv.scrollHeight;
  };

  const resetIncomingCallUI = () => {
    setIsIncomingCall({
      value: false,
    });
    setCallAccepted(false);
    resetStartCallTimeStamp();
  };

  return {
    phoneNumber,
    isIncomingCall,
    logs,
    callAccepted,
    startCallTimestamp,
    setPhoneNumber,
    startupClient,
  };
};
