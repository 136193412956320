import moment from "moment";
import * as yup from "yup";

export type BroadcastSettingsForm = {
  week_start_time: string;
  week_end_time: string;
  weekend_timings: boolean;
  weekend_start_time: string;
  weekend_end_time: string;
};

export const defaultValues = {
  week_start_time: "",
  week_end_time: "",
  weekend_timings: false,
  weekend_start_time: "",
  weekend_end_time: "",
};

export const broadcastSettingsValidation = yup.object().shape({
  week_start_time: yup.string().required("Start Time is required."),
  week_end_time: yup
    .string()
    .required("End Time is required.")
    .test("is-greater", "end time should be greater", function (value) {
      const { week_start_time } = this.parent;
      return moment(value, "HH:mm A").isSameOrAfter(
        moment(week_start_time, "HH:mm A")
      );
    }),
  weekend_timings: yup.boolean(),
  weekend_start_time: yup.string().when("weekend_timings", {
    is: (weekend_timings: boolean) => weekend_timings,
    then: yup.string().required("Weekend Start Time is required."),
    otherwise: yup.string(),
  }),
  weekend_end_time: yup.string().when("weekend_timings", {
    is: (weekend_timings: boolean) => weekend_timings,
    then: yup
      .string()
      .required("Weekend End Time is required.")
      .test("is-greater", "End Time should be greater", function (value) {
        const { weekend_start_time, weekend_timings } = this.parent;
        if(!weekend_timings) return true
        return moment(value, "HH:mm A").isSameOrAfter(
          moment(weekend_start_time, "HH:mm A")
        );
      }),
    otherwise: yup
      .string()
      .test("is-greater", "End Time should be greater", function (value) {
        const { weekend_start_time, weekend_timings } = this.parent;
        if(!weekend_timings) return true
        return moment(value, "HH:mm A").isSameOrAfter(
          moment(weekend_start_time, "HH:mm A")
        );
      }),
  }),
});
