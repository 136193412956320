import { Button, DrawerFooter, VStack, Text, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  suspendOrRestoreDPUser,
  suspendOrRestoreDealershipUser,
} from "../../../utils/api/users.api";
import { Drawer } from "../../common";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

type data = {
  isOpen: boolean;
  onClose: any;
  userId: number;
  dealershipId?: string;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  status: string;
}
const ReActivateDPUser = (props: data) => {
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, userId, refetchParent, dealershipId } = props;
  const toast = useToast();

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request: ApiRequest): Promise<ApiResult> => {
      if (dealershipId) {
        try {
          const response = await suspendOrRestoreDealershipUser(
            userId,
            dealershipId,
            request,
            {
              menu: "User",
              "Sub-Menu": "Dealership",
              "Dealership": dealershipId,
            }
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await suspendOrRestoreDPUser(
            userId,
            dealerId?.id,
            request,
            {
              menu: 'User',
              "Sub-Menu": 'Dealership',
              "Dealership": dealerId?.id,
            }
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "User re-activated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error re-activating User: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = { status: "active" };

    mutation.mutate(requestData);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title={"Re-Activate User"}>
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to re-activate the user?
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default ReActivateDPUser;
