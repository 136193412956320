import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { PaginatedTable } from "../../../../../components/common";
import {
  fetchDuplicateRecords,
  mergedDupLeads,
} from "../../../../../utils/api/lead.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import Loader from "../../../../common/Spinner";
import Popup from "../../../../Charts/Popup";

type DupCheckProps = {
  clickedRow: any;
};

const DupCheck = ({ clickedRow }: DupCheckProps) => {
  const { id: contactId } = clickedRow || {};
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toast = useToast();

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  const tableHeader = [
    { id: 1, value: "Name", label: "name" },
    { id: 2, value: "Email", label: "email" },
    { id: 3, value: "Phone No", label: "phoneNo" },
  ];

  const { isLoading, data: resData } = useQuery({
    queryKey: ["fetchDuplicateCheck", contactId],
    queryFn: () =>
      fetchDuplicateRecords({
        contactId: contactId,
        dealershipId: dealershipId,
      }),
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await mergedDupLeads({
          payload: { contact_ids: selectedRecords },
          contactId,
          dealershipId,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Leads Merged Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setSelectedRecords([]);
    },
    onError: (error: any) => {
      toast({
        description: `Error Merging Leads: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleContactsMerge = async () => {
    await mutation.mutateAsync({});
    onClosePopupOpen()
  };

  const { data } = resData || {};
  const { contacts, contacts_count } = data || {};

  const tableData = useMemo(() => {
    if (!contacts?.length) {
      return [];
    }

    return contacts.map((lead: any) => {
      return {
        id: lead.id,
        name: `${lead.first_name} ${lead.last_name}`,
        email: lead.email,
        phoneNo: lead.phone_number,
      };
    });
  }, [contacts]);

  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      {isLoading && <Loader />}
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="1rem"
        h="calc(100vh - 120px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Duplicate Customer Check
          </Text>
          {/* <Button variant="outline" padding="0.75rem" minW="auto">
            <DeSelectIcon />
          </Button> */}
        </HStack>
        {/* <Input hasIcon={true} icon={<SearchIcon />} /> */}
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          isGreen={true}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          hasCheckbox={true}
          hasPadding={false}
          isPaginated={true}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={contacts_count}
          parentRecords={selectedRecords}
          setParentRecords={setSelectedRecords}
        />
        <Button w="100%" mt="auto" onClick={onOpenPopupOpen} isDisabled={!selectedRecords.length}>
          Merge The Duplicated Leads
        </Button>
      </VStack>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={handleContactsMerge}
          title={"Merge Contacts"}
          message={"Are you sure you want to merge these contacts?"}
          loading={mutation?.isLoading}
        />
      )}
    </VStack>
  );
};

export default DupCheck;
