"use client";

import { Box, Button, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { countDifferentAttributes } from "../../../../components/AccountSettings/LeadCenterSettings/utils";
import SearchAndFilterDrawer from "../../../../components/Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../../../components/Drawers/SearchAndFilterDrawer/utils";
import ContactDetails from "../../../../components/LeadCenter/LeadDetails/ContactDetails/ContactDetails";
import DetailTabs from "../../../../components/LeadCenter/LeadDetails/DetailTabs/DetailTabs";
import { Input, Spinner } from "../../../../components/common";
import { DarkFilterIcon, SearchIcon } from "../../../../components/icons/svg";
import { getTransformedFilters } from "../../../../utils";
import { fetchContacts } from "../../../../utils/api/contact-details";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import ContactListing from "./ContactListing";
import SaveAsTagDrawer from "./SaveAsTagDrawer";

export default function LeadDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const contact_id = location?.state?.contact_id;
  const email_id = location?.state?.email_id;
  const queryParams = new URLSearchParams(location.search);
  const priority = queryParams.get("priority");

  let [searchParams] = useSearchParams();
  const contactId = searchParams.get("contact_id") ?? contact_id;
  const searchStr = searchParams.get("search");
  const statKey = searchParams.get("statKey");

  const shouldShowFilterSort = statKey || priority;

  const dealer = useUserInfo("dealership");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [contacts, setContacts] = useState<any[]>([]);
  const [foundIndex, setFoundIndex] = useState(0);
  const [searchAndFilterOpen, setSearchAndFilterOpen] = useState(false);
  const [isSaveAsTagOpen, setIsSaveAsTagOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [clickedRow, setClickedRow] = useState<any | null>(null);
  const [showAllTags, setShowAllTags] = useState(false);

  const searchAndFilters = JSON.parse(
    localStorage.getItem("search_and_filter") || "null"
  );
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchContacts", searchValue, page, searchAndFilters],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = 12;
      } else {
        params.q = searchValue;
      }

      if (searchAndFilters) {
        const tempFilters = getTransformedFilters(searchAndFilters);
        if (!tempFilters.datePicked) {
          delete (tempFilters as any).date_range;
          delete (tempFilters as any).datePicked;
        }
        delete (tempFilters as any).datePicked;
        params.filter_and_sort = tempFilters;
      }
      if (priority) params.priority = priority;
      if (statKey) params.stat_key = statKey;
      return fetchContacts(dealer?.id, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      const foundLead = data?.data?.contacts?.find(
        (contact: any) => Number(contact.id) === Number(contactId)
      );
      const foundIndex = data?.data?.contacts?.findIndex(
        (contact: any) => Number(contact.id) === Number(contactId)
      );

      setClickedRow(
        foundLead ||
          (page === 1
            ? contactId
              ? clickedRow
              : data?.data?.contacts?.[0]
            : clickedRow)
      );
      setFoundIndex(foundIndex < 0 ? 0 : foundIndex);
      setCount(data?.data?.contacts_count);
      setContacts((prev: any) => [...prev, ...data?.data?.contacts]);
    },
  });

  useEffect(() => {
    if (searchStr) {
      setSearchValue(searchStr);
    }
  }, [searchStr]);

  const filterCount = useMemo(() => {
    const searchAndFilters = JSON.parse(
      localStorage.getItem("search_and_filter") || "null"
    );
    if (!searchAndFilters) {
      return;
    }
    delete searchAndFilters.datePicked;
    delete (defaultFilterValues.filter_and_sort as any).datePicked;
    if (
      defaultFilterValues.filter_and_sort.date_range.end_date === null ||
      isNaN(defaultFilterValues.filter_and_sort.date_range.end_date as any)
    ) {
      defaultFilterValues.filter_and_sort.date_range.end_date = "" as any;
    }
    return countDifferentAttributes(
      { filter_and_sort: searchAndFilters },
      defaultFilterValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAndFilterOpen]);

  useEffect(() => {
    setContacts([]);
    setPage(1);
  }, [searchValue]);

  return (
    <Box padding="1.5rem" paddingBottom="0">
      <Box
        background="white"
        border="1px solid var(--grey-300)"
        borderRadius="1rem"
        display="flex"
      >
        <>
          <Box w="26rem">
            <VStack gap="0" alignItems="flex-start">
              <HStack padding="1.25rem 0.7rem" gap="15px">
                {/* <Select
                  label=""
                  onChange={(value: any) => console.log(value)}
                  placeholder=""
                  value="new-to-old"
                  options={typeOptions}
                  variant="dropdown"
                  w="7.5rem"
                /> */}
                {!shouldShowFilterSort && (
                  <Button
                    variant="none"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="0.55rem"
                    onClick={() => setSearchAndFilterOpen(true)}
                  >
                    <div style={{ position: "relative" }}>
                      <DarkFilterIcon />
                      {!!filterCount && (
                        <span
                          style={{
                            position: "absolute",
                            top: -15,
                            left: 20,
                            background: "green",
                            padding: 2,
                            borderRadius: "100%",
                            width: 30,
                            color: "white",
                          }}
                        >
                          <span style={{ marginLeft: 5 }}>{filterCount}</span>
                        </span>
                      )}
                    </div>
                  </Button>
                )}
                {!!filterCount && contacts && contacts.length > 0 && (
                  <Button
                    variant="none"
                    border="1px solid"
                    borderRadius="0.5rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="0.45rem 0.55rem"
                    background="#fff900"
                    border-color="black"
                    onClick={() => {
                      setIsSaveAsTagOpen(true);
                    }}
                  >
                    Save As Tag
                  </Button>
                )}
              </HStack>
              {searchAndFilters?.contact_attributes?.tags && (
                <HStack flexWrap={"wrap"} mb={"0.5rem"} ml="0.5rem">
                  {searchAndFilters?.contact_attributes?.tags
                    .slice(0, showAllTags ? undefined : 3)
                    .map((item: any, index: number) => {
                      return (
                        <Tooltip
                          key={index}
                          label={item?.label}
                          placement="top"
                          zIndex="99999"
                        >
                          <Text
                            backgroundColor={"var(--grey-200)"}
                            px={2}
                            py={1}
                            fontSize={"0.75rem"}
                            borderRadius="9999px"
                            color={"var(--grey-900)"}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth="100px"
                          >
                            {item.label}
                          </Text>
                        </Tooltip>
                      );
                    })}
                  {searchAndFilters?.contact_attributes?.tags.length > 3 && (
                    <Button
                      variant="link"
                      colorScheme="blue"
                      fontSize={"1rem"}
                      pr={2}
                      color="#1e88e5"
                      onClick={() => setShowAllTags(!showAllTags)}
                    >
                      {showAllTags ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </HStack>
              )}

              <Input
                hasIcon={true}
                icon={<SearchIcon color="var(--grey-900)" />}
                placeholder="Search Contacts.."
                type="text"
                maxW="100%"
                border="none"
                borderRadius="0"
                background="var(--grey-200)"
                value={searchValue}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Text
                textStyle="captionSmall"
                padding="0.62rem 0.75rem"
                color="var(--grey-600)"
              >
                {count} Contacts found
              </Text>
            </VStack>
            {count > 0 && (
              <div
                id="scrollableDiv"
                style={{ height: "calc(100vh - 284px)", overflow: "auto" }}
              >
                <ContactListing
                  setPage={setPage}
                  contacts={contacts}
                  count={count}
                  foundIndex={foundIndex}
                  onClick={(item, index) => {
                    setClickedRow({
                      ...item,
                      imageUrl: `https://source.unsplash.com/random/1920x1080/?abstract,pattern&${item?.id}`,
                    });
                    setFoundIndex(index);
                    navigate(location.pathname);
                  }}
                />
              </div>
            )}
          </Box>
          {data?.data?.contacts?.length < 1 || !data ? (
            <HStack
              width="100%"
              height="calc(100vh - 120px)"
              alignItems="center"
              padding="10rem"
              justifyContent="center"
            >
              <iframe
                src="https://lottie.host/embed/c13879c0-69af-45b2-89f7-a73beec5a874/GnmxokbVbv.json"
                width="100%"
                height="100%"
              />
            </HStack>
          ) : (
            <HStack alignItems="flex-start" w="100%" gap="0">
              <ContactDetails
                id={contactId ?? clickedRow?.id}
                setClickedRecord={setClickedRow}
                refetch={() => {
                  setContacts([]);
                  setPage(1);
                  refetch();
                }}
              />

              <DetailTabs
                clickedRow={
                  email_id
                    ? { id: contactId, details: { id: email_id } }
                    : clickedRow
                }
              />
            </HStack>
          )}
        </>
      </Box>
      {isLoading && <Spinner />}

      {searchAndFilterOpen && (
        <SearchAndFilterDrawer
          isOpen={searchAndFilterOpen}
          onClose={() => {
            setSearchAndFilterOpen(false);
          }}
          pageReset={() => {
            setContacts([]);
            setPage(1);
          }}
          screenName={"LeadDetailsPage"}
        />
      )}
      {isSaveAsTagOpen && (
        <SaveAsTagDrawer
          isOpen={true}
          onClose={() => {
            setIsSaveAsTagOpen(false);
          }}
          contactIds={data?.data?.contacts?.map((i: any) => i.id)}
        />
      )}
    </Box>
  );
}
