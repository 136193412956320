import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const createInboundCall = async (dId: any, id: any, payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${id}/inbound_call_logs`,
      payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
