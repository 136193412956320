// chakra-select-theme.ts
import { extendTheme } from "@chakra-ui/react";
import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseSelectStyle = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderRadius: "0.5rem",
    borderColor: "var(--grey-300)",
    color: "var(--grey-900)",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.25rem",
    minHeight: "44px",
  }),
  placeholder: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "var(--grey-600)",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.25rem",
  }),
  indicatorSeparator: (baseStyles: any, state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (baseStyles: any, state: any) => ({
    ...baseStyles,
    svg: {
      color: "black",
    },
  }),
  menu: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderRadius: "0.5rem",
    borderWidth: "1px",
    borderColor: "var(--grey-300)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    display: "block",
    padding: "0.62rem",
    paddingBottom: "0.18rem",
  }),
  menuList: (baseStyles: any, state: any) => ({
    ...baseStyles,
    padding: "0",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "var(--grey-800)",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1rem",
    padding: "0.62rem",
    borderRadius: "0.5rem",
    marginBottom: "0.44rem",
    background: state.isFocused ? "var(--secondary-50)" : "white",
  }),
};

const customSelectStyles = {
  default: {
    ...baseSelectStyle,
  },
  borderLess: {
    ...baseSelectStyle,
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      border: "none",
    }),
  },
  borderLeftOnly: {
    ...baseSelectStyle,
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      border: "none",
      borderLeft: "1px solid var(--grey-300)",
      borderRadius: "0",
    }),
  },
  table: {
    ...baseSelectStyle,
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      maxWidth: "7.4375rem",
      background: "var(--grey-100)",
      borderRadius: "8px",
    }),
  },
  dropdown: {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      // width: "200px",
      borderRadius: "0.5rem",
      border: "1px solid var(--grey-300)",
      fontSize: "0.875rem",
      fontWeight: "500",
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      color: "var(--grey-800)",
      fontSize: "0.875rem",
      fontWeight: "500",
      lineHeight: "1rem",
      padding: "0.62rem",
      borderBottom: "1px solid var(--grey-200)",
      marginBottom: 0,
      background: state.isFocused ? "var(--grey-50)" : "white",
      _last: {
        borderBottom: "none",
      },
    }),
    menuList: (baseStyles: any, state: any) => ({
      // ...baseStyles,
      padding: "2px 0",
    }),
    indicatorSeparator: (baseStyles: any, state: any) => ({
      ...baseStyles,
      margin: 0,
    }),
    placeholder: (baseStyles: any, state: any) => ({
      ...baseStyles,
      color: "var(--grey-800)",
      fontSize: "0.875rem",
      fontWeight: "500",
      // lineHeight: "1.25rem",
    }),
  },
  dropdownSm: {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      width: "125px",
      borderRadius: "0.5rem",
      border: "1px solid var(--grey-300)",
      fontSize: "0.875rem",
      fontWeight: "500",
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      color: "var(--grey-800)",
      fontSize: "0.875rem",
      fontWeight: "500",
      lineHeight: "1rem",
      padding: "0.62rem",
      borderBottom: "1px solid var(--grey-200)",
      marginBottom: 0,
      background: state.isFocused ? "var(--grey-50)" : "white",
      _last: {
        borderBottom: "none",
      },
    }),
    menuList: (baseStyles: any, state: any) => ({
      // ...baseStyles,
      padding: "2px 0",
    }),
    indicatorSeparator: (baseStyles: any, state: any) => ({
      ...baseStyles,
      margin: 0,
    }),
    placeholder: (baseStyles: any, state: any) => ({
      ...baseStyles,
      color: "var(--grey-800)",
      fontSize: "0.875rem",
      fontWeight: "500",
      // lineHeight: "1.25rem",
    }),
  },
};

export default extendTheme({
  styles: {
    customSelect: customSelectStyles,
  },
});
