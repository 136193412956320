import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import Pending from "./ProcessSaleTabs/Pending/Pending";

const ProcessSale = ({ clickedRow, indexSetter }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box h="100%" padding="1rem" background="var(--grey-50)">
      <VStack
        w="100%"
        h="100%"
        background="white"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        alignItems="flex-start"
      >
        <HStack
          justifyContent="space-between"
          borderBottom="1px solid var(--grey-300)"
          padding="1rem"
          w="100%"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Process Sale
            </Text>
          </HStack>
        </HStack>

        <VStack
          h="calc(100vh - 200px)"
          w="100%"
          justifyContent="center"
          padding="1rem"
        >
          <Text
            textStyle="h4"
            fontWeight="500"
            maxW="19rem"
            textAlign="center"
          ></Text>
          <Button onClick={onOpen}>Add New Process Sale</Button>
        </VStack>
      </VStack>

      {isOpen && (
        <Pending
          isOpen={isOpen}
          onClose={onClose}
          contact_id={clickedRow}
          indexSetter={indexSetter}
        />
      )}
    </Box>
  );
};

export default ProcessSale;
