import { useUserInfo } from "../utils/hooks/useUserInfo";
import { Simpsocial_Roles } from "../utils/roles";
import { useEffect } from "react";
import { GlobalSidebarMenu } from "../config/constants/globalSidebar";
import { SidebarMenu } from "../config/constants/sidebar";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAdminPermissions } from "../utils/api/usersetting.api";
import { getPermissions } from "../utils/api/usersetting.api";
import Loader from "../components/common/Spinner";


export const HomeRoute = () => {
    const role=useUserInfo('role')
    const active_tab=useUserInfo('active_tab')
    const dealership=useUserInfo('dealership')
    const navigate=useNavigate()
    const userID = useUserInfo("id");
    const { data, isFetching: isLoading } = useQuery({
      queryKey: ["sidebar", userID,dealership?.id],
      queryFn: Simpsocial_Roles.includes(role)?getAdminPermissions:getPermissions,
      refetchOnWindowFocus: false,
      retry: false,
    });
    useEffect(() => {
      if (data?.data) {
        let targetPath = null;
        if(role){
          if (Simpsocial_Roles.includes(role)) {
            const globalSidebarItems = GlobalSidebarMenu.filter(item => item.name === data?.data?.active_tab);
            if (globalSidebarItems.length > 0) {
                targetPath = globalSidebarItems[0].path;
            }
        } else {
            const sidebarItems = SidebarMenu.filter(item => item.name === data?.data?.active_tab);
            if (sidebarItems.length > 0) {
                targetPath = sidebarItems[0].path;
            }
        }
        // Check global sidebar menu if target path not found
        if (!targetPath) {
          if(Simpsocial_Roles.includes(role)){
            navigate('/not-found');

          }
          else{
            navigate('/not-found-user');

          }
          
        }
  
        // If a matching path is found and it's not already the current location, navigate to it
        if (targetPath && targetPath !== window.location.pathname) {
          navigate(targetPath);
        }}
      }
    }, [data?.data?.active_tab, navigate]);
  
    if (isLoading) {
      return <Loader />;
  }
      return null

  };