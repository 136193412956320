import {
  HStack,
  VStack,
  Button,
  Box,
  DrawerFooter,
  useToast,
} from "@chakra-ui/react";
import { Drawer } from "../common";
import CustomInput from "../common/Input";
import VoiceRecorder from "../common/VoiceRecorder";
import FileUploader from "../common/FileUploader";
import { useState } from "react";
import AudioPlayer from "../common/AudioPlayer";
import { useMutation } from "@tanstack/react-query";
import { addVoicemail } from "../../utils/api/user-settings-dp.api";
import { useParams } from "react-router-dom";
import { useUserInfo } from "../../utils/hooks/useUserInfo";

interface ApiResult {
  data: any;
}
interface ApiRequest {
  voicemail: {
    name: string;
    voicemail_type: string;
  };
  audio: Blob;
}
export const AddVoicemail = (props: any) => {
  const { isOpen, onClose, refetch, type } = props;
  const { id: userIdFromParam } = useParams();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");
  const toast = useToast();

  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [audioData, setAudioData] = useState("");
  const [transcribed, setTranscribed] = useState(false);

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await addVoicemail(
          [type, dealerId?.id, userIdFromParam ?? userID],
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      onClose();
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const validateFileType = (file: Blob) => {
    const validateType = ["audio/wav", "audio/mp3", "audio/mpeg"];
    return validateType.includes(file.type);
  };

  const validateFileSize = (file: Blob) => {
    return file.size <= 8 * 1024 * 1024;
  };
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!name?.trim()) {
      setError("Title cannot be null");
      return;
    }

    if (!audioData) {
      toast({
        description: "Please add a voicemail",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const response = await fetch(audioData);
    const blob = await response.blob();

    if (!validateFileType(blob)) {
      toast({
        description: "Invalid file type. Please upload a valid image file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (!validateFileSize(blob)) {
      toast({
        description: "File size exceeds 8 MB limit.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const requestData: ApiRequest = {
      voicemail: {
        name: name,
        voicemail_type: "outbound",
      },
      audio: blob,
    };

    mutation.mutate(requestData);
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Add Voicemail"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <CustomInput
          placeholder="Write title here"
          label="Title"
          value={name}
          onChange={(value) => setName(value.target.value)}
          isRequired={true}
          error={error}
        />

        <VStack w="100%" alignItems="center">
          <VoiceRecorder
            transcribed={transcribed}
            handleTranscribed={() => setTranscribed(true)}
            handleAudioData={(src: string) => setAudioData(src)}
          />
        </VStack>
        <HStack w="100%" gap="1.5rem">
          <Box
            as="span"
            width="100%"
            height="1px"
            background="var(--grey-300)"
          ></Box>
          <Box
            as="span"
            fontSize="1.125rem"
            fontWeight="600"
            color="var(--secondary-600)"
            borderRadius="6.25rem"
            background="var(--secondary-50)"
            padding="0.31rem 1.19rem"
          >
            Or
          </Box>
          <Box
            as="span"
            width="100%"
            height="1px"
            background="var(--grey-300)"
          ></Box>
        </HStack>
        <VStack w="100%" alignItems="center">
          <FileUploader
            transcribed={transcribed}
            handleTranscribed={() => {
              setTranscribed(true);
            }}
            handleAudioData={(src: string) => {
              setAudioData(src);
            }}
          />
        </VStack>
        {audioData && transcribed && (
          <AudioPlayer
            audioSrc={audioData}
            transcribed={transcribed}
            handleTranscribed={() => {
              setTranscribed(false);
              setAudioData("");
            }}
          />
        )}
      </VStack>

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Upload
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
