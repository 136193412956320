import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../components/icons/svg";
import {
  fetchDealershipUsers,
  impersonateUser,
} from "../../../utils/api/users.api";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Input, PaginatedTable, Select, Spinner } from "../../common";
import CreateUser from "../CreateUser/CreateUser";
import EditUser from "../EditUser/EditUser";
import ResetPassword from "../ResetPassword/ResetPassword";
import { UserData, getUserMenuHeaders, mapTableData } from "../UserUtils";

const DealershipUser = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [clickedRow, setClickedRow] = useState<UserData>({
    id: 0,
    logged_ip: "",
    full_name: "",
    last_login: "",
    access_level: "",
    created_at: "",
    status: "",
    dealerships: [],
    email: "",
    time_zone: "",
  });
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const [searchValue, setSearchValue] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const user = useUserInfo("user");
  const handleUserImpersonate = async (userData: UserData) => {
    await impersonateUser(
      userData.id,
      dispatch,
      false,
      {
        menu: "Dealerships",
        "Sub-Menu": "Settings/Users",
      },
      user?.role,
      user?.id
    );
    navigate("/");
  };

  const handleSelect = (option: SelectOptionProps, user: UserData) => {
    if (option.value === "impersonate") {
      return handleUserImpersonate(user);
    }
    setActiveModal(option.value);
  };
  const ROLE_TITLES = "Owner/GM,Manager/Admin,Sales Person,BDC Agent";

  const optionsHeader: SelectOptionProps[] = [
    { label: "All Access Levels", value: ROLE_TITLES },
    { label: "Owner/GM", value: "Owner/GM" },
    { label: "Manager/Admin", value: "Manager/Admin" },
    { label: "Sales person", value: "Sales Person" },
    { label: "BDC Agent", value: "BDC Agent" },
  ];

  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);

  const [tableHeader, setTableHeader] = useState(
    getUserMenuHeaders(isActive, false)
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["dealershipUsers", isActive, page, perPage],
    queryFn: () => {
      const queryParameters: any = {
        role_titles: selectedOption?.value || ROLE_TITLES,
        dealership_status: isActive ? "active" : "suspended",
        searchValue: searchValue,
      };

      if (!searchValue) {
        queryParameters.page = page;
        queryParameters.per_page = perPage;
      }

      return fetchDealershipUsers(queryParameters);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const optionsActive: SelectOptionProps[] = [
    { label: "Impersonate", value: "impersonate" },
    { label: "Edit User", value: "editUser" },
    { label: "Reset Password", value: "resetPassword" },
  ];
  const optionsSuspended: SelectOptionProps[] = [
    { label: "Edit User", value: "editUser" },
    { label: "Reset Password", value: "resetPassword" },
  ];
  const activeData =
    data &&
    data.users?.map((item: UserData) =>
      mapTableData(
        item,
        (event: any) => handleSelect(event, item),
        isActive ? optionsActive : optionsSuspended
      )
    );

  const [tableData, setTableData] = useState<any>(activeData);

  //...............use effects..................
  useEffect(() => {
    refetch();
  }, [refetch, searchValue, selectedOption]);

  useEffect(() => {
    setTableHeader(getUserMenuHeaders(isActive, false));
    setTableData(activeData);

    setUsersCount(data?.users_count ?? 0);
  }, [data, isActive]);

  useEffect(() => {
    resetPagination();
  }, [isActive]);

  const resetPagination = () => {
    setPage(1);
    setPerPage(20);
  };
  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Type here to search"
              value={searchValue}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
            />
            <VStack position="relative" zIndex="3">
              <Select
                onChange={(value: any) => {
                  setSelectedOption(value);
                  resetPagination();
                }}
                placeholder="All Access Levels"
                options={optionsHeader}
                variant="default"
                value={selectedOption?.value}
                w={"240px"}
              />
            </VStack>

            <HStack border="1px solid var(--grey-300)" borderRadius="0.5rem">
              <Button
                variant="none"
                padding="0.5rem 1rem"
                onClick={() => setIsActive(true)}
                borderRadius="0.5rem"
                h="100%"
                fontWeight="500"
                background={isActive ? "var(--secondary-600)" : "white"}
                color={isActive ? "white" : "var(--grey-900)"}
              >
                Active
              </Button>
              <Button
                variant="none"
                h="100%"
                padding="0.5rem 1rem"
                borderRadius="0.5rem"
                fontWeight="500"
                onClick={() => setIsActive(false)}
                color={!isActive ? "white" : "var(--grey-900)"}
                background={!isActive ? "var(--secondary-600)" : "white"}
              >
                Suspended
              </Button>
            </HStack>
          </HStack>
          <Button onClick={onOpen}>Create User</Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Users found ({usersCount})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPage(1);
                setPerPage(Number(value?.label));
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={usersCount}
            />
          </Box>
        </VStack>

        {isOpen && (
          <CreateUser
            isOpen={isOpen}
            onClose={onClose}
            isSimpSocial={false}
            refetchParent={refetch}
          ></CreateUser>
        )}

        {activeModal === "editUser" && (
          <EditUser
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

        {activeModal === "resetPassword" && (
          <ResetPassword
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}
        {isLoading && <Spinner />}
      </VStack>
    </>
  );
};

export default DealershipUser;
