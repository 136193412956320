"use client";
import { Box, FormLabel, Input, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";

const valueMapper: any = {
  "30": "0",
  "60": "1",
  "90": "2",
  "180": "3",
  "": "4",
};

const SlideRange = ({
  handleClick,
  rangeWidth,
}: {
  handleClick?: (value: string) => void;
  rangeWidth?: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState<string>("30");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    handleClick && handleClick(event.target.value);
  };

  const radioData = [
    {
      id: 1,
      value: "30",
      label: "30 days",
    },
    {
      id: 2,
      value: "60",
      label: "60 days",
    },
    {
      id: 3,
      value: "90",
      label: "90 days",
    },
    {
      id: 4,
      value: "180",
      label: "6 months",
    },
    {
      id: 5,
      value: "",
      label: "All time",
    },
  ];

  const widthValue = rangeWidth ? 28 : 10;

  const sliderWidth =
    valueMapper[selectedValue] !== "0"
      ? `calc(${
          (+valueMapper[selectedValue] / (radioData.length - 0.5)) * 100
        }% + ${widthValue * valueMapper[selectedValue]}px)`
      : "0";

  const sliderWidthLarge =
    valueMapper[selectedValue] !== "0"
      ? `calc(${
          (+valueMapper[selectedValue] / (radioData.length - 0.5)) * 100
        }% + ${52 * valueMapper[selectedValue]}px)`
      : "0";

  const sliderWidthMobile =
    valueMapper[selectedValue] !== "0"
      ? `calc(${
          (+valueMapper[selectedValue] / (radioData.length - 0.5)) * 100
        }% + ${1 * valueMapper[selectedValue]}px)`
      : "0";

  const leftValue = useBreakpointValue({
    base: sliderWidthMobile,
    md: sliderWidth,
    // xl: sliderWidthLarge,
  });

  return (
    <Box
      style={{
        maxWidth: "100%",
        width: "100%",
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          zIndex: "1",
        }}
      >
        {radioData.map((item, index) => (
          <React.Fragment key={item.id}>
            <Box
              style={{ flex: index !== radioData.length - 1 ? "1" : "unset" }}
            >
              <Input
                type="radio"
                id={`radio-${item.id}`}
                name="SlideRange"
                hidden
                value={item.value.toString()}
                checked={selectedValue == item.value.toString()}
                onChange={handleRadioChange}
                style={{ height: "12px" }}
              />
              <FormLabel
                htmlFor={`radio-${item.id}`}
                fontSize={{ md: "14px", base: "12px" }}
                whiteSpace={{ base: "nowrap" }}
                style={{
                  display: "flex",
                  height: "45px",
                  width: "100%",
                  cursor: "pointer",

                  alignItems:
                    selectedValue == item.value.toString()
                      ? "flex-start"
                      : "center",
                }}
              >
                {item.label}
              </FormLabel>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <Box
        style={{
          width: "100%",
          backgroundColor: "#CFD8DC",
          height: "6px",
          position: "absolute",
          bottom: "10px",
        }}
      >
        <Box
          // width={{ md: sliderWidth, sm: sliderWidthMobile }}
          style={{
            width: leftValue,
            height: "6px",
            backgroundColor: "#43A047",
            position: "relative",
          }}
        ></Box>
      </Box>
      <Box
        as="span"
        // left={{ md: sliderWidth, sm: sliderWidthMobile }}
        style={{
          content: '""',
          position: "absolute",
          height: "24px",
          width: "40px",
          borderRadius: "22px",
          backgroundColor: "#43A047",
          border: "5px solid #FFF",
          boxShadow: "4px 0px 20px 0px rgba(0, 0, 0, 0.25);",
          left: leftValue,
          bottom: "2px",
        }}
      ></Box>
    </Box>
  );
};
export default SlideRange;
