import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';
import { initialState, TwilioReducer, TwilioState, TwilioAction } from './reducer';

interface TwilioContextProps {
  children: ReactNode;
}

export const TwilioStateContext = createContext<TwilioState | undefined>(undefined);
export const TwilioDispatchContext = createContext<Dispatch<TwilioAction> | undefined>(undefined);

export function useTwilioState() {
  const context = useContext(TwilioStateContext);
  if (context === undefined) {
    throw new Error('useTwilioState must be used within an TwilioProvider');
  }

  return context;
}

export function useTwilioDispatch() {
  const context = useContext(TwilioDispatchContext);
  if (context === undefined) {
    throw new Error('useTwilioDispatch must be used within an TwilioProvider');
  }

  return context;
}

export const TwilioProvider: React.FC<TwilioContextProps> = ({ children }) => {
  const [user, dispatch] = useReducer(TwilioReducer, initialState);

  return (
    <TwilioStateContext.Provider value={user}>
      <TwilioDispatchContext.Provider value={dispatch}>
        {children}
      </TwilioDispatchContext.Provider>
    </TwilioStateContext.Provider>
  );
};
