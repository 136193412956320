import * as yup from "yup";
import { getVehiclesbyStock } from "../../../../../../../utils/api/lead-deatail.api";
export type CreateProcessSalesForm = {
  sales_person_id: Array<{
    value: number;
    label: string;
  }>;
  vehicle_attributes: {
    make: string;
    model: string;
    year: string;
    stock: string;
    vin: string;
    inventory_id: {value: number; label: string};
  };
  payment_type: SelectOptionProps;
  term: string;
  sale_type: string;
  delivery_status: SelectOptionProps;
  notes: string;
};
export const paymentOptions: SelectOptionProps[] = [
  { label: "Cash", value: "cash" },
  { label: "Loan", value: "loan" },
  { label: "Lease", value: "lease" },
];
export const deliveryStatusOptions: SelectOptionProps[] = [
  { label: "Delivered", value: "delivered" },
  { label: "Future Delivery", value: "future_delivery" },
];
export const saleTypeOptions: SelectOptionProps[] = [
  { label: "Sales", value: "sales" },
  { label: "R.O. Tradeup", value: "ro_tradeup" },
  { label: "Services", value: "services" },

];

export const loadVehicles = async (searchStr: string, id?: string) => {
  try {
    const response = await getVehiclesbyStock({ searchStr, dealershipId: id });
    const options = response?.data?.map((vehicle: any) => ({
      value: vehicle.id,
      label: `${vehicle.make} - ${vehicle.model} - ${vehicle.year}`,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const defaultValues = {
  sales_person_id: [],
  vehicle_attributes: {
    make: "",
    model: "",
    year: "",
    stock: "",
    vin: "",
    inventory_id: {},
  },
  payment_type: paymentOptions[0],
  term: "",
  delivery_status: deliveryStatusOptions[0],
  notes: "",
  sale_type:""
};
export const CreateProcessSalesFormSchema = yup.object().shape({
  vehicle_attributes: yup.object({
    inventory_id: yup.object({
      value: yup.number(),
      label: yup.string(),
    }).nullable(),  // This allows the object to be null

    // Conditional validation based on inventory_id
    make: yup.string().when("inventory_id", {
      is: (inventory_id:any) => !inventory_id || !inventory_id.value, // Check if inventory_id is not set
      then: yup.string().required("Make is required"),
      otherwise: yup.string().notRequired(),
    }),
    model: yup.string().when("inventory_id", {
      is: (inventory_id:any) => !inventory_id || !inventory_id.value,
      then: yup.string().required("Model is required"),
      otherwise: yup.string().notRequired(),
    }),
    year: yup.string().when("inventory_id", {
      is: (inventory_id:any) => !inventory_id || !inventory_id.value,
      then: yup.string().required("Year is required").matches(/^\d{4}$/, "Year must be a 4 digit number"),
      otherwise: yup.string().notRequired(),
    }),
    stock: yup.string().when("inventory_id", {
      is: (inventory_id:any) => !inventory_id || !inventory_id.value,
      then: yup.string().required("Stock is required"),
      otherwise: yup.string().notRequired(),
    }),
    vin: yup.string().when("inventory_id", {
      is: (inventory_id:any) => !inventory_id || !inventory_id.value,
      then: yup.string().required("VIN is required"),
      otherwise: yup.string().notRequired(),
    }),
  }),
  term: yup.string().required("Term is required"),
});