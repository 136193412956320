"use client";

import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { countDifferentAttributes } from "../../../components/AccountSettings/LeadCenterSettings/utils";
import {
  Dropdown,
  Input,
  PaginatedTable,
  Popover,
} from "../../../components/common";
import SearchAndFilterDrawer from "../../../components/Drawers/SearchAndFilterDrawer";
import { defaultFilterValues } from "../../../components/Drawers/SearchAndFilterDrawer/utils";
import {
  DarkFilterIcon,
  EditIcon,
  InfoIcon,
  SearchIcon,
} from "../../../components/icons/svg";
import { convertDate, simpAdminChecker } from "../../../utils";
import { fetchTags } from "../../../utils/api/tags";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import AssignContact from "./AssignContact";
import CreateTag from "./CreateTag";
import EditTag from "./EditTag";
import EnrollContact from "./EnrollContact";

export default function Tags() {
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const navigate = useNavigate();

  const role = useUserInfo("user")?.role;
  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";

  const isEditableRole = impSSRole
    ? simpAdminChecker(impSSRole)
    : simpAdminChecker(role);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  useEffect(() => {
    if (activeModal === "contact") {
      const tag = { value: clickedRow?.id, label: clickedRow?.name };
      const valuesWithTags = {
        ...defaultFilterValues,
        filter_and_sort: {
          ...defaultFilterValues.filter_and_sort,
          contact_attributes: {
            ...defaultFilterValues.filter_and_sort.contact_attributes,
            tags: [tag],
          },
        },
      };

      localStorage.setItem(
        "search_and_filter",
        JSON.stringify(valuesWithTags.filter_and_sort)
      );

      const url = `/leads/lead-details`;
      navigate(url);
    }
  }, [clickedRow, activeModal]);

  const [searchAndFilterOpen, setSearchAndFilterOpen] = useState(false);

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PersonIcon />
          Contact
        </Box>
      ),
      value: "contact",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <AccountBoxIcon />
          Assign Contacts
        </Box>
      ),
      value: "assign",
    },
    // {
    //   label: (
    //     <Box as="span" display="flex" alignItems="center" gap="0.5rem">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="20"
    //         height="20"
    //         viewBox="0 0 20 20"
    //         fill="none"
    //       >
    //         <path
    //           d="M14.375 8.75C17.4816 8.75 20 11.2684 20 14.375C20 17.4816 17.4816 20 14.375 20C11.2684 20 8.75 17.4816 8.75 14.375C8.75 11.2684 11.2684 8.75 14.375 8.75ZM14.375 11.25C14.0299 11.25 13.75 11.5298 13.75 11.875V13.75H11.875C11.5298 13.75 11.25 14.0299 11.25 14.375C11.25 14.7201 11.5298 15 11.875 15H13.75V16.875C13.75 17.2201 14.0299 17.5 14.375 17.5C14.7201 17.5 15 17.2201 15 16.875V15H16.875C17.2201 15 17.5 14.7201 17.5 14.375C17.5 14.0299 17.2201 13.75 16.875 13.75H15V11.875C15 11.5298 14.7201 11.25 14.375 11.25ZM9.07145 10C8.08978 11.1887 7.5 12.713 7.5 14.375C7.5 15.025 7.59019 15.6539 7.75874 16.2498L7.57524 16.251C4.41584 16.251 2.62208 15.1952 2.50601 13.0672L2.5 12.8433V11.875C2.5 10.9004 3.24361 10.0994 4.19442 10.0086L4.375 10H9.07145ZM7.5 2.5C9.22617 2.5 10.6255 3.89934 10.6255 5.6255C10.6255 7.35168 9.22617 8.75101 7.5 8.75101C5.77383 8.75101 4.37449 7.35168 4.37449 5.6255C4.37449 3.89934 5.77383 2.5 7.5 2.5ZM13.75 3.75C15.1307 3.75 16.25 4.86929 16.25 6.25C16.25 6.76965 16.0915 7.25227 15.8201 7.65214C15.3541 7.55246 14.8708 7.5 14.375 7.5C13.5095 7.5 12.6815 7.65991 11.9186 7.95181C11.5037 7.50554 11.25 6.9074 11.25 6.25C11.25 4.86929 12.3693 3.75 13.75 3.75Z"
    //           fill="#424242"
    //         />
    //       </svg>
    //       Enrol Contacts
    //     </Box>
    //   ),
    //   value: "enroll",
    // },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchTags", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchTags(params, dealerId?.id);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.tags_count ?? 0);
    },
  });
  const tableHeader = [
    { id: 4, value: "Name", label: "name" },
    { id: 5, value: "Description", label: "description" },
    { id: 6, value: "Number of Contacts", label: "noOfContacts" },
    { id: 2, value: "Last Modified By", label: "date" },
    { id: 2, value: "Last Modified Date/Time", label: "date" },
    { id: 7, value: "Type", label: "type" },
    { id: 9, value: "Actions", label: "actions" },
  ];
  const createDateTimeField = (dateArray: string[]) => {
    return (
      <HStack gap="1rem">
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[0] ?? "-"}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[1] ?? null}
          </Text>
        </VStack>
      </HStack>
    );
  };

  const tableData = data?.tags?.map((item: any) => ({
    id: item.id,
    date: createDateTimeField(convertDate(item.date)),
    name: item.name || "-",
    description: item.description || "-",
    noOfContacts: item.number_of_contacts || "-",
    type: (
      <Box
        as="span"
        py="0.25rem"
        px="0.62rem"
        color="var(--secondary-600)"
        fontSize="0.875rem"
        fontWeight="400"
        lineHeight="1.25rem"
        border="1px solid var(--secondary-600)"
        borderRadius="2.5rem"
      >
        {item.category || ""}
      </Box>
    ),
    actions: (
      <Dropdown
        options={options}
        onSelect={handleSelect}
        disabled={!isEditableRole}
      />
    ),
  }));

  const filterCount = useMemo(() => {
    const searchAndFilters = JSON.parse(
      localStorage.getItem("search_and_filter") || "null"
    );
    if (!searchAndFilters) {
      return;
    }
    return countDifferentAttributes(
      { filter_and_sort: searchAndFilters },
      defaultFilterValues
    );
  }, []);

  return (
    <>
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack>
          <Input
            w="350px"
            maxW="350px"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Button
            variant="none"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="0.55rem"
            onClick={() => setSearchAndFilterOpen(true)}
          >
            <div style={{ position: "relative" }}>
              <DarkFilterIcon />
              {!!filterCount &&
                JSON.parse(
                  localStorage.getItem("search_and_filter") || "null"
                ) && (
                  <span
                    style={{
                      position: "absolute",
                      top: -15,
                      left: 20,
                      background: "green",
                      padding: 2,
                      borderRadius: "100%",
                      width: 25,
                      color: "white",
                    }}
                  >
                    <span style={{ marginLeft: 5 }}>{filterCount}</span>
                  </span>
                )}
            </div>
          </Button>
        </HStack>
        <Box display="flex" alignItems="center" gap="0.62rem">
          {isEditableRole && <Button onClick={onOpen}>Create Tag</Button>}

          <Popover
            btnVariant="outline"
            variant="custom"
            trigger={<InfoIcon color="var(--grey-800)" />}
            contentStyle={{ width: "28rem", borderRadius: "1rem" }}
          >
            <Box padding="2rem">
              <Text
                textStyle="h6"
                lineHeight="1.5rem"
                color="var(--grey-800)"
                mb="1.25rem"
              >
                Tags assist in the categorization and segmentation of your
                audience, functioning similarly to lists but with the added
                advantage of allowing the same contact to be present in multiple
                places.
              </Text>
              <Text textStyle="h6" lineHeight="1.5rem" color="var(--grey-800)">
                The entirety of a tag's audience can be included in a campaign.
                Additionally, when contacts are imported, they appear in a new
                tag, indicating the date of upload.
              </Text>
            </Box>
          </Popover>
        </Box>
      </Box>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Text textStyle="caption" color="var(--grey-600)">
            Total Tags ({count})
          </Text>
        </Box>
        <PaginatedTable
          isLoadingData={isLoading}
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          tableHeight={560}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          rowOnClick={(rowItem) => {
            setClickedRow(rowItem);
          }}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
        />
      </Box>

      {isOpen && (
        <CreateTag
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
        ></CreateTag>
      )}
      {activeModal === "edit" && (
        <EditTag
          id={clickedRow?.id}
          onClose={() => {
            refetch();
            setActiveModal(null);
          }}
        ></EditTag>
      )}

      {activeModal === "assign" && (
        <AssignContact
          onClose={() => {
            refetch();
            setActiveModal(null);
          }}
          id={clickedRow?.id}
        />
      )}
      {activeModal === "enroll" && (
        <EnrollContact
          onClose={() => {
            refetch();
            setActiveModal(null);
          }}
          id={clickedRow?.id}
        />
      )}
      {searchAndFilterOpen && (
        <SearchAndFilterDrawer
          isOpen={true}
          onClose={() => {
            setSearchAndFilterOpen(false);
            refetch();
          }}
        />
      )}
    </>
  );
}
