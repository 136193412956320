import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Logo from "../../../../assets/images/black-logo.svg";
import UptimeGraph from "./UptimeGraph";

export default function UptimeGraphExpandable(props: any) {
  const { setIsBack, selectedMonth, setSelectedMonth } = props;

  return (
    <>
      <HStack
        w="100%"
        padding="1rem 1.5rem"
        background="white"
        borderBottom="1px solid var(--grey-300)"
      >
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => setIsBack(false)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--grey-900)",
              }}
            />
          </Box>
          <Text textStyle="h6" fontWeight="500">
            Back to Dashboard
          </Text>
        </Button>
      </HStack>
      <VStack padding="1.5rem" gap="1.5rem">
        <VStack
          alignItems="flex-start"
          w="100%"
          padding="1.5rem"
          background="white"
          borderRadius="0.5rem"
          height="calc(100vh - 275px)"
          overflow="auto"
        >
          <UptimeGraph
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </VStack>
        <HStack
          w="100%"
          padding="1.31rem 1.25rem"
          borderRadius="0.5rem"
          justifyContent="space-between"
          background="white"
        >
          <Image src={Logo} width="122px" height="22px" alt="logo" />
          <Text textStyle="h5">Copyright© All rights reserved</Text>
        </HStack>
      </VStack>
    </>
  );
}
