// util.ts

import {
   CalendarIcon,
   ContactReminderIcon,
   ServiceIcon,
   StartMeetingIcon,
   InfoIcon
} from "../icons/svg";
import * as yup from "yup";

export const AppointmentOptions: SelectIconOptionProps[] = [
   {
     label: "Reminder",
     value: "reminder",
     icon: <ContactReminderIcon/>,
   },
   { label: "Meeting", value: "appointment", icon: <StartMeetingIcon/> },
   {
     label: "Service",
     value: "service_appointment",
     icon: <ServiceIcon/>,
   },
];

export const ReminderOptions: SelectIconOptionProps[] = [
   {
     label: "Reminder",
     value: "reminder",
     icon: <ContactReminderIcon/>,
   },
];
export const LeadAppointmentOptions: SelectIconOptionProps[] = [
  { label: "Meeting", value: "appointment", icon: <StartMeetingIcon/> },
  {
    label: "Service",
    value: "service_appointment",
    icon: <ServiceIcon/>,
  },
];
export type CreateAppointmentForm = {
  title?: string;
  userid?: Array<{
    value: number;
    label: string;
  }>;
  date?: string;
  start_time?: string;
  type?: SelectOptionProps | SelectIconOptionProps
  // end_time?: string;

};
 export const defaultValues={
    title:'',
    userid:[],
    date:'',
    start_time:'',
    // end_time:'',
    type:AppointmentOptions[0],

 }
 export const createAppointmentValidation = yup.object().shape({
    title: yup
    .string()
    .nullable()
    .required("Title is required."),
    userid: yup
    .array()
    .min(1, "At least one user is required.")
    .nullable()
    .required("User is required."),
    date: yup
    .string()
    .nullable()
    .required("required."),
    start_time: yup
    .string()
    .nullable()
    .required("required."),
    type: yup
    .object()
    .nullable()
    .required("required."),


 })
 export const createReminderValidation = yup.object().shape({
   title: yup
   .string()
   .nullable()
   .required("Title is required."),
   date: yup
   .string()
   .nullable()
   .required("required."),
   start_time: yup
   .string()
   .nullable()
   .required("required."),
   type: yup
   .object()
   .nullable()
   .required("required."),
})
export const createLeadReminderValidation = yup.object().shape({
  title: yup
  .string()
  .nullable()
  .required("Title is required."),
  date: yup
  .string()
  .nullable()
  .required("required."),
  start_time: yup
  .string()
  .nullable()
  .required("required."),

})