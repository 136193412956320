import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { defaultMentionStyles, defaultStyles } from "../../../../../../config";
import {
  filterLinks,
  getFileNameFromURL,
  getPingMessage,
} from "../../../../../../utils";
import { fetchUsersDP } from "../../../../../../utils/api/users.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import AttachmentBar from "../../../../../AttachmentBar";
import { Select } from "../../../../../common";
import AttachmentViewer from "../../../../../common/AttachmentViewer";
import Loader from "../../../../../common/Spinner";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";
import { SendMsgIcon } from "../../../../../icons/svg";
import NoItem from "../NoItem";
import MessageStyles from "./Messages.module.scss";
import DOMPurify from "dompurify";
import GTranslateIcon from "@mui/icons-material/GTranslate";

type MessageThreadType = {
  clickedRecord: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
  selectedDate?: string;
  clickedRow: any;
  type: string;
  messageThreadProps?: any;
  ping?: boolean;
};

function MessageThread({
  setClickedRecord,
  messageThreadProps,
  clickedRow,
  ping = false,
}: MessageThreadType) {
  const {
    lineDevices,
    ref,
    sendMessage,
    thread,
    attachmentMutation,
    dateFormatter,
    divRefCurrent,
    device,
    setDevice,
    attachments,
    setAttachments,
    messageBody,
    setMessageBody,
    mutation,
    setMessageThread,
    setInitialLoad,
    setMentions,
    setTranslate,
    translateMessage,
    translatedArray,
    setTranslatedArray,
  } = messageThreadProps;
  const [shouldShowIndicator, setShouldShowIndicator] = useState(false);
  const [visibleSelectId, setVisibleSelectId] = useState<string | null>(null);
  const [selectedTranslation, setSelectedTranslation] = useState<string | null>(
    null
  );
  const toggleSelectVisibility = (id: string) => {
    setVisibleSelectId(visibleSelectId === id ? null : id);
  };
  const options = [
    { label: "To English", value: "english" },
    { label: "To Spanish", value: "spanish" },
  ];

  const user = useUserInfo("user");

  useEffect(() => {
    return () => {
      const { current }: { current: any } = ref;
      current?.remove();
      ref.current = null;
      setMessageThread({});
      setInitialLoad(false);
      setMessageBody("");
      setTranslate({});
      setTranslatedArray([]);
    };
  }, [ref, setInitialLoad, setMessageThread, setMessageBody]);
  function fetchUsers(query: any, callback: any) {
    // if (!query) return;
    try {
      fetchUsersDP({
        searchValue: query,
        dealership_id: user.dealership.id,
        status: "active",
      })
        // Transform the users to what react-mentions expects
        .then((res) => {
          return (
            res?.users
              ?.filter((userInst: any) => userInst.id !== user.id)
              ?.map((user: any) => ({
                display: user.full_name,
                id: user.id,
              })) || []
          );
        })
        .then(callback);
    } catch (error) {}
  }

  const { messages = [] } = thread || {};

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      background="var(--grey-50)"
      padding="1rem 1.3rem"
      paddingTop="0"
      h={`calc(100vh - ${ping ? 100 : 260}px)`}
      id="message-body"
    >
      {(attachmentMutation.isLoading || mutation.isLoading) && <Loader />}
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1rem 0"
        borderTop="1px solid var(--grey-300)"
      >
        <Button variant="none" display="flex" alignItems="center" gap="0.63rem">
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
            onClick={() => setClickedRecord(null)}
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "black",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem"
        border="1px solid var(--grey-300)"
        position="relative"
        h="100%"
        borderRadius="0.5rem"
      >
        <VStack
          alignItems="flex-start"
          w="100%"
          h={`calc(100vh - ${ping ? 400 : 580}px)`}
          overflowY="auto"
          marginBottom="auto"
          gap="0.96rem"
          padding="1.3rem"
          ref={ref as any}
          id="message-container"
          onScroll={({ target }: { target: any }) => {
            const isAtBottom =
              target.scrollHeight - target.clientHeight <= target.scrollTop + 1; // Adding a tolerance
            if (isAtBottom) {
              setShouldShowIndicator(false);
            } else {
              !shouldShowIndicator && setShouldShowIndicator(true);
            }
          }}
        >
          {!!messages?.length ? (
            messages.map((item: any, index: number) => (
              <>
                {(dateFormatter(messages[index]?.created_at) !==
                  dateFormatter(messages[index - 1]?.created_at) ||
                  !messages.some(
                    (message: any) =>
                      dateFormatter(message.created_at) ===
                      dateFormatter(messages[0]?.created_at)
                  )) && (
                  <HStack w="100%" gap="1.5rem">
                    <Box
                      as="span"
                      width="100%"
                      height="1px"
                      background="var(--grey-300)"
                    ></Box>
                    <Box
                      as="span"
                      fontSize="0.71rem"
                      fontWeight="500"
                      color="var(--grey-700)"
                      whiteSpace="nowrap"
                    >
                      {moment(item.created_at).format("ddd D MMM, YYYY")}
                    </Box>
                    <Box
                      as="span"
                      width="100%"
                      height="1px"
                      background="var(--grey-300)"
                    ></Box>
                  </HStack>
                )}
                <VStack
                  w="50%"
                  gap="0.25"
                  maxW="22rem"
                  alignSelf={
                    item.message_status === "received"
                      ? "flex-start"
                      : "flex-end"
                  }
                  ref={index === messages.length - 1 ? divRefCurrent : null}
                >
                  <HStack w="100%" justifyContent="space-between">
                    <Text
                      fontSize="0.71rem"
                      color="var(--grey-700)"
                      fontWeight="500"
                    >
                      {item.is_scheduled ? (
                        <>
                          <Text textStyle="h6" color="var(--secondary-600)">
                            Scheduled At:
                          </Text>{" "}
                          {moment(item.scheduled_at).format(
                            "ddd D MMM, YYYY HH:mma"
                          )}
                        </>
                      ) : (
                        moment(item.created_at).format("ddd D MMM, YYYY HH:mma")
                      )}
                    </Text>
                    <Text
                      fontSize="0.83rem"
                      color="var(--grey-900)"
                      fontWeight="400"
                    >
                      <TooltipIfOverflow>
                        {item.message_source === "sarah_ai"
                          ? "Sarah.ai"
                          : item.message_status === "received"
                          ? item?.contact_name
                          : item.user_name
                          ? item.user_name
                          : item.from}
                      </TooltipIfOverflow>
                    </Text>
                  </HStack>
                  <VStack
                    alignItems="flex-start"
                    padding="0.96rem 1.32rem 0.67rem 0.9rem"
                    borderRadius="0.5rem"
                    alignSelf={
                      item.message_status === "received" ? "baseline" : "end"
                    }
                    width="100%"
                    style={
                      !(item.message_status === "received")
                        ? {
                            background: "#fafafa",
                            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                          }
                        : { background: "var(--secondary-50)" }
                    }
                    className="messageHistory"
                  >
                    {/* <Text
                      fontSize="0.83rem"
                      color="var(--grey-900)"
                      fontWeight="500"
                      style={{ lineBreak: "anywhere" }}
                      dangerouslySetInnerHTML={{
                        __html: getPingMessage(item.content),
                      }}
                    ></Text> */}

                    <Text
                      fontSize="0.83rem"
                      color="var(--grey-900)"
                      fontWeight="500"
                      style={{ lineBreak: "anywhere" }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          getPingMessage(item.content)
                        ),
                      }}
                    />
                    <Box
                      mt="0.5rem"
                      display="flex"
                      flexWrap="wrap"
                      width="100%"
                    >
                      {filterLinks(item.media_urls || [])?.map((media: any) => {
                        return (
                          <img
                            src={media}
                            alt={media}
                            width={100}
                            height={100}
                          />
                        );
                      })}
                    </Box>

                    {filterLinks(item.media_urls || [], false)?.map(
                      (media: any) => {
                        const filename = getFileNameFromURL(media);
                        return (
                          <a href={media} download>
                            {filename}
                          </a>
                        );
                      }
                    )}

                    {item?.content && (
                      <VStack w="100%" alignItems="flex-start">
                        {(!translatedArray?.find(
                          (items: any) => items?.id == item?.id
                        )&&!item?.translation?.language )? (
                          <HStack justifyContent="flex-end" w="100%">
                            <Button
                              variant="none"
                              onClick={() => {
                                toggleSelectVisibility(item.id);
                                setTranslate({
                                  content: item.content,
                                  id: item.id,
                                });
                              }}
                            >
                              <GTranslateIcon />
                            </Button>
                            {visibleSelectId === item.id && (
                              <>
                                <Select
                                  label=""
                                  onChange={(event) => {
                                    if (item?.translation) {
                                      setTranslatedArray((prev: any) => [
                                        ...prev,
                                        {
                                          id: item?.id,
                                          translated: item?.translation,
                                          language: event.value,
                                        },
                                      ]);
                                    } else {
                                      translateMessage(event.value);
                                      setTranslate((prevState: any) => ({
                                        ...prevState,
                                        ["language"]: event.value,
                                      }));
                                    }
                                  }}
                                  options={options}
                                  variant="default"
                                  w="100%"
                                  maxW="8rem"
                                  h="3rem"
                                />
                                {selectedTranslation && (
                                  <Text
                                    mt="0.5rem"
                                    fontSize="0.83rem"
                                    color="var(--grey-700)"
                                  >
                                    {selectedTranslation}
                                  </Text>
                                )}
                              </>
                            )}
                          </HStack>
                        ) : (
                          <>
                            <Text
                              fontSize="0.74rem"
                              color="var(--grey-900)"
                              fontWeight="700"
                              style={{ lineBreak: "anywhere" }}
                              fontStyle="bold"
                            >
                              Translated Text:
                            </Text>
                            <Text
                              fontSize="0.73rem"
                              color="var(--grey-600)"
                              fontWeight="500"
                              // style={{ lineBreak: "anywhere" }}
                              fontStyle="italic"
                              maxW="100%"
                            >
                              {item?.translation?.language?item?.translation?.[item?.translation?.language]:translatedArray?.find(
                                (items: any) => items?.id == item?.id
                              )?.language === "spanish"
                                ? translatedArray?.find(
                                    (items: any) => items?.id == item?.id
                                  )?.translated?.spanish
                                : translatedArray?.find(
                                    (items: any) => items?.id == item?.id
                                  )?.translated?.english}
                            </Text>{" "}
                          </>
                        )}
                      </VStack>
                    )}
                  </VStack>
                </VStack>
              </>
            ))
          ) : (
            <NoItem />
          )}
          {shouldShowIndicator && (
            <span
              className={MessageStyles.ScrollIndicator}
              onClick={() => {
                const objDiv = document.getElementById("message-container");
                if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
              }}
            >
              <span
                style={{
                  marginTop: 6,
                }}
              >
                <KeyboardArrowDownIcon />
              </span>
            </span>
          )}
        </VStack>

        <VStack
          w="100%"
          alignItems="flex-start"
          gap="0"
          background="white"
          position="absolute"
          borderBottomRadius="0.5rem"
          bottom="0"
          left="0"
        >
          <AttachmentBar
            type="sms"
            setBody={(text, concatText, type = "text") => {
              if (type === "text") {
                const messagesText = `${messageBody} ${
                  concatText ? `${concatText}: ` : ""
                }${text}`;
                setMessageBody(messagesText);
              } else {
                setAttachments((prev: any) => [...prev, text]);
              }
            }}
            handleTemplate={(value: string) => setMessageBody(value)}
            contactId={clickedRow?.uuid}
          />

          <AttachmentViewer
            attachments={attachments}
            setAttachments={setAttachments}
          />
          <HStack w="100%" padding="0">
            <Select
              onChange={(value: any) => setDevice(value.value)}
              placeholder="Select Line"
              options={
                lineDevices?.map((lineDevice: any) => ({
                  value: lineDevice.phoneNumber,
                  label: lineDevice.phoneNumber,
                })) || []
              }
              variant="default"
              w="100%"
              value={device}
            />
          </HStack>

          <HStack
            w="100%"
            padding="0"
            paddingLeft="4"
            borderBottom="1px solid var(--grey-300)"
          >
            <Text
              textStyle="h6"
              fontWeight="400"
              color="var(--grey-600)"
              m="8px 0"
            >
              You are messaging from this line
            </Text>
          </HStack>

          <HStack w="100%" padding="0">
            <MentionsInput
              value={messageBody}
              onChange={(_: any, newValue: string) => setMessageBody(newValue)}
              style={{ ...defaultStyles, width: "100%" }}
            >
              <Mention
                displayTransform={(id: string, display: string) =>
                  `@${display}`
                }
                trigger="@"
                data={fetchUsers}
                style={defaultMentionStyles}
                appendSpaceOnAdd
                onAdd={(id: any) => setMentions((prev: any) => [...prev, id])}
              />
            </MentionsInput>
            <Button
              variant="none"
              padding="1rem"
              borderRadius="0.5rem"
              background="var(--primary-600)"
              isDisabled={!(messageBody && device)}
              onClick={sendMessage}
            >
              <SendMsgIcon />
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default MessageThread;
