import { Button, DrawerFooter, VStack, Text, useToast } from "@chakra-ui/react";
import { Drawer } from "../../common";
import { useMutation } from "@tanstack/react-query";
import { suspendOrReactivate } from "../../../utils/api/disposition-settings.api";

type data = {
  isOpen: boolean;
  onClose: any;
  id: number;
  refetchParent: any;
  susccessToast: string;
  failureToast: string;
  payload: any;
  title: string;
  bodyText: string;
  endpoint: string;
  mappings: any;
  dId: any;
};
interface ApiResult {
  data: any;
}

const SuspendOrReActivate = (props: data) => {
  const {
    isOpen,
    onClose,
    refetchParent,
    susccessToast,
    failureToast,
    payload,
    title,
    bodyText,
    endpoint,
    mappings,
    dId,
  } = props;
  const toast = useToast();

  const mutation = useMutation<ApiResult, Error, any, unknown>({
    mutationFn: async (payload: any): Promise<ApiResult> => {
      try {
        const response = await suspendOrReactivate(
          endpoint,
          payload,
          mappings,
          dId
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: `${susccessToast}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `${failureToast}: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    mutation.mutate(payload);
    onClose();
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          {bodyText}
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default SuspendOrReActivate;
