import { Box, Flex, ScaleFade } from "@chakra-ui/react";
import GlobalHeader from "./GlobalHeader";

const GlobalContent = ({ children }: ReactChildren) => {
  // const [isMobile] = useMediaQuery("(max-width: 480px)");
  // const [isTablet] = useMediaQuery("(min-width: 481px) and (max-width: 768px)");
  // const [isDesktop] = useMediaQuery("(min-width: 769px)");

  // // const width = isMobile
  // //   ? "20rem"
  // //   : isTablet
  // //   ? "25rem"
  // //   : isDesktop
  // //   ? "40rem"
  // //   : "91.5rem";

  return (
    <Flex
      id="main"
      className="main-content"
      as="main"
      ml={{ base: 0, Tablet: 8 }}
      background="var(--grey-100)"
      flexGrow={1}
      direction="column"
      position="relative"
      w="60%"
    >
      <ScaleFade
        in={true}
        transition={{ enter: { duration: 0.5, delay: 0.3 } }}
      >
        <GlobalHeader />
        <Box mt="4.3rem" height="calc(100vh - 64px)" overflow="auto">
          {children}
        </Box>
      </ScaleFade>
    </Flex>
  );
};

export default GlobalContent;
