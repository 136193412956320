"use client";

import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import { EditIcon, EmptyIcon } from "../icons/svg";
import Select from "./Select";
import TooltipIfOverflow from "./TooltipIfOverflow";

interface TableProps {
  header: any[];
  data: any[];
  itemsPerPage?: number;
  maxPageNumbers?: number;
  isPaginated?: boolean;
  hasMultiBody?: boolean | undefined;
  openDrawer?: () => void | undefined;
  hasPadding?: boolean;
  lastCenter?: boolean;
  isSmall?: boolean;
  isBlue?: boolean;
  isGreen?: boolean;
  hasCheckbox?: boolean;
  isBold?: boolean;
  rowOnClick?: (rowItem: any) => void;
  perPageChange?: (value: any) => void;
  currentPage?: number;
  setCurrentPage?: (value: any) => void;
  rowCount?: number;
  isLoadingData?: boolean;
  tableHeight?: number;
  setParentRecords?: (records: any) => void;
  parentRecords?: any[];
  handleSaveClick?: (itemBody: any) => void;
  handleCancelClick?: () => void;
  isSticky?: boolean;
  is_ro_tradeup?: boolean;
  columnBackgrounds?: string[];
  columnDisableStatus?: boolean[];
  columnMinWidths?: string[];
}

const PaginatedTable = ({
  header,
  data,
  itemsPerPage = 20,
  maxPageNumbers = 3,
  hasMultiBody = false,
  isPaginated = false,
  openDrawer,
  hasPadding = true,
  lastCenter = true,
  isSmall = false,
  isBlue = false,
  isGreen = false,
  hasCheckbox = false,
  tableHeight,
  isBold = false,
  isLoadingData = false,
  rowOnClick,
  perPageChange,
  currentPage = 1,
  setCurrentPage,
  rowCount = 10,
  setParentRecords,
  parentRecords,
  handleSaveClick,
  handleCancelClick,
  isSticky = false,
  is_ro_tradeup = false,
  columnBackgrounds = [],
  columnDisableStatus = [],
  columnMinWidths = [],
}: TableProps) => {
  const [tableData, setTableData] = useState(data);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(20);
  const [paginatedData, setPaginatedData] = useState(
    data?.slice(startIndex, endIndex)
  );
  useEffect(() => {
    setTableData(data);
    setTotalPages(Math.ceil(rowCount / itemsPerPage));
  }, [data, itemsPerPage, rowCount]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const options: SelectOptionProps[] = [
    { label: "20", value: "1" },
    { label: "50", value: "2" },
    { label: "100", value: "3" },
  ];
  const [totalPages, setTotalPages] = useState(Math.ceil(10 / itemsPerPage));
  const handlePageChange = (newPage: number) => {
    if (setCurrentPage) setCurrentPage(newPage);
  };

  const handleSelectChange = (option: SelectOptionProps) => {
    perPageChange?.(option);
  };

  const handleEditClick = (itemIndex: number) => {
    const updatedData = [...tableData];
    updatedData[itemIndex].isEditing = true;
    setTableData(updatedData);
  };

  // const handleSaveClick = (itemIndex: number) => {
  //   const updatedData = [...tableData];
  //   updatedData[itemIndex].isEditing = false;
  //   setTableData(updatedData);
  // };

  const handleCheckboxChange = (itemIndex: number, id: number) => {
    const isSelected = selectedRows.includes(itemIndex);
    const isSelectedParent = parentRecords?.includes(id);
    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((index) => index !== itemIndex)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, itemIndex]);
    }

    if (isSelectedParent) {
      setParentRecords &&
        setParentRecords((prevSelectedRows: any) =>
          prevSelectedRows.filter((index: any) => index !== id)
        );
    } else {
      setParentRecords &&
        setParentRecords((prevSelectedRows: any) => [...prevSelectedRows, id]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedRows?.length === paginatedData?.length) {
      setSelectedRows([]);
      setParentRecords && setParentRecords([]);
    } else {
      setSelectedRows(paginatedData?.map((_, index) => index));
      setParentRecords &&
        setParentRecords(paginatedData?.map((data: any) => data.id));
    }
  };

  const renderPageNumbers = () => {
    const middlePage = Math.floor(maxPageNumbers / 2);
    let startPage = currentPage - middlePage;
    if (startPage < 1) {
      startPage = 1;
    }
    let endPage = startPage + maxPageNumbers - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers?.map((pageNumber) => (
      <Box
        as="span"
        key={pageNumber}
        padding="0.62rem 0.75rem"
        cursor="pointer"
        color={currentPage === pageNumber ? "white" : "var(--grey-900)"}
        fontSize="1rem"
        fontWeight="400"
        lineHeight="1.25rem"
        borderRadius="0.375rem"
        minWidth="2.0625rem"
        textAlign="center"
        background={
          currentPage === pageNumber ? "var(--secondary-500)" : "none"
        }
        onClick={() => handlePageChange(pageNumber)}
      >
        {pageNumber}
      </Box>
    ));
  };

  return (
    <>
      <Box
        padding={hasPadding ? "0 16px 24px" : "0"}
        width="100%"
        position="relative"
        fontFamily={"Red Hat Display variable"}
      >
        <Box
          width="100%"
          border={!hasMultiBody ? "1px solid var(--grey-300)" : "none"}
          borderRadius={!hasMultiBody ? "8px" : "0"}
          maxH={`calc(100vh - ${tableHeight ? `${tableHeight}px` : "500px"})`}
          h="100%"
          overflow="auto"
          marginBottom="5rem"
        >
          <Table
            variant="simple"
            width="100%"
            fontFamily={"Red Hat Display variable"}
          >
            <Thead
              background="var(--grey-100, #F5F5F5)"
              borderBottom="1px solid var(--grey-300)"
              borderTopLeftRadius="8px"
              borderTopRightRadius="8px"
              position="sticky"
              top="0"
              zIndex={2}
              fontFamily={"Red Hat Display variable"}
            >
              <Tr fontFamily={"Red Hat Display variable"}>
                {hasCheckbox && (
                  <Th
                    borderRight="1px solid var(--grey-300)"
                    borderTopLeftRadius="8px"
                    background={
                      isBlue
                        ? "var(--primary-50)"
                        : isGreen
                        ? "var(--secondary-50)"
                        : ""
                    }
                    width="10px"
                    minW="10px"
                    fontFamily={"Red Hat Display variable"}
                  >
                    <Checkbox
                      size="lg"
                      variant="whiteCheck"
                      onChange={handleSelectAllChange}
                      isChecked={
                        !!selectedRows.length &&
                        selectedRows?.length === paginatedData?.length
                      }
                    />
                  </Th>
                )}
                {header?.map((item: any, index: any) => (
                  <Th
                    fontSize="14px"
                    lineHeight="16px"
                    textAlign="left"
                    background={
                      isBlue
                        ? "var(--primary-50)"
                        : isGreen
                        ? "var(--secondary-50)"
                        : ""
                    }
                    fontWeight={isBlue ? "700" : isBold ? "700" : "400"}
                    padding={isSmall ? "0.62rem" : "12px"}
                    borderRight="1px solid var(--grey-300)"
                    textTransform="capitalize"
                    _last={{
                      width: item?.value == "Actions" ? "5rem" : "auto",
                      borderRight: "none",
                      borderTopRightRadius: "8px",
                    }}
                    _first={{
                      borderTopLeftRadius: "8px",
                    }}
                    key={index}
                    color={
                      columnDisableStatus[index]
                        ? "var(--grey-500)"
                        : "var(--grey-900)"
                    }
                    cursor={columnDisableStatus[index] ? "not-allowed" : ""}
                    fontFamily={"Red Hat Display variable"}
                  >
                    <Box>
                      <TooltipIfOverflow>{item?.value}</TooltipIfOverflow>
                    </Box>
                  </Th>
                ))}
              </Tr>
            </Thead>
            {hasMultiBody && (
              <>
                {tableData?.map((itemBody: any, ItemIndex: any) => {
                  const paginatedData = itemBody?.data?.slice(
                    startIndex,
                    endIndex
                  );
                  return (
                    <>
                      <Tbody>
                        <Tr
                          onClick={() => {
                            rowOnClick && rowOnClick(itemBody);
                          }}
                        >
                          <Td
                            colSpan={10}
                            paddingLeft="0"
                            fontFamily={"Red Hat Display variable"}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="0.75rem"
                            >
                              {itemBody?.isEditing ? (
                                <Input
                                  variant="edit"
                                  type="text"
                                  value={itemBody?.name}
                                  onChange={(e) => {
                                    const updatedData = [...tableData];
                                    updatedData[ItemIndex].name =
                                      e.target.value;
                                    setTableData(updatedData);
                                  }}
                                />
                              ) : (
                                <Text
                                  padding="0.25rem 0.32rem"
                                  fontWeight="600"
                                  textStyle="h6"
                                >
                                  <TooltipIfOverflow>
                                    {itemBody.name}
                                  </TooltipIfOverflow>
                                </Text>
                              )}
                              {!itemBody?.isEditing && !is_ro_tradeup && (
                                <Button
                                  variant="none"
                                  display="flex"
                                  alignItems="center"
                                  color="var(--primary-600)"
                                  gap="0.25rem"
                                  onClick={() => handleEditClick(ItemIndex)}
                                >
                                  <EditIcon color="var(--primary-600)" />
                                  Edit
                                </Button>
                              )}
                              {itemBody?.isEditing && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="0.25rem"
                                >
                                  <Button
                                    variant="none"
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => {
                                      handleSaveClick &&
                                        handleSaveClick(itemBody);
                                    }}
                                  >
                                    <CheckCircleIcon
                                      style={{
                                        color: "var(--secondary-600)",
                                        width: "20px",
                                      }}
                                    />
                                  </Button>
                                  <Button
                                    variant="none"
                                    display="flex"
                                    alignItems="center"
                                    onClick={() =>
                                      handleCancelClick && handleCancelClick()
                                    }
                                  >
                                    <CancelIcon
                                      style={{
                                        color: "var(--red-600)",
                                        width: "20px",
                                      }}
                                    />
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                      <Tbody
                        key={ItemIndex}
                        border="1px solid var(--grey-300)"
                        borderRadius="8px"
                      >
                        {paginatedData?.map((item: any, index: any) => (
                          <Tr
                            borderBottom="1px solid var(--grey-300)"
                            _last={{
                              borderBottom: "none",
                            }}
                            key={index}
                            onClick={() => {
                              rowOnClick && rowOnClick(item?.campaign_id);
                            }}
                          >
                            {header?.map((column: any, colIndex: any) => (
                              <Td
                                height="58px"
                                color="var(--grey-900)"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="16px"
                                textAlign="left"
                                borderRight="1px solid var(--grey-300)"
                                _last={{
                                  borderRight: "none",
                                  textAlign: "center",
                                }}
                                padding="12px"
                                key={colIndex}
                                fontFamily={"Red Hat Display variable"}
                              >
                                {" "}
                                {typeof item[column?.label] === "string" ||
                                Array.isArray(item[column?.label]) ? (
                                  <TooltipIfOverflow>
                                    {item[column?.label]}
                                  </TooltipIfOverflow>
                                ) : (
                                  item[column?.label]
                                )}
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                    </>
                  );
                })}
                {!is_ro_tradeup && (
                  <Tbody
                    position={isSticky ? "sticky" : "relative"}
                    bottom={isSticky ? "0px" : ""}
                    background={isSticky ? "#fff" : ""}
                  >
                    <Tr>
                      <Td
                        colSpan={10}
                        paddingLeft="0"
                        borderBottom={isSticky ? "0px" : "1px"}
                        fontFamily={"Red Hat Display variable"}
                      >
                        <Button
                          variant="outlineBlue"
                          onClick={openDrawer}
                          color="var(--primary-default)"
                        >
                          Create Category
                        </Button>
                      </Td>
                    </Tr>
                  </Tbody>
                )}
              </>
            )}
            {!hasMultiBody && (
              <Tbody>
                {tableData?.map((item: any, index: any) => (
                  <Tr
                    borderBottom="1px solid var(--grey-300)"
                    _last={{
                      borderBottom: "none",
                    }}
                    key={index}
                    onClick={() => {
                      rowOnClick && rowOnClick(item);
                    }}
                  >
                    {hasCheckbox && (
                      <Td
                        borderRight="1px solid var(--grey-300)"
                        fontFamily={"Red Hat Display variable"}
                      >
                        <Checkbox
                          variant="whiteCheck"
                          size="lg"
                          onChange={() => handleCheckboxChange(index, item.id)}
                          isChecked={selectedRows.includes(index)}
                        />
                      </Td>
                    )}
                    {header?.map((column, colIndex) => (
                      <Td
                        height={isSmall ? "2rem" : "58px"}
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight={isSmall ? "0.62rem" : "16px"}
                        // background={isBlue ? "white" : ""}
                        background={
                          columnBackgrounds[colIndex] || (isBlue ? "white" : "")
                        }
                        textAlign="left"
                        borderRight="1px solid var(--grey-300)"
                        _last={{
                          width: item?.value == "Actions" ? "5rem" : "auto",
                          borderRight: "none",
                          textAlign: lastCenter ? "center" : "left",
                        }}
                        _hover={{
                          color: isSmall && "var(--primary-600)",
                          background: isSmall && "var(--grey-200)",
                        }}
                        padding="12px"
                        key={colIndex}
                        cursor={
                          columnDisableStatus[colIndex] ? "not-allowed" : ""
                        }
                        color={
                          columnDisableStatus[colIndex]
                            ? "var(--grey-500)"
                            : "var(--grey-900)"
                        }
                        fontFamily={"Red Hat Display variable"}
                        minWidth={columnMinWidths[colIndex] ?? ""}
                      >
                        {typeof item[column?.label] === "string" ||
                        Array.isArray(item[column?.label]) ? (
                          <TooltipIfOverflow>
                            {item[column?.label]}
                          </TooltipIfOverflow>
                        ) : (
                          item[column?.label]
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            )}
            {/* No Data */}
            {!isLoadingData &&
              (tableData?.length === 0 || tableData === undefined) && (
                <Tbody>
                  <Tr>
                    <Td colSpan={10}>
                      <VStack w="100%" height="400px" justifyContent="center">
                        <EmptyIcon />
                        <Text
                          textStyle="h5"
                          fontWeight="400"
                          color="var(--grey-500)"
                        >
                          Oops! Looks like there's no data at the moment.
                        </Text>
                      </VStack>
                    </Td>
                  </Tr>
                </Tbody>
              )}
            {isLoadingData && (
              <Tbody>
                <Tr>
                  {header?.map((item) => (
                    <Td>
                      <SkeletonText
                        noOfLines={10}
                        spacing="7"
                        skeletonHeight="2"
                      />
                    </Td>
                  ))}
                </Tr>
              </Tbody>
            )}
          </Table>
        </Box>
        {isPaginated && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid var(--grey-300)"
            padding="16px"
            borderBottomRightRadius="0.5rem"
            borderBottomLeftRadius="0.5rem"
            position="absolute"
            bottom="0"
            right="0"
            w="100%"
            background="white"
          >
            <Select
              // label="Select an option"
              options={options}
              value={itemsPerPage}
              onChange={handleSelectChange}
              placeholder={itemsPerPage.toString()}
              isSearchable={true}
              placement="top"
              // isMulti={true}
              variant="table"
            />
            <HStack spacing="2">
              <Button
                variant="none"
                display="flex"
                alignItems="center"
                onClick={() => handlePageChange(currentPage - 1)}
                isDisabled={currentPage === 1}
              >
                <KeyboardArrowLeftIcon />
              </Button>
              {renderPageNumbers()}
              <Button
                display="flex"
                variant="none"
                alignItems="center"
                onClick={() => handlePageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </HStack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PaginatedTable;
