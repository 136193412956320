import { Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { fetchHome } from "../../../utils/api/guest-mode";

export default function GuestMode() {
  const { id, dId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["fetchHome", { contact_uuid: id }, dId],
    queryFn: fetchHome,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
  });

  return (
    <VStack w="100%" mt="2.5rem" px={{ base: "1rem", md: "0" }}>
      <VStack
        w={{ base: "100%", md: "60%" }}
        alignItems="flex-start"
        background="#fff"
        borderRadius="0.5rem"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Text
          textStyle={{ md: "h2", base: "h4" }}
          textAlign="left"
          fontWeight="400"
          color="var(--primary-300)"
        >
          Welcome to {data?.data?.dealership_name}'s Private Portal!
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          Welcome to our secure and private portal, where you can explore at
          your own pace. Whether you're interested in purchasing or leasing a
          vehicle, checking your credit, valuing your current car or truck, or
          simply browsing our inventory, everything is conveniently accessible
          with just a click.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          To protect your privacy, we ask that you create a profile with us.
          Rest assured, your information will not be sold or shared. Your
          profile stores all your activities within our portal, allowing you to
          pause and resume whenever you like, without any pressure. If you
          decide to visit our dealership, your saved information will expedite
          the process.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          Explore the various options in the menu at your leisure and in any
          sequence that suits you. If you need assistance or feel uncertain at
          any point, we're just a phone call, email, or text away. Our contact
          details are at the bottom of each page.
        </Text>
        <Text
          textStyle={{ md: "h4", base: "bodySmall" }}
          mb={{ md: "1rem", base: "0.5rem" }}
        >
          Ready to get started? Discovering your options has never been this
          simple—give it a try!
        </Text>
      </VStack>
      {isLoading && <Spinner />}
    </VStack>
  );
}
