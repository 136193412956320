import { HStack, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import UptimeChart from "../../../../components/Charts/UptimeChart";
import { Spinner } from "../../../../components/common";
import DatePicker from "../../../../components/common/DateRangePicker";
import { makeDataPoints } from "../../../../utils";
import { fetchSystemHealthGraphs } from "../../../../utils/api/global-dashboard";
import UptimeGraph from "./UptimeGraph";
import UptimeGraphExpandable from "./UptimeGraphExpandable";

const SystemHealth = () => {
  const [cpuAndMemoryUtilization, setCpuAndMemoryUtilization] =
    useState<any>(null);
  const [network, setNetwork] = useState<any>(null);
  const [dbLoad, setDbLoad] = useState<any>(null);
  const [database, setDatabase] = useState<any>(null);
  const [isBack, setIsBack] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const { isLoading } = useQuery({
    queryKey: [
      "fetchSystemHealthGraphs",
      selectionRange?.startDate,
      selectionRange?.endDate,
    ],
    queryFn: fetchSystemHealthGraphs,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setDbLoad({
        series: [
          {
            name: "DB Load",
            data: makeDataPoints(
              data?.data?.db_load?.timestamps,
              data?.data?.db_load?.values
            ),
            color: "var(--secondary-700)",
          },
        ],
        xaxisTitle: "Time",
        yaxisTitle: "Units",
        isSmall: true,
      });

      setNetwork({
        series: [
          {
            name: "Network-In",
            data: makeDataPoints(
              data?.data?.network_in?.timestamps,
              data?.data?.network_in?.values?.map((item: any) => item / 1048576)
            ),
            color: "#4F46E5",
          },
          {
            name: "Network-Out",
            data: makeDataPoints(
              data?.data?.network_out?.timestamps,
              data?.data?.network_out?.values?.map(
                (item: any) => item / 1048576
              )
            ),
            color: "var(--primary-600)",
          },
        ],
        xaxisTitle: "Time",
        yaxisTitle: "Bytes (M)",
        isSmall: true,
      });

      setCpuAndMemoryUtilization({
        series: [
          {
            name: "CPU Utilization",
            data: makeDataPoints(
              data?.data?.cpu?.timestamps,
              data?.data?.cpu?.values
            ),
            color: "var(--primary-600)",
          },
          {
            name: "Memory Usage",
            data: makeDataPoints(
              data?.data?.memory?.timestamps,
              data?.data?.memory?.values
            ),
            color: "#00CFC1",
          },
        ],
        xaxisTitle: "Time",
        yaxisTitle: "Percentage",
        isSmall: false,
      });

      setDatabase({
        series: [
          {
            name: "Database Connections",
            data: makeDataPoints(
              data?.data?.database_connections?.timestamps,
              data?.data?.database_connections?.values
            ),
            color: "#4F46E5",
          },
        ],
        xaxisTitle: "Time",
        yaxisTitle: "Counts",
        isSmall: false,
      });
    },
  });

  const RightPanel = (
    <VStack w="50%" gap="1rem">
      <HStack w="100%" alignItems="flex-end">
        <DatePicker
          value={selectionRange}
          onChange={(value) => setSelectionRange(value)}
          maxDate={new Date()}
        />
      </HStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
      >
        <Text
          textStyle="h5"
          fontWeight="700"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          padding="1rem"
        >
          Network
        </Text>
        {!network ? (
          <Skeleton height="150px" w="100%" />
        ) : (
          <UptimeChart data={network} fixedTo={0} />
        )}
      </VStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
      >
        <Text
          textStyle="h5"
          fontWeight="700"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          padding="1rem"
        >
          Database Load
        </Text>
        {!dbLoad ? (
          <Skeleton height="150px" w="100%" />
        ) : (
          <UptimeChart data={dbLoad} fixedTo={1} />
        )}
      </VStack>
    </VStack>
  );
  const BottomPanel = (
    <HStack w="100%" gap="1rem">
      <VStack
        w="50%"
        alignItems="flex-start"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
      >
        <Text
          textStyle="h5"
          fontWeight="700"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          padding="1rem"
        >
          CPU and Memory Utilization
        </Text>
        {!cpuAndMemoryUtilization ? (
          <Skeleton height="250px" w="100%" />
        ) : (
          <UptimeChart data={cpuAndMemoryUtilization} fixedTo={1} />
        )}
      </VStack>
      <VStack
        w="50%"
        alignItems="flex-start"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
      >
        <Text
          textStyle="h5"
          fontWeight="700"
          borderBottom="1px solid var(--grey-300)"
          w="100%"
          padding="1rem"
        >
          Database Connections
        </Text>
        {!database ? (
          <Skeleton height="250px" w="100%" />
        ) : (
          <UptimeChart data={database} />
        )}
      </VStack>
    </HStack>
  );

  if (isBack) {
    return (
      <UptimeGraphExpandable
        setIsBack={setIsBack}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
    );
  }
  return (
    <VStack
      alignItems="flex-start"
      gap="1rem"
      padding="1rem"
      h="calc(100vh - 172px)"
      overflow="auto"
    >
      <HStack w="100%" gap="1rem" alignItems="flex-start">
        <UptimeGraph
          setIsBack={setIsBack}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
        {RightPanel}
      </HStack>
      {BottomPanel}
      {isLoading && <Spinner />}
    </VStack>
  );
};

export default SystemHealth;
