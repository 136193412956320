import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import StoreTemplates from "./StoreTemplates";
import MyTemplates from "./MyTemplates";

const SMSTemplate = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  return (
    <Box>
      <Tabs variant="primary" onChange={handleTabChange} defaultIndex={0}>
        <TabList borderTopLeftRadius="0.5rem" borderBottomLeftRadius="0.5rem">
          <Tab padding="0.75rem 1rem">Store Templates</Tab>
          <Tab padding="0.75rem 1rem">My Templates</Tab>
        </TabList>
        <TabPanels>
          <TabPanel mt="1rem">{activeTab === 0 && <StoreTemplates />}</TabPanel>
          <TabPanel mt="1rem">{activeTab === 1 && <MyTemplates />}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SMSTemplate;
