import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Drawer, Input } from "../../../common";
import { SearchIcon, TikIcon } from "../../../icons/svg";
import { getDealershipUsers,getRings } from "../../../../utils/api/line-rings.api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Loader from "../../../common/Spinner";
import { useParams } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export const UserListDrawer = (props: any) => {
  const {id}=useParams()
  const [search,setSearch]=useState('')
  const [result,setResult] = useState([])
  const { data, isFetching } = useQuery({
    queryKey: props?.type==='user'?[
      "userlistdealerships", props?.id,search
    ]:[ 'linerings',id,1,20, search ],
    queryFn: props?.type==='user'?getDealershipUsers:getRings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data){
      setResult(data?.data?.users??data?.data?.ring_groups)

    }
  });

  return (
    <Drawer isOpen={props?.isOpen} onClose={props?.onClose} title={props?.type==='user'?"User List":"Ring Groups"}>
      <VStack w="100%" alignItems="flex-start" gap="0">
        <VStack w="100%" alignItems="flex-start" padding="1rem" gap="1rem">
          <Input
            placeholder="Search..."
            w="100%"
            hasIcon={true}
            icon={<SearchIcon />}
            onChange={(e)=>{setSearch(e.target.value)}}
          />
          <Text
            textStyle="captionSmall"
            color="var(--grey-700"
            fontWeight="500"
          >
            {data?.data?.users_count??data?.data?.line_count} results found
          </Text>
        </VStack>
        <VStack w="100%" alignItems="flex-start" gap="0">
          {result?.map((item:any) => (
            <HStack
              w="100%"
              padding="0.75rem 1rem"
              background={item === 2 ? "var(--grey-100)" : "transparent"}
              position="relative"
              borderBottom="1px solid var(--grey-200)"
              cursor="pointer"
              onClick={()=>{props?.onSelect(item?.id,item?.full_name??item?.name)}}
            >
              {props?.type==='user'&&<AccountCircleIcon  />}
              <VStack gap="0" alignItems="flex-start">
                <Text textStyle="h6" fontWeight="700">
                  {item?.full_name??item?.name}
                </Text>
                <Text textStyle="h6" fontWeight="400">
                {props?.type==='user'&&(item?.phone??'-')}
                </Text>
              </VStack>
               {props?.user_id==item?.id&& <Box
                  position="absolute"
                  right="1rem"
                  top="50%"
                  transform="translateY(-50%)"
                >
                  <TikIcon />
                </Box>}
            </HStack>
          ))}
        </VStack>
      </VStack>
      {isFetching&&<Loader/>}
    </Drawer>
  );
};
