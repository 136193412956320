import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchData = async (
  endpoint: string,
  params?: Record<string, any>
) => {
  try {
    const response = await axios.get(`${SERVICES.apiBaseUrl}${endpoint}`, {
      params,
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchById = async (endpoint: string) => {
  try {
    const response = await axios.get(`${SERVICES.apiBaseUrl}${endpoint}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const deleteSMSTemplate = async (endpoint: string, headers: any) => {
  try {
    const response = await axios.delete(`${SERVICES.apiBaseUrl}${endpoint}`, {
      headers: headers,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateSMSTemplate = async (
  endpoint: string,
  payload: any,
  headers: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}${endpoint}`,
      payload,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createSMSTemplate = async (
  endpoint: string,
  payload: any,
  headers: any
) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}${endpoint}`,
      payload,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchVariables = async (params?: Record<string, any>) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/variables`,
      { params }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchVariablesActive = async (params?: Record<string, any>) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/variables/fetch_variables`,
      { params }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateVariables = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/variables`,
      payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Variables",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
