import BarChart from "../../../../components/Charts/BarChart";
import {
  CustomRadio,
  Drawer,
  Dropdown,
  Input,
  PaginatedTable,
  Select,
} from "../../../../components/common";
import SwitchToggle from "../../../../components/common/Switch";
import { EditIcon, SettingsIcon } from "../../../../components/icons/svg";
import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  Flex,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import UnderConstruction from "../../../UnderConstruction/UnderConstruction";
import GraphUsage from "./GraphUsage";
import BasicInformation from "./BasicInformation";

const Dealership = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const options: SelectOptionProps[] = [
    { label: "Communication Logs", value: "logs" },
    { label: "Pause", value: "pause" },
  ];
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };
  const tableHeader = [
    { id: 1, value: "ID", label: "id" },
    { id: 2, value: "Customer Profile ID", label: "profileID" },
    { id: 3, value: "Active", label: "active" },
    { id: 4, value: "Name", label: "name" },
    { id: 5, value: "SID", label: "sid" },
    { id: 6, value: "Type", label: "type" },
    { id: 7, value: "Status", label: "status" },
    { id: 8, value: "Submitted At", label: "submittedAt" },
    { id: 9, value: "Actions", label: "actions" },
  ];
  const tableData = [
    {
      id: "#123",
      profileID: "---",
      active: (
        <Box
          as="span"
          width="5.875rem"
          padding="0.25rem 0.625rem"
          borderRadius="2.5rem"
          border="1px solid var(--secondary-600)"
          color="var(--secondary-700)"
          fontSize="0.875rem"
        >
          Yes
        </Box>
      ),
      name: "Autoexpo Ent. Inc.",
      sid: "Bua0f0b98jn4...",
      type: "Standard",
      status: (
        <Box
          as="span"
          width="5.875rem"
          padding="0.25rem 0.625rem"
          borderRadius="2.5rem"
          border="1px solid var(--secondary-600)"
          color="var(--secondary-700)"
          fontSize="0.875rem"
        >
          Approved
        </Box>
      ),
      submittedAt: "Aug 20, 2021",
      actions: (
        <Button
          variant="none"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="0.25rem 0.62rem"
        >
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
        </Button>
      ),
    },
  ];

  return (
    // <UnderConstruction/>
    <VStack padding="1.5rem" w="100%" alignItems="flex-start" gap="2rem">
      <HStack w="100%" gap="1.5rem" alignItems="flex-start">
        <BasicInformation />
        <GraphUsage />
      </HStack>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        title="Register Customer Profile"
      >
        <VStack
          w="100%"
          alignItems="flex-start"
          padding="1rem 1.25rem"
          gap="1rem"
        >
          <Text textStyle="h4" fontWeight="700">
            One-time Fee
          </Text>
          <Text textStyle="h6" fontWeight="400">
            Please be aware that there is a one-time $44 registration fee for US
            A2P (Application to Person) so carriers know your business will be
            using A2P Messaging.
          </Text>
          <Checkbox variant="whiteCheck">
            I agree to a one - time $44 fee
          </Checkbox>
          <Input label="Business Information" placeholder="Add Information" />
          <Select
            label="Business Type"
            onChange={(value: any) => console.log(value)}
            placeholder="Select Business Type"
            options={options}
            variant="default"
            w="100%"
          />
          <CustomRadio
            label="Company Status"
            options={[
              { label: "Private", value: "private" },
              { label: "Public", value: "public" },
            ]}
          />
          <Select
            label="Business ID Type"
            onChange={(value: any) => console.log(value)}
            placeholder="Select Business ID Type"
            options={options}
            variant="default"
            w="100%"
          />
          <Select
            label="Business Industry"
            onChange={(value: any) => console.log(value)}
            placeholder="Select Business Industry"
            options={options}
            variant="default"
            w="100%"
          />
          <Input
            label="Business Registration Number"
            placeholder="Add Details"
          />
          <Input label="Website URL" placeholder="Add Website URL" />

          <Select
            label="Business Region of Operation"
            onChange={(value: any) => console.log(value)}
            placeholder="Select Business Region of Operation"
            options={options}
            variant="default"
            w="100%"
          />
          <Input label="Stock Ticker & Exchange" placeholder="Add Details" />
          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            <Button variant="outline" w="100%" size="xl">
              Cancel
            </Button>
            <Button w="100%" size="xl">
              Register Customer Profile
            </Button>
          </DrawerFooter>
        </VStack>
      </Drawer>
    </VStack>
  );
};

export default Dealership;
