import {
  Box,
  Button,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import AttachmentBar from "../../../components/AttachmentBar";
import { Drawer, Input, Select, Spinner } from "../../../components/common";
import AttachmentViewer from "../../../components/common/AttachmentViewer";
import TextEditor from "../../../components/common/TextEditor";
import ScheduleModal from "../../../components/Drawers/SmsDrawer/ScheduleModal";
import {
  createAnnouncement,
  createSmsAnnouncement,
  createSmsTemplate,
  createTemplate,
  editAnnouncement,
  editSmsAnnouncement,
  fetchAnnouncementById,
  fetchSmsAnnouncementById,
  fetchUsers,
} from "../../../utils/api/announcements";
import { fetchRoles } from "../../../utils/api/users.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import UserListing from "./UserListing";
import {
  ComposeBlastForm,
  composeBlastValidation,
  defaultValues,
  getPayload,
  loadDealerships,
  throttlingOptions,
} from "./utils";

const ComposeBlast = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const type = searchParam.get("type");
  const user = useUserInfo("user");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const editorRef = useRef();
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [removedUsers, setRemovedUsers] = useState<any[]>([]);
  const [removedUsersParam, setRemovedUsersParam] = useState<any[]>([]);
  const [countEdit, setCountEdit] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const mutation = useMutation(createAnnouncement);
  const mutationEdit = useMutation(editAnnouncement);
  const mutationSms = useMutation(createSmsAnnouncement);
  const mutationEditSms = useMutation(editSmsAnnouncement);
  const mutationSaveAsTemplate = useMutation(createTemplate);
  const mutationSaveAsTemplateSms = useMutation(createSmsTemplate);
  const toast = useToast();

  const { handleSubmit, control, setValue, watch, getValues } =
    useForm<ComposeBlastForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(composeBlastValidation()) as any,
    });

  const { isLoading: isLoadingAnnouncement } = useQuery({
    queryKey: ["fetchAnnouncementById", id, type],
    queryFn: type ? fetchSmsAnnouncementById : fetchAnnouncementById,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setValue(
        "dealerships",
        data?.data?.dealerships?.map((item: any) => ({
          value: item?.value?.toString(),
          label: item?.label,
        }))
      );
      setValue(
        "role",
        data?.data?.roles?.map((item: any) => ({
          value: item?.value?.toString(),
          label: item?.label,
        }))
      );
      setValue("users", data?.data?.contacts_count);
      setValue("throttling_rate", data?.data?.throttling_rate);
      setValue("messageBody", data?.data?.body);
      setValue("messageBody", type ? data?.data?.content : data?.data?.body);
      setValue("subject", type ? data?.data?.name : data?.data?.subject);

      setRemovedUsersParam(data?.data?.removed_users); // added this so that the announcement dealership users api does not refetch on each removed contact
      setAttachmentUrls(
        type ? data?.data?.media_urls : data?.data?.attachment_urls
      );
      setCountEdit(data?.data?.contacts_count);
    },
  });

  const formVals = watch();

  const makeCountEditNull = () => {
    if (id) {
      setCountEdit(null);
      setRemovedUsersParam([]);
    }
  };

  const handleOnChange = (value: any) => {
    if (value?.length === 0) {
      setUsers([]);
    }
    setRemovedUsers([]);
    makeCountEditNull();
  };

  const { isLoading: isLoadingUsers } = useQuery({
    queryKey: [
      "fetchUsers",
      {
        per_page: 10,
        page,
        roles: formVals?.role?.map((role: any) => role.label),
        ids: formVals?.dealerships?.map((dealership: any) => dealership.value),
        removed_users: removedUsersParam,
      },
    ],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: formVals?.role?.length > 0 && formVals?.dealerships?.length > 0,
    onSuccess(data: any) {
      if (page === 1) {
        setUsers(data?.data?.dealership_users);
      } else {
        setUsers((prev: any) => [...prev, ...data?.data?.dealership_users]);
      }
    },
  });

  const disableButtons =
    formVals?.role?.length <= 0 ||
    formVals?.dealerships?.length <= 0 ||
    users?.length - removedUsers?.length === 0 ||
    !formVals?.messageBody ||
    formVals?.messageBody === "<p><br></p>" ||
    !formVals?.subject;

  const disableForEmailTemplate =
    !formVals?.subject ||
    !formVals?.messageBody ||
    formVals?.messageBody === "<p><br></p>";

  const [role, setRole] = useState<any>(null);
  const { data: roles, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
  });

  useEffect(() => {
    setRole(
      roles?.roles?.map((role: any) => ({
        value: role.id.toString(),
        label: role.title,
      }))
    );
  }, [roles]);

  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      editor.insertText(range.index, text, "user");
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;
        editor.insertText(cursorPosition, text);
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };

  const createOrEditAnnouncementMethod = (dateFields: any) => {
    const mutationFunc = id
      ? type
        ? mutationEditSms
        : mutationEdit
      : type
      ? mutationSms
      : mutation;

    mutationFunc.mutate(
      getPayload(dateFields, formVals, removedUsers, attachmentUrls, type, id),
      {
        onSuccess: (data) => {
          toast({
            description: `Announcement ${id ? "edited" : "sent"} successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          navigate("/announcements");
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleFormSubmit = (values: ComposeBlastForm) => {
    createOrEditAnnouncementMethod({});
  };
  const handleSaveAsTemplate = (values: ComposeBlastForm) => {
    const mutationFunc = type
      ? mutationSaveAsTemplateSms
      : mutationSaveAsTemplate;

    const emailPayload = {
      email_template: {
        subject: formVals.subject,
        body: formVals.messageBody,
        template_type: "announcement",
        status: "active",
        attachment_urls: attachmentUrls.map(
          (attachment: any) => attachment.url
        ),
      },
    };

    const smsPayload = {
      sms_template: {
        name: formVals.subject,
        body: formVals.messageBody,
        media_urls: attachmentUrls.map((attachment: any) => attachment.url),
      },
    };

    mutationFunc.mutate(
      {
        payload: type ? smsPayload : emailPayload,
      },
      {
        onSuccess: (data: any) => {
          toast({
            description: `Template created successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          navigate("/announcements");
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleSchedule = (date: Date) => {
    const dateFields = {
      is_scheduled: true,
      date_scheduled: date.toUTCString(),
      broadcast_date: date.toUTCString(),
      broadcast_time: date.toUTCString(),
    };

    createOrEditAnnouncementMethod(dateFields);
    setIsScheduleModalOpen(false);
  };

  return (
    <>
      {isLoading ||
        isLoadingUsers ||
        (id && isLoadingAnnouncement && <Spinner />)}
      <Box p="1.5rem" h="100%" overflow="hidden">
        <VStack
          w="100%"
          alignItems="flex-start"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="white"
          padding="1rem"
          h="100%"
        >
          <HStack justifyContent="space-between" w="100%">
            <Button
              variant="none"
              display="flex"
              alignItems="center"
              gap="0.63rem"
              fontWeight="500"
              onClick={() => navigate("/announcements")}
            >
              <Box
                as="span"
                display="flex"
                alignItems="center"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                padding="0.38rem"
              >
                <ChevronLeftIcon
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    color: "black",
                  }}
                />
              </Box>
              Back
            </Button>
          </HStack>

          <Box h="100%" w="100%">
            <HStack w="100%" justifyContent="space-between" mb="1rem">
              <Text textStyle="h4" fontWeight="500">
                Compose
              </Text>
            </HStack>

            <Box
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              h="calc(100vh - 310px)"
              // width="calc(100vw - 325px)"
            >
              <VStack gap="0" h="100%" className="emailBlastDropdown">
                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text
                    textStyle="h6"
                    padding="1rem"
                    w={"20%"}
                    fontWeight="500"
                  >
                    Dealerships
                  </Text>
                  <Box
                    width="100%"
                    p="4px 6px 7px 7px"
                    borderLeft="1px solid var(--grey-300)"
                  >
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <AsyncSelect
                            loadOptions={(inputValue) =>
                              loadDealerships(inputValue)
                            }
                            onChange={(value: any) => {
                              setValue("dealerships", value);
                              handleOnChange(value);
                            }}
                            placeholder="Enter the dealerships"
                            isMulti={true}
                            value={value}
                            defaultOptions
                            className="dealership-dropdown"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minWidth: "128px",
                                maxWidth: "100%",
                                padding: "2px 4px",
                                borderRadius: "0.5rem",
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                                "&:hover": {
                                  border: "none",
                                },
                                fontSize: "0.875rem",
                                fontWeight: "500",
                                lineHeight: "1rem",
                                color: "#000",
                                display: "flex",
                                background: "transparent",
                              }),
                              container: (provided) => ({
                                ...provided,
                                border: "none",
                                "&:hover": {
                                  border: "none",
                                },
                              }),
                              input: (provided) => ({
                                ...provided,
                                border: "none",
                              }),
                              indicatorsContainer: (provided) => ({
                                ...provided,
                                border: "none",
                                marginRight: "5px",
                              }),
                              dropdownIndicator: (provided, state) => ({
                                ...provided,
                                color: "#000",
                                padding: "0",

                                "&:hover": {
                                  color: "#000",
                                },
                              }),
                              indicatorSeparator: () => ({
                                display: "none",
                              }),
                            }}
                          />
                        );
                      }}
                      name="dealerships"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                </HStack>

                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text
                    textStyle="h6"
                    padding="1rem"
                    w={"20%"}
                    fontWeight="500"
                  >
                    Roles
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Select
                          label=""
                          onChange={(value: any) => {
                            setValue("role", value);
                            handleOnChange(value);
                          }}
                          placeholder="Select Role"
                          options={role}
                          variant="default"
                          w="100%"
                          value={value}
                          isRequired
                          isMulti
                          error={error?.message}
                          borderColor="transparent"
                          parentStyles={{
                            width: "100%",
                            border: "none",
                            borderLeft: 0,
                            borderRadius: 0,
                            borderBottom: error?.message
                              ? "1px solid var(--grey-300)"
                              : "none",
                            boxShadow: "none",
                            "&:hover": {
                              borderLeft: 0,
                              border: "none",
                            },
                          }}
                          boxStyles={{
                            borderLeft: "1px solid var(--grey-300)",
                            paddingLeft: "7px",
                            paddingRight: "6px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            height: "100%",
                          }}
                        />
                      );
                    }}
                    name="role"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>
                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text
                    textStyle="h6"
                    padding="1rem"
                    w={"20%"}
                    fontWeight="500"
                  >
                    Users
                  </Text>
                  <Box
                    w="100%"
                    p="4px 6px 7px 16px"
                    borderLeft="1px solid var(--grey-300)"
                    height="100%"
                  >
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Text onClick={onOpen}>
                            {countEdit
                              ? Number(value) - removedUsers?.length
                              : users?.length - removedUsers?.length}
                          </Text>
                        );
                      }}
                      name="users"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                </HStack>

                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text
                    textStyle="h6"
                    padding="1rem"
                    w={"20%"}
                    fontWeight="500"
                  >
                    Throttling
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Select
                          label=""
                          onChange={(value: any) =>
                            setValue("throttling_rate", value.value)
                          }
                          placeholder="Select Throttling"
                          options={throttlingOptions}
                          variant="default"
                          w="100%"
                          value={value}
                          isRequired
                          error={error?.message}
                          borderColor="transparent"
                          parentStyles={{
                            width: "100%",
                            border: "none",
                            borderLeft: 0,
                            borderRadius: 0,
                            borderBottom: error?.message
                              ? "1px solid var(--grey-300)"
                              : "none",
                            boxShadow: "none",
                            "&:hover": {
                              borderLeft: 0,
                              border: "unset",
                            },
                          }}
                          boxStyles={{
                            borderLeft: "1px solid var(--grey-300)",
                            height: "100%",
                            paddingLeft: "7px",
                            paddingRight: "6px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        />
                      );
                    }}
                    name="throttling_rate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                <HStack
                  w="100%"
                  alignItems="flex-start"
                  borderBottom="1px solid var(--grey-300)"
                  gap="0"
                >
                  <Text textStyle="h6" padding="1rem" w="20%" fontWeight="500">
                    Enter {type ? "Name" : "Subject"}
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          value={value}
                          onChange={onChange}
                          maxW="100%"
                          border="0"
                          borderLeft="1px solid var(--grey-300)"
                          height="48px"
                          borderRadius="0"
                          fontWeight="500"
                          error={error?.message || ""}
                          borderBottom={
                            error?.message ? "1px solid var(--grey-300)" : "0"
                          }
                          errorStyle={{
                            borderLeft: "1px solid var(--grey-300)",
                            paddingTop: "6px",
                            paddingLeft: "8px",
                          }}
                        />
                      );
                    }}
                    name="subject"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>

                {!type ? (
                  <AttachmentBar
                    setBody={handleSetBody}
                    type="email"
                    handleTemplate={(value, body = "") => {
                      setValue("subject", value);
                      setValue("messageBody", body);
                    }}
                    parentMenu={"global"}
                  />
                ) : (
                  <AttachmentBar
                    setBody={(text, concatText, type = "text") => {
                      if (type === "text") {
                        const messagesText = `${getValues("messageBody")} ${
                          concatText ? `${concatText}: ` : ""
                        }${text}`;
                        setValue("messageBody", messagesText);
                      } else {
                        setAttachmentUrls((prev: any) => [...prev, text]);
                      }
                    }}
                    handleTemplate={(value, body = "") => {
                      setValue("subject", value);
                      setValue("messageBody", body);
                    }}
                    parentMenu={"global"}
                  />
                )}

                <Controller
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return type ? (
                      <div
                        style={{
                          width: "100%",
                          height: "calc(100vh - 650px)",
                        }}
                        className="emailMsgEditor"
                      >
                        <Input
                          placeholder="Type here"
                          label=""
                          hasIcon={false}
                          type="text"
                          // maxW="100%"
                          // w="100%"
                          minH={"100%"}
                          height="100%"
                          isTextarea={true}
                          onChange={onChange}
                          // rows={8.5}
                          value={value}
                          whiteSpace={"nowrap"}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "calc(100vh - 300px)",
                          overflow: "auto",
                        }}
                        className="emailComposeWrapper"
                      >
                        <TextEditor
                          value={value}
                          onChange={(value) => setValue("messageBody", value)}
                          error={error?.message}
                          editorRef={editorRef}
                        />
                      </div>
                    );
                  }}
                  name="messageBody"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
                <Box className="attachmentViewer" w="100%">
                  <AttachmentViewer
                    attachments={attachmentUrls}
                    setAttachments={setAttachmentUrls}
                  />
                </Box>
              </VStack>
            </Box>
            <HStack justifyContent="space-between" w="100%" mt="2rem">
              <Text whiteSpace="nowrap">
                Logged in as:{" "}
                <span style={{ color: "blue" }}>{user?.email}</span>
              </Text>
              <HStack justifyContent="flex-end" w="100%">
                <Button
                  w="10rem"
                  onClick={() =>
                    handleSubmit((values) => handleSaveAsTemplate(values))()
                  }
                  isDisabled={disableForEmailTemplate}
                  variant="outline"
                >
                  Save Template
                </Button>
                <Button
                  variant="outline"
                  w="10rem"
                  onClick={() => {
                    setIsScheduleModalOpen(true);
                  }}
                  isDisabled={disableButtons}
                >
                  <HStack>
                    <Text>Schedule</Text>
                  </HStack>
                </Button>
                {!id && (
                  <Button
                    w="10rem"
                    onClick={() =>
                      handleSubmit((values) => handleFormSubmit(values))()
                    }
                    isDisabled={disableButtons}
                  >
                    Send
                  </Button>
                )}
              </HStack>
            </HStack>
          </Box>
        </VStack>

        <Drawer isOpen={isOpen} onClose={onClose} title="Contacts">
          <div
            id="scrollableDiv"
            style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
          >
            <UserListing
              setPage={setPage}
              users={users}
              count={Number(formVals?.users)}
              setRemovedUsers={setRemovedUsers}
              removedUsers={removedUsers}
              type={type}
            />
          </div>
        </Drawer>

        <ScheduleModal
          isOpen={isScheduleModalOpen}
          onClose={() => setIsScheduleModalOpen(false)}
          handleSchedule={handleSchedule}
        />
      </Box>
    </>
  );
};

export default ComposeBlast;
