import {
  Button,
  Checkbox,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Input, PhoneInput, Select } from "../../../components/common";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchContact, updateContact } from "../../../utils/api/guest-mode";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Spinner } from "../../../components/common";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const schema = yup.object().shape({
  employments_attributes: yup.array().of(
    yup.object().shape({
      company_name: yup
        .string()
        .required("Company name is required")
        .nullable()
        .min(1, "Company name cannot be empty"),
      phone: yup
        .string()
        .nullable()
        .test("is-phone-valid", "Phone number is not valid", (value) => {
          if (!value || value === "+1") return true;
          return isValidPhoneNumber(value);
        }),
    })
  ),
});

const AddEmpInfo = () => {
  const { id, dId } = useParams();
  const toast = useToast();

  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { isFetching } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess(data: any) {
      if (data?.data?.contacts) {
        const { address_attributes, employments_attributes, ...rest } =
          data?.data?.contacts;

        // making arrays of key value pairs of fields without nested objects
        Object.entries(rest).forEach(([key, value]: any) => {
          setValue(key, value);
        });

        // nested object
        if (address_attributes) {
          Object.entries(address_attributes).forEach(([key, value]: any) => {
            setValue(`address_attributes.${key}`, value);
          });
        }

        // Update nested employment fields
        if (employments_attributes && employments_attributes.length > 0) {
          employments_attributes.forEach((employment: any, index: any) => {
            append(employment);
          });
        }
      }
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employments_attributes",
  });
  const residency_statuses = ["Parents", "Rent", "Own", "Other"];

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    mutation.mutate(
      { contact_uuid: id,dId, payload: { contact: data } },
      {
        onSuccess: () => {
          toast({
            description: "My profile updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error updating my profile: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack position="relative">
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1rem 0 4rem"
        gap="1rem"
        h={{ md: "calc(100vh - 140px)", base: "calc(100vh - 220px)" }}
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Address
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.62rem"
          >
            <Controller
              name="address_attributes.street_address"
              control={control}
              render={({ field }) => (
                <Input
                  maxLength={200}
                  {...field}
                  type="text"
                  placeholder=""
                  label="Street Address"
                />
              )}
            />
            <HStack w="100%">
              <Controller
                name="address_attributes.unit"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="Unit #"
                    maxW="100px"
                  />
                )}
              />
              <Controller
                name="address_attributes.city"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="City"
                    w="100%"
                  />
                )}
              />
            </HStack>
            <HStack w="100%">
              <Controller
                name="address_attributes.state"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={200}
                    {...field}
                    type="text"
                    placeholder=""
                    label="State"
                  />
                )}
              />
              <Controller
                name="address_attributes.zip"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={10}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Zip"
                  />
                )}
              />
            </HStack>
            <Controller
              name="address_attributes.country"
              control={control}
              defaultValue={"usa"}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Country"
                  onChange={(value: any) =>
                    setValue("address_attributes.country", value?.value)
                  }
                  placeholder="Country"
                  options={[
                    { label: "USA", value: "usa" },
                    { label: "Canada", value: "canada" },
                  ]}
                  variant="default"
                  w="100%"
                />
              )}
            />
            <HStack w="100%">
              <Controller
                name="address_attributes.residence_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Residency Status"
                    onChange={(value: any) =>
                      setValue(
                        "address_attributes.residence_status",
                        value?.value
                      )
                    }
                    placeholder="Residency Status"
                    options={residency_statuses?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                  />
                )}
              />
            </HStack>
            <Controller
              name="address_attributes.rental_mortgage_amount"
              control={control}
              render={({ field }) => (
                <Input
                  maxLength={20}
                  {...field}
                  type="number"
                  placeholder=""
                  label="Rent/Mortgage Amount"
                />
              )}
            />
            <HStack w="100%">
              <Controller
                name="address_attributes.years"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    maxLength={3}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Years"
                  />
                )}
              />
              <Controller
                name="address_attributes.months"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={2}
                    {...field}
                    type="number"
                    placeholder=""
                    label="Month"
                  />
                )}
              />
            </HStack>
          </VStack>
        </VStack>

        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Employment History
          </Text>
          {fields &&
            fields?.map((employment, index) => (
              <VStack
                key={index}
                w="100%"
                alignItems="flex-start"
                padding="1rem"
                borderRadius="0.5rem"
                border="1px solid var(--grey-300)"
                background="#efeded9c"
                gap="0.5rem"
                position="relative"
                mb="10px"
              >
                <Button
                  variant="none"
                  position="absolute"
                  top="0.25rem"
                  right="0.35rem"
                  padding="0"
                  background="transparent"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon
                    style={{ color: "var(--grey-800)", width: "1.25rem" }}
                  />
                </Button>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].company_name`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Company Name"
                        error={error?.message || ""}
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].monthly_income`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={20}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Monthly Income"
                      />
                    )}
                  />
                </HStack>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].years`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={3}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Years"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].months`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={2}
                        {...field}
                        type="number"
                        placeholder=""
                        label="Month"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].occupation`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Occupation"
                      />
                    )}
                  />
                </HStack>
                <HStack w="100%" alignItems="flex-start">
                  <Controller
                    name={`employments_attributes[${index}].title`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={200}
                        {...field}
                        type="text"
                        placeholder=""
                        label="Title"
                      />
                    )}
                  />
                  <Controller
                    name={`employments_attributes[${index}].phone`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <PhoneInput
                        {...field}
                        label="Phone Number"
                        placeholder="Enter phone number"
                        isRequired={false}
                        isSmall={true}
                        error={error?.message || ""}
                      />
                    )}
                  />
                </HStack>
                <Controller
                  name={`employments_attributes[${index}].current_employer`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      size="lg"
                      variant="whiteCheck"
                      onChange={(e: any) =>
                        setValue(
                          `employments_attributes[${index}].current_employer`,
                          e.target.checked
                        )
                      }
                      isChecked={field.value}
                    >
                      Current Employer
                    </Checkbox>
                  )}
                />
              </VStack>
            ))}
        </VStack>
        {fields && fields.length < 7 && (
          <Button
            padding="1rem"
            variant="outline"
            w="100%"
            background="var(--grey-50)"
            onClick={() => append({})}
          >
            Add More
          </Button>
        )}
      </VStack>
      <Button
        w="100%"
        marginTop="auto"
        position="absolute"
        left="0"
        bottom={{ md: "0", base: "30px" }}
        onClick={handleSubmit(onSubmit)}
      >
        Save Settings
      </Button>
      {(isFetching || mutation?.isLoading) && <Spinner />}
    </VStack>
  );
};

export default AddEmpInfo;
