import axios from "axios";
import { SERVICES } from "../../config";

  export interface ApiResult {
    // Define your API response structure here
    data: any;
    headers: any;
    // Add other relevant fields
  }
  export const login = async (payload: any): Promise<ApiResult> =>axios.post(`${SERVICES.apiBaseUrl}/login`,payload, {
    headers: {
      menu:'',
      "Sub-Menu":'',
    }
  })
  export const forgotpassword = async (payload: any) =>axios.post(`${SERVICES.apiBaseUrl}/password`,payload, {
    headers: {
      menu:'',
      "Sub-Menu":'',
    }
  })
  export const resetPassword = async (payload: any) =>axios.put(`${SERVICES.apiBaseUrl}/password`,payload)
  export const getUser = async ({queryKey}:any) =>axios.post(`${SERVICES.apiBaseUrl}/oauth2?access_token=${queryKey[1]}&provider=${queryKey[2]}`, {
    headers: {
      menu:'',
      "Sub-Menu":'',
    }
  })
  export const getUrl = async (key:string) =>axios.get(`${SERVICES.apiBaseUrl}/auth_url?provider=${key}`)
  export const submitOtp = async (payload: any): Promise<ApiResult> =>axios.post(`${SERVICES.apiBaseUrl}/verify_otp`,payload, {
    headers: {
      menu:'',
      "Sub-Menu":'',
    }
  })
  export const resendOtp = async (payload: any) =>axios.post(`${SERVICES.apiBaseUrl}/resend_otp`,payload, {
    headers: {
      menu:'',
      "Sub-Menu":'',
    }
  })
  export const active2fa = async (payload: any) =>axios.patch(`${SERVICES.apiBaseUrl}/api/settings/user/${payload.id}/update_setting`,payload.payload,{
    headers: {
      menu: '',
      "Sub-Menu": '',
    },
  })
  export const get2fa = async ({queryKey}:any) =>axios.get(`${SERVICES.apiBaseUrl}/api/settings/user/${queryKey[1]}/setting?key=two_factor_auth`)
  export const logoutapi = async () =>axios.delete(`${SERVICES.apiBaseUrl}/logout`, {
    headers: {
      menu:'',
      subMenu:'',
    }
  })











