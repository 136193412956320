import React from "react";
import ReactApexChart from "react-apexcharts";

interface LGHeatMapChartProps {
  isLarge: boolean;
  categories: string[];
  resp?: any;
}

class LGHeatMapChart extends React.Component<any, any> {
  constructor(props: LGHeatMapChartProps) {
    super(props);
    const { categories, resp } = props;

    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const getMapping = (weekday: string, category: string) => {
      const obj = resp[category];
      return obj?.hasOwnProperty(weekday) ? obj[weekday] : 0;
    };

    const seriesData = weekdays.map((weekday) => ({
      name: weekday,
      data: resp
        ? Object.keys(resp)?.map((category) => ({
            x: category,
            y: getMapping(weekday, category),
          }))
        : [],
    }));

    const maxYValue = Math.max(
      ...seriesData.flatMap((day) => day.data.map((d) => d.y))
    );

    this.state = {
      options: {
        grid: {
          show: false,
          gap: 20,
        },
        chart: {
          toolbar: {
            show: false,
          },

          height: 35,
          width: 35,
          type: "heatmap",
          //   stacked: false,
        },

        dataLabels: {
          enabled: props.isLarge,
          style: {
            colors: ["#fff"],
            fontSize: "22px",
          },
        },
        colors: ["#FF1654", "#247BA0"],
        series: seriesData,
        plotOptions: {
          heatmap: {
            // radius: props.isLarge ? 20 : 12,
            radius: 0,
            borderWidth: 12,
            borderColor: "transparent",
            enableShades: false,
            shadeIntensity: 1,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: -30,
                  to: 0,
                  color: "transparent",
                  name: "low",
                },
                {
                  from: -10,
                  to: 0,
                  color: "#E3F2FD",
                  name: "low",
                },

                {
                  from: 1,
                  to: maxYValue * 0.25,
                  color: "#BBDEFB",
                  name: "medium",
                },
                {
                  from: maxYValue * 0.25,
                  to: maxYValue * 0.5,
                  color: "#64B5F6",
                  name: "high",
                },
                {
                  from: maxYValue * 0.5,
                  to: maxYValue * 0.75,
                  color: "#2196F3",
                  name: "very high",
                },
                {
                  from: maxYValue * 0.75,
                  to: maxYValue,
                  color: "#0D47A1",
                  name: "extreme",
                },
              ],
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "round",
          width: 12,
          dashArray: 0,
          colors: ["#F5F5F5"],
          // colors: ["transparent"],
        },
        fill: {
          type: "pattern",
          pattern: {
            style: "verticalLines",
            width: 6,
            height: 6,
            strokeWidth: 2,
          },
        },

        xaxis: {
          show: false,
          position: "top",
          labels: {
            show: true,
            style: {
              color: ["#000"],
              fontSize: this.props.isLarge ? "14px" : "10px",
              fontWeight: this.props.isLarge ? "700" : "500",
              fontFamily: "var(--font-roboto)",
            },
            offsetX: 18,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#FF1654",
          },
          categories: categories ?? [],
        },
        yaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#FF1654",
          },
          labels: {
            show: true,
            style: {
              color: ["#000"],
              fontSize: this.props.isLarge ? "14px" : "10px",
              fontWeight: this.props.isLarge ? "700" : "500",
            },
          },
        },

        tooltip: {
          show: false,
          shared: false,
          intersect: false,
          x: {
            show: false,
          },
        },

        legend: {
          show: false,
        },
        markers: {
          show: false,
          size: 0,
          style: "hollow",
        },
      },
    };
  }

  getWidthBasedOnScreenSize = () => {
    const { isLarge } = this.props;
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1900) {
      return isLarge ? 880 : 700;
    } else if (screenWidth >= 1462 && screenWidth <= 1900) {
      return isLarge ? 720 : 500;
    } else if (screenWidth >= 1200 && screenWidth <= 1462) {
      return isLarge ? 600 : 450;
    } else {
      return isLarge ? 500 : 300;
    }
  };

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.options.series}
        type="heatmap"
        height={this.props.isLarge ? 500 : 330}
        // style={{
        //   marginLeft: "-20px",
        //   marginTop: "-20px",
        // }}
        // width={this.props.isLarge ? 880 : 490}
        width={this.getWidthBasedOnScreenSize()}
      />
    );
  }
}

export default LGHeatMapChart;
