import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
export const getMapping = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/latest_csv_feed_mapping`
  );
export const updateMapping = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${payload?.id}/update_setting`,
    payload.payload,
    {
      headers: {
        menu: "Inventory Mapping",
        "Sub-Menu": "Pending",
      },
    }
  );
export const getPendingMapping = async (params: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/dealerships/pending_inventory`, {
    params,
  });
export const getCompletedMapping = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/completed_inventory`,
    { params }
  );

export const getVehicles = async (params: any): Promise<any> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/inventories/search?query=${params.searchStr}`,
  );
  export const previewMapping = async ({ queryKey }: any): Promise<ApiResult> => {
    const mapping = queryKey[2];
    const dealership_name=queryKey[1];
    const url = `${SERVICES.apiBaseUrl}/api/admin/dealerships/csv_preview?page=${queryKey[3]}&per_page=${queryKey[4]}`;
    return axios.get(url, {
      params: { dealership_name, mapping }, // Pass the mapping object directly
      paramsSerializer: params => {
        // Serialize nested objects to match the expected format
        return Object.entries(params).map(([key, value]) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : value)}`;
        }).join('&');
      }
    });
  };
  export const getDealershipInventory = async ({ queryKey }: any): Promise<ApiResult> =>
    axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/inventories?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
    ); 
  