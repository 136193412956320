import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
export const getPermissions = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/user/permissions/${queryKey[1]}?dealership_id=${queryKey[2]}`);
export const updatePermissions = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/user/permissions/${payload.id}/update_permissions`,
    payload.payload,
  );
export const getAdminPermissions = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/user/permissions/${queryKey[1]}`);
export const getDealershipPermissions = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}`);
export const updateDealershipPermissions = async (
  payload: any
): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${payload.id}/update_permissions`,
    { dealership: payload.payload },
    {
      headers: {
        menu:'Dealerships',
        "Sub-Menu":'Settings/Dealership Settings/Manage Permissions',
        "Dealership": payload.id,
        action:'Update'
      }
    }
  );
