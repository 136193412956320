import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

export type OutboundCallForm = {
  mode: string;
  to1: string;
  to2: string;
  customersPhone: string;
  from: string;
  note: string;
};

export const defaultValues = {
  mode: "clickToCall",
  to1: "",
  to2: "",
  customersPhone: "",
  from: "",
  note: "",
};

export const outboundCallFormValidation = yup.object().shape({
  mode: yup.string(),
  from: yup.string().required("Line is required."),
  to1: yup.string().when("mode", {
    is: (mode: string) => mode === "clickToCall",
    then: yup.string().required("Phone number is required."),
    otherwise: yup.string(),
  }),
  customersPhone: yup.string().required("Customer Phone number is required."),
});

export const callTypes = [
  { value: "headset", label: "Use Headset" },
  {
    value: "clickToCall",
    label: "Click to Call",
  },
];
