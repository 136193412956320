import { useNavigate } from "react-router-dom";
import PriorityIcon from "../../../components/icons/Priority";
import { HStack, Text, VStack } from "@chakra-ui/react";
// import { useRouter } from "next/navigation";

interface PriorityProps {
  priority: string;
  number: number;
  dashboardStats: any;
}

const priorityMapping: any = {
  1: "priority_1",
  2: "priority_2",
  3: "priority_3",
};

const priorityFollowMapping: any = {
  1: "priority_one_follow_up",
  2: "priority_two_follow_up",
  3: "priority_three_follow_up",
};

const Priority = ({ priority, number, dashboardStats }: PriorityProps) => {
  // const router = useRouter();
  const navigate = useNavigate();
  return (
    <VStack
      background="white"
      flex="1"
      borderRadius="1rem"
      position="relative"
      alignItems="flex-start"
      cursor="pointer"
      onClick={() =>
        navigate(`/leads/lead-details?priority=${priorityMapping[number]}`)
      }
    >
      <VStack alignItems="flex-start" padding="1.38rem 0.87rem" gap="1.5rem">
        <VStack alignItems="flex-start" gap="0.31rem">
          <Text fontWeight="600" textStyle="h3">
            Priority# {number} Follow up
          </Text>
          <Text fontWeight="500" textStyle="h6">
            Leads {dashboardStats?.[priorityFollowMapping[number]]?.start || 0}-
            {dashboardStats?.[priorityFollowMapping[number]]?.end || 0} Days -
            No Appointment Set / Not Sold
          </Text>
        </VStack>
        <HStack w="100%" alignItems="flex-end" ml="0.71rem">
          <PriorityIcon priority={priority} />
          <Text fontWeight="700" textStyle="h2">
            {dashboardStats?.leads?.[priorityMapping[number]] || 0}
          </Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Priority;
