import React from "react";
import { VStack, HStack, FormLabel, Button } from "@chakra-ui/react";
import { Input, Select } from "../../../../../components/common";
import { getDealershipUsers } from "../../../../../utils/api/line-rings.api";
import AsyncSelect from "react-select/async";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  startMeetingForm,
  startMeetingFormValidation,
  defaultValues,
} from "./utils";
import { startMeeting } from "../../../../../utils/api/meeting.api";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import Loader from "../../../../common/Spinner";
const MeetingContent = ({ tab, contactid, is_sale_meeting }: any) => {
  const toast = useToast();
  const dealership = useUserInfo("dealership");
  const mutation = useMutation(startMeeting);
  const { handleSubmit, control, setValue, reset } = useForm<startMeetingForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(startMeetingFormValidation) as any,
  });
  const loadEmails = async (inputValue: string) => {
    try {
      const response = await getDealershipUsers({
        queryKey: ["dealerUsers", dealership?.id, ""],
      });
      const options = response?.data?.users?.map((user: any) => ({
        value: user.id.toString(),
        label: user.full_name,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  const handleFormSubmit = (values: startMeetingForm) => {
    mutation.mutate(
      {
        id: dealership.id,
        contact_id: contactid,
        data: {
          meeting: {
            user_id: values?.user?.value,
            dealership_id: dealership.id,
            contact_id: contactid,
            is_sale_meeting: is_sale_meeting,
            notes: values?.notes,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          reset(defaultValues);
          toast({
            description: "Meeting created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack alignItems="flex-start" gap="1rem" w="100%">
      <VStack alignItems="flex-start" gap="0.25rem" w="100%">
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          User Name
        </FormLabel>
        <HStack w="100%" justifyContent="space-between">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AsyncSelect
                  loadOptions={(searchStr: any) => loadEmails(searchStr)}
                  defaultOptions={true}
                  value={value}
                  onChange={onChange}
                  isMulti={false}
                  required={true}
                  placeholder="Select User"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              );
            }}
            name="user"
            control={control}
            rules={{
              required: true,
            }}
          />
        </HStack>
      </VStack>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Input
              isTextarea={true}
              label="Add Note"
              placeholder="Type something"
              height="10rem"
              onChange={onChange}
              value={value}
            />
          );
        }}
        name="notes"
        control={control}
        rules={{
          required: true,
        }}
      />
      <Button
        alignSelf="flex-end"
        onClick={(e) => {
          e.preventDefault();
          handleSubmit((values) => handleFormSubmit(values))();
        }}
      >
        Save Progress
      </Button>
      {mutation.isLoading && <Loader />}
    </VStack>
  );
};

export default MeetingContent;
