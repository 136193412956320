import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

import { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import AddEmpInfo from "./AddEmpInfo";
import { useParams } from "react-router-dom";

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  return (
    <VStack w="100%">
      <VStack
        alignItems="flex-start"
        w={{ base: "100%", md: "45%" }}
        padding="1rem"
        h="100%"
      >
        <Tabs
          variant="primary"
          w="100%"
          h="calc(100% - 3rem)"
          onChange={handleTabChange}
          defaultIndex={0}
        >
          <TabList>
            <Tab w="100%" fontSize={{ md: "14px", base: "12px" }}>
              Personal Info
            </Tab>
            <Tab
              w="100%"
              fontSize={{ md: "14px", base: "12px" }}
              isDisabled={id === "[contact_id]"}
            >
              Address / Employee Info
            </Tab>
          </TabList>
          <TabPanels h="100%">
            <TabPanel h="100%">{activeTab === 0 && <PersonalInfo />}</TabPanel>
            <TabPanel>{activeTab === 1 && <AddEmpInfo />}</TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
};

export default MyProfile;
