import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

type BillingFeatures = {
  sms: string;
  mms: string;
  voicemail: string;
  ringLessVM: string;
  call: string;
};

export type BasicInformationForm = {
  start_date: string;
  metered_type: string;
  dollar_amount: string;
  billing_features: BillingFeatures;
};

export const defaultValues = {
  start_date: "",
  metered_type: "",
  dollar_amount: "",
  billing_features: {
    sms: "",
    mms: "",
    voicemail: "",
    ringLessVM: "",
    call: "",
  },
};

export const basicInformationForm = yup.object().shape({
  start_date: yup
    .date()
    .required("Star Date is required.")
    .nullable()
    .default(undefined),
    // .min(new Date(Date.now() - 86400000), "Date cannot be in the past"),
  metered_type: yup.string().required("Please select at least one type"),
  dollar_amount: yup
    .string()
    .test("is-required", "Dollar Amount is required.", function (value) {
      const { metered_type } = this.parent;
      return metered_type === "unlimited" ? !!value : true;
    }),
});

export const meteredOptions = ["unlimited", "metered", "custom"];
