import {
  Box,
  HStack,
  Text,
  VStack,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import React from "react";

interface ProgressProps {
  text: string;
  amount: number;
  color: string;
}

interface ActiveCardProps {
  totalText: string;
  totalAmount: number;
  icon: React.ReactNode;
  iconBg: string;
  progressItems: ProgressProps[];
  isLoading: boolean; // Add isLoading prop
}

const ActiveCard = ({
  totalText,
  totalAmount,
  icon,
  iconBg,
  progressItems,
  isLoading,
}: ActiveCardProps) => {
  const isInvalidAmount = (amount: number | null | string): boolean => {
    return amount === null || amount === 0 || amount === "";
  };

  const isAnyAmountNullOrZero = progressItems.some((item) =>
    isInvalidAmount(item.amount)
  );

  return (
    <VStack
      p="0.75rem 1rem"
      alignItems="flex-start"
      border="1px solid var(--grey-300)"
      borderRadius="0.75rem"
      w="100%"
      gap="1rem"
    >
      <HStack
        gap="0.5rem"
        w="100%"
        pb="1rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <Skeleton isLoaded={!isLoading}>
          <Box
            p="0.5rem"
            background={iconBg}
            borderRadius="0.5rem"
            display="flex"
          >
            {icon}
          </Box>
        </Skeleton>
        <VStack alignItems="flex-start" gap="0">
          <SkeletonText
            isLoaded={!isLoading}
            noOfLines={1}
            skeletonHeight="20px"
          >
            <Text textStyle="h6" fontWeight="600">
              {totalText}
            </Text>
          </SkeletonText>
          <SkeletonText
            isLoaded={!isLoading}
            noOfLines={1}
            skeletonHeight="24px"
          >
            <Text textStyle="h4" fontWeight="700">
              {totalAmount}
            </Text>
          </SkeletonText>
        </VStack>
      </HStack>
      <HStack
        w="100%"
        gap={isAnyAmountNullOrZero ? "0" : "0.38rem"}
        paddingTop="2rem"
      >
        {progressItems.map((item, index) => {
          return (
            <Skeleton
              isLoaded={!isLoading}
              w={`${(100 / totalAmount) * item.amount}%`}
            >
              <VStack
                alignItems="flex-start"
                w="100%"
                gap="0.38rem"
                position="relative"
              >
                <Box
                  display="flex"
                  position="absolute"
                  top="-30px"
                  left={index === 0 ? "0" : "auto"}
                  right={
                    index === 1 && (100 / totalAmount) * item.amount < 25
                      ? "0"
                      : "auto"
                  }
                >
                  <Text textStyle="h4" fontWeight="600">
                    {item.text}:
                    <Box as="span" fontWeight="700" ml="0.38rem">
                      {item.amount}
                    </Box>
                  </Text>
                </Box>
                <Box
                  h="1.6rem"
                  w="100%"
                  background={item.color}
                  borderRadius={
                    isAnyAmountNullOrZero
                      ? "0.5rem"
                      : index === 0
                      ? "0.5rem 0 0 0.5rem"
                      : "0 0.5rem 0.5rem 0"
                  }
                ></Box>
              </VStack>
            </Skeleton>
          );
        })}
      </HStack>
    </VStack>
  );
};

export default ActiveCard;
