import React from "react";
import Inbox from "./Inbox";
import SendItems from "./SendItems";
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function EmailBlast() {
  const navigate = useNavigate();
  const TabsData = [
    {
      id: 3,
      title: (
        <HStack justifyContent="center" w="100%">
          <Text>Inbox</Text>
        </HStack>
      ),
      content: <Inbox />,
    },
    {
      id: 4,
      title: (
        <HStack justifyContent="center" w="100%">
          <Text>Sent</Text>
        </HStack>
      ),
      content: <SendItems />,
    },
  ];
  return (
    <Box
      background="white"
      borderRadius="1rem"
      border="1px solid var(--grey-300)"
      margin="1.5rem"
      height="100%"
      padding="1rem"
    >
      <Tabs variant="primary" isLazy>
        <HStack w="100%" justifyContent="space-between" mb="1rem">
          <TabList w="max-content">
            {TabsData.map((item, index) => {
              return (
                <Tab key={item?.id} padding="1rem" w="13rem">
                  {item?.title}
                </Tab>
              );
            })}
          </TabList>
          <HStack>
            <Button
              background="var(--secondary-600)"
              onClick={() => navigate("/email-blast/template")}
            >
              Templates
            </Button>
            <Button
              variant="outline"
              onClick={() => navigate("/email-blast/compose")}
            >
              Compose
            </Button>
            ss
            <Button onClick={() => navigate("/email-blast/compose?type=Blast")}>
              Compose Blast
            </Button>
          </HStack>
        </HStack>

        <TabPanels height="100%" overflow="auto">
          {TabsData.map((item) => (
            <TabPanel key={item.id} h="100%">
              {item.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default EmailBlast;
