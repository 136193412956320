import { Box, Text } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { Input } from "../../common";
import SwitchToggle from "../../common/Switch";
import { SearchAndFilterForm } from "./utils";

type RestrictedContactsProps = {
  control: Control<SearchAndFilterForm>;
  setValue: UseFormSetValue<SearchAndFilterForm>;
  prefix?: string;
};

function RestrictedContacts({
  control,
  setValue,
  prefix = "",
}: RestrictedContactsProps) {
  const eventFields = [
    {
      label: "Unsubscribed",
      value: `${prefix}filter_and_sort.restricted_contacts.unsubscribed`,
    },
    {
      label: "DNC",
      value: `${prefix}filter_and_sort.restricted_contacts.dnc`,
    },
    {
      label: "Block",
      value: `${prefix}filter_and_sort.restricted_contacts.block`,
    },
  ];

  return (
    <>
      <Box background="var(--secondary-50)" padding="1.25rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Restricted Contacts
        </Text>
      </Box>

      <Box padding="1.25rem" display="grid" gridTemplateColumns="1fr 1fr">
        {eventFields.map((eventField) => {
          return (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Box mb="1.5rem">
                    <SwitchToggle
                      label={eventField.label}
                      id={`#${eventField.label.split(" ").join("-")}`}
                      defaultChecked={value}
                      onChange={(checked: boolean) =>
                        setValue(eventField.value as any, checked)
                      }
                    />
                  </Box>
                );
              }}
              name={eventField.value as any}
              control={control}
              rules={{
                required: true,
              }}
            />
          );
        })}
      </Box>
    </>
  );
}

export default RestrictedContacts;
