import { Button, HStack, useToast, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState,useRef,useEffect } from "react";
import { uploadAttachment } from "../../utils/api/social-talk.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import {
  AppointmentIcon,
  AttachIcon,
  CreditAppIcon,
  ImageIcon,
  InventoryIcon,
  VideoIcon,
  VariableIcon,
  TemplateIcon
} from "../icons/svg";
import ImageModal from "./ImageModal";
import { Input } from "../common";
import { APPOINTMENT_TEXT, CREDIT_TEXT, VIDEO_UPLOAD_TEXT } from "../../config";
import Loader from "../common/Spinner";
import InventoryModal from "./InventoryModal";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Picker, { EmojiStyle } from "emoji-picker-react";
import TemplateModal from "./TemplateModal";
import { MessengerVariables } from "../WebChat/MessengerVariables";

type MessengerAttachmentBarProps = {
  setBody?: (text: string, concatText?: string, type?: string,isVariable?: boolean ) => void;
  type?: string;
  contactId?: string;
  apiCall?: (type?: any, id?: any) => void;
  attachmentApiCall?: (type?: any, id?: any) => void;
  handleTemplate: (value: string, body?: string) => void;
};

function MessengerAttachmentBar({
  setBody,
  type = "sms",
  contactId = "[contact_id]",
  apiCall,
  attachmentApiCall,
  handleTemplate,
}: MessengerAttachmentBarProps) {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [openInsertVariable, setOpenInsertVariable] = useState(false);

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const pickerRef = useRef<any>(null);
  const toast = useToast();

  const handleImage = (text: any) => {
    setIsImageOpen(false);
    attachmentApiCall && attachmentApiCall("image", text?.url);
  };

  const handleInventory = (text: string) => {
    setIsInventoryOpen(false);
    apiCall && apiCall("car-template", text);
  };
  const handleClickOutside = (event: any) => {
    if (pickerRef?.current && !pickerRef?.current?.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await uploadAttachment({
          id: dealership.id,
          data: payload,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleVideoChat = async (e: any) => {
    const file = e.target.files[0];
    if (file && file.size <= 62914560) {
      const formData = new FormData();
      formData.append("file", file);
      //   formData.append("dealership_id", dealership.id);
      const res = await attachmentMutation.mutateAsync(formData);
      attachmentApiCall &&
        attachmentApiCall(
          "video",
          `http://${window.location.host}/video/${encodeURIComponent(
            res.url || ""
          )}`
        );
    } else {
      toast({
        description: "File size exceeds the limit of 60MB.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    e.target.value = "";
  };

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
      const formData = new FormData();
      formData.append("file", file);
      //   formData.append("dealership_id", user.dealership.id);
      e.target.value = "";

      try {
        const res = await attachmentMutation.mutateAsync(formData);
        attachmentApiCall && attachmentApiCall(file?.type, res.url);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAppointment = () => {
    const webLink = `http://${window.location.host}/guest_mode/dealership/${dealership.uuid}/contact/${contactId}/schedule_a_visit`;
    setBody && setBody(webLink, APPOINTMENT_TEXT);
  };

  const handleCreditApp = () => {
    const webLink = `http://${window.location.host}/guest_mode/dealership/${dealership.uuid}/contact/${contactId}/apply_for_credit`;
    setBody && setBody(webLink, CREDIT_TEXT);
  };
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji: any) => {
    // Handle emoji selection here (e.g., insert emoji into text)
    // For example, you can set the emoji in the body if needed.
    setBody && setBody(emoji?.emoji, "", "text");
  };
  const handleVariableSelect = (variable: any) => {
    setBody && setBody(` ${variable?.value}`, undefined, "text", true);
  };

  return (
    <>
      {attachmentMutation.isLoading && <Loader />}
      <HStack
        w="100%"
        padding="1rem"
        background="var(--secondary-50)"
        gap="1rem"
      >
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() => setIsImageOpen(true)}
        >
          <ImageIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={toggleEmojiPicker}
        >
          <EmojiEmotionsIcon />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() => document.getElementById(`${type}videoOpen`)?.click()}
        >
          <VideoIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() => setOpenInsertVariable(true)}
        >
          <VariableIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={handleCreditApp}
        >
          <CreditAppIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={handleAppointment}
        >
          <AppointmentIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() => setIsInventoryOpen(true)}
        >
          <InventoryIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w={'100%'}
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() => setIsTemplateOpen(true)}
        >
          <TemplateIcon color="var(--grey-800)" />
        </Button>
        <Button
          variant="outline"
          w="100%"
          padding="0.75rem"
          h="2.8rem"
          sx={{
            _hover: {
              background: "var(--secondary-600)",
              svg: {
                color: "white",
                path: {
                  fill: "white",
                },
              },
            },
          }}
          onClick={() =>
            document.getElementById(`${type}broadCastAttachmentBar`)?.click()
          }
        >
          <AttachIcon color="var(--grey-800)" />
        </Button>
      </HStack>
      <Input
        maxW="20rem"
        type="file"
        hidden
        accept="video/mp4,video/x-m4v,video/*"
        id={`${type}videoOpen`}
        onChange={handleVideoChat}
      />
      <Input
        maxW="20rem"
        type="file"
        hidden
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        id={`${type}broadCastAttachmentBar`}
        onChange={handleUploadDocuments}
      />
      <ImageModal
        isOpen={isImageOpen}
        onClose={() => setIsImageOpen(false)}
        handleImage={handleImage}
        api={true}
      />
      <InventoryModal
        isOpen={isInventoryOpen}
        onClose={() => setIsInventoryOpen(false)}
        handleInventory={handleInventory}
      />
      {showEmojiPicker && (
        <VStack pos="absolute" bottom="65%" zIndex="9" left="19%">
                    <div ref={pickerRef}>
          <Picker
            onEmojiClick={handleEmojiSelect}
            // lazyLoadEmojis={true}
            // emojiStyle={EmojiStyle.NATIVE}
          /></div>
        </VStack>
      )}
       {openInsertVariable && (
        <MessengerVariables
          isOpen={openInsertVariable}
          isOnRight={true}
          onClose={() => setOpenInsertVariable(false)}
          onVariableSelect={handleVariableSelect}
        />
      )}
       {isTemplateOpen && (
        <TemplateModal
          isOpen={isTemplateOpen}
          type={type}
          onClose={() => setIsTemplateOpen(false)}
          handleTemplate={handleTemplate}
          // parentMenu={parentMenu}
        />
      )}
    </>
  );
}

export default MessengerAttachmentBar;
