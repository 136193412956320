import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { PhoneIcon } from "../../../../components/icons/svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import {
  roleChecker,
  simpAdminChecker,
  dealershipAdminChecker,
} from "../../../../utils";
import {
  tabConfigurationAdmin,
  tabConfigurationUser,
  tabConfigurationUserAdmin,
  tabConfigurationRestrictedDealershipUser,
  tabConfigurationSimpUsers,
  tabConfigurationSimpAdminUsers,
} from "../../../../utils/settings-tabs";
import { useNavigate, useParams } from "react-router-dom";

export default function UsersSettingPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };
  const role = useUserInfo("role");
  const userid = useUserInfo("id");
  const { id } = useParams();
  const modifySettings = useUserInfo("modify_settings");
  const gettabs = () => {
    if (roleChecker(role)) {
      if (simpAdminChecker(role)) {
        if (!id || id == userid) {
          return tabConfigurationAdmin;
        } else {
          return tabConfigurationSimpAdminUsers;
        }
      } else {
        return tabConfigurationSimpUsers;
      }
    } else if (!id || id == userid) {
      if (modifySettings) {
        return tabConfigurationUser;
      } else {
        return tabConfigurationRestrictedDealershipUser;
      }
    } else {
      if (modifySettings) {
        return tabConfigurationUserAdmin;
      } else {
        return tabConfigurationRestrictedDealershipUser;
      }
    }
  };
  return (
    <>
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          Back
        </Button>
        <HStack gap="0.62rem">
          {/* <Button leftIcon={<PhoneIcon />} variant="outline" width="8rem">
            Test a Call
          </Button> */}
          {/* <Button leftIcon={<PauseIcon />} variant="outline">
            Pause
          </Button> */}
        </HStack>
      </Box>
      <Box
        background="white"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
        height="calc(100vh - 200px)"
      >
        <Tabs
          variant="vertical"
          display="flex"
          height="100%"
          index={activeTab}
          onChange={handleTabChange}
        >
          <TabList>
            <Text textStyle="h4" fontWeight="500" padding="1rem">
              User Settings
            </Text>
            {gettabs()?.map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {gettabs()?.map((tab, index) => (
              <TabPanel key={index} padding="1rem" height="100%">
                {activeTab === index && tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
