"use client";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import InventoryPending from "../../../components/GlobalDashboard/InventoryPending/InventoryPending";
import InventoryCompleted from "../../../components/GlobalDashboard/InventoryCompleted/InventoryCompleted";

export default function InventoryMappingPage() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs variant="table" w="100%" background="white" borderRadius="0.5rem" onChange={(index) => setSelectedTab(index)}>
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Pending</Tab>
            <Tab w="15rem">Completed</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
             {selectedTab===0&& <InventoryPending />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
             {selectedTab===1&& <InventoryCompleted />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
