import { HStack, Text } from "@chakra-ui/react";
import ClearIcon from "@mui/icons-material/Clear";
import { isImageLink, isVideoLink } from "../../utils";
type AttachmentViewerProps = {
  attachments: any[];
  setAttachments: (attachments: any[]) => void;
};

function AttachmentViewer({
  attachments,
  setAttachments,
}: AttachmentViewerProps) {
  const handleRemove = (attachment: any) => {
    setAttachments(
      attachments.filter(function (att: any) {
        return att.url !== attachment.url;
      })
    );
  };

  return (
    <>
      {!!attachments?.length && (
        <HStack
          w="100%"
          padding="1rem"
          background="var(--secondary-50)"
          gap="1rem"
          borderTop="1px solid black"
          paddingTop="1.5rem"
          flexWrap="wrap"
        >
          {attachments?.map((attachment: any) => {
            return (
              <div
                style={{
                  background: "rgb(251 251 251)",
                  padding: 6,
                  position: "relative",
                  borderRadius: "1rem",
                }}
              >
                <Text
                  fontSize="13px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth="40px"
                >
                  {attachment.filename ? attachment.filename : attachment.url}
                </Text>
                {isImageLink(attachment.url) ? (
                  <img
                    src={attachment.url}
                    alt={attachment.url}
                    width={100}
                    style={{ whiteSpace: "nowrap" }}
                  />
                ) : (
                  <></>
                )}
                <span
                  style={{
                    position: "absolute",
                    top: -15,
                    right: -10,
                    background: "var(--secondary-50)",
                    borderRadius: "50%",
                    border: "1px solid red",
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemove(attachment)}
                >
                  <ClearIcon style={{ margin: 2 }} />
                </span>
              </div>
            );
          })}
        </HStack>
      )}
    </>
  );
}

export default AttachmentViewer;
