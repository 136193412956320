import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getDeskLogs = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${
      params.dealershipId
    }/dashboard/desk_log?q=${params.searchStr}${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `&start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );

export const getDeskLogTiles = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${
      params.dealershipId
    }/dashboard/desk_log_tiles?q=${params.searchStr}${
      !!params.dateRange.start_date && params.dateRange.selected
        ? `&start_date=${params.dateRange.start_date}&end_date=${params.dateRange.end_date}`
        : ""
    }`
  );
