
import { Center, Heading, Text, VStack, Button } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

export default function ForbiddenPage() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate('/');
    };
  return (
    <Center h="100vh" bg="gray.100">
      <VStack spacing={8} textAlign="center">
        <Heading as="h1" size="2xl" color="gray.700">
        403 - Forbidden
        </Heading>
        <Text fontSize="xl" color="gray.600">
        You do not have permission to access this resource.
        </Text>
        <button onClick={handleGoBack} >Go to Home Page</button>
      </VStack>
    </Center>
  );
};