import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//brand apis
export const getDeviceTokens = async (
  dealershipId: string
): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/calls/call_identity_token?dealership_id=${dealershipId}`
  );

export const startTwoLeggedCall = async (params: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/calls/voice`, {
    contactId: params.contactId,
    To: params.to1,
    To1: params.to2,
    From: params.from,
    full_name: params.fullName,
    two_legged_call: true,
    userId: params.userId
  });

export const declineTwoLeggedCall = async (params: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/calls/decline_two_legged_call`, {
    CallSid: params.callSid,
    dealership_id: params.dealershipId
  });
