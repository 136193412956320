import { Box, Button, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import DeskLog from "../../../components/DeskLog";
import {
  AppointmentIcon,
  EditIcon,
  HandShakeIcon,
  NoOfMeetingIcon,
} from "../../../components/icons/svg";

const numberCards = [
  {
    id: 1,
    icon: <NoOfMeetingIcon />,
    number: 34,
    title: "Number of Meetings",
    bg: "var(--primary-900)",
  },
  {
    id: 2,
    icon: <HandShakeIcon />,
    number: 48,
    title: "Number of Sales",
    bg: "var(--primary-700)",
  },
];

interface NumberCardProps {
  icon: React.ReactNode;
  number: number;
  title: string;
  bg: string;
}

const NumberCard = ({ icon, number, title, bg }: NumberCardProps) => {
  return (
    <VStack
      w="100%"
      background={bg}
      alignItems="flex-start"
      borderRadius="0.5rem"
      padding="1rem"
    >
      {icon}
      <Text textStyle="h4" color="white" fontWeight="700">
        {number}
      </Text>
      <Text textStyle="captionSmall" color="white" fontWeight="500">
        {title}
      </Text>
    </VStack>
  );
};

export default function DeskLogPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAdvanceFilterOpen, setIsAdvanceFilerOpen] = useState<boolean>(false);
  const onAdvanceFilterClose = () => setIsAdvanceFilerOpen(false);
  const onAdvanceFilterOpen = () => setIsAdvanceFilerOpen(true);
  const tableHeader = [
    { id: 1, value: "Customer", label: "customer" },
    { id: 2, value: "Time", label: "time" },
    { id: 3, value: "Phone Number", label: "phoneNo" },
    { id: 4, value: "Vehicle", label: "vehicle" },
    { id: 5, value: "Trade", label: "trade" },
    { id: 6, value: "Origin", label: "origin" },
    { id: 7, value: "Status", label: "status" },
    { id: 8, value: "Type", label: "type" },
    { id: 9, value: "REP", label: "rep" },
    { id: 10, value: "TO", label: "to" },
    { id: 11, value: "SOLD", label: "sold" },
    { id: 12, value: "APPT", label: "appt" },
  ];
  const tableData = [
    {
      customer: (
        <VStack alignItems="flex-start">
          <Text textStyle="h6" fontWeight="500">
            Kaizen Valeron - NY
          </Text>
          <Button
            p="0.25rem"
            fontSize="0.75rem"
            h="1.5rem"
            gap="0.25rem"
            onClick={onOpen}
            sx={{
              ".chakra-button__icon": { marginRight: "0" },
            }}
            variant="outline"
            color="var(--primary-600)"
          >
            Add Notes
          </Button>
        </VStack>
      ),
      time: "12:00 AM",
      phoneNo: "+92 341 9785311",
      vehicle: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          2021 Model 3 Red 1567
        </Text>
      ),
      origin: "Internet Car Gurus",
      type: "NEW",
      rep: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          NW
        </Text>
      ),
      status: (
        // <Box
        //   w="4.6rem"
        //   textAlign="center"
        //   borderRadius="2.5rem"
        //   padding="0.25rem 0.62rem"
        //   border="1px solid var(--secondary-600)"
        // >
        //   <Text textStyle="h6" color="var(--secondary-600)">
        //     Sold
        //   </Text>
        // </Box>

        // <Box
        //   w="4.6rem"
        //   textAlign="center"
        //   borderRadius="2.5rem"
        //   padding="0.25rem 0"
        //   border="1px solid var(--red-400)"
        // >
        //   <Text textStyle="h6" color="var(--red-800)">
        //     Lost Since
        //   </Text>
        // </Box>
        <Box
          as="span"
          width="5.875rem"
          padding="0.25rem 0.625rem"
          borderRadius="2.5rem"
          border="1px solid var(--primary-600)"
          color="var(--primary-600)"
          fontSize="0.875rem"
        >
          Sold Since
        </Box>
        // <Box
        //   as="span"
        //   width="5.875rem"
        //   padding="0.25rem 0.625rem"
        //   borderRadius="2.5rem"
        //   border="1px solid var(--orange-500)"
        //   color="var(--orange-500)"
        //   fontSize="0.875rem"
        // >
        //   In Progress
        // </Box>
      ),
      to: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          RC
        </Text>
      ),
      appt: (
        <Button
          variant="none"
          sx={{
            svg: {
              width: "1.25rem",
              height: "1.25rem",
            },
          }}
        >
          <AppointmentIcon color="var(--grey-800)" />
        </Button>
      ),
    },
    {
      customer: (
        <VStack alignItems="flex-start">
          <Text textStyle="h6" fontWeight="500">
            Kaizen Valeron - NY
          </Text>
          <Button
            variant="none"
            p="0.25rem !important"
            fontSize="0.75rem"
            gap="0.25rem"
            onClick={onOpen}
            sx={{
              svg: { fill: "var(--grey-800)", width: "1rem", height: "1rem" },
            }}
            color="var(--grey-800)"
            display="flex"
            background="var(--grey-200)"
            borderRadius="0.375rem"
          >
            <Text>Same day refund</Text> <EditIcon />
          </Button>
        </VStack>
      ),
      time: "12:00 AM",
      phoneNo: "+92 341 9785311",
      vehicle: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          2021 Model 3 Red 1567
        </Text>
      ),
      origin: "Internet Car Gurus",
      type: "NEW",
      rep: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          NW
        </Text>
      ),
      to: (
        <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
          RC
        </Text>
      ),
      status: (
        <Box
          w="4.6rem"
          textAlign="center"
          borderRadius="2.5rem"
          padding="0.25rem 0.62rem"
          border="1px solid var(--secondary-600)"
        >
          <Text textStyle="h6" color="var(--secondary-600)">
            Sold
          </Text>
        </Box>
      ),
      appt: (
        <Button
          variant="none"
          sx={{
            svg: {
              width: "1.25rem",
              height: "1.25rem",
            },
          }}
        >
          <AppointmentIcon color="var(--grey-800)" />
        </Button>
      ),
    },
  ];
  return <DeskLog />;
}
