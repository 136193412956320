import {
  Box,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useQuery } from "@tanstack/react-query";
import ActiveCard from "../../../../components/GlobalDashboard/ActiveCard/ActiveCard";
import { TotalDealershipIcon } from "../../../../components/icons/svg";
import {
  fetchPerformance,
  fetchTotalDealerships,
  fetchTotalUsers,
} from "../../../../utils/api/global-dashboard";

const TopPane = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["fetchTotalDealerships"],
    queryFn: fetchTotalDealerships,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["fetchTotalUsers"],
    queryFn: fetchTotalUsers,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: performance, isFetching: isFetchingPerformance } = useQuery({
    queryKey: ["fetchPerformance"],
    queryFn: fetchPerformance,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return (
    <HStack w="100%" gap="1rem" alignItems="normal">
      <VStack alignItems="flex-start" w="50%" gap="1rem" h="100%">
        <ActiveCard
          totalAmount={data?.data?.total}
          totalText={"Total Dealerships"}
          progressItems={[
            {
              text: "Active",
              amount: data?.data?.active || 0,
              color: "var(--primary-500)",
            },
            {
              text: "Suspended",
              amount: data?.data?.suspended || 0,
              color: "var(--yellow-800)",
            },
          ]}
          iconBg="var(--primary-600)"
          icon={<TotalDealershipIcon />}
          isLoading={isFetching}
        />
        <ActiveCard
          totalAmount={users?.data?.total}
          totalText={"Total Users"}
          isLoading={isFetching}
          progressItems={[
            {
              text: "Active",
              amount: users?.data?.active || 0,
              color: "var(--primary-500)",
            },
            {
              text: "Suspended",
              amount: users?.data?.suspended || 0,
              color: "var(--orange-900)",
            },
          ]}
          iconBg="var(--secondary-600)"
          icon={
            <PeopleAltIcon
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                color: "white",
              }}
            />
          }
        />
      </VStack>
      <HStack w="50%" gap="1rem">
        <VStack
          alignItems="flex-start"
          border="1px solid var(--grey-300)"
          borderRadius="0.75rem"
          overflow="hidden"
          w="50%"
        >
          <Box p="1rem" borderBottom="1px solid var(--grey-300)" w="100%">
            <SkeletonText isLoaded={!isFetchingPerformance}>
              <Text textStyle="h5" fontWeight="700">
                Top 5 Active Dealerships
              </Text>
            </SkeletonText>
          </Box>
          <VStack w="100%" alignItems="flex-start" gap="0rem">
            {isFetchingPerformance
              ? Array(5)
                  .fill("")
                  .map((_, index) => (
                    <Skeleton key={index} w="100%" p="1rem" h="16px">
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16px"
                        color="var(--grey-900)"
                        w="100%"
                        borderBottom="1px solid var(--grey-300)"
                        sx={{
                          "&:last-child": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        Loading...
                      </Text>
                    </Skeleton>
                  ))
              : performance?.data?.top_five_least_active?.map(
                  (item: string, index: number) => (
                    <Text
                      key={index}
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                      color="var(--grey-900)"
                      w="100%"
                      p="1rem"
                      borderBottom="1px solid var(--grey-300)"
                      sx={{
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      {item}
                    </Text>
                  )
                )}
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          border="1px solid var(--grey-300)"
          borderRadius="0.75rem"
          overflow="hidden"
          w="50%"
        >
          <Box p="1rem" borderBottom="1px solid var(--grey-300)" w="100%">
            <SkeletonText isLoaded={!isFetchingPerformance}>
              <Text textStyle="h5" fontWeight="700">
                Top 5 Least Active Dealerships
              </Text>
            </SkeletonText>
          </Box>
          <VStack w="100%" alignItems="flex-start" gap="0rem">
            {isFetchingPerformance
              ? Array(5)
                  .fill("")
                  .map((_, index) => (
                    <Skeleton key={index} w="100%" p="1rem" h="16px">
                      <Text
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16px"
                        color="var(--grey-900)"
                        w="100%"
                        borderBottom="1px solid var(--grey-300)"
                        sx={{
                          "&:last-child": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        Loading...
                      </Text>
                    </Skeleton>
                  ))
              : performance?.data?.top_five_most_active?.map(
                  (item: string, index: number) => (
                    <Text
                      key={index}
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                      color="var(--grey-900)"
                      w="100%"
                      p="1rem"
                      borderBottom="1px solid var(--grey-300)"
                      sx={{
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      {item}
                    </Text>
                  )
                )}
          </VStack>
        </VStack>
      </HStack>

      {/* {(isFetching || isFetchingUsers || isFetchingPerformance) && <Spinner />} */}
    </HStack>
  );
};

export default TopPane;
