import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const idleTimeTracking = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dealershipId}/users/${payload.userId}/user_time_trackers/track_idle`
  );

export const productiveTimeTracking = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dealershipId}/users/${payload.userId}/user_time_trackers/track_activity`
  );
