import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getNotificationCount = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/notifications/unread_count`
  );
export const getReminderNotification = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/notifications?page=${queryKey[2]}&per_page=${queryKey[3]}`
  );
export const markReminderAsRead = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/notifications/${payload.id}/mark_as_read`
  );
export const markAllReminderAsRead = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/notifications/mark_all_as_read`,
    payload.payload
  );

export const fetchEmails = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/inbound_emails_list?page=${queryKey[2]}&per_page=${queryKey[3]}`
  );

export const markEmailAsRead = async (payload: any) =>
  await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/mark_as_read?${payload.payload}`
  );

export const getMessageNotification = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/messages/unread_messages_notifications?dealership_id=${queryKey[1]}&page=${queryKey[2]}&per_page=${queryKey[3]}`
  );
export const markMessageAsRead = async (payload: any) =>
  await axios.post(
    `${SERVICES.apiBaseUrl}/api/messages/mark_as_read?dealership_id=${payload?.dId}&id=${payload.id}`
  );

export const markAllMessageAsRead = async (payload: any) =>
  await axios.post(
    `${SERVICES.apiBaseUrl}/api/messages/mark_as_read?dealership_id=${
      payload?.dId
    }${payload.ping ? "&ping=true" : ""}&all=true`
  );
export const fetchNotifications = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/notifications/general_notification?page=${queryKey[2]}&per_page=${queryKey[3]}`
  );

export const fetchMissedCalls = async (params: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/notifications/general_notification?page=${params.page}&per_page=${params.per_page}&missed=true`
  );
export const fetchGlobalNotifications = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_notifications/general_notification?page=${queryKey[2]}&per_page=${queryKey[3]}`
  );
export const getGlobalNotificationCount = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_notifications/unread_count`
  );
export const markGlobalNotificationAsRead = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/global_notifications/${payload.id}/mark_as_read`
  );
export const markAllGlobalNotificationAsRead = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/global_notifications/mark_all_as_read`,
    payload.payload
  );
