import {
  Button,
  DrawerFooter,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Drawer, Input } from "../../common";
import { useState } from "react";
import { resetPassword } from "../../../utils/api/users.api";
import { useMutation } from "@tanstack/react-query";
import { validatePassword } from "../../../utils";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HideViewIcon from "@mui/icons-material/VisibilityOffOutlined";
import Loader from "../../common/Spinner";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  id: number;
  dealershipId?: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  user_id: number;
  password?: string;
}

const ResetPassword = (props: data) => {
  const { isOpen, onClose, id, refetchParent, dealershipId } = props;
  const [newPass, setNewPass] = useState("");
  const [reEnteredPass, setReEnteredPass] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  //...........api handling...........
  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request) => {
      try {
        const response = await resetPassword(request, {
          menu: "User",
          "Sub-Menu": "Reset Password",
          Dealership: dealershipId ?? "",
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Password reset successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error reseting password: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  //...........api handling...........
  const mutationEmail = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (request) => {
      try {
        const response = await resetPassword(request, {
          menu: "User",
          "Sub-Menu": "Reset Password",
          Dealership: dealershipId ?? "",
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Reset password email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error sending reset password email: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!newPass || !reEnteredPass) {
      setErrorMsg("Please enter password.");
      setPasswordsMatch(false);
    } else if (!validatePassword(newPass) || !validatePassword(reEnteredPass)) {
      setErrorMsg(
        "Password must be a minimum of 8 characters long and should include both a letter and a number."
      );
      setPasswordsMatch(false);
    } else if (newPass !== reEnteredPass) {
      setErrorMsg("Passwords don't match.");
      setPasswordsMatch(false);
    } else {
      const requestData: ApiRequest = {
        user_id: id,
        password: reEnteredPass,
      };

      mutation.mutate(requestData);
    }
  };

  const handleSubmitEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {
      user_id: id,
    };
    mutationEmail.mutate(requestData);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Reset Dealership User Password"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        {/* {!passwordsMatch && (
          <Text textStyle="caption" color="var(--red-500)">
            {errorMsg}
          </Text>
        )} */}
        {/* <InputGroup>
          <Input
            placeholder="Enter New Password"
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={newPass}
            onChange={(e: any) => {
              setNewPass(e.target.value);
              setPasswordsMatch(true);
            }}
          />
          <InputRightElement
            onClick={() => setShowPassword(!showPassword)}
            style={{ top: "20px" }}
          >
            {showPassword ? (
              <HideViewIcon sx={{ color: "var(--grey-500)" }} />
            ) : (
              <ViewIcon sx={{ color: "var(--grey-500)" }} />
            )}
          </InputRightElement>
        </InputGroup> */}

        {/* <VStack w="100%" alignItems="flex-start">
          <InputGroup>
            <Input
              placeholder="Re-Enter New Password"
              label="Re-Enter Password"
              type={showPasswordConfirm ? "text" : "password"}
              value={reEnteredPass}
              onChange={(e: any) => {
                setReEnteredPass(e.target.value);
                setPasswordsMatch(true);
              }}
            />
            <InputRightElement
              onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              style={{ top: "20px" }}
            >
              {showPasswordConfirm ? (
                <HideViewIcon sx={{ color: "var(--grey-500)" }} />
              ) : (
                <ViewIcon sx={{ color: "var(--grey-500)" }} />
              )}
            </InputRightElement>
          </InputGroup>

          <Text textStyle="caption" color="var(--grey-600)">
            Enter your new password again to confirm
          </Text>
        </VStack> */}

        <VStack
          alignItems="center"
          justifyContent="center"
          h="100%"
          w="100%"
          textAlign="center"
        >
          {/* <Text textStyle="h6" color="black" fontWeight="600">
            or
          </Text> */}

          <Button
            color="var(--blue-500)"
            textColor="white"
            justifySelf="center"
            width="100%"
            onClick={handleSubmitEmail}
          >
            Trigger Password Reset Email
          </Button>
        </VStack>
      </VStack>
      {/* <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Reset Password
        </Button>
      </DrawerFooter> */}

      {(mutationEmail.isLoading || mutation.isLoading) && <Loader />}
    </Drawer>
  );
};

export default ResetPassword;
