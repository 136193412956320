import {
  Button,
  HStack,
  Text,
  VStack,
  Checkbox,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  disconnect,
  eventService,
  updateeventService,
  disconnectCalltracking,
} from "../../../../utils/api/php-endpoints.api";
import { useState } from "react";
import Loader from "../../../common/Spinner";
import { useToast } from "@chakra-ui/react";
import { Select } from "../../../common";
import Popup from "../../../Charts/Popup";
const VinSolution = ({ id, dealerId }: any) => {
  const toast = useToast();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [status, setStatus] = useState();
  const [pendingStatus, setPendingStatus] = useState(); // State for pending status
  const [pendingOptions, setPendingOptions] = useState<string[]>([]);
  const unsubscribe = useMutation(disconnect);
  const updateSubs = useMutation(updateeventService);
  const removeCallTracking = useMutation(disconnectCalltracking);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["eventService", id],
    queryFn: eventService,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setStatus(
        data?.data?.data?.subscriptions?.find(
          (item: any) => item.id == dealerId
        )?.vs_subscription?.status
      );
      setSelectedOptions(
        data?.data?.data?.subscriptions?.find(
          (item: any) => item.id == dealerId
        )?.vs_subscription?.subscriptions
      );
    },
  });
  const disconnectMutation = () => {
    unsubscribe.mutate(
      {
        id: id,
        dealer_id: dealerId,
      },
      {
        onSuccess: () => {
          toast({
            description: "Dealer removed successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const disconnecttracking = () => {
    removeCallTracking.mutate(
      {
        id: id,
        payload: {
          DealerId: dealerId,
        },
      },
      {
        onSuccess: () => {
          toast({
            description: "Dealer removed successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const updateSubscription = () => {
    updateSubs.mutate(
      {
        id: id,
        payload: {
          dealerId: dealerId,
          status: pendingStatus ?? status,
          subscriptions: pendingOptions.length > 0 ? pendingOptions : selectedOptions,
        },
      },
      {
        onSuccess: () => {
          toast({
            description: "Subscription updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          setStatus(pendingStatus ?? status);
          setSelectedOptions(
            pendingOptions.length > 0 ? pendingOptions : selectedOptions
          );
          setPendingStatus(undefined); // Reset pending state
          setPendingOptions([]); // Reset pending state
          onClosePopupOpen();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleCheckboxChange = (item: string, isChecked: boolean) => {
    const updatedOptions = isChecked
      ? [...selectedOptions, item]
      : selectedOptions.filter((option) => option !== item);

    setPendingOptions(updatedOptions); // Store pending changes
    setPopup({
      name: "Confirm Subscription Change",
      description: "Are you sure you want to update your subscriptions?",
      value: "",
    });
    onOpenPopupOpen();
  };

  const handleStatusChange = (value: any) => {
    setPendingStatus(value?.value); // Store pending changes
    setPopup({
      name: "Confirm Status Change",
      description: "Are you sure you want to update your status?",
      value: "",
    });
    onOpenPopupOpen();
  };
  const formatLabel = (label: string) => {
    return label?.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <VStack p="1rem 0" gap="1rem">
      <VStack
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        w="100%"
        alignItems="flex-start"
        background="white"
        padding="1rem"
      >
        <Text textStyle="h5" fontWeight="600">
          Digital Showroom
        </Text>
        <HStack w="100%" gap="1.25rem" justifyContent="space-between">
          <Button
            //   rightIcon={<RouteIcon />}
            sx={{
              svg: {
                path: {
                  fill: "white",
                },
              },
            }}
            background="var(--red-400)"
            _hover={{
              background: "var(--red-400)",
            }}
            border="none"
            onClick={() => {
              setPopup({
                name: "Disconnect Digital Showroom",
                description: "Are you sure you want to disconnect?",
                value: "",
              });
              onOpenPopupOpen();
            }}
          >
            {"Disconnect"}
          </Button>
        </HStack>
      </VStack>
      <VStack
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        w="100%"
        alignItems="flex-start"
        background="white"
        padding="1rem"
      >
        <Text textStyle="h5" fontWeight="600">
          Call Tracking
        </Text>
        <HStack w="100%" gap="1rem" justifyContent="space-between">
          <Button
            sx={{
              svg: {
                path: {
                  fill: "white",
                },
              },
            }}
            border="none"
            background="var(--red-400)"
            _hover={{
              background: "var(--red-400)",
            }}
            onClick={() => {
              setPopup({
                name: "Disconnect Call Tracking",
                description: "Are you sure you want to disconnect?",
                value: "",
              });
              onOpenPopupOpen();
            }}
          >
            {"Disconnect"}
          </Button>
        </HStack>
      </VStack>

      <VStack
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        w="100%"
        alignItems="flex-start"
        background="white"
        padding="1rem"
      >
        <Text textStyle="h5" fontWeight="600">
          Manage Subscription
        </Text>
        <HStack
          w="50%"
          gap="1rem"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Select
            label="Select Status"
            options={
              data?.data?.data?.status_options?.map((stat: any) => ({
                label: stat,
                value: stat,
              })) ?? []
            }
            variant="default"
            w="100%"
            value={status}
            onChange={handleStatusChange}
          />
        </HStack>
        <Text textStyle="h5" fontWeight="600">
          Subscriptions
        </Text>
        <HStack
          w="100%"
          paddingLeft="1rem"
          paddingBottom="1rem"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Wrap spacing="1rem" justify="flex-start" align="flex-end">
            {data?.data?.data?.subscription_options?.map((item: any) => (
              <WrapItem key={item}>
                <Checkbox
                  size="lg"
                  variant="whiteCheck"
                  onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                  isChecked={selectedOptions?.includes(item)}
                  key={item}
                >
                  {formatLabel(item)}
                </Checkbox>
              </WrapItem>
            ))}
          </Wrap>
        </HStack>
      </VStack>
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={popup?.name==='Disconnect Call Tracking'?disconnecttracking:popup?.name==='Disconnect Digital Showroom'?disconnectMutation:updateSubscription}
          title={popup.name}
          message={popup.description}
          loading={
            unsubscribe?.isLoading ||
            removeCallTracking?.isLoading ||
            updateSubs?.isLoading
          }
        />
      )}
    </VStack>
  );
};

export default VinSolution;
