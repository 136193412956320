"use client";
import EmailBlast from "../../../components/EmailBlast";

export default function EmailBlastPage() {
  return (
    <>
      <EmailBlast />
    </>
  );
}
