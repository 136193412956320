import { Text, VStack } from "@chakra-ui/react";
import React from "react";

interface NumberCardProps {
  icon: React.ReactNode;
  stats: { key: string; value: number }[];
  bg: string;
}

const NumberCard = ({ icon, stats,  bg }: NumberCardProps) => {
  return (
    <VStack
      w="100%"
      background={bg}
      alignItems="flex-start"
      borderRadius="0.5rem"
      padding="1rem"
      height="172px"
    >
      {icon}
      {stats.map((stat) => {
        return (
          <>
            <Text textStyle="h4" color="white" fontWeight="700">
              {stat.value}
            </Text>
            <Text textStyle="captionSmall" color="white" fontWeight="500">
              {stat.key}
            </Text>
          </>
        );
      })}
    </VStack>
  );
};

export default NumberCard;
