import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Delete, EditNote, Info } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SearchIcon } from "../../../components/icons/svg";
import { fetchKeywords } from "../../../utils/api/global-config.api";
import { Dropdown, Input, PaginatedTable, Spinner } from "../../common";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import CreateKeyword from "../CreateKeyword/CreateKeywords";
import DeleteKeyword from "../DeleteKeyword/DeleteKeyword";
import EditKeyword from "../EditKeyword/EditKeywords";

const Keyword = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditNote />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <Delete />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchKeywords", page, perPage, searchValue],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchKeywords(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.keywords_count ?? 0);
    },
  });
  const tableHeader = [
    { value: "Opt Out Keywords", label: "keywords" },
    { value: "Actions", label: "actions" },
  ];
  const tableData = data?.keywords?.map((item: any) => {
    return {
      id: item.id,
      keywords: <TooltipIfOverflow char={180}>{item.name}</TooltipIfOverflow>,
      actions: <Dropdown options={options} onSelect={handleSelect} />,
    };
  });

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </HStack>
          <Button onClick={onOpen}>Create Keyword</Button>
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <HStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="1rem"
            background="var(--grey-50)"
          >
            <Info />
            <Text
              font-size="0.875rem"
              color="var(--grey-600)"
              font-weight="500"
              line-height="1rem"
            >
              When a contact send any of these (below) "keywords" they will be
              opted out of being texted. Once they are opt out of messages, only
              way to be opted back is, they have to submit a new lead
            </Text>
          </HStack>
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Keywords ({count})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              tableHeight={560}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={count}
            />
          </Box>
        </VStack>

        {isOpen && (
          <CreateKeyword
            isOpen={isOpen}
            onClose={onClose}
            refetchParent={refetch}
          ></CreateKeyword>
        )}

        {activeModal === "edit" && (
          <EditKeyword
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

        {activeModal === "delete" && (
          <DeleteKeyword
            isOpen={true}
            onClose={() => setActiveModal(null)}
            refetchParent={refetch}
            id={clickedRow?.id}
          />
        )}

        {isLoading && <Spinner />}
      </VStack>
    </>
  );
};

export default Keyword;
