export const Simpsocial_Roles=[
    'SimpSocial Owner',
     'SimpSocial Admin',
      'Customer Service Agent'
]
export const Dealership_Roles=[
    'Owner/GM', 'Manager/Admin', 'Sales Person', 'BDC Agent'
]
export const Simpsocial_Super=[
    'SimpSocial Owner',
     'SimpSocial Admin',
]
export const Dealership_Super=[
    'Owner/GM', 'Manager/Admin'
]