import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack
} from "@chakra-ui/react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import { useState } from "react";

type SoundTestProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SoundTest = ({ isOpen, onClose }: SoundTestProps) => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const playSound = (value: number) => {
    if (!audioContext) {
      const context = new AudioContext();
      setAudioContext(context);
    } else {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      const panNode = audioContext.createStereoPanner();

      oscillator.type = "sine"; // You can change the type of sound here
      oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // Set frequency as per your requirement

      // Set volume for left channel
      gainNode.gain.setValueAtTime(1, audioContext.currentTime);

      oscillator.connect(gainNode);
      gainNode.connect(panNode);
      panNode.pan.setValueAtTime(value, audioContext.currentTime); // Panning to the left (-1 means fully left)
      panNode.connect(audioContext.destination);

      oscillator.start();

      setTimeout(() => {
        oscillator.stop();
      }, 1000); // Adjust duration as needed
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Speaker Test</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <HStack>
              <Button onClick={()=>playSound(-1)}>
                <NavigateBeforeIcon />
                <span>{"Left"}</span>
              </Button>
              <Button onClick={()=>playSound(0)}>
                <SurroundSoundIcon />
                <span>{"Both"}</span>
              </Button>
              <Button onClick={()=>playSound(1)}>
                <NavigateNextIcon />
                <span>{"Right"}</span>
              </Button>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SoundTest;
