"use client";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { convertUnderscoreToLabel } from "../../utils";

class PieChart extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      newOption: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: true,
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: false,
          position: "bottom",
          markers: {
            width: 16,
            height: 16,
          },
        },
        stroke: {
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#1565C0", "#2196F3", "#66BB6A"],
        labels: Object.keys(this.props?.data)?.map((item: string) =>
          convertUnderscoreToLabel(item)
        ),
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "13px",
                  fontFamily: "var(--font-red-hat)",
                  fontWeight: 400,
                  color: "#212121",
                  lineHeight: "normal",
                  whiteSpace: "wrap",
                  width: "100px",
                },
                value: {
                  show: true,
                  fontSize: "24px",
                  fontFamily: "var(--font-red-hat)",
                  fontWeight: 700,
                  color: "#212121",
                  lineHeight: "40px",
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, w }: any) => {
            const seriesName = w.globals.labels[seriesIndex];
            const value = series[seriesIndex];
            const color = w.config.colors[seriesIndex];
            return `
            <div style="padding: 0.5rem 0.75rem; background: #263238; color: #fff; border-radius: 5px;">
                <div style="display: flex; align-items: center;">
                  <div style="width: 0.5rem; height: 0.5rem; background: ${color}; margin-right: 0.38rem;"></div>
                  <span style="font-size: 0.75rem; line-height: 1rem; font-weight: 500">${seriesName}</span>
                </div>
                <div style="margin-top: 1px; margin-left: 14px; font-size: 0.75rem; line-height: 1rem; font-weight: 500">${value}</div>
                <div style="position: absolute; background: #263238; clip-path: polygon(100% 0, 0 0, 50% 100%); width: 1.5rem; height: 1.5rem; bottom: -16px; left: 50%; transform: translateX(-50%); z-index: 999"></div>
              </div>`;
          },
        },
      },
      newSeries: Object.values(this.props?.data),
    };
  }

  render() {
    return (
      <VStack
        className="pie-chart-wrapper"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        h="100%"
      >
        <ReactApexChart
          options={this.state.newOption}
          series={this.state.newSeries}
          type="donut"
          height={250}
          width={250}
        />
        <HStack
          w="100%"
          alignItems="flex-start"
          justifyContent="space-between"
          // margin="3rem"
          // padding="2rem"
        >
          {Object.keys(this.props?.data).map((key: string, index: number) => (
            <HStack alignItems="flex-start">
              <Box
                w="0.8rem"
                h="0.8rem"
                background={this.state.newOption.colors[index]}
                borderRadius="0.3rem"
                mt="0.15rem"
              ></Box>
              <VStack alignItems="flex-start" gap="0">
                <Text textStyle="captionSmall" fontWeight="600">
                  {convertUnderscoreToLabel(key)}
                </Text>
                <Text textStyle="h4" fontWeight="700">
                  {this.props?.data[key]}
                </Text>
              </VStack>
            </HStack>
          ))}
        </HStack>
      </VStack>
    );
  }
}

export default PieChart;
