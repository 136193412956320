import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchTags = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/tags`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateTag = async (payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/tags/${payload.id}`,
      payload.payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteTag = async (id: any, dId: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/tags/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createTag = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/tags`,
      payload.payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchTagById = async (id: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/tags/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const assignContact = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/tags/${payload.tId}/assign_contacts`,
    payload.payload
  );
};
