import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchTotalDealerships = async () =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/dealership_stats`
  );

export const fetchTotalUsers = async () =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/user_stats`
  );

export const fetchPerformance = async () =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/dealership_performance`
  );
export const fetchHeatMapStats = async (params: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/communication_heat_map_stats`,
    { params }
  );
export const fetchMap = async (params: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/user_location_stats`,
    { params }
  );

export const fetchSystemHealthGraphs = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/system_health?start_time=${queryKey[1]}&end_time=${queryKey[2]}`
  );
export const fetchIpDetail = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/ip_details/${queryKey[1]}?state=${queryKey[2]}&page=${queryKey[3]}&per_page=${queryKey[4]}&start_date=${queryKey[5]}&&end_date=${queryKey[6]}&&q=${queryKey[7]}`
  );
export const fetchSystemDownTime = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/global_dashboard/system_down_times?date=${queryKey[1]}`
  );
