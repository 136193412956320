import {
  Grid,
  HStack,
  Text,
  VStack,
  Checkbox,
  Button,
  Flex,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import SwitchToggle from "../common/Switch";
import { Input, PhoneInput } from "../common";
import { useEffect, useState } from "react";
import { isValidEmail } from "../../utils";
import {
  fetchUserSettingsDP,
  updateUserSettingsDP,
} from "../../utils/api/user-settings-dp.api";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { isValidPhoneNumber } from "react-phone-number-input";

interface ApiResult {
  data: any;
}

interface ApiRequest {
  key: string;
  settings_hash: {
    communication: {
      call: boolean;
      text: boolean;
      voicemail: boolean;
      mention: boolean;
      contact_assigned: boolean;
      appointment_assigned: boolean;
    };
    dealership: {
      billing_warnings: boolean;
      account_reports: boolean;
      integration_reports: boolean;
      other_notifications: boolean;
    };
    emails: string[];
    notification_channels: {
      browser: boolean;
      mobile: boolean;
      send_texts_to_custom_numbers: boolean;
      custom_number: string;
    };
    call_notification: {
      send_text_after_call_completion: boolean;
    };
  };
}

export const NotificationSetting = (props: any) => {
  const { id: userIdFromParam } = useParams();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");

  const toast = useToast();

  const [communication, setCommunication] = useState({
    call: false,
    text: false,
    voicemail: false,
    mention: false,
    contact_assigned: false,
    appointment_assigned: false,
  });

  const [dealership, setDealership] = useState({
    billing_warnings: false,
    account_reports: false,
    integration_reports: false,
    other_notifications: false,
  });

  const [newEmail, setNewEmail] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [notification_channels, setNotificationChannels] = useState({
    browser: false,
    mobile: false,
    send_texts_to_custom_numbers: false,
    custom_number: "",
  });
  const [call_notification, setCallNotification] = useState({
    send_text_after_call_completion: false,
  });

  //...............all flag...........
  const [all, setAll] = useState<boolean>(true);
  const [none, setNone] = useState<boolean>(false);

  const handleAllChange = () => {
    setNone(false);
    // if (!all) {
      setNotificationChannels((prev: any) => {
        const updated = { ...prev };
        updated.browser = true;
        updated.mobile = true;
        updated.send_texts_to_custom_numbers = true;
        return updated;
      });
    // }
    setAll(!all);
  };
  const handleNoneChange = () => {
    setAll(false);
    // if (!none) {
      setNotificationChannels((prev: any) => {
        const updated = { ...prev };
        updated.custom_number = "";
        updated.browser = false;
        updated.mobile = false;
        updated.send_texts_to_custom_numbers = false;
        return updated;
      });
      setPhone("");
      setPhoneError("");
    // }

    setNone(!none);
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["notification_settings", userID, userIdFromParam],
    queryFn: () =>
      fetchUserSettingsDP(
        dealerId?.id,
        userIdFromParam ?? userID,
        "notification_settings"
      ),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setEmails(data?.settings_hash?.emails);
      setDealership(data?.settings_hash?.dealership);
      setCommunication(data?.settings_hash?.communication);
      setNotificationChannels(data?.settings_hash?.notification_channels);
      setCallNotification(data?.settings_hash?.call_notification);
      setPhone(data?.settings_hash?.notification_channels?.custom_number);
    },
  });

  useEffect(() => {
    if (notification_channels) {
      const areAllChecked =
        notification_channels?.browser &&
        notification_channels?.mobile &&
        notification_channels?.send_texts_to_custom_numbers;

      const areAllUnchecked =
        notification_channels?.browser === false &&
        notification_channels?.mobile === false &&
        notification_channels?.send_texts_to_custom_numbers === false;

      setAll(areAllChecked);
      setNone(areAllUnchecked);
    }
  }, [notification_channels]);
  //..................change handlers..........................
  const handleCommunicationChange = (key: keyof typeof communication) => {
    setCommunication((prev) => {
      const updatedValue = { ...prev };
      updatedValue[key] = !communication[key];
      return updatedValue;
    });
  };

  const handleDealershipChange = (key: keyof typeof dealership) => {
    setDealership((prev) => {
      const updatedValaue = { ...prev };
      updatedValaue[key] = !dealership[key];
      return updatedValaue;
    });
  };

  const addEmail = (newEmail: string) => {
    if (!isValidEmail(newEmail)) {
      setError("Email is invalid");
      return;
    }
    if (emails?.includes(newEmail?.trim())) {
      setError("Duplicate emails are not allowed");
      return;
    }
    if (emails.length >= 20) {
      setError("You can only add upto 20 emails.");
      return;
    }
    setEmails((prev) => [...prev, newEmail]);
  };

  const deleteEmail = (index: number) => {
    setEmails((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNotificationChannelsChange = (
    key: keyof typeof notification_channels
  ) => {
    if (key === "send_texts_to_custom_numbers") {
      setPhoneError("");
      setPhone("");
    }

    setNotificationChannels((prev: any) => ({
      ...prev,
      [key]: !prev[key],
      ...(key === "send_texts_to_custom_numbers" && { custom_number: "" }),
    }));
  };

  const handleCallNotificationChange = (
    key: keyof typeof call_notification
  ) => {
    setCallNotification((prev: any) => {
      const updatedValue = { ...prev };
      updatedValue[key] = !call_notification[key];
      return updatedValue;
    });
  };

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateUserSettingsDP(
          payload,
          dealerId?.id,
          userIdFromParam ?? userID
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const validatePhoneNumber = () => {
    if (!phone) {
      setPhoneError("Phone number is required");
      return false;
    }

    if (!isValidPhoneNumber(phone)) {
      setPhoneError("Please enter a valid phone number");
      return false;
    }

    return true;
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let hasError = false;

    if (
      notification_channels?.send_texts_to_custom_numbers &&
      !validatePhoneNumber()
    ) {
      hasError = true;
    }

    if (error) {
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const requestData: ApiRequest = {
      key: "notification_settings",
      settings_hash: {
        communication,
        dealership,
        emails,
        notification_channels: notification_channels,
        call_notification: call_notification,
      },
    };

    mutation.mutate(requestData);
  };

  return (
    <VStack w="100%" alignItems="flex-start" position="relative">
      <HStack
        gap="0.75rem"
        h="calc(100vh - 240px)"
        pb="75px"
        overflow="auto"
        alignItems="flex-start"
        // opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        // pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <VStack w="50%" gap="0.62rem">
        <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-start"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500">
              Communication Notification
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Notify me when the following events happen:
            </Text>
            <SwitchToggle
              label="When a contact is assigned to this user"
              id="#1"
              defaultChecked={communication?.contact_assigned}
              onChange={() => handleCommunicationChange("contact_assigned")}
            />
            <SwitchToggle
              label="When an appointment is assigned to this user"
              id="#1"
              defaultChecked={communication?.appointment_assigned}
              onChange={() => handleCommunicationChange("appointment_assigned")}
            />
          </VStack>
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-start"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500">
              Dealership Level Notifications (Admins Only)
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Receive daily emails for dealership activity
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <SwitchToggle
                label="Billing Warnings"
                id="#1"
                defaultChecked={dealership?.billing_warnings}
                onChange={() => handleDealershipChange("billing_warnings")}
              />
              <SwitchToggle
                label="Account Reports"
                id="#1"
                defaultChecked={dealership?.account_reports}
                onChange={() => handleDealershipChange("account_reports")}
              />
              <SwitchToggle
                label="Integration Reports"
                id="#1"
                defaultChecked={dealership?.integration_reports}
                onChange={() => handleDealershipChange("integration_reports")}
              />
              <SwitchToggle
                label="Other Notifications"
                id="#1"
                defaultChecked={dealership?.other_notifications}
                onChange={() => handleDealershipChange("other_notifications")}
              />
            </Grid>
          </VStack>
          {/* <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-start"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500">
              Add Emails Manually
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Add emails below to receive notifications
            </Text>
            <HStack w="100%">
              <Input
                h="35px"
                type="email"
                placeholder="Enter Email"
                value={newEmail}
                onChange={(e) => {
                  setError("");
                  setNewEmail(e.target.value);
                }}
              />
              <Button
                h="35px"
                colorScheme="blue"
                p="0"
                onClick={() => {
                  if (newEmail?.trim() !== "") {
                    addEmail(newEmail);
                  }
                }}
              >
                Add
              </Button>
            </HStack>
            {error && (
              <Text color="red" textStyle="h5">
                {error}
              </Text>
            )}
            <Flex wrap="wrap" alignItems="flex-start" w="100%">
              {emails?.map((email, index) => (
                <HStack
                  key={index}
                  spacing={2}
                  bgColor="#e5e5e5"
                  borderRadius="0.5rem"
                  p="0.5rem"
                  m="5px"
                  maxH="25px"
                  minW="80px"
                >
                  <Text color="var(--grey-800)">{email}</Text>
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="green"
                    onClick={() => deleteEmail(index)}
                    minW="0"
                    p="0"
                    alignItems="center"
                  >
                    X
                  </Button>
                </HStack>
              ))}
            </Flex>
          </VStack> */}
        </VStack>

        <VStack w="50%" gap="0.62rem">
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-start"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500">
              Notification Channels
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Choose what channels you want this user to get notified on.
            </Text>
            <HStack w="100%" gap="50%">
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={handleAllChange}
                isChecked={all}
              >
                All
              </Checkbox>
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={handleNoneChange}
                isChecked={none}
              >
                None
              </Checkbox>
            </HStack>

            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() => handleNotificationChannelsChange("browser")}
              isChecked={notification_channels?.browser}
            >
              Browser
            </Checkbox>
            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() => handleNotificationChannelsChange("mobile")}
              isChecked={notification_channels?.mobile}
            >
              Mobile App
            </Checkbox>
            <HStack justifyContent="space-between" w="100%">
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={() => {
                  handleNotificationChannelsChange(
                    "send_texts_to_custom_numbers"
                  );
                }}
                isChecked={notification_channels?.send_texts_to_custom_numbers}
              >
                Text to Phone Number
              </Checkbox>
              <HStack w="42%">
                <PhoneInput
                  onChange={(value) => {
                    setPhone(value);
                    setNotificationChannels((prev: any) => {
                      setPhoneError("");
                      return {
                        ...prev,
                        custom_number: value ?? "",
                      };
                    });
                  }}
                  isSmall={true}
                  isRequired={false}
                  disabled={
                    !notification_channels?.send_texts_to_custom_numbers
                  }
                  value={phone ?? ""}
                  error={phoneError}
                />
              </HStack>
            </HStack>
          </VStack>
          {/* <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-start"
            w="100%"
          >
            <Text textStyle="h4" fontWeight="500">
              Call Notification
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Notify caller with a text message
            </Text>
            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() =>
                handleCallNotificationChange("send_text_after_call_completion")
              }
              isChecked={call_notification?.send_text_after_call_completion}
            >
              If the call is completed, send a text message to the caller.
            </Checkbox>
          </VStack> */}
        </VStack>
      </HStack>
      <HStack
        bottom="0"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        position="absolute"
        w="100%"
        background="white"
        borderTop="1px solid var(--grey-300)"
      >
        <Button
          width="8rem"
          onClick={handleSubmit}
          isDisabled={!props?.allowed}
        >
          Save
        </Button>
      </HStack>
      {(isLoading || mutation.isLoading) && <Spinner />}
    </VStack>
  );
};
