import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//facebook apis
export const getFacebookPages = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/facebook/${queryKey[2]}/pages`
  );
export const fbcheckoutUrl = (id: any) => {
  return `${SERVICES.apiBaseUrl}/p/v1/facebook/redirect/${id}`;
};
export const checkSubscription = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/facebook/${queryKey[2]}/pages/subscription?page_id=${queryKey[3]}&access_token=${queryKey[4]}&type=get`
  );
export const getForm = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/facebook/${queryKey[2]}/pages/forms?page_id=${queryKey[3]}&access_token=${queryKey[4]}`
  );
export const subscribePage = async (payload: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload.id}/facebook/${payload.userid}/pages/subscription?page_id=${payload.page_id}&access_token=${payload.token}&type=post&name=${payload?.name}`
  );
export const unsubscribePage = async (payload: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload.id}/facebook/${payload.userid}/pages/subscription?page_id=${payload.page_id}&access_token=${payload.token}&type=post&name=${payload?.name}&type=delete`
  );
export const syncLeads = async (payload: any): Promise<any> => {
  // Create an instance of URLSearchParams to build the query string
  const params = new URLSearchParams({
    page_id: payload.page_id,
    forms: payload.forms,
  });

  // Append 'date_range' only if it exists in the payload
  if (payload.range) {
    params.append("date_range", payload.range);
  }

  // Construct the URL with the base path and the serialized query parameters
  const url = `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload.id}/facebook/${payload.userid}/lead-sync?${params}`;

  // Make the GET request
  return axios.get(url);
};
//tiktok apis
export const tiktokcheckoutUrl = (id: any) => {
  return `${SERVICES.apiBaseUrl}/p/v1/tiktok/redirect/${id}`;
};

export const getTiktokSubs = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/tiktok/subscriptions`
  );

export const createSubs = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/tiktok/subscriptions`,
    payload.payload
  );
};
export const removeSubs = async (payload: any) => {
  return await axios.delete(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/tiktok/subscriptions/${payload?.tid}`,
    payload.payload
  );
};
export const importLeads = async (payload: any) => {
  const params = new URLSearchParams({});
  if (payload.range) {
    params.append("date_range", payload.range);
  }
  const url = `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/tiktok/import-leads/${payload?.tid}?${params}`;

  return axios.delete(url);
};
export const disconnect = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/vs/dealer/integrations/remove?DealerId=${payload?.dealer_id}`
  );
};
export const eventService = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/vs/dealer/integrations/connect-event-service`
  );
export const updateeventService = async (payload: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/vs/dealer/integrations/connect-event-service`,
    payload.payload
  );

export const apiLogs = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/crm-dms-integrations/http_logs/${queryKey[2]}/${queryKey[3]}?per_page=5&page=${queryKey[4]}`
  );
export const disconnectCalltracking = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/vs/dealer/integrations/remove-call-tracking`,
    payload.payload
  );
};
