import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//brand apis
export const sendSMS = async (payload: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/messages`, payload);

export const createNote = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/call_history/${params.callHistoryId}/notes`,
    params.payload
  );

export const getNotes = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/call_history/${params.callHistoryId}/notes`
  );

export const sendEmail = async (params: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/email_logs`,
      params.payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const sendBulkEmail = async (params: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/compose_blast`,
      params.payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const translateMsg = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/messages/${payload?.id}/translate`,
    payload.payload
  );

export const getScoreCard = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/contacts/${params.contactId}/call_histories/${params.callHistoryId}/scorecard`  );
