import { Box, SystemStyleObject } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import checkIcon from "../../assets/checkIcon.svg";

interface DataPoint {
  x: string;
  y: number;
}

interface Series {
  name: string;
  data: DataPoint[];
  color: string;
}

interface ChartDataProps {
  series: Series[];
  xaxisTitle: string;
  yaxisTitle: string;
  isSmall: boolean;
}

interface UptimeChartProps {
  data: ChartDataProps;
  fixedTo?: number;
}

const CustomStyles: SystemStyleObject = {
  ".apexcharts-legend": {
    justifyContent: "flex-start !important",
    overflow: "hidden !important",
  },
  ".apexcharts-legend-marker": {
    borderRadius: "4px !important",
    width: "14px !important",
    height: "14px !important",
  },
  ".apexcharts-legend-marker:not(.apexcharts-inactive-legend):before": {
    content: `url(${checkIcon})`,
    position: "absolute",
    backgroundImage: `url(${checkIcon}) no-repeat`,
    backgroundPosition: "100% 100%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const UptimeChart: React.FC<UptimeChartProps> = ({ data, fixedTo = 2 }) => {
  const styles = useMemo<SystemStyleObject | undefined>(
    () => (data?.series?.length > 1 ? CustomStyles : undefined),
    [data?.series?.length]
  );

  const options: ApexOptions = useMemo(
    () => ({
      grid: {
        borderColor: "var(--grey-200)",
        strokeDashArray: 4,
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 400,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      colors: data?.series?.map((item: Series) => item.color),
      series: data?.series?.map((item: Series) => ({
        name: item.name,
        data: item.data,
      })),
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "round",
        width: [2, 2],
        dashArray: 0,
      },
      xaxis: {
        type: "datetime",
        show: true,
        labels: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: data?.xaxisTitle,
          style: {
            color: "var(--grey-700)",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        tickAmount: 6,
      },
      yaxis: {
        grid: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tickAmount: 3,
        title: {
          text: data?.yaxisTitle,
          style: {
            color: "var(--grey-700)",
            fontWeight: "500",
          },
        },
        labels: {
          show: true,
          formatter: (val: number) => val?.toFixed(fixedTo),
        },
      },
      tooltip: {
        show: true,
        shared: true,
        intersect: false,
        x: {
          show: true,
          format: "MMM dd, yyyy HH:mm",
        },
      },
      legend: {
        show: data?.series?.length > 1,
      },
      markers: {
        show: false,
        size: 0,
        style: "hollow",
      },
    }),
    [data]
  );

  return (
    <Box sx={styles} width="100%">
      <ReactApexChart
        options={options}
        series={options?.series}
        type="line"
        height={data?.isSmall ? 180 : 300}
        // width={data?.isSmall ? 450 : 650}
        width="100%"
      />
    </Box>
  );
};

export default UptimeChart;
