import { Box, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import { useState } from "react";
import {
  fetchEmails,
  markEmailAsRead,
} from "../../../utils/api/header-notifications.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Spinner } from "../../common";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";

const EmailCard = (props: any) => {
  const dealerId = useUserInfo("dealership");
  const [emails, setEmails] = useState<any>([]);
  const mutation = useMutation(markEmailAsRead);
  const toast = useToast();
  const navigate = useNavigate();
  const { refetchNotifications } = useNotificationContext();

  const { isLoading } = useQuery({
    queryKey: ["fetchEmails", dealerId?.id, props?.page, props?.perPage],
    queryFn: fetchEmails,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setEmails(data?.data?.emails);
    },
  });
  const handleMarkAsRead = (item: any) => {
    mutation.mutate(
      {
        dId: dealerId?.id,
        payload: `id=${item?.id}`,
      },
      {
        onSuccess: (data: any) => {
          refetchNotifications(); // This will trigger the refetch of the parent's query
          if (item?.contact_id) {
            navigate(`/leads/lead-details`, {
              state: {
                contact_id: item?.contact_id,
                email_id: item?.id,
                activeKey: 3,
                activeIndex: 0,
              },
            });
          } else {
            navigate("/email-blast", {
              state: {
                email_id: item?.id,
              },
            });
          }
          props.onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      gap="0.59rem"
      // h="38rem"
      overflow="auto"
      maxH="calc(100vh - 450px)"
      padding="1rem"
      pt="0"
    >
      {emails?.map((item: any) => (
        <HStack
          padding="1rem"
          w="100%"
          key={item.id}
          background={item?.is_read ? "white" : "var(--grey-100)"}
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          alignItems="flex-start"
          position="relative"
          onClick={() => {
            handleMarkAsRead(item);
          }}
          cursor="pointer"
        >
          {!item?.is_read && (
            <Box
              w="0.47rem"
              h="0.47rem"
              borderRadius="50%"
              background="#F44336"
              position="absolute"
              top="0.5rem"
              left="0.5rem"
            ></Box>
          )}
          <Box
            padding="0.25rem 0.5rem 0"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            background="white"
          >
            <EmailIcon
              style={{
                width: "1.75rem",
                height: "1.75rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          <VStack gap="0.25rem" w="100%" alignItems="flex-start">
            <Text textStyle="h5" fontWeight="500">
              From:{" "}
              <Box as="span" color="var(--primary-600)">
                {item.from_address}
              </Box>
            </Text>
            {item?.contact_id && (
              <Text textStyle="h5" fontWeight="500">
                {item.name}
              </Text>
            )}
            <Text textStyle="captionSmall" fontWeight="500">
              {moment(item?.created_at?.replace("Z", ""))?.format(
                "dddd D MMMM"
              )}
              <Box as="span" color="var(--primary-600)">
                • {moment(item?.created_at?.replace("Z", ""))?.format("h:mm a")}
              </Box>
            </Text>
          </VStack>
        </HStack>
      ))}
      {isLoading && <Spinner />}
    </VStack>
  );
};

export default EmailCard;
