import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    HStack,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import { CloseIcon } from "../icons/svg";  
  interface MessengerVariablesProps {
    isOpen: boolean;
    onClose: () => void;
    isOnRight?: boolean;
    onVariableSelect?: (selectedVariable: string) => void;
  }
  
  export const MessengerVariables = ({
    isOpen,
    onClose,
    isOnRight,
    onVariableSelect,
  }: MessengerVariablesProps) => {
    const data={variables:[
        {name:`[FirstName] to include contact's first name`,value:'[FirstName]'},
        {name:`[LastName] to include contact's last name`,value:'[LastName]'},
        {name:`[FullName] to include contact's full name`,value:'[FullName]'},
        {name:`[AccountName] to include contact's company name`,value:'[AccountName]'},
        {name:`[AgentName] to include contact's agent name`,value:'[AgentName]'},
      ]}
    return (
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        id={isOnRight ? "variable-drawer-right" : "variable-drawer"}
      >
        <DrawerContent
          padding="0"
          borderTopRightRadius="0.5rem"
          borderTopLeftRadius="0.5rem"
          overflow="auto"
        >
          <DrawerBody padding="0">
            <VStack w="100%" gap="0" alignItems="flex-start">
              <HStack
                justifyContent="space-between"
                w="100%"
                background="var(--grey-200)"
                borderTopRightRadius="0.5rem"
                borderTopLeftRadius="0.5rem"
                padding="0.69rem 1rem"
              >
                <Text textStyle="h5" fontWeight="600">
                  Insert Variables
                </Text>
                <HStack>
                  <Button
                    variant="none"
                    padding="0.38rem"
                    borderRadius="0.5rem"
                    onClick={onClose}
                    sx={{
                      svg: {
                        path: {
                          fill: "var(--grey-900)",
                        },
                      },
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </HStack>
              </HStack>
              <VStack
                w="100%"
                alignItems="flex-start"
                padding="1rem"
                height="27.5rem"
                overflow="auto"
                gap="18"
              >
                {data?.variables?.map((variable: any, index: number) => (
                  <Text
                    padding="0.69rem 0"
                    w="100%"
                    textStyle="h6"
                    fontWeight="500"
                    borderBottom="1px solid var(--grey-300)"
                    key={index}
                    cursor="pointer"
                    _hover={{
                      background: "var(--secondary-50)",
                    }}
                    onClick={() => {
                      onVariableSelect?.(variable);
                      onClose();
                    }}
                  >
                    {variable?.name}
                  </Text>
                ))}
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };
  