import React from "react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Box, Button, HStack, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "../../../../icons/svg";

SwiperCore.use([Navigation]);

interface ImageSliderProps {
  images: File[] | string[];
  onDelete?: (index: number) => void;
  isString?: boolean;
}

const ImageGrid: React.FC<ImageSliderProps> = ({
  images,
  onDelete,
  isString = false,
}) => {
  return (
    <HStack flexWrap="wrap" mb="1rem" w="100%" alignItems="flex-start">
      {images?.map((image: any, index: any) => (
        <Box
          key={index}
          w="calc(100% / 3 - 6px)"
          height="100%"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="10px"
          position="relative"
        >
          <HStack>
            <Button
              variant="none"
              border="1px solid var(--grey-300)"
              position="absolute"
              right="0.5rem"
              top="0.5rem"
              padding="0.15rem"
              borderRadius="0.5rem"
              background="white"
              onClick={() => onDelete?.(index)}
              sx={{
                svg: {
                  w: "1rem",
                  h: "1rem",
                  path: {
                    fill: "var(--grey-900)",
                  },
                },
              }}
            >
              <CloseIcon />
            </Button>
            <img
              src={isString ? image : URL.createObjectURL(image)}
              alt={`Image ${index + 1}`}
              style={{
                width: "100%",
                maxHeight: "100px",
                minHeight: "100px",
                objectFit: "cover",
              }}
            />
          </HStack>
        </Box>
      ))}
    </HStack>
  );
};

export default ImageGrid;
