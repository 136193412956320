interface User {
  token: string;
  // role: string;
  user: string;
}

export interface AuthState {
  token: string;
  // role: string;
  user: string;
  loading: boolean;
  errorMessage: string;
  success: boolean;
  twoFa: boolean;
  adminRole: string;
  previousUserId?: string;
}

export type AuthAction =
  | { type: "REQUEST_LOGIN" }
  | {
      type: "LOGIN_SUCCESS";
      payload: {
        status: { data: User };
      };
      token: string;
      previousUserId?: string;
    }
  | { type: "LOGOUT" }
  | { type: "2FA" }
  | { type: "LOGIN_ERROR"; error: string }
  | { type: "RESET_LOGIN" }
  | { type: "UPDATE_STORE"; data: { key: keyof AuthState; value: any } }
  | { type: "UPDATE_ADMIN_ROLE"; adminRole: string };

const user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")!)
  : "";

export const initialState: AuthState = {
  token: user?.token || "",
  // role: user?.role || '',
  user: user || "",
  loading: false,
  errorMessage: "",
  success: false,
  twoFa: false,
  adminRole: "",
  previousUserId: ""
};

export const AuthReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.token,
        //    role: action.payload.status.data.user,
        user: action.payload.status.data.user,
        previousUserId: action.previousUserId,
        loading: false,
        success: true,
        twoFa: false,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
        //   role: '',
        user: "",
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
        success: false,
        twoFa: false,
      };
    case "2FA":
      return {
        ...state,
        loading: false,
        twoFa: true,
      };
    case "RESET_LOGIN":
      return {
        ...state,
        loading: false,
        errorMessage: "",
        success: false,
        twoFa: false,
      };
    case "UPDATE_STORE":
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case "UPDATE_ADMIN_ROLE":
      return {
        ...state,
        adminRole: action.adminRole,
      };
    default:
      const _exhaustiveCheck: never = action;
      throw new Error(`Unhandled action type: ${_exhaustiveCheck}`);
  }
};
