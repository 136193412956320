import { Button, DrawerFooter, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Drawer, Input, PhoneInput, Select } from "../../common";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import {
  fetchInventoryFeedProviderById,
  updateInventoryFeedProvider,
} from "../../../utils/api/global-config.api";
import { useQuery } from "@tanstack/react-query";
import { isValidEmail } from "../../../utils";
import { isValidPhoneNumber } from "react-phone-number-input";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  inventory_feed_provider: {
    provider_name: string;
    email?: string;
    provider_phone?: string;
    datatype?: string;
    dealership_column?: string;
    directory_path?: string;
    note?: string;
    image_separator?:string;
    column_separator?:string;
  };
}
const EditInventoryFeedProvider = (props: data) => {
  const { isOpen, onClose, refetchParent, id } = props;
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [directory, setDirectory] = useState("");
  const [column, setColumn] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
    const [imgSep, setImgSep] = useState<any>(null);
    const [colSep, setColSep] = useState<any>(null);
     const [options, setOptions] = useState<SelectOptionProps[]>([
       { label: "Single file", value: "single_file" },
       {
         label: "Multiple Dealerships in one Directory",
         value: "multiple_dealerships_directory",
       },
       {
         label: "Multiple Dealerships in one File",
         value: "multiple_dealerships_file",
       },
     ]);
     const separators = [
      { id: 1, value: ',', label: 'Comma ,' },
      { id: 2, value: ';', label: 'Semicolon ;' },
      { id: 3, value: '\t', label: `Tab \\t` },
      { id: 4, value: ' ', label: `Space ' '` },
      { id: 5, value: '|', label: 'Pipe |' },
      { id: 6, value: ':', label: 'Colon :' },
    ];

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [dataTypeError, setDataTypeError] = useState("");
  const [directoryError, setDirectoryError] = useState("");
  const [columnError, setColumnError] = useState("");
  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateInventoryFeedProvider(id, payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Inventory Feed Provider created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error creating Inventory Feed Provider: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!name?.trim()) {
      setNameError("Name cannot be empty");
      return;
    }

    if (!email?.trim()) {
      setNameError("Email cannot be empty");
      return;
    }

    if (email && !isValidEmail(email)) {
      setEmailError("Email is invalid");
      return;
    }
    if (!phone) {
      setPhoneError("Please enter a valid phone number");
      return;
    }
    if (phone && !isValidPhoneNumber(phone)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }

    if (!selectedOption) {
      setDataTypeError("Please select a data type");
      return;
    }
    if(selectedOption?.value!=='single_file'&&!directory){
      setDirectoryError("Please select a directory path");
      return;
    }
    if(selectedOption?.value === "multiple_dealerships_file"&&!column){
      setColumnError("Please select a column header")
      return;
    }

    setNameError("");
    setEmailError("");
    setPhoneError("");
    setDataTypeError("");
    setColumnError('');
    setDataTypeError("");

    const requestData: ApiRequest = {
      inventory_feed_provider: {
        provider_name: name,
        email: email,
        provider_phone: phone,
        datatype: selectedOption?.value,
        note: note,
        dealership_column: column,
        directory_path: directory,
        image_separator:imgSep?.map((item:any) => item?.value).join(''),
        column_separator:colSep?.map((item:any) => item?.value).join('')
      },
    };
    mutation.mutate(requestData);
  };
  const { data, isLoading } = useQuery({
    queryKey: ["fetchIFP"],
    queryFn: () => fetchInventoryFeedProviderById(id),
  });

  useEffect(() => {
    setName(data?.provider_name);
    setEmail(data?.email);
    setSelectedOption(
      data?.datatype ? { label: data.datatype, value: data.datatype } : null
    );
    setPhone(data?.provider_phone);
    setNote(data?.note);
    setDirectory(data?.directory_path);
    setColumn(data?.dealership_column)
    const parseImgSeparators = () => {
      return data?.image_separator?.split('').map((separatorChar:any) => {
        // Find the corresponding object in the `separators` array
        const found = separators.find(sep => sep.value === separatorChar);
        return found ? { value: found.value, label: found.label } : null;
      }).filter((sep:any) => sep !== null); // Filter out any nulls (in case of unmatched separators)
    };
    const parseColSeparators = () => {
      return data?.column_separator?.split('').map((separatorChar:any) => {
        // Find the corresponding object in the `separators` array
        const found = separators.find(sep => sep.value === separatorChar);
        return found ? { value: found.value, label: found.label } : null;
      }).filter((sep:any) => sep !== null); // Filter out any nulls (in case of unmatched separators)
    };

    setImgSep(parseImgSeparators)
    setColSep(parseColSeparators)
  }, [data]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Inventory Feed Provider"
      isLoading={isLoading || mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Write Inventory Feed Provider"
          label="Enter Inventory Feed Provider"
          onChange={(e: any) => {
            setNameError("");
            setName(e.target.value);
          }}
          value={name}
          isRequired={true}
          error={nameError}
        />
        <Input
          placeholder="Write Email"
          label="Enter Email"
          onChange={(e: any) => {
            setEmailError("");
            setEmail(e.target.value);
          }}
          value={email}
          isRequired={true}
          error={emailError}
        />
         <PhoneInput
          label="Phone number"
          placeholder="Enter phone number"
          onChange={(value) => {
            setPhoneError("");
            setPhone(value);
          }}
          isRequired={false}
          value={phone}
          error={phoneError}
        />
        <Select
          w="100%"
          onChange={(value: SelectOptionProps | null) => {
            setDataTypeError("");
            setSelectedOption(value);
          }}
          placeholder="Select Data Type"
          options={options}
          variant="default"
          value={selectedOption?.value}
          label="Data Type"
          error={dataTypeError}
        />
            <Select
                  w="100%"
          label="Image Separator"
          placeholder="Enter Image Separator"
          options={separators}
          onChange={(value) => {
    
            setImgSep(
              value
            );
          }}
          isMulti
          value={imgSep}
        />
        <Select
                  w="100%"
          label="Column Separator"
          placeholder="Enter Column Separator"
          options={separators}
          onChange={(value) => {
       
            setColSep(value);
          }}
          isMulti
          value={colSep}
        />
     {(selectedOption?.value === "multiple_dealerships_directory" ||
          selectedOption?.value === "multiple_dealerships_file") && (
          <Input
            placeholder="Enter Directory Path"
            label=" Directory Path"
            onChange={(e: any) => {
              setDirectory(e.target.value);
              setDirectoryError("")
            }}
            value={directory}
            isRequired={true}
            error={directoryError}
          />
        )}
        {selectedOption?.value === "multiple_dealerships_file" && (
          <Input
            label="Dealership Column Header"
            placeholder="Enter Dealership Column Header"
            onChange={(e: any) => {
              setColumn( e.target.value );
              setColumnError("")
            }}
            value={column}
            isRequired={true}
            error={columnError}
          />
        )}
       

        <Input
          placeholder="Enter notes"
          label="Write here"
          onChange={(e: any) => setNote(e.target.value)}
          value={note}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default EditInventoryFeedProvider;
