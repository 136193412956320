import React, { useEffect, useState } from "react";
import { VStack, Text, Button, useToast, HStack } from "@chakra-ui/react";
import SwitchToggle from "../common/Switch";
import {
  fetchNotificationSettingsGlobal,
  updateNotificationSettingsGlobal,
} from "../../utils/api/user-settings-dp.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Spinner } from "../common";
import { useParams } from "react-router-dom";

interface ApiResult {
  data: any;
}

interface ApiRequest {
  key: string;
  settings_hash: {
    health_of_system: boolean;
    broken_lines: boolean;
    error_messages_from_carriers: boolean;
    no_activity_last_48_hours: boolean;
    at_loss_MTD: boolean;
    account_suspended: boolean;
    account_created: boolean;
    line_removed_or_added: boolean;
    sequence_suspended: boolean;
    broadcast_sent: boolean;
    lead_drop_last_72_hours: boolean;
    email_delivery_failure: boolean;
    social_media_API_errors: boolean;
    inventory_update_failures: boolean;
  };
}

export const NotificationSettingsGlobal = (props: any) => {
  const toast = useToast();
  const userID = useUserInfo("id");
  const { id: userIdFromParam } = useParams();

  const labelMappings: Record<string, string> = {
    health_of_system: "Health of System",
    // broken_lines: "Broken Lines",
    // error_messages_from_carriers: "Error Messages from Carriers",
    no_activity_last_48_hours: "Accounts with No Activity Last 48 Hours",
    at_loss_MTD: "Accounts at Loss MTD",
    account_suspended: "Account Suspended",
    account_created: "Account Created",
    line_removed_or_added: "Line Removed or Added",
    sequence_suspended: "Sequence Suspended",
    broadcast_sent: "Broadcast Edit",
    lead_drop_last_72_hours: "Account with Lead Drop 30% or More Last 72 Hours",
    email_delivery_failure: "Account with Email Delivery Failure",
    social_media_API_errors: "Social Media API Errors",
    inventory_update_failures: "Inventory Update Failures from Vendors or FTP",
  };

  const [globalNotifications, setGlobalNotifications] = useState({
    health_of_system: true,
    broken_lines: true,
    error_messages_from_carriers: true,
    no_activity_last_48_hours: true,
    at_loss_MTD: true,
    account_suspended: true,
    account_created: true,
    line_removed_or_added: true,
    sequence_suspended: true,
    broadcast_sent: true,
    lead_drop_last_72_hours: true,
    email_delivery_failure: true,
    social_media_API_errors: true,
    inventory_update_failures: true,
  });

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateNotificationSettingsGlobal(
          payload,
          userIdFromParam ?? userID
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {
      key: "global_notifications",
      settings_hash: globalNotifications,
    };

    mutation.mutate(requestData);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["fetchNotifGlobal", userID],
    queryFn: () => fetchNotificationSettingsGlobal(userIdFromParam ?? userID),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setGlobalNotifications(data?.settings_hash);
  }, [data]);

  const handleGlobalNotificationChange = (
    key: keyof typeof globalNotifications
  ) => {
    setGlobalNotifications((prev: any) => {
      const updatedValue = { ...prev };
      updatedValue[key] = !globalNotifications[key];
      return updatedValue;
    });
  };

  return (
    <VStack
      w="100%"
      gap="0.62rem"
      position="relative"
      alignItems="flex-start"
      h="100%"
      opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
      pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
    >
      <VStack
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-start"
        w="50%"
      >
        <Text textStyle="h4" fontWeight="500">
          Global Notifications
        </Text>
        {globalNotifications &&
          Object.keys(labelMappings)?.map((key) => (
            <SwitchToggle
              label={labelMappings[key]}
              id={key}
              defaultChecked={
                globalNotifications[key as keyof typeof globalNotifications]
              }
              onChange={() =>
                handleGlobalNotificationChange(
                  key as keyof typeof globalNotifications
                )
              }
            />
          ))}
      </VStack>

      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        zIndex="0"
      >
        <Button width="8rem" onClick={handleSubmit}>
          Save
        </Button>
      </HStack>

      {isLoading && <Spinner />}
    </VStack>
  );
};
