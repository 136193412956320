import { HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { humanize } from "../../utils";
import {
  getPowerDialerCampaign,
  getPowerDialerCampaigns,
} from "../../utils/api/power-dialer.api";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Dropdown, PaginatedTable } from "../common";
import Loader from "../common/Spinner";
import CreatePowerDialer from "./CreatePowerDialer";

type PowerDialerListingProps = {
  title: string;
  type: string;
  shouldRefetch: boolean;
  setShouldRefetch: (value: boolean) => void;
};

const tableHeader = [
  { id: 1, value: "Name", label: "campaign_name" },
  { id: 2, value: "Completed Date", label: "date" },
  { id: 3, value: "Total Contacts", label: "people" },
  { id: 3, value: "Pending Contacts", label: "pending_contacts" },
  { id: 3, value: "Completed Contacts", label: "completed_contact" },
  { id: 4, value: "Status", label: "status" },
  { id: 4, value: "Action", label: "actions" },
];

const options = [
  { label: "Edit", value: "edit" },
  { label: "Communication Logs", value: "logs" },
  { label: "Details", value: "details" },
];

function PowerDialerListing({
  title,
  type,
  shouldRefetch,
  setShouldRefetch,
}: PowerDialerListingProps) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const user = useUserInfo("user");
  const { shouldUpdatePWListing, campaignParams } = useTwilioState();
  const dispatch = useTwilioDispatch();
  const toast = useToast();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`messages-${type}`, page, perPage],
    queryFn: () =>
      getPowerDialerCampaigns({
        status: type,
        page,
        perPage,
        dealership_id: user?.dealership?.id,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (shouldUpdatePWListing) {
      refetch();
      dispatch({
        type: "SET_SHOULD_UPDATE_PW_LISTING",
        payload: { shouldUpdatePWListing: false },
      });
    }
  }, [dispatch, refetch, shouldUpdatePWListing]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [refetch, setShouldRefetch, shouldRefetch]);

  const handleSelect = useCallback(
    async (option: SelectOptionProps, id: string) => {
      setSelectedCampaign(id);
      if (option.value === "logs") {
        return navigate(`/power-dialer/communication/${id}`);
      }

      if (option.value === "edit") {
        return setIsEditModalOpen(true);
      }

      if (option.value === "details") {
        const transformedOptions = campaignParams.running
          ? Number(id) === Number(campaignParams.id)
            ? options
            : options.filter((option: any) => option.value !== "details")
          : options;

        const powerDialerData = await getPowerDialerCampaign({
          dealership_id: user?.dealership?.id,
          id,
        });

        const isLock = powerDialerData?.data?.lock_status;

        const finalOption = isLock
          ? Number(id) === Number(campaignParams.id)
            ? options
            : transformedOptions.filter(
                (option: any) => option.value !== "details"
              )
          : transformedOptions;

        if (!finalOption.some((value) => value.value === "details")) {
          return toast({
            description: `Campaign is currently locked`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
        return navigate(`/power-dialer/${id}`);
      }
    },
    [campaignParams.id, campaignParams.running, navigate, toast, user?.dealership?.id]
  );

  const tableData = useMemo(() => {
    if (!data?.data?.power_dialers?.length) {
      return [];
    }

    return data?.data?.power_dialers.map((item: any) => {
      const transformedOptions =
        item.status === "completed"
          ? options.filter((option: any) => option.value !== "details")
          : options;
      return {
        ...item,
        date: item.completed_date
          ? moment(item.completed_date).format("DD-MM-YYYY hh:mm A")
          : "- -",
        people: item.total_contacts,
        status: humanize(item.status),
        pending_contacts: item.total_contacts - item.completed_contact,
        actions: (
          <Dropdown
            options={
              !item.logs_exist
                ? transformedOptions
                : transformedOptions.filter(
                    (option: any) => option.value !== "edit"
                  )
            }
            onSelect={(option) => handleSelect(option, item?.id)}
          />
        ),
      };
    });
  }, [data?.data?.power_dialers, handleSelect]);

  return (
    <>
      {isLoading && <Loader />}
      <VStack alignItems="flex-start" w="100%">
        <HStack w="100%" justifyContent="space-between" p="1rem 0">
          <Text textStyle="h6" fontWeight="500">
            {title} ({data?.data?.total_dialer_campaigns || 0})
          </Text>
        </HStack>
        <PaginatedTable
          data={tableData}
          header={
            !type
              ? tableHeader
              : tableHeader.filter((header) => header.label !== "status")
          }
          itemsPerPage={10}
          maxPageNumbers={10}
          hasPadding={false}
          lastCenter={false}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={data?.data?.total_dialer_campaigns || 0}
        />
      </VStack>

      {isEditModalOpen && (
        <CreatePowerDialer
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setShouldRefetch(true);
          }}
          powerDialerId={selectedCampaign}
        />
      )}
    </>
  );
}

export default PowerDialerListing;
