import { Box, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Dropdown } from "../../../components/common";
import { ExcelIcon, PDFIcon } from "../../../components/icons/svg";
import { downloadFile } from "../../../utils/api/reports";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

export default function ExportReport(props: any) {
  const { reportType } = props;
  const dId = useUserInfo("dealership")?.id;
  const userId = useUserInfo("user")?.id;
  const toast = useToast();
  const mutation = useMutation(downloadFile);

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <ExcelIcon />
          Excel
        </Box>
      ),
      value: "excel",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];

  const handleSelect = async (option: SelectOptionProps) => {
    mutation?.mutate(
      { dId, userId, format: option?.value, reportType },
      {
        onSuccess: (data) => {
          toast({
            description: `${
              option?.value === "pdf" ? "PDF" : "Excel"
            } file will be generated shortly and the download link will be available in the notifications.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <Dropdown
      options={options}
      onSelect={handleSelect}
      hasText={true}
      text="Export"
    />
  );
}
