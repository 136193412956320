import { Box, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import AttachmentBar from "../../../components/AttachmentBar";
import { Input } from "../../../components/common";
import AttachmentViewer from "../../../components/common/AttachmentViewer";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { loadOutboundLine } from "../bdc-campaign/CreateCampaign/utils";

export default function SmsContent({
  control,
  setValue,
  getValues,
  attachmentUrls,
  setAttachmentUrls,
}: any) {
  const dId = useUserInfo("dealership")?.id;
  // line will not be mandatory as if not present default dealership line is used
  return (
    <>
      <Box mb="1rem">
        <Text textStyle="h5" mb="0.62rem">
          Line
        </Text>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AsyncSelect
                loadOptions={(searchStr) => loadOutboundLine(searchStr, dId)}
                defaultOptions
                value={value}
                onChange={(val) => onChange(val)}
                placeholder="Select line"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                    marginBottom: "0.5rem",
                  }),
                }}
              />
            );
          }}
          name="line_id"
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>
      <Box mb="1rem">
        <Controller
          name="content"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Input
                placeholder="Type sms here"
                hasIcon={false}
                label="Message"
                type="text"
                maxW="lg"
                w="100%"
                isTextarea={true}
                rows={5}
                {...field}
                error={error?.message}
                isRequired
              />
            );
          }}
        />
        <Box mt="1rem">
          <AttachmentBar
            gap="0.5rem"
            width="3rem"
            flexWrap="wrap"
            setBody={(text, concatText, type = "text") => {
              if (type === "text") {
                const messagesText = `${getValues("content")} ${
                  concatText ? `${concatText}: ` : ""
                }${text}`;
                setValue("content", messagesText);
              } else {
                setAttachmentUrls((prev: any) => [...prev, text]);
              }
            }}
            handleTemplate={(value, body = "") => {
              setValue("content", body);
            }}
          />
          <AttachmentViewer
            attachments={attachmentUrls}
            setAttachments={setAttachmentUrls}
          />
        </Box>
      </Box>
    </>
  );
}
