import axios from "axios";
import { SERVICES } from "../../config";

export const fetchTemplate = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchTemplateWithDealershipId = async (
  id: any,
  page = 1,
  per_page = 20
) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${id}/scorecards`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          page,
          per_page,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchTemplateById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchTemplateByIdWithDealershipId = async (id: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/scorecards/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createTemplate = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Global Configurations",
          "Sub-Menu": "Scorecard Transcription Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createTemplateWithDealershipId = async (
  dId: any,
  payload: any
) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/scorecards`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Scorecard Transcription Settings",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateTemplate = async (id: any, payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards/${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Global Configurations",
          "Sub-Menu": "Scorecard Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateTemplateWithDealershipId = async (
  id: any,
  payload: any,
  dId: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/scorecards/${id}`,
      payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealership Settings",
          "Sub-Menu": "Settings/Dealership Settings/Scorecard Settings",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const deleteTemplate = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Global Configurations",
          "Sub-Menu": "Scorecard Transcription Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteTemplateWithDealershipId = async (id: any, dId: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/scorecards/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealership",
          "Sub-Menu":
            "Settings/Dealership Settings/Scorecard Transcription Settings",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const duplicateTemplate = async (id: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/scorecards/${id}/duplicate`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Global Configurations",
          "Sub-Menu": "Scorecard Transcription Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const duplicateTemplateWithDealershipId = async (id: any, dId: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/scorecards/${id}/duplicate`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Scorecard Transcription Settings",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
