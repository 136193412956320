import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout,useAuthDispatch } from './context/AuthContext';
const axiosClient = axios.create();


axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 2000;

axiosClient.defaults.withCredentials = true;

axios.defaults.headers.common['ngrok-skip-browser-warning'] = "test";
const useAxiosInterceptors = () => {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 403) {
          navigate('/forbidden'); // Redirect to the forbidden page
        }
        if (error.response && error.response.status === 401) {
            logout(dispatch);
            navigate('/login'); // Redirect to the forbidden page
                 }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]);
};

export default useAxiosInterceptors;