import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import CreatePowerDialer from "./CreatePowerDialer";
import PowerDialerListing from "./PowerDialerListing";

const tabMapping: any = {
  All: "",
  "In Progress": "in_progress",
  Pending: "pending",
  Paused: "paused",
  Completed: "completed",
};

function PowerDialer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  return (
    <VStack w="100%" h="100%">
      <HStack
        padding="1rem 1.5rem"
        w="100%"
        background="white"
        justifyContent="space-between"
      >
        <Text textStyle="h4" fontWeight="700">
          SimpSocial Power Dialer
        </Text>
        <Button onClick={onOpen}>
          Create SimpSocial Power Dialer Campaign
        </Button>
      </HStack>
      <Box padding="1.5rem" w="100%">
        <VStack
          w="100%"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="1rem"
        >
          <Tabs variant="primary" w="100%" isLazy>
            <TabList w="100%">
              <Tab>All Statuses</Tab>
              <Tab borderRight="0">In Progress</Tab>
              <Tab borderRight="0">Pending</Tab>
              <Tab borderRight="0">Paused</Tab>
              <Tab>Completed</Tab>
            </TabList>
            <TabPanels>
              {["All", "In Progress", "Pending", "Paused", "Completed"].map(
                (type) => {
                  return (
                    <TabPanel>
                      <PowerDialerListing
                        type={tabMapping[type]}
                        title={type}
                        shouldRefetch={shouldRefetch}
                        setShouldRefetch={setShouldRefetch}
                      />
                    </TabPanel>
                  );
                }
              )}
            </TabPanels>
          </Tabs>
        </VStack>
      </Box>
      {isOpen && (
        <CreatePowerDialer
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setShouldRefetch(true);
          }}
        />
      )}
    </VStack>
  );
}

export default PowerDialer;
