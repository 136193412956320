"use client";

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import BDCCampaignPage from "../bdc-campaign/page";
import MainListing from "./MainListing";

export default function DMSEquityPage() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <Tabs
          variant="table"
          defaultIndex={activeIndex}
          onChange={(index) => setActiveIndex(index)}
          index={activeIndex}
        >
          <TabList>
            <Tab>Sales</Tab>
            <Tab>Services</Tab>
            <Tab>R.O. TradeUp</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {activeIndex === 0 && <MainListing activeIndex={activeIndex} />}
            </TabPanel>
            <TabPanel>
              {activeIndex === 1 && <MainListing activeIndex={activeIndex} />}
            </TabPanel>
            <TabPanel>
              {activeIndex === 2 && <BDCCampaignPage is_ro_tradeup={true} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
