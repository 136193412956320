import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  data: any;
  headers: any;
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchNotes = async (dId: any, cId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${cId}/notes`
  );
};
export const fetchNoteById = async (payload: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/notes/${payload.noteId}`
  );
};
export const createNote = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/notes`,
    payload.payload
  );
};
export const updateNote = async (payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/notes/${payload.noteId}`,
    payload.payload
  );
};
export const deleteNote = async (payload: any) => {
  return await axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/notes/${payload.noteId}`
  );
};
