"use client";

import { Box, Button, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import LGHeatMapChart from "../../../components/Charts/LGHeatMapChart";
import { Spinner } from "../../../components/common";
import DatePicker from "../../../components/common/DateRangePicker";
import ColorKey from "../../../components/GlobalDashboard/ColorKey/ColorKey";
import { EmptyIcon, GreyAlertIcon } from "../../../components/icons/svg";
import { convertUnderscoreToLabelArray } from "../../../utils";
import { fetchHeatMapStats } from "../../../utils/api/global-dashboard";
import DealershipDropdown from "../dashboard/DealershipAnalytics/DealershipDropdown";

export default function HeatMapPage(props: any) {
  const {
    setIsback,
    selectionRange,
    dealershipCom,
    setSelectionRange,
    setDealershipCom,
  } = props;
  const printRef = useRef<any>(null);
  const [isLoadingDoc, setIsLoadingDoc] = useState<Boolean>(false);

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ["fetchHeatMapStats", selectionRange, dealershipCom],
    queryFn: () =>
      fetchHeatMapStats({
        start_date: selectionRange?.selected ? selectionRange?.startDate : null,
        end_date: selectionRange?.selected ? selectionRange?.endDate : null,
        dealership_id: dealershipCom?.value,
      }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled:
      dealershipCom !== undefined &&
      selectionRange?.startDate !== selectionRange?.endDate,
  });

  const handlePrint = async () => {
    const printableContent = printRef.current;
    if (!printableContent) return;

    try {
      const canvas = await html2canvas(printableContent, {
        scrollY: -window.scrollY,
        useCORS: true,
        scale: 2,
      });

      let pdf = new jsPDF("l", "mm", "a4");
      let pageWidth = pdf.internal.pageSize.getWidth();

      let canvasWidth = pageWidth;
      let canvasHeight = (canvas.height * pageWidth) / canvas.width;

      pdf.addImage(
        canvas.toDataURL("image/png", 1.0),
        "PNG",
        0,
        0,
        canvasWidth,
        canvasHeight
      );
      pdf.save("Communication Heatmap.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoadingDoc(false);
    }
  };

  useEffect(() => {
    if (isLoadingDoc) {
      handlePrint();
    }
  }, [isLoadingDoc]);

  return (
    <VStack alignItems="flex-start" w="100%" gap="0" ref={printRef}>
      <HStack
        w="100%"
        padding="1rem 1.5rem"
        background="white"
        borderBottom="1px solid var(--grey-300)"
      >
        {" "}
        {!isLoadingDoc && (
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => setIsback(true)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "var(--grey-900)",
                }}
              />
            </Box>

            <Text textStyle="h6" fontWeight="500">
              Back to Dashboard
            </Text>
          </Button>
        )}
      </HStack>
      <VStack padding="1rem 1.5rem" w="100%" alignItems="flex-start">
        <VStack
          border="1px solid var(--grey-300)"
          padding="1.5rem"
          background="white"
          borderRadius="0.5rem"
          w="100%"
          alignItems="flex-start"
          gap="3rem"
        >
          <HStack w="100%" justifyContent="space-between" alignItems="center">
            <HStack>
              <Text textStyle="h2" fontWeight="700">
                Communication Heatmap
              </Text>
              <Tooltip
                label={
                  <Text>You can hover over the block to see details info</Text>
                }
                background="var(--bluegray-900)"
                border="none"
                borderRadius="0.5rem"
                padding="0.75rem"
                hasArrow
                w="max-content"
                placement="top"
              >
                <Button variant="none">
                  <GreyAlertIcon />
                </Button>
              </Tooltip>
            </HStack>
            <HStack alignItems="center">
              <DatePicker
                value={selectionRange}
                onChange={(value) => {
                  setSelectionRange(value);
                }}
              />
              {isLoadingDoc ? (
                <Text fontWeight="600" marginRight={"2rem"}>
                  {dealershipCom?.label}
                </Text>
              ) : (
                <DealershipDropdown
                  placeholder="Select dealership"
                  dealerships={dealershipCom}
                  setDealerships={setDealershipCom}
                />
              )}
              {!isLoadingDoc && (
                <Button
                  background="none"
                  borderColor="var(--grey-300)"
                  color="black"
                  onClick={() => setIsLoadingDoc(true)}
                  h="48px"
                >
                  Export (PDF)
                </Button>
              )}
            </HStack>
          </HStack>
          {isSuccess ? (
            <>
              <HStack
                w="100%"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <LGHeatMapChart
                  isLarge={true}
                  key={`${dealershipCom?.value}-${selectionRange.startDate}-${selectionRange.endDate}`}
                  categories={
                    data?.data?.group_count
                      ? convertUnderscoreToLabelArray(data?.data?.group_count)
                      : {}
                  }
                  resp={data?.data?.group_count ?? {}}
                />
              </HStack>
              <ColorKey isLarge={true} isMap={false} />
            </>
          ) : (
            <>
              <VStack minH="600px" w="100%" justifyContent="center">
                <EmptyIcon />
                <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
                  Please select a dealership.
                </Text>
              </VStack>
            </>
          )}
        </VStack>
      </VStack>
      {isFetching && <Spinner />}
    </VStack>
  );
}
