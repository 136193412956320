import { Button, DrawerFooter, VStack, Text } from "@chakra-ui/react";
import { Drawer } from ".";
import Loader from "./Spinner";

const ConfirmationDrawer = (props: any) => {
  const { isOpen, onClose, userId, refetchParent, dealershipId } = props;
  return (
    <Drawer isOpen={isOpen} onClose={onClose} title={props?.content?.name}>
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          {props?.content?.description}
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={props?.apiCall}>
          Confirm
        </Button>
      </DrawerFooter>
      {props?.loading&&<Loader/>}
    </Drawer>
  );
};

export default ConfirmationDrawer;
