import { Box, Button, DrawerFooter } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Input } from "../../../components/common";

const schema = yup.object().shape({
  year: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .integer("Year must be an integer")
    .positive("Year must be a positive number")
    .max(9999, "Year cannot be more than 4 digits"),
  make: yup.string().nullable(),
  model: yup.string().nullable(),
  exteriorColor: yup.string().nullable(),
  interiorColor: yup.string().nullable(),
  mileage: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .positive("Mileage must be a positive number"),
});

const InventoryFiltersDrawer = (props: any) => {
  const { setParams, onClose, params, reset } = props;
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: params || {},
  });

  const onSubmit = (data: any) => {
    setParams(data);
    onClose();
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Controller
            name="year"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Year"
                label="Year"
                type="number"
                maxLength={4}
                error={error?.message || ""}
              />
            )}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            name="make"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Make"
                label="Make"
                type="text"
                error={error?.message || ""}
              />
            )}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            name="model"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Model"
                label="Model"
                error={error?.message || ""}
              />
            )}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            name="exteriorColor"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Exterior Color"
                label="Exterior Color"
                error={error?.message || ""}
              />
            )}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            name="interiorColor"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Interior Color"
                label="Interior Color"
                error={error?.message || ""}
              />
            )}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            name="mileage"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="Enter Mileage"
                label="Mileage"
                type="number"
                error={error?.message || ""}
              />
            )}
          />
        </Box>
      </Box>

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
        background="white"
      >
        <Button
          variant="outline"
          w="100%"
          size="xl"
          onClick={() => {
            reset();
            onClose();
          }}
        >
          Reset
        </Button>
        <Button w="100%" size="xl" type="submit">
          Apply
        </Button>
      </DrawerFooter>
    </Box>
  );
};

export default InventoryFiltersDrawer;
