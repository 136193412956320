import {
  HStack,
  PinInput,
  PinInputField,
  Text,
  Button,
  Link,
  Box,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { submitOtp, resendOtp } from "../../../utils/api/login.api";
import { useMutation } from "@tanstack/react-query";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { Spinner } from "../../../components/common";
import { useToast } from "@chakra-ui/react";
import { ChevronLeft } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

const TwoFA = (props: any) => {
  const dispatch = useAuthDispatch();
  const [otp, setOtp] = useState("");
  const mutation = useMutation(submitOtp);
  const resendMutation = useMutation(resendOtp);
  const toast = useToast();

  const handleOtpChange = (value: any) => {
    setOtp(value);
  };

  const handleSubmit = () => {
    if (otp.length === 4) {
      mutation.mutate(
        {
          user: {
            email: props?.email,
            otp: otp,
          },
        },
        {
          onSuccess: (response) => {
            const authorizationHeader = response?.headers.get("Authorization");
            const token = authorizationHeader.split(" ")[1];
            dispatch({
              type: "LOGIN_SUCCESS",
              payload: response.data,
              token: token,
            });
            let data = response?.data?.status?.data?.user;

            // Append token to user data
            data.token = token;

            // Store the updated user data in localStorage
            localStorage.setItem("currentUser", JSON.stringify(data));
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.error ?? "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  };

  const handleResend = () => {
    resendMutation.mutate(
      {
        user: {
          email: props?.email,
        },
      },
      {
        onSuccess: () => {
          toast({
            description:
              "An OTP has been sent to your registered email/mobile number. Please check your inbox and enter the OTP to proceed.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: error?.response?.data?.error ?? "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <VStack
        w="31.25rem"
        borderRadius={8}
        bg="white"
        align="flex-start"
        alignSelf="center"
        spacing={4}
        m="0 auto"
      >
        <Text textStyle="h2">Enter OTP</Text>
        <Text textStyle="h4" color="grey.700">
          An OTP was sent to your registered email/phone number. Please enter it
          below
        </Text>
        <HStack>
          <PinInput type="number" value={otp} onChange={handleOtpChange}>
            <PinInputField onKeyDown={handleKeyDown} />
            <PinInputField onKeyDown={handleKeyDown} />
            <PinInputField onKeyDown={handleKeyDown} />
            <PinInputField onKeyDown={handleKeyDown} />
          </PinInput>
        </HStack>
        <HStack w="full" justify="space-between">
          <Link onClick={handleResend}>
            <Text textStyle="h6">Resend OTP</Text>
          </Link>
        </HStack>
        <Button
          size="xl"
          w="full"
          mt={4}
          onClick={handleSubmit}
          disabled={otp.length < 4}
        >
          Submit
        </Button>
        {(mutation.isLoading || resendMutation.isLoading) && <Spinner />}
      </VStack>
    </>
  );
};

export default TwoFA;
