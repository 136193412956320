import { Box, Button, HStack, Text } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PaginatedTable } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { humanize } from "../../../utils";
import {
  fetchCommunicationLogs,
  fetchSmsCommunicationLogs,
} from "../../../utils/api/announcements";

export default function CommunicationLog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const communicationTableHeader = [
    { id: 1, value: "First Name", label: "first_name" },
    { id: 2, value: "Last Name", label: "last_name" },
    {
      id: 3,
      value: type ? "Phone Number" : "Email",
      label: type ? "phone" : "email",
    },
    { id: 5, value: "Status", label: "status" },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["fetchCommunicationLogs", id, { page, per_page: perPage }, type],
    queryFn: type ? fetchSmsCommunicationLogs : fetchCommunicationLogs,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableData = useMemo(() => {
    if (type) {
      if (!data?.data?.messages?.length) {
        return [];
      }

      return data?.data?.messages.map((item: any) => ({
        ...item,
        status: item.status ? "Sent" : "Failed",
      }));
    } else {
      if (!data?.data?.emails?.length) {
        return [];
      }

      return data?.data?.emails.map((item: any) => ({
        ...item,
        status: humanize(item.status),
      }));
    }
  }, [data?.data?.emails, data?.data?.messages, type]);

  const totalCount = type
    ? data?.data?.messages_count
    : data?.data?.emails_count;

  return (
    <Box>
      {isLoading && <Loader />}
      <HStack padding="1rem" w="100%" background="white">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <Box background="white" borderRadius="0.5rem" m="1.5rem">
        <Text textStyle="h2" pl="1rem" pt="1.5rem">
          Communication Logs
        </Text>
        <Text
          textStyle="caption"
          color="var(--grey-600)"
          mb="1rem"
          pl="1rem"
          pt="1.5rem"
        >
          Found ({totalCount || 0})
        </Text>

        <PaginatedTable
          header={communicationTableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          isPaginated={true}
          hasMultiBody={false}
          lastCenter={false}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={totalCount || 0}
        />
      </Box>
    </Box>
  );
}
