import * as yup from "yup";
import { EMAIL_REGEX } from "../../../../config";

export type CreateTemplateForm = {
  subject: string;
  messageBody: string;
};

export const defaultValues = {
  subject: "",
  messageBody: "",
};

export const createTemplateValidation = yup.object().shape({
  subject: yup.string().required("Subject is required"),
  messageBody: yup.string().required("Body is required"),
});
