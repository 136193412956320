import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchVehicles = async (dId: any, cId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${cId}/vehicles`
  );
};
export const getUrlForDetailsInSMS = async (dId: any, cId: any, vId: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/contacts/${cId}/vehicles/${vId}/send_vehicle_details`
  );
};
export const getVehicleDetails = async (slug: any) => {
  return await axios.get(`${SERVICES.apiBaseUrl}/api/vehicle_details/${slug}`);
};
export const fetchVehicleById = async (payload: any) => {
  return await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/vehicles/${payload.vId}`
  );
};
export const createVehicle = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/vehicles`,
    payload.payload
  );
};
export const updateVehicle = async (payload: any) => {
  return await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/vehicles/${payload.vId}`,
    payload.payload
  );
};
export const deleteVehicle = async (payload: any) => {
  return await axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/contacts/${payload.id}/vehicles/${payload.vId}`
  );
};
