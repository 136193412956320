import React, { Dispatch, SetStateAction } from "react";
import Activity from "../Activity/Activity";
import { humanize } from "../../../../../../utils";
import moment from "moment";
import { InboundCallIcon, OutboundCallIcon } from "../../../../../icons/svg";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { StartMeetingIcon } from "../../../../../icons/svg";

type SingleMeetingCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
};

function SingleMeetingCard({
  item,
  setClickedRecord,
  clickedRow,
}: SingleMeetingCardProps) {
  console.log(clickedRow)
  return (
    <Activity
      key={item.id}
      id={item.id}
      // handleClick={() => setClickedRecord(item)}
      title={moment(item?.created_at).format("dddd DD MMM YY • hh:mm a")}
      icon={<StartMeetingIcon />}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created By:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.created_by?.name}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created For:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {clickedRow?.first_name + " " + clickedRow?.last_name}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Notes:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.notes}
              </Text>
            </HStack>
          </VStack>
        </>
      }
    />
  );
}

export default SingleMeetingCard;
