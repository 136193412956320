import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { EMAIL_REGEX, NAME_REGEX } from "../../config";
import { getVehicles } from "../../utils/api/inventory-mapping.api";
import { fetchDealershipUserById } from "../../utils/api/users.api";

export type CreateLeadForm = {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  sales_person_id: string;
  manager_id: string;
  bdc_agent_id: string;
  leads_attributes: {
    new_lead_pool: boolean;
    lead_source: string;
    campaign_id: string;
    dealership_id: string;
    vehicle_attributes?: {
      make: string;
      model: string;
      year: string;
      exterior_color: string;
      interior_color: string;
      mileage: string;
      inventory_id: string;
    };
  };
  contacts_tags_attributes: string[];
};

export const defaultValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  sales_person_id: "",
  manager_id: "",
  bdc_agent_id: "",
  leads_attributes: {
    new_lead_pool: false,
    lead_source: "",
    campaign_id: "",
    dealership_id: "",
    vehicle_attributes: {
      make: "",
      model: "",
      year: "",
      exterior_color: "",
      interior_color: "",
      mileage: "",
      inventory_id: "",
    },
  },
  contacts_tags_attributes: [],
};

export const createLeadValidation = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid First Name")
    .required("First Name is required."),
  last_name: yup
    .string()
    .nullable()
    .matches(NAME_REGEX, "Invalid Last Name")
    .required("Last Name is required."),
  email: yup.string().nullable().matches(EMAIL_REGEX, "Invalid Email Address"),
  phone_number: yup
    .string()
    .nullable()
    .required("Phone Number is required")
    .test("phone-number''", "Invalid Phone Number.", function (value) {
      return value ? isValidPhoneNumber(value) : false;
    }),
  leads_attributes: yup.object().shape({
    vehicle_attributes: yup.object().shape({
      make: yup
        .string()
        .test("oneOfRequired", "Make is required.", function (value: any) {
          return value ? true : !(this.parent.model || this.parent.year);
        }),
      model: yup
        .string()
        .test("oneOfRequired", "Model is required.", function (value: any) {
          return value ? true : !(this.parent.make || this.parent.year);
        }),
      year: yup
        .string()
        .test("oneOfRequired", "Year is required.", function (value: any) {
          return value ? true : !(this.parent.make || this.parent.model);
        }),
    }),
  }),
});

export const loadVehicles = async (searchStr: string, id?: string) => {
  try {
    const response = await getVehicles({ searchStr, dealershipId: id });
    const options = response?.data?.map((vehicle: any) => ({
      value: vehicle.id,
      label: `${vehicle.year} - ${vehicle.make} - ${vehicle.model}`,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadDealershipUsers = async (
  inputValue: string,
  role: string,
  id: string
) => {
  try {
    const response = await fetchDealershipUserById({
      role_titles: role,
      status: "active",
      searchValue: inputValue,
      id,
      page: 1,
      per_page: 10,
    });
    const options = response?.users?.map((user: any) => {
      return {
        value: user.id,
        label: user.full_name,
      };
    });
    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
