import React, { useState } from "react";
import { Box, Input, Text, VStack, useToast } from "@chakra-ui/react";
import { UploadFileIcon, UploadMediaIcon } from "../icons/svg";

const validFileTypes = ["audio/mpeg", "audio/wav"];
const maxFileSize = 8 * 1024 * 1024; // 8MB

interface AudioPlayerProps {
  transcribed: boolean | undefined;
  handleTranscribed: () => void;
  handleAudioData: (src: string) => void;
  variant?: string;
  isDisabled?: boolean;
}

const FileUploader = ({
  transcribed,
  handleTranscribed,
  handleAudioData,
  variant = "default",
  isDisabled
}: AudioPlayerProps) => {
  const toast = useToast();

  const handleFileChange = (event: ChangeEvent) => {
    const file = event.target.files[0];
    const fileInput = event.target;

    if (file) {
      if (validFileTypes.includes(file.type) && file.size <= maxFileSize) {
        const fileSrc = URL.createObjectURL(file);
        handleAudioData(fileSrc);
        handleTranscribed();
      } else {
        toast({
          title: "Invalid File",
          description: "Please upload a valid MP3 or WAV file less than 8MB.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    fileInput.value = ''; // This clears the input value

  };

  // const handleUpload = () => {
  //   if (selectedFile) {
  //     console.log("Uploading file:", selectedFile);
  //   } else {
  //     toast({
  //       title: "No File Selected",
  //       description: "Please select a valid MP3 or WAV file before uploading.",
  //       status: "warning",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  return (
    <VStack
      position="relative"
      width="100%"
      gap="1rem"
      textAlign="center"
      height={variant === "default" ? "100%" : "auto"}
      padding={variant === "default" ? "1rem" : "0"}
    >
      <Input
        type="file"
        accept=".mp3,.wav"
        onChange={handleFileChange}
        variant="file"
        isDisabled={
          variant === "default" ? (transcribed || isDisabled) : false
        }
      />
      {variant === "default" && (
        <Text textStyle="h6" fontWeight="500">
          Drop file here or click to upload
        </Text>
      )}
      {variant === "default" ? (
        <Box
          as="span"
          w="5.5rem"
          h="5.5rem"
          border="1px dashed var(--grey-300)"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <UploadFileIcon />
        </Box>
      ) : (
        <>
          <Box
            as="span"
            padding="1.2rem"
            background="var(--grey-200)"
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <UploadMediaIcon />
          </Box>
          <Text textStyle="h6" fontWeight="400" color="var(--grey-900)">
            Drop file here or click to upload
          </Text>
          <Box
            as="span"
            borderRadius="0.5rem"
            background="var(--primary-600)"
            padding="0 18px"
            height="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            fontSize="1rem"
            fontWeight="500"
          >
            Upload Here
          </Box>
        </>
      )}

      {variant === "default" && (
        <Text textStyle="h6" fontWeight="400" color="var(--grey-700)">
          MP3/WAV file (less than 8MB)
        </Text>
      )}
    </VStack>
  );
};

export default FileUploader;
