import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { textToSpeech } from "../../utils/api/users.api";
import { Input } from "../common";
import AudioPlayer from "../common/AudioPlayer";
import FileUploader from "../common/FileUploader";
import VoiceRecorder from "../common/VoiceRecorder";
import { InsertVariableDrawer } from "../Drawers/InsertVariableDrawer";

interface AudioSettingsBoxProps {
  input: string;
  setInput: any;
  defaultIndex: number;
  setSelectedTab?: any;
  transcribed?: any;
  setTranscribed?: any;
  audioData?: any;
  setAudioData?: any;
}

const AudioSettingsBox = (props: AudioSettingsBoxProps) => {
  const {
    input,
    setInput,
    defaultIndex,
    setSelectedTab,
    transcribed,
    setTranscribed,
    audioData,
    setAudioData,
  } = props;
  const [openInsertVariable, setOpenInsertVariable] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultIndex);

  const handleVariableSelect = (variable: any) => {
    setInput((prev: any) => `${prev} {{${variable?.name}}}`);
  };
  const handleTabChange = (index: any) => {
    setActiveTab(index);
    setSelectedTab?.(index);
  };

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await textToSpeech(payload.text, "Personal Voicemail");
        return response;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleTranscribe = async () => {
    const res = await mutation.mutateAsync({
      text: input,
    });

    setTranscribed(true);
    setAudioData(res.url);
  };
  return (
    <Tabs
      variant="primary"
      defaultIndex={defaultIndex}
      onChange={handleTabChange}
    >
      {mutation.isLoading}
      <TabList mb="1rem" background="white">
        <Tab>Text To Speech</Tab>
        <Tab>Play Recording</Tab>
      </TabList>

      <TabPanels>
        <TabPanel mb="1rem">
          {activeTab === 0 && (
            <>
              <Input
                type="text"
                placeholder="Write here.."
                onChange={(e) => setInput(e.target.value)}
                value={input}
                mb="10px"
                isDisabled={transcribed}
              />
              {!openInsertVariable && (
                <Button
                  onClick={() => setOpenInsertVariable(true)}
                  isDisabled={transcribed}
                >
                  Add Variable
                </Button>
              )}
              <Button
                marginTop="1rem"
                marginBottom="1rem"
                width={"100%"}
                variant="outlineBlue"
                isDisabled={!input}
                onClick={() => handleTranscribe()}
              >
                Transcribe
              </Button>
              {openInsertVariable && (
                <InsertVariableDrawer
                  isOpen={openInsertVariable}
                  isOnRight={true}
                  onClose={() => setOpenInsertVariable(false)}
                  onVariableSelect={handleVariableSelect}
                />
              )}

              {audioData && transcribed && (
                <AudioPlayer
                  audioSrc={audioData}
                  transcribed={transcribed}
                  handleTranscribed={() => {
                    setTranscribed(false);
                    setAudioData("");
                  }}
                />
              )}
            </>
          )}
        </TabPanel>
        <TabPanel>
          {activeTab === 1 && (
            <>
              <Box
                mb="1rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
              >
                <VoiceRecorder
                  transcribed={transcribed}
                  handleTranscribed={() => setTranscribed(true)}
                  handleAudioData={(src: string) => setAudioData(src)}
                />
                <FileUploader
                  transcribed={transcribed}
                  handleTranscribed={() => {
                    setTranscribed(true);
                  }}
                  handleAudioData={(src: string) => {
                    setAudioData(src);
                  }}
                />
              </Box>
              {audioData && transcribed && (
                <AudioPlayer
                  audioSrc={audioData}
                  transcribed={transcribed}
                  handleTranscribed={() => {
                    setTranscribed(false);
                    setAudioData("");
                  }}
                />
              )}
            </>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AudioSettingsBox;
