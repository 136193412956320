import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { Drawer, Input } from "../../../components/common";
import { fetchTagById, updateTag } from "../../../utils/api/tags";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { CreateTagForm, createTagValidation, defaultValues } from "./utils";

const EditTag = (props: any) => {
  const { onClose, id } = props;
  const toast = useToast();
  const dealerId = useUserInfo("dealership");

  const { handleSubmit, control, reset } = useForm<CreateTagForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(createTagValidation) as any,
  });
  const { isLoading } = useQuery({
    queryKey: ["fetchTagById"],
    queryFn: () => fetchTagById(id, dealerId?.id),
    retry: false,
    onSuccess: (data) => {
      reset(data);
    },
  });
  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateTag(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      onClose();
      toast({
        description: "Edited successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error editing tags: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const handleFormSubmit = (values: CreateTagForm) => {
    const requestData = {
      dId: dealerId?.id,
      id,
      payload: {
        tag: values,
      },
    };

    mutation.mutate(requestData);
  };
  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      isLoading={mutation?.isLoading || isLoading}
      title="Edit Tag"
    >
      <Box padding="1.25rem">
        <Box mb="1rem">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Enter Tag Name"
                  label="Name"
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="name"
            control={control}
          />
        </Box>

        <Box mb="1rem">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Write here..."
                  label="Description (Optional)"
                  isTextarea={true}
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
            name="description"
            control={control}
          />
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Confirm
          </Button>
        </DrawerFooter>
      </Box>
    </Drawer>
  );
};
export default EditTag;
