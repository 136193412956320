import { Button, DrawerFooter, VStack, useToast } from "@chakra-ui/react";
import { Drawer, Input } from "../../common";
import { useEffect, useState } from "react";
import {
  fetchVariables,
  updateVariables,
} from "../../../utils/api/sms-templates.api";
import { useMutation, useQuery } from "@tanstack/react-query";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  variables: Array<{
    id: number;
    name: string;
    field: string;
  }>;
}
const UpdateMapping = (props: data) => {
  const toast = useToast();
  const { isOpen, onClose, refetchParent } = props;
  const [variables, setVariables] = useState([
    {
      id: 0,
      name: "",
      field: "",
    },
  ]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { data, isLoading } = useQuery({
    queryKey: ["fetchVariablesUpdateMapping"],
    queryFn: () => fetchVariables(),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setVariables(data?.variables);
    },
  });

  const handleInputChange = (field: string, value: string) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    setVariables((prev) => {
      const updatedValue = prev.map((variable) =>
        variable.field === field ? { ...variable, name: value } : variable
      );
      return updatedValue;
    });
  };

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateVariables(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Mapping updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error updating mapping: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const isSpecialCharacter = (value: string): boolean => {
    const regex = /^[a-zA-Z0-9_]+$/;
    return !regex.test(value);
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const fieldErrors: { [key: string]: string } = {};

    const hasSpecialCharacters = variables.some((variable) => {
      if (variable.name?.trim() && isSpecialCharacter(variable.name?.trim())) {
        fieldErrors[variable.field] =
          "Variable name can only contain letters, numbers, and underscores";
        return true;
      }
      return false;
    });

    if (hasSpecialCharacters) {
      setErrors(fieldErrors);
      return;
    }

    const requestData: ApiRequest = {
      variables: variables,
    };

    mutation.mutate(requestData);
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Update mapping"
      isLoading={isLoading || mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        {variables?.map((variable: any) => (
          <Input
            placeholder="Enter Variable Name"
            label={variable.field}
            value={variable.name}
            onChange={(e) => handleInputChange(variable.field, e.target.value)}
            error={errors[variable.field]}
          />
        ))}
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default UpdateMapping;
