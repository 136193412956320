import { VStack } from "@chakra-ui/react";
import { useState } from "react";

import HeatMapPage from "../../heatmap/page";
import BottomPane from "./BottomPane";
import MiddlePane from "./MiddlePane";
import TopPane from "./TopPane";

export default function DealershipAnalytics() {
  const [isback, setIsback] = useState(true);
  const [dealershipCom, setDealershipCom] = useState<SelectOptionProps>();
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [dealerships, setDealerships] = useState<SelectOptionProps>();

  const [isGlobalView, setIsGlobalView] = useState<boolean>(false);
  const [selectionRangeMap, setSelectionRangeMap] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [selectionRespTime, setSelectionRespTime] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });

  if (isback) {
    return (
      <VStack
        alignItems="flex-start"
        gap="1rem"
        padding="1.5rem 1rem"
        h="calc(100vh - 172px)"
        overflow="auto"
      >
        <TopPane />
        <MiddlePane
          setIsback={setIsback}
          dealershipCom={dealershipCom}
          setDealershipCom={setDealershipCom}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          dealerships={dealerships}
          setDealerships={setDealerships}
          selectionRespTime={selectionRespTime}
          setSelectionRespTime={setSelectionRespTime}
        />
        <BottomPane
          isGlobalView={isGlobalView}
          setIsGlobalView={setIsGlobalView}
          selectionRange={selectionRangeMap}
          setSelectionRange={setSelectionRangeMap}
        />
      </VStack>
    );
  } else {
    return (
      <HeatMapPage
        setIsback={setIsback}
        dealershipCom={dealershipCom}
        setDealershipCom={setDealershipCom}
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    );
  }
}
