import {
  Button,
  HStack,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AppsIcon,
  ExpandIcon,
  IntegrationsIcon,
  MsgIcon,
  PublicAPIIcon,
  TelephoneyIcon,
  WebIcon,
} from "../../../../components/icons/svg";
import sendGrid from "../../../../assets/images/sendGrid.png";
import { fetchSystemDownTime } from "../../../../utils/api/global-dashboard";
import AppStatus from "./AppStatus";

interface SystemHealthItem {
  id: number;
  name: string;
  icon: JSX.Element;
  color: string;
  isAccordion: boolean;
  uptime: number;
  data: boolean[];
}

type BaseSystemHealthData = Omit<
  SystemHealthItem,
  "uptime" | "data" | "isAccordion"
>;

const baseSystemHealthData: Record<string, BaseSystemHealthData> = {
  Twilio: {
    id: 1,
    name: "Twilio",
    icon: <AppsIcon />,
    color: "var(--primary-600)",
  },
  "Cdk dms": {
    id: 7,
    name: "CDK/DMS",
    icon: <TelephoneyIcon />,

    color: "var(--secondary-600)",
  },
  Elead: {
    id: 3,
    name: "ELead",
    icon: <MsgIcon />,
    color: "var(--yellow-900)",
  },
  "Vin solution": {
    id: 4,
    name: "VinSolution",
    icon: <IntegrationsIcon />,
    color: "#CB3438",
  },
  "Dealer socket": {
    id: 5,
    name: "Dealer Socket",
    icon: <PublicAPIIcon />,
    color: "#685BBC",
  },
  "Dealer vault": {
    id: 6,
    name: "Dealer Vault",
    icon: <WebIcon />,
    color: "#F76D8A",
  },
  "Send grid": {
    id: 2,
    name: "Send Grid",
    // icon: <Grid4x4Icon sx={{ color: "#fff" }} />,
    icon: <img src={sendGrid} alt="" width={24} height={24} />,

    color: "#f1eded",
  },
};

const UptimeGraph = (props: any) => {
  const { setIsBack, selectedMonth, setSelectedMonth } = props;
  const [systemHealthData, setSystemHealthData] = useState<SystemHealthItem[]>(
    []
  );

  const { isLoading: isLoadingDowntime } = useQuery({
    queryKey: ["fetchSystemDownTime", selectedMonth],
    queryFn: fetchSystemDownTime,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setSystemHealthData(
        data?.data
          ?.filter((item: any) => baseSystemHealthData[item?.service_name])
          .map((item: any) => ({
            ...baseSystemHealthData[item?.service_name],
            data: item?.down_time
              ? item?.down_time?.map((item: number) => (item ? true : false))
              : [],
            uptime: item?.uptime_percentage,
            details: item?.details,
            selectedMonth: selectedMonth,
            isAccordion: false,
          }))
          ?.sort((a: any, b: any) => a.id - b.id)
      );
    },
  });

  return (
    <VStack
      w={setIsBack ? "50%" : "100%"}
      borderRadius="0.5rem"
      border="1px solid var(--grey-300)"
      padding="1rem"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        paddingBottom="1rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <SkeletonText isLoaded={!isLoadingDowntime}>
          <Text textStyle="h5" fontWeight="700">
            Uptime
          </Text>
        </SkeletonText>
        <HStack className="singleDatePicker">
          <DatePicker
            selected={selectedMonth}
            onChange={(date: Date | null) => {
              setSelectedMonth(date);
            }}
            dateFormat="MMMM YYYY"
            showMonthYearPicker
            maxDate={new Date()}
          />

          {setIsBack && (
            <Button variant="none" onClick={() => setIsBack(true)}>
              <ExpandIcon />
            </Button>
          )}
        </HStack>
      </HStack>
      <VStack alignItems="flex-start" w="100%">
        {isLoadingDowntime ? (
          <>
            <Skeleton w="100%" h="40px" />
            <Skeleton w="100%" h="40px" />
            <Skeleton w="100%" h="40px" />
            <Skeleton w="100%" h="40px" />
            <Skeleton w="100%" h="40px" />
            <Skeleton w="100%" h="40px" />
          </>
        ) : (
          systemHealthData?.length > 0 &&
          systemHealthData?.map((item: SystemHealthItem) => (
            <AppStatus key={item.id} status={item} />
          ))
        )}
      </VStack>
    </VStack>
  );
};

export default UptimeGraph;
