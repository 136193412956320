import { VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { memo, useState } from "react";
import { Drawer, Input } from "../../../../components/common";
import InfiniteList from "../../../../components/common/InfiniteList";
import { fetchIpDetail } from "../../../../utils/api/global-dashboard";

interface UsersDrawerProps {
  state: string | null;
  country: string | null;
  page: number;
  onClose: () => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectionRange?: {
    selected?: boolean;
    startDate?: Date;
    endDate?: Date;
  };
}
type DataItem = {
  id: string;
  ip_address: string;
  user_name: string;
  dealership_name: string;
};
const UsersDrawer: React.FC<UsersDrawerProps> = ({
  state,
  country,
  page,
  onClose,
  setPage,
  selectionRange,
}) => {
  const [ipListing, setIpListing] = useState<DataItem[]>([]);
  const [searchValue, setSearchValue] = useState<any>("");

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchIpDetail",
      country,
      state,
      page,
      12,
      selectionRange?.startDate,
      selectionRange?.endDate,
      searchValue,
    ],
    queryFn: fetchIpDetail,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setIpListing((prev) =>
        page === 1 ? data?.data : [...prev, ...data?.data]
      );
    },
  });

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title="Users IP Listing"
      isLoading={isLoading}
    >
      <VStack alignItems="flex-start" justifyContent="flex-start" margin="1rem">
        <Input
          placeholder="Search"
          label="Search"
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
          value={searchValue}
          width="100%"
        />
        <div
          id="scrollableDiv"
          style={{
            height: "calc(100vh - 0px)",
            overflow: "auto",
            width: "100%",
          }}
        >
          <InfiniteList
            setPage={setPage}
            data={ipListing}
            count={data?.data?.count}
          />
        </div>
      </VStack>
    </Drawer>
  );
};

export default memo(UsersDrawer);
