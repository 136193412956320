import {
  Box,
  Text
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export default function VideoViewerPage() {
  const { url } = useParams();
  const videoUrl = decodeURIComponent(url || '');

  if (!url) {
    return <>No Video Found</>;
  }

  return (
    <Box
      w="100vw"
      h="100vh"
      position="relative"
      bg="black"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* Header */}
      <Text
        position="absolute"
        top="20px"
        left="20px"
        fontSize="2xl"
        color="white"
        fontWeight="bold"
        zIndex="10"
      >
        Video
      </Text>

      {/* Video Player */}
      <video
        width="100%"
        height="100%"
        controls
        autoPlay
        style={{ objectFit: "contain" }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
