import { useQuery } from "@tanstack/react-query";
import { fetchLeads } from "../../../../../utils/api/contact-lead";
import { HStack, VStack, Text } from "@chakra-ui/layout";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../common/Spinner";
import NoItem from "../History/NoItem";
import { useState } from "react";
import SingleLeadCard from "./SingleLeadCard";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import LeadDetailPage from "./LeadDetailPage";

const Leads = ({ clickedRow }: any) => {
  const [page, setPage] = useState(1);
  const dId = useUserInfo("dealership")?.id;
  const [count, setCount] = useState(0);
  const [leads, setLeads] = useState<any[]>([]);

  const [clickedRecord, setClickedRecord] = useState();

  const { isFetching } = useQuery({
    queryKey: ["fetchLeads"],
    queryFn: () => fetchLeads(dId, clickedRow, { page }),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setLeads(data?.data?.leads);
      setCount(data?.data?.leads_count);
    },
  });

  if (clickedRecord) {
    return (
      <LeadDetailPage
        clickedRecord={clickedRecord}
        setClickedRecord={setClickedRecord}
        id={clickedRow}
      ></LeadDetailPage>
    );
  }
  return (
    <>
      {isFetching && <Loader />}
      {leads && leads?.length > 0 ? (
        <div
          id="scrollableDiv"
          style={{ height: "calc(100vh - 120px)", overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={leads.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={leads.length < count}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <VStack
              alignItems="flex-start"
              gap="1rem"
              padding="1rem"
              background="var(--grey-200)"
            >
              <VStack
                h="100%"
                background="var(--grey-50)"
                padding="1rem"
                gap="1rem"
                alignItems="flex-end"
                w="100%"
                borderTopRightRadius="1rem"
              >
                <VStack
                  background="white"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  padding="1rem"
                  alignItems="flex-start"
                  gap="0"
                  h="calc(100vh - 130px)"
                  overflow="auto"
                  w="100%"
                >
                  <HStack w="100%" justifyContent="space-between" pb="1rem">
                    <Text textStyle="h4" fontWeight="500">
                      Leads
                    </Text>
                  </HStack>
                  <VStack w="100%" mb="10px">
                    {leads?.map((item: any, index: number) => (
                      <SingleLeadCard
                        item={item}
                        setClickedRecord={setClickedRecord}
                        index={index}
                      />
                    ))}
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
          </InfiniteScroll>
        </div>
      ) : (
        <NoItem />
      )}
    </>
  );
};

export default Leads;
