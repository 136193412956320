import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import IncomingCall from "../../components/IncomingCall/IncomingCall";
import IncomingCallWidget from "../../components/IncomingCallWidget/IncomingCallWidget";
import { Content, Sidebar } from "../../components/common";
import { HistoryProvider } from "../../utils/context/HistoryContext";
import {
  TwilioProvider
} from "../../utils/context/TwilioContext";
import ScriptModal from "../../components/ScriptModal";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { roleChecker } from "../../utils";
import useIntercomScript from "../../utils/useIntercom";
import { BreadcrumbProvider } from "../../utils/context/BreadCrumsContext/BreadcrumbContext";


const LayoutClient = ({ children }: ReactChildren) => {
  const [list, setList] = useState<any>();
  const location = useLocation();
  const token = useUserInfo("token");
  const role = useUserInfo("role");
  const user = useUserInfo("user");
  const dealership = useUserInfo("dealership");

  useEffect(() => {
    if (location.pathname !== `/leads/lead-details`) {
      localStorage.removeItem("search_and_filter");
    }
  }, [location.pathname]);
  const permissionSetter = (data: any) => {
    setList(data);
  };
  const appId = 'v0z6q502'; // Replace with your Intercom App ID

  useIntercomScript(appId);
  useEffect(() => {
    if (window.Intercom && token && role && !roleChecker(role)) {
      window.Intercom('update', {
        app_id: appId,
        user_id: user.id,
        name: `${user.full_name} (${dealership?.name})`,
        email: user.email,
      });
    } else {
      window.Intercom && window.Intercom('shutdown');
    }

    return () => {
      window.Intercom && window.Intercom('shutdown');
    };
  }, [token, role, user, appId]);
  return (
    // <ToggleProvider>
    <TwilioProvider>
      <HistoryProvider>
      <BreadcrumbProvider>
        <Flex className="dashboard-layout" height="full">
          {location.pathname !== "/vehicle" &&
            location.pathname !== "/email-campaign" && (
              <Sidebar permissionSetter={permissionSetter} />
            )}

          <Content list={list}>{children}</Content>
          <IncomingCall />
          <IncomingCallWidget />
          <ScriptModal />
        </Flex>
        </BreadcrumbProvider>
      </HistoryProvider>
    </TwilioProvider>
    // </ToggleProvider>
  );
};

export default LayoutClient;
