import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import {
  idleTimeTracking,
  productiveTimeTracking,
} from "../../utils/api/user-tracking";
import { useUserInfo } from "../../utils/hooks/useUserInfo";

export const IdleTracking: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isIdleRef = useRef<boolean>(false); // Track whether the user is idle
  const user = useUserInfo("user");

  const mutation = useMutation(productiveTimeTracking);
  const mutationIdle = useMutation(idleTimeTracking);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear any existing timer
    }

    // If the user was idle, mark them as productive
    if (isIdleRef.current) {
      isIdleRef.current = false; // Reset idle status immediately
      mutation.mutate(
        {
          userId: user?.id,
          dealershipId: user?.dealership?.id,
        },
        {
          onError: (error) =>
            console.error("Error in productive time API:", error),
        }
      );
    }

    // Set a new timer for idle time tracking
    timerRef.current = setTimeout(() => {
      // When the timer expires, mark the user as idle if they are not already idle
      if (!isIdleRef.current) {
        isIdleRef.current = true; // Set idle status
        mutationIdle.mutate(
          {
            userId: user?.id,
            dealershipId: user?.dealership?.id,
          },
          {
            onError: (error) => console.error("Error in idle time API:", error),
          }
        );
      }
    }, 5 * 1000); // 5 seconds for testing purposes
  };

  useEffect(() => {
    if (user?.dealership?.id) {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);

      return () => {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
        if (timerRef.current) {
          clearTimeout(timerRef.current); // Clean up on unmount
        }
      };
    }
  }, [user?.dealership?.id]);

  return <>{children}</>;
};
