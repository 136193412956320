import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//..................Keywords..............
export const fetchKeywords = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/keywords`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateKeyword = async (id: any, payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/keywords/${id}`,
      payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Keywords",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteKeyword = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/keywords/${id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Keywords",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createKeyword = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/keywords`,
      payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Keywords",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchKeywordById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/keywords/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
//..................Inventory Feed Provider..............
export const fetchInventoryFeedProvider = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateInventoryFeedProvider = async (id: any, payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`,
      payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Inventory Feed Providers",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteInventoryFeedProvider = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Inventory Feed Provider",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createInventoryFeedProvider = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers`,
      payload,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Inventory Feed Providers",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchInventoryFeedProviderById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
//..................Load Souce..............
export const fetchLeadSource = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateLeadSource = async (id: any, payload: any, dId: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteLeadSource = async (id: any, dId: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createLeadSource = async (payload: any, dId: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          "Dealership": dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchLeadSourceById = async (id: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchTags = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/tags`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
