"use client";
import { Box, Text, VStack } from "@chakra-ui/react";
import { geoCentroid } from "d3-geo";
import { memo, useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import world from "../../assets/world.json";

const WorldMap = (props: any) => {
  const { count, onOpen, setCountry } = props;
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [number, setNumber] = useState<number>();
  const [maxCount, setMaxCount] = useState<number>(0);

  const getCount = (name: string) =>
    count?.hasOwnProperty(name) ? count[name] : 0;

  useEffect(() => {
    if (count) {
      const counts: number[] = Object.values(count) as number[];
      setMaxCount(Math.max(...counts));
    }
  }, [count]);

  const getRelativeFillColor = (count: number, maxCount: number): string => {
    if (count === 0) return "var(--primary-100)";
    const scale = count / maxCount;
    if (scale > 0.75) return "var(--primary-900)";
    if (scale > 0.5) return "var(--primary-700)";
    if (scale > 0.25) return "var(--primary-500)";
    return "var(--primary-300)";
  };

  return (
    <Box
      sx={{
        "path.rsm-geography:focus": {
          outline: "none",
        },
      }}
    >
      <ComposableMap>
        <Geographies geography={world}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const geoCount = getCount(geo.properties.name);
                return (
                  <Geography
                    key={geo.rsmKey}
                    onMouseOver={() => {
                      setTooltipContent(`${geo.properties.name}`);
                      setNumber(getCount(`${geo.properties.name}`));
                      setId(`#${geo.rsmKey}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                      setNumber(undefined);
                      setId("");
                    }}
                    style={{
                      hover: {
                        fill: "#212121",
                        stroke: "#829DBA",
                      },
                      pressed: {
                        fill: "#F53",
                        outline: "none",
                      },
                    }}
                    id={geo.rsmKey}
                    geography={geo}
                    fill={getRelativeFillColor(geoCount, maxCount)}
                    onClick={() => {
                      setCountry(geo.properties.name);
                      onOpen();
                    }}
                  />
                );
              })}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const countryName = geo.id;
                return (
                  <Marker key={geo.rsmKey} coordinates={centroid}>
                    <text
                      textAnchor="center"
                      fontSize="0.5rem"
                      fontWeight="600"
                      style={{ fill: "#fff", textAlign: "center" }}
                    >
                      {countryName}
                    </text>
                  </Marker>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
      <Tooltip
        anchorSelect={id}
        style={{
          backgroundColor: "var(--bluegray-900)",
          width: "7rem",
          padding: "0.25rem 0.625rem !important",
          borderRadius: "0.5rem",
        }}
      >
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="captionSmall" color="#E0E0E0" fontWeight="500">
            {tooltipContent}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {number}
          </Text>
        </VStack>
      </Tooltip>
    </Box>
  );
};

export default memo(WorldMap);
