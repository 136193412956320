import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Drawer, Input } from "../../../components/common";
import {
  createDMS,
  editDMS,
  fetchContacts,
} from "../../../utils/api/dms-equity-mining";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import ContactListing from "../leads/lead-details/ContactListing";
import { SearchIcon } from "../../../components/icons/svg";

export const ContactsDrawer = (props: any) => {
  const {
    onClose,
    refetch,
    id = null,
    isContactsOpen,
    onContactsClose,
    formData,
    category,
    attachmentUrls,
    isSms,
  } = props;

  const [searchStr, setSearchStr] = useState("");

  const toast = useToast();
  const dId = useUserInfo("dealership")?.id;
  const [page, setPage] = useState(1);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);

  const mutation = useMutation(createDMS);
  const mutationEdit = useMutation(editDMS);

  const getFilterData = () => {
    const { name, content, line_id, subject, ...rest } = formData;
    return rest;
  };
  const { data: contactsData, isLoading } = useQuery({
    queryKey: [
      "fetchContacts",
      dId,
      {
        page,
        per_page: 12,
        filter_and_sort: getFilterData(),
        q: searchStr
      },
    ],
    queryFn: fetchContacts,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isContactsOpen,
    onSuccess(data) {
      if (data?.data?.contacts && Array.isArray(data.data.contacts)) {
        setContacts((prev: any) => [...prev, ...data.data.contacts]);
      } else {
        console.error("Unexpected data structure:", data);
      }
    },
  });

  const onSubmit = (type: "save" | "email" | "sms") => {
    const mutationFunction = id ? mutationEdit.mutate : mutation.mutate;
    const { name, line_id, content, subject } = formData;

    mutationFunction(
      {
        dId,
        id,
        payload: {
          campaign_type: type,
          dms_equity_mining: {
            category,
            filter_and_sort: formData,
            contact_ids: removedContacts,
            name: name || null,
            line_id: line_id?.value || null,
            content: content || null,
            subject: subject || null,
            media_urls:
              attachmentUrls?.map((attachment: any) => attachment.url) || [],
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          onClose();
          toast({
            description: id
              ? "DMS Equity Mining edited successfully."
              : "DMS Equity Mining created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error ${
              id ? "updating" : "creating"
            } DMS Equity Mining: ${
              error?.response?.data?.message?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const disableButton =
    contacts?.length === 0 ||
    contactsData?.data?.contacts_count - removedContacts?.length === 0;
  return (
    <Drawer
      isOpen={isContactsOpen}
      onClose={onContactsClose}
      title="Contacts"
      isLoading={isLoading || mutation.isLoading || mutationEdit.isLoading}
    >
      <Box padding="0 20px" marginTop="10px">
        <Input
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Search Contacts..."
          onChange={({ target: { value } }) => {
            setSearchStr(value);
            setPage(1);
            setContacts([]);
          }}
          width="100%"
          value={searchStr}
        />
      </Box>
      <Box>
        <div
          id="contactsDiv"
          style={{ height: "calc(89vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={contactsData?.data?.contacts_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="contactsDiv"
          />
        </div>
      </Box>

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
        background="white"
      >
        <Button
          w="100%"
          size="xl"
          type="submit"
          onClick={() => onSubmit("save")}
          isDisabled={disableButton}
        >
          Save
        </Button>

        {isSms ? (
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={() => onSubmit("sms")}
            isDisabled={disableButton}
          >
            Broadcast Message Now
          </Button>
        ) : (
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={() => onSubmit("email")}
            isDisabled={disableButton}
          >
            Email Blast Now
          </Button>
        )}
      </DrawerFooter>
    </Drawer>
  );
};
