import axios from "axios";
import { SERVICES } from "../../config";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//..............provide key wrt the user setting DP..................
export const fetchUserSettingsDP = async (dId: any, id: any, key: string) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/settings/dealerships/${dId}/users/${id}/setting?key=${key}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
//..............provide key wrt the user setting in the payload DP..................
export const updateUserSettingsDP = async (payload: any, dId: any, id: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/settings/dealerships/${dId}/users/${id}/update_setting`,
      payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchNotificationSettingsGlobal = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/settings/user/${id}/setting?key=global_notifications`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateNotificationSettingsGlobal = async (
  payload: any,
  id: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/settings/user/${id}/update_setting`,
      payload
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

//...........inbound and outbound settings..................
export const fetchSettingsIO = async ({ queryKey }: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/users/${queryKey[2]}/voicemails?type=${queryKey[0]}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteVoicemail = async (id: any, params: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/dealerships/${params[0]}/users/${params[1]}/voicemails/${id}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const addVoicemail = async (params: any, payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${params[1]}/users/${params[2]}/voicemails?type=${params[0]}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
