import * as yup from "yup";
import { EMAIL_REGEX } from "../../../config";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../Drawers/SearchAndFilterDrawer/utils";

export type ComposeBlastForm = {
  subject: string;
  messageBody: string;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
  filterApplied: boolean;
  to: string;
  broadcast_date: string;
  broadcast_time: string;
  broadcast_type: string;
  cc: string[];
  throttling_rate: string;
};

export const defaultValues = {
  subject: "",
  messageBody: "",
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  filterApplied: false,
  to: "",
  broadcast_date: "",
  broadcast_time: "",
  broadcast_type: "send_now",
  cc: [],
  throttling_rate: "",
};

export const composeBlastValidation = (type: string) =>
  yup.object().shape({
    to: type
      ? yup.string()
      : yup
          .string()
          .nullable()
          .matches(EMAIL_REGEX, "Invalid Email Address")
          .required("Email is required"),
    subject: yup.string().required("Subject is required"),
    messageBody: yup.string().required("Body is required"),
    throttling_rate: !type
      ? yup.string()
      : yup.string().required("Throttling Type is required."),
  });
