import { Button, DrawerFooter, VStack } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { fetchNoteById, updateNote } from "../../../../../utils/api/notes";
import { Drawer, Input } from "../../../../common";
import { useState } from "react";

const EditNote = (props: any) => {
  const { info, onClose } = props;
  const toast = useToast();
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const { isLoading } = useQuery({
    queryKey: ["fetchNotesById", info],
    queryFn: () => fetchNoteById(info),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setNote(data?.data?.content);
    },
  });

  const mutation = useMutation(updateNote);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!note?.trim()) {
      setNoteError("Note cannot be empty");
      return;
    }

    setNoteError("");

    mutation.mutate(
      { ...info, payload: { content: note } },
      {
        onSuccess: () => {
          onClose()
          toast({
            description: "Note edited successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error editing note: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title="Edit Note"
      isLoading={mutation?.isLoading || isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Write Note"
          label="Enter Note"
          onChange={(e: any) => setNote(e.target.value)}
          value={note}
          isRequired={true}
          error={noteError}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default EditNote;
