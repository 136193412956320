"use client";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import Tags from "./Tags";
import DispositionSettings from "../../../components/AccountSettings/DispositionSettings/DispositionSettings";

export default function TagPage() {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };
  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          defaultIndex={0}
          onChange={handleTabChange}
        >
          <TabList borderTopRightRadius="0.5rem">
            <Tab w="15rem">Tags</Tab>
            <Tab w="15rem">Dispositions</Tab>
          </TabList>
          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              {activeTab === 0 && <Tags />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 1 && <DispositionSettings dp={true} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
