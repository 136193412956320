import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const createEmailTemplate = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_templates`,
    params.payload
  );

export const updateEmailTemplate = async (params: any): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_templates/${params.id}`,
    params.payload
  );

export const getDealershipTemplates = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${
      params.dealership_id
    }/email_templates?template_type=${params.type}&page=${
      params.page
    }&per_page=${params.per_page || 10}&search=${params.searchStr}`
  );

export const getDealershipTemplate = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_templates/${params.id}`
  );

export const duplicateTemplate = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_templates/${params.id}/duplicate`
  );

export const deleteTemplate = async (params: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealership_id}/email_templates/${params.id}`
  );

export const getGlobalTemplates = async (params: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/global_templates?page=${params.page}&per_page=${
      params.per_page || 10
    }&search=${params.searchStr}`
  );

export const getGlobalTemplate = async (params: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/global_template/${params.id}`);

export const duplicateGlobalTemplate = async (
  params: any
): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/global_template/${params.id}/duplicate`);

export const deleteGlobalTemplate = async (params: any): Promise<ApiResult> =>
  axios.delete(`${SERVICES.apiBaseUrl}/global_template/${params.id}`);

export const createGlobalEmailTemplate = async (
  params: any
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/global_template`,
    params.payload
  );

export const updateGlobalEmailTemplate = async (
  params: any
): Promise<ApiResult> =>
  axios.put(
    `${SERVICES.apiBaseUrl}/global_template/${params.id}`,
    params.payload
  );
