import { Dropdown, Input, Spinner } from "../../../../../components/common";
import { EditIcon } from "../../../../../components/icons/svg";
import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  createNote,
  deleteNote,
  fetchNotes,
} from "../../../../../utils/api/notes";
import EditNote from "./EditNote";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { convertDateContacts } from "../../../../../utils";
import ConfirmationDrawer from "../../../../common/ConfirmationDrawer";
import DOMPurify from 'dompurify';


const Notes = (props: any) => {
  const { id } = props;
  const dealer = useUserInfo("dealership");
  const [noteId, setNoteId] = useState();
  const [notesData, setNotesData] = useState([]);
  const [currentNote, setCurrentNote] = useState<string>("");
  const mutation = useMutation(createNote);
  const toast = useToast();

  const [activeModal, setActiveModal] = useState<string | null>(null);
  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  const { isFetching, refetch } = useQuery({
    queryKey: ["fetchNotes", id],
    queryFn: () => fetchNotes(dealer?.id, id),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setNotesData(data?.data);
    },
  });

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];

  const handleAddNote = (noteObj: string) => {
    setCurrentNote("");
    mutation.mutate(
      {
        dId: dealer?.id,
        id,
        payload: { note: { content: noteObj } },
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "Note added successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error adding note: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const mutationDelete = useMutation(deleteNote);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    mutationDelete.mutate(
      { id, dId: dealer?.id, noteId },
      {
        onSuccess: () => {
          refetch();
          setActiveModal(null);
          toast({
            description: "Note deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error deleting note: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="0"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Notes
          </Text>
        </HStack>
        <VStack
          mt="1rem"
          w="100%"
          alignItems="flex-start"
          h="calc(100% - 200px)"
        >
          {notesData?.length ? (
            <VStack
              alignItems="flex-start"
              w="100%"
              height="calc(100vh - 400px)"
              overflow="auto"
              gap="1rem"
            >
              {notesData?.map((item: any) => (
                <HStack
                  w="100%"
                  key={item.note}
                  padding="1rem"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  background="var(--grey-100)"
                  justifyContent="space-between"
                >
                  <VStack alignItems="flex-start">
                    <HStack alignItems="center">
                      <Box
                        w="0.5rem"
                        h="0.5rem"
                        background="var(--primary-600)"
                        borderRadius="50%"
                      ></Box>
                               <Text textStyle="h6" fontWeight="600" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}>
            {/* HTML content is injected here */}
          </Text>

                    </HStack>
                    <HStack ml="1rem">
                      <Text
                        textStyle="h6"
                        fontWeight="600"
                        color="var(--grey-600)"
                      >
                        Add by/on:
                      </Text>
                      <Text textStyle="h6" fontWeight="600">
                        {item?.added_by} •
                      </Text>
                      <Text textStyle="h6" fontWeight="600">
                        {convertDateContacts(
                          item?.created_at,
                          "DD MMM, h:mm a"
                        )}
                      </Text>
                    </HStack>
                  </VStack>
                  <Box
                    bg="white"
                    borderRadius="0.5rem"
                    border="1px solid var(--grey-300)"
                  >
                    <Dropdown
                      options={options}
                      onSelect={(option) => {
                        handleSelect(option);
                        setNoteId(item?.id);
                      }}
                    />
                  </Box>
                </HStack>
              ))}
            </VStack>
          ) : (
            <Text fontWeight="600">No note have been added!</Text>
          )}
          <VStack w="100%" alignItems="flex-start" gap="1rem" mt="auto">
            <Input
              isTextarea={true}
              placeholder="Enter Note Here"
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
            />
            <Button
              w="100%"
              onClick={() => {
                if (currentNote) {
                  handleAddNote(currentNote);
                }
              }}
            >
              Save
            </Button>
          </VStack>
        </VStack>
      </VStack>
      {activeModal === "delete" && (
        <ConfirmationDrawer
          isOpen={true}
          onClose={() => {
            refetch();
            setActiveModal(null);
          }}
          content={{
            name: "Delete Note",
            description: "Are you sure you want to delete this note?",
          }}
          apiCall={handleSubmit}
          loading={mutationDelete?.isLoading}
        />
      )}
      {activeModal === "edit" && (
        <EditNote
          onClose={() => {
            refetch();
            setActiveModal(null);
          }}
          info={{ id, dId: dealer?.id, noteId }}
        />
      )}
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default Notes;
